import React, { Component } from "react";
import { MDBCloseIcon } from "mdbreact";
import TenXTen from "../../Assets/images/Mr.-10.gif";
import TenXTenWalking from "../../Assets/images/Mr.10-Walking.gif";
import ProfTenTalking from "../../Assets/images/Prof_ten_talk.gif";
import { MDBIcon } from "mdbreact";
import "../commonStyles.css";
import right from "../../Assets/images/right.png";
import left from "../../Assets/images/left.png";
import $ from "jquery";

export default class tenXten extends Component {
  constructor(props) {
    super(props);
    this.dialogueRef = React.createRef();
    this.state = {
      animation: false,
      peeping: true,
      walking: false,
      showDialogue: false,
      talking: false,
      returning: false,
      defaultImage: TenXTen,
      walkingImage: TenXTenWalking,
      talkingImage: ProfTenTalking,
      firstDialogue: `Hello There!!! My name is Professor TenTen...`,
      secondDialogue: `Welcome to 10x10 Learning.com - your online partner in Education,
      Knowledge and Information`,
      thirdDialogue: `Click around me to continue our discussion or if you prefer to
      start exploring on your own, click the cross button`,
      activeDialogue: "",
      dialogues: props.dialogues,
      dialogueIndex: 0,
    };
  }

  // componentShouldUpdate()

  componentDidMount() {}

  componentWillReceiveProps(props) {
    this.setState({ dialogues: props.dialogues }, () =>
      console.log(this.state.dialogues,'dialga')
    );
  }

  calcCLick = (e) => {
    console.log(this.dialogueRef.current.offsetWidth, "refe");
    console.log(this.dialogueRef);
    const coords = this.dialogueRef.current.getBoundingClientRect();
    console.log(e.clientX);

    if (e.clientX > coords.left + (coords.right - coords.left) / 2) {
      console.log("right");
      if (this.state.dialogueIndex < this.state.dialogues.length - 1) {
        this.setState((prevState) => ({
          dialogueIndex: prevState.dialogueIndex + 1,
          activeDialogue: prevState.dialogues[prevState.dialogueIndex + 1],
        }));
      } else {
        this.setState({
          talking: false,
          walking: true,
          returning: true,
          activeDialogue: null,
          dialogueIndex: 0,
        });
        let walkPromise = new Promise(function (resolve, reject) {
          setTimeout(() => resolve("done!"), 5000);
        });
        walkPromise.then((result) =>
          this.setState({
            walking: false,
            peeping: true,
            returning: false,
          })
        );
      }
    } else {
      if (this.state.dialogueIndex !== 0) {
        this.setState((prevState) => ({
          dialogueIndex: prevState.dialogueIndex - 1,
          activeDialogue: prevState.dialogues[prevState.dialogueIndex - 1],
        }));
      }

      console.log("left");
    }

    //     var position=""
    //     $("div").click(function (e) {
    //       var pWidth = $(this).innerWidth(); //use .outerWidth() if you want borders
    //       var pOffset = $(this).offset();
    //       var x = e.pageX - pOffset.left;
    //       // console.log(pWidth, pOffset, x);
    //       if (pWidth / 2 > x) {
    //         console.log("left");
    //         position="left"
    //         localStorage.setItem('direction','left')
    //       }
    //       //  $(this).text('left');
    //       else {
    //         console.log("right");
    //         position="right"
    //         localStorage.setItem('direction','right')

    //       }
    //       //  $(this).text('right');
    //     });
    //     let pos=localStorage.getItem("direction")
    //     if(pos=='left'){

    //     }
    //     else{
    // return this.state.dialogueIndex < this.state.dialogues.length - 1
    //                 ? () =>
    //                     this.setState((prevState) => ({
    //                       dialogueIndex: prevState.dialogueIndex + 1,
    //                       activeDialogue:
    //                         prevState.dialogues[prevState.dialogueIndex + 1],
    //                     }))
    //                 : () => {
    //                     this.setState({
    //                       talking: false,
    //                       walking: true,
    //                       returning: true,
    //                       activeDialogue: null,
    //                       dialogueIndex: 0,
    //                     });
    //                     let walkPromise = new Promise(function (resolve, reject) {
    //                       setTimeout(() => resolve("done!"), 5000);
    //                     });
    //                     walkPromise.then((result) =>
    //                       this.setState({
    //                         walking: false,
    //                         peeping: true,
    //                         returning: false,
    //                       })
    //                     );
    //                   }

    //     }

    //     console.log(position)
  };
  showDialogue() {
    if (this.state.activeDialogue) {
      return (
        <React.Fragment>
          <div
            ref={this.dialogueRef}
            className="dialogue-box cursor-pointer"
            onClick={(e) => this.calcCLick(e)}
            dangerouslySetInnerHTML={{
              __html: `<p>${
                this.state.activeDialogue
              }. <br/><br/><span class="arrowsProf"><span><image src="${left}" class="rightArrowProf ${
                this.state.dialogueIndex == 0 ? "d-none" : ""
              }" /></span><span><image src="${right}" class="rightArrowProf ${
                this.state.dialogueIndex == this.state.dialogues.length - 1
                  ? "d-none"
                  : ""
              }" /><span></span></p>`,
            }}
          />
        </React.Fragment>
      );
    }
  }

  render() {
    console.log(this.state.dialogues,'dialga')
    if (this.state.peeping) {
      return (
        <div className="d-none d-md-block">
          <div className="position-relative">
            <div className={`tenten-container`}>
              <img
                className={`ProfTen`}
                src={this.state.defaultImage}
                alt={"Proffessor Ten Ten"}
                onClick={() => {
                  this.setState({ peeping: false, walking: true });
                  let walkPromise = new Promise(function (resolve, reject) {
                    setTimeout(() => resolve("done!"), 5000);
                  });
                  walkPromise.then((result) =>
                    this.setState({
                      walking: false,
                      talking: true,
                      activeDialogue: this.state.dialogues[
                        this.state.dialogueIndex
                      ],
                    })
                  );
                }}
              />
            </div>
          </div>
          <div
            className="clickTenTen"
            onClick={() => {
              this.setState({ peeping: false, walking: true });
              let walkPromise = new Promise(function (resolve, reject) {
                setTimeout(() => resolve("done!"), 5000);
              });
              walkPromise.then((result) =>
                this.setState({
                  walking: false,
                  talking: true,
                  activeDialogue: this.state.dialogues[
                    this.state.dialogueIndex
                  ],
                })
              );
            }}
          >
            Click me
          </div>
        </div>
      );
    }
    if (this.state.walking) {
      return (
        <div className="d-none d-md-block">
          <div className="position-relative">
            <div className={`tenten-container`}>
              <img
                className={`ProfTen ${
                  this.state.returning ? "walk-out" : "walk-in"
                }`}
                src={this.state.walkingImage}
                alt={"Proffessor Ten Ten"}
              />
            </div>
          </div>
        </div>
      );
    }
    if (this.state.talking) {
      return (
        <div className="d-none d-md-block">
          <div className="position-relative">
            <div className={`tenten-container`}>
              <img
                className={`ProfTenTalking`}
                src={this.state.talkingImage}
                alt={"Proffessor Ten Ten"}
                // onClick={
                //   this.state.dialogueIndex<this.state.dialogues.length-1
                //   ?
                //   () => this.setState((prevState) => ({
                //     dialogueIndex:prevState.dialogueIndex + 1,
                //     activeDialogue:prevState.dialogues[prevState.dialogueIndex + 1]
                //   }))
                //   :
                //   ()=>{
                //     this.setState({talking:false, walking:true, returning:true, activeDialogue:null, dialogueIndex:0})
                //     let walkPromise = new Promise(function(resolve, reject) {
                //       setTimeout(() => resolve("done!"), 5000);
                //     });
                //     walkPromise.then(result=>this.setState({walking:false, peeping:true, returning:false}))
                //   }
                // }
              />
              <div
                className="ProfTenCross"
                onClick={() => {
                  this.setState({
                    talking: false,
                    walking: true,
                    returning: true,
                    activeDialogue: null,
                    dialogueIndex: 0,
                  });
                  let walkPromise = new Promise(function (resolve, reject) {
                    setTimeout(() => resolve("done!"), 5000);
                  });
                  walkPromise.then((result) =>
                    this.setState({
                      walking: false,
                      peeping: true,
                      returning: false,
                    })
                  );
                }}
              >
                <i className={`fa fa-times `}></i>
              </div>
            </div>

            {this.showDialogue()}
          </div>
        </div>
      );
    }

    return "";
  }
}

//__html: `<p>${this.state.activeDialogue}. <br /><span style="font-size:12px">Click to continue...</span><br/><br/><span class="arrowsProf"><span><image src="${left}" class="rightArrowProf" /></span><span><image src="${right}" class="rightArrowProf" /><span></span></p>`,

// this.state.dialogueIndex < this.state.dialogues.length - 1
//                 ? () =>
//                     this.setState((prevState) => ({
//                       dialogueIndex: prevState.dialogueIndex + 1,
//                       activeDialogue:
//                         prevState.dialogues[prevState.dialogueIndex + 1],
//                     }))
//                 : () => {
//                     this.setState({
//                       talking: false,
//                       walking: true,
//                       returning: true,
//                       activeDialogue: null,
//                       dialogueIndex: 0,
//                     });
//                     let walkPromise = new Promise(function (resolve, reject) {
//                       setTimeout(() => resolve("done!"), 5000);
//                     });
//                     walkPromise.then((result) =>
//                       this.setState({
//                         walking: false,
//                         peeping: true,
//                         returning: false,
//                       })
//                     );
//                   }
