import React from "react";
import { Link } from "react-router-dom";
import "./Page404.css";

export default function Page404() {
  return (
    <div>
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h3>Oops! Page not found</h3>
            <h1>
              <span>4</span>
              <span>0</span>
              <span>4</span>
            </h1>
          </div>
          <h2>we are sorry, but the page you requested was not found</h2>
          <Link to="/">
            <button
              className="py-2 px-3"
              style={{
                backgroundColor: "#C01825",
                color: "white",
                border: "1px solid #C01825",
              }}
            >
              {" "}
              Go to homepage
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
