import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Scroll from "./Scroll";
import signUP from "../Components/SignUp/signUP";
import Homepage from "../Components/Homepage/Homepage";
import Adminloginpage from "../Components/Admin/adminLogIn/admin-login";
import Adminquiz from "../Components/Admin/AdminQuiz/Quiz";
import AddQuiz from "../Components/Admin/AdminQuiz/AddQuiz";
import Banner from "../Components/Admin/Banner/bannerimages";
import EditBanner from "../Components/Admin/Banner/Editbanner";
import Loginreport from "../Components/Admin/LoginReport/Loginreport";
import AllService from "../Components/Admin/AllService/AllService";
import EditQuiz from "../Components/Admin/AdminQuiz/EditQuiz";
import AddBanner from "../Components/Admin/Banner/AddBanner";
import Aboutus from "../Components/About Us/aboutus";
import AdminForgotPass from "../Components/Admin/AdminForgotPass/AdminForgotPass";
import ProductService from "../Components/Dashboard/ProductService";
import Reports from "../Components/Admin/Reports/Reports";
import Otherservice from "../Components/Dashboard/OtherService/otherservice";
import Adminaboutus from "../Components/Admin/Aboutus/Aboutus";
import MyExplanation from "../Components/MyExplanations/MyExplainations";
import AdminEditAbouts from "../Components/Admin/Aboutus/editAbout";
import Otherservicesubcategory from "../Components/Dashboard/Otherservicesubcategory/otherservicesubcategory";
import AdminEvaluation from "../Components/Admin/Evaluation/evaluation";
import AllSubcategories from "../Components/Admin/OtherService/Subcategories/subcategories";
import AddSubcategories from "../Components/Admin/OtherService/Subcategories/addsubcategories";
import EditSubcategories from "../Components/Admin/OtherService/Subcategories/editsubcategories";
import product from "../Components/Products/products";
import Login from "../Reusable/Header/login";
import AccountDetails from "../Components/My Account/AccountDetails";
import AddOtherservice from "../Components/Admin/OtherService/Addotherservice";
import AllOtherService from "../Components/Admin/OtherService/otherservice";
import Cart from "../Components/My Cart/myCart";
import CourseComponent from "../Components/My Courses/CourseComponent";
import OrderHistory from "../Components/Order History/OrderHistory";
import Explaination from "../Components/Explainations/Explainations";
import EvaluationDetail from "../Components/Admin/Evaluation/EvaluationDetail";
import AllQuestions from "../Components/Admin/Questions/AllQuestions";
import AddQuestion from "../Components/Admin/Questions/AddQuestion";
import EditQuestion from "../Components/Admin/Questions/EditQuestion";
import EditOtherService from "../Components/Admin/OtherService/editotherservice";
import Faculty from "../Components/Admin/Faculty/Faculty";
import AdminprivacyPolicy from "../Components/Admin/privacyPolicy/privacyPolicy.js";
import AdmincookiePolicy from "../Components/Admin/cookiePolicy/cookiePolicy.js";
import Addfaculty from "../Components/Admin/Faculty/Addfaculty";
import Editfaculty from "../Components/Admin/Faculty/Editfaculty";
import Menu from "../Components/Admin/Menu/Menu";
import Lecture from "../Components/Admin/Lectures/Lectures";
import AddLecture from "../Components/Admin/Lectures/Addlectures";
import Editlecture from "../Components/Admin/Lectures/Editlecture";

import AllUserDetails from "../Components/Admin/UserDetail/userDetail";
import Ratings from "../Components/Admin/Ratings/ratings";
import EditUserPage from "../Components/Admin/UserDetail/editUser/editUserDetail";
import OrderPage from "../Components/Admin/OrderPage/allOrders";
import SubjectPage from "../Components/Admin/SubjectPage/SubjectPage";
import Keydates from "../Components/Admin/Key Dates/KeyDates";
import Addkeydates from "../Components/Admin/Key Dates/AddKeyDates";
import EditKeydates from "../Components/Admin/Key Dates/editKeyDates";
import Latestupdates from "../Components/Admin/Latest Updates/LatestUpdates";
import Addlatestupdates from "../Components/Admin/Latest Updates/AddLatestUpdates";
import EditlatestUpdates from "../Components/Admin/Latest Updates/editLatestUpdates";
import EditSubject from "../Components/Admin/EditSubject/EditSubject";
import AddPromoCode from "../Components/Admin/PromoCode/AddPromoCode/addPromoCode";
import AllPromoCode from "../Components/Admin/PromoCode/allPromoCode";
import editPromo from "../Components/Admin/PromoCode/editPromo";
import AddSubject from "../Components/Admin/AddSubject/AddSubject";
import editExam from "../Components/Admin/Exam/editExam";
import allExams from "../Components/Admin/Exam/allExams";
import AddExam from "../Components/Admin/Exam/addExam";
import examinations from "../Components/Admin/Examination/examinations";
import addExamination from "../Components/Admin/Examination/addExamination";
import editExamination from "../Components/Admin/Examination/editExamination";
import Addnews from "../Components/Admin/News/Addnews";
import editNews from "../Components/Admin/News/editnews";
import Subscribers from "../Components/Admin/Subscribers/subscribers";
import allNews from "../Components/Admin/News/news";
import AddTax from "../Components/Admin/tax/addTax";
import allTax from "../Components/Admin/tax/allTax";
import editTax from "../Components/Admin/tax/editTax";
import AddTopic from "../Components/Admin/Topic/AddTopic";

import EditTopic from "../Components/Admin/Topic/EditTopics";
import Testimonial from "../Components/Admin/Testimonials/Testimonials";
import Addtestimonial from "../Components/Admin/Testimonials/addTestimonials";
import Edittestimonial from "../Components/Admin/Testimonials/edittestimonials";
import Topic from "../Components/Admin/Topic/Topic";
// import { Menu } from 'react-bootstrap/lib/Dropdown';
import Lesson from "../Components/lesson/lesson";
import EditVerticals from "../Components/Admin/Verticals/EditVerticals";
import AddVerticals from "../Components/Admin/Verticals/AddVerticals";
import allVerticals from "../Components/Admin/Verticals/Verticals";
import DashboardComponent from "../Components/Dashboard/DashboardComponent/DashboardComponent";
import startTest from "../Components/StarTest/startTest";
import newEvaluation from "../Components/Evaluation/newEvaluation";
import TestFeedback from "../Components/Feedback/feedback";
import AddTest from "../Components/Admin/TopicTests/AddTest";
import EditTest from "../Components/Admin/TopicTests/EditTest";
import getTest from "../Components/Admin/TopicTests/Test";
import mainLatestUpdates from "../Components/LatestUpdates/latest-updates";
import mainKeyDates from "../Components/KeyDates/key-dates";
import editContent from "../Components/Admin/content/editcontent";
import Content from "../Components/Admin/content/Content";
import Exams from "../Components/Exams/Exams";
import Page404 from "../Components/Page404/Page404";

import ProfHomepage from "../Components/Admin/Professor1010/Homepage";
import ProfDashboard from "../Components/Admin/Professor1010/Dashboard";
import ProfOtherService from "../Components/Admin/Professor1010/Otherservice";
import ProfOtherServiceSubCategory from "../Components/Admin/Professor1010/OtherserviceSubCategory";
import ProfProducts from "../Components/Admin/Professor1010/Products";
import ProfLessons from "../Components/Admin/Professor1010/Lessons";
import ProfTest from "../Components/Admin/Professor1010/Test";
import ProfMyAccount from "../Components/Admin/Professor1010/MyAccount";
import ProfMyCourses from "../Components/Admin/Professor1010/MyCourses";
import ProfMyCart from "../Components/Admin/Professor1010/MyCart";
import Feedback from "../Components/Admin/Professor1010/Feedback";
import OrderPassword from "../Components/Admin/orderPassword/orderPassword";

import EditProfHomepage from "../Components/Admin/Professor1010/EditHomepage";
import EditProfDashboard from "../Components/Admin/Professor1010/EditDashboard";
import EditProfOtherService from "../Components/Admin/Professor1010/EditOtherservice";
import EditProfOtherServiceSubCategory from "../Components/Admin/Professor1010/EditOtherserviceSubcategory";
import EditProfProducts from "../Components/Admin/Professor1010/EditProduct";
import EditProfLessons from "../Components/Admin/Professor1010/EditLessons";
import EditProfTest from "../Components/Admin/Professor1010/EditTest";
import EditProfMyAccount from "../Components/Admin/Professor1010/EditMyAccount";
import EditProfMyCourses from "../Components/Admin/Professor1010/EditMyCourses";
import EditProfMyCart from "../Components/Admin/Professor1010/EditMyCart";
// import EditProfPurchaseHistory from "../Components/Admin/Professor1010/EditPurchaseHistory";
import Editfeedback from "../Components/Admin/Professor1010/Editfeedback";
import PrivacyPolicy from "../Components/Privacy/PrivacyPolicy";
import Invoice from "../Components/My Cart/Invoice";
import TermsOfPurchase from "../Components/TermsofPurchase/TermsOfPurchase";
import Historical_Dates from "../Components/Historical-Dates/historicalDates";
import HistoricalDates from "../Components/Admin/historicalDates/historicalDates";
import editHistoricalDates from "../Components/Admin/historicalDates/editHistoricalDates";
import addHistoricalDates from "../Components/Admin/historicalDates/addHistoricalDates";
import lessonsAdmin from "../Components/lesson/lessonAdmin";

class Routes extends React.Component {
  render() {
    return (
      <BrowserRouter style={{ overflow: "auto" }}>
        <Scroll>
          <Switch>
            {/*************************** ADMIN SIDE *********************/}
            <Route
              exact
              path="/limitedaccess/about-us"
              component={Adminaboutus}
            />
            <Route
              exact
              path="/limitedaccess/user-details"
              component={AllUserDetails}
            />
            <Route exact path="/edit-user" component={EditUserPage} />
            <Route exact path="/limitedaccess" component={Adminloginpage} />
            <Route
              exact
              path="/limitedaccess/all-subcategories"
              component={AllSubcategories}
            />
            <Route
              exact
              path="/limitedaccess/add-subcategories"
              component={AddSubcategories}
            />
            <Route
              exact
              path="/limitedaccess/edit-subcategories"
              component={EditSubcategories}
            />
            <Route
              exact
              path="/limitedaccess/edit-banner"
              component={EditBanner}
            />
            <Route exact path="/limitedaccess/menu" component={Menu} />
            <Route exact path="/limitedaccess/ratings" component={Ratings} />
            <Route exact path="/limitedaccess/add-topic" component={AddTopic} />
            <Route exact path="/limitedaccess/all-topics" component={Topic} />
            <Route
              exact
              path="/limitedaccess/edit-topic"
              component={EditTopic}
            />
            <Route
              exact
              path="/limitedaccess/users-with-other-service"
              component={AllService}
            />
            <Route
              exact
              path="/limitedaccess/evaluation"
              component={AdminEvaluation}
            />
            <Route
              exact
              path="/limitedaccess/all-questions"
              component={AllQuestions}
            />
            <Route
              exact
              path="/limitedaccess/add-question"
              component={AddQuestion}
            />
            <Route
              exact
              path="/limitedaccess/login-report"
              component={Loginreport}
            />
            <Route
              exact
              path="/limitedaccess/banner-images"
              component={Banner}
            />
            <Route
              exact
              path="/limitedaccess/add-banner"
              component={AddBanner}
            />
            <Route
              exact
              path="/limitedaccess/edit-question"
              component={EditQuestion}
            />
            <Route exact path="/limitedaccess/news" component={allNews} />
            <Route exact path="/limitedaccess/add-news" component={Addnews} />
            <Route exact path="/limitedaccess/edit-news" component={editNews} />
            <Route
              exact
              path="/limitedaccess/other-service/add-category"
              component={AddOtherservice}
            />
            <Route
              exact
              path="/limitedaccess/professor/feedback"
              component={Feedback}
            />
            <Route
              exact
              path="/limitedaccess/other-service/categories"
              component={AllOtherService}
            />
            <Route
              exact
              path="/limitedaccess/subscribers"
              component={Subscribers}
            />
            <Route exact path="/limitedaccess/faculties" component={Faculty} />
            <Route
              exact
              path="/limitedaccess/add-faculty"
              component={Addfaculty}
            />
            <Route
              exact
              path="/limitedaccess/edit-faculty"
              component={Editfaculty}
            />
            <Route
              exact
              path="/limitedaccess/professor/homepage"
              component={ProfHomepage}
            />
            <Route
              exact
              path="/limitedaccess/professor/dashboard"
              component={ProfDashboard}
            />
            <Route
              exact
              path="/limitedaccess/professor/other-service"
              component={ProfOtherService}
            />
            <Route
              exact
              path="/limitedaccess/professor/products"
              component={ProfProducts}
            />
            <Route
              exact
              path="/change-password-order"
              component={OrderPassword}
            />
            <Route
              exact
              path="/limitedaccess/professor/lessons"
              component={ProfLessons}
            />{" "}
            <Route
              exact
              path="/limitedaccess/professor/test"
              component={ProfTest}
            />{" "}
            <Route
              exact
              path="/limitedaccess/professor/other-service-subcategory"
              component={ProfOtherServiceSubCategory}
            />{" "}
            <Route
              exact
              path="/limitedaccess/professor/myaccounts"
              component={ProfMyAccount}
            />{" "}
            <Route
              exact
              path="/limitedaccess/professor/mycourses"
              component={ProfMyCourses}
            />{" "}
            <Route
              exact
              path="/limitedaccess/professor/mycart"
              component={ProfMyCart}
            />
            <Route
              exact
              path="/limitedaccess/professor/edit-homepage"
              component={EditProfHomepage}
            />
            <Route
              exact
              path="/limitedaccess/professor/edit-dashboard"
              component={EditProfDashboard}
            />
            <Route
              exact
              path="/limitedaccess/professor/edit-feedback"
              component={Editfeedback}
            />
            <Route
              exact
              path="/limitedaccess/professor/edit-other-service"
              component={EditProfOtherService}
            />
            <Route
              exact
              path="/limitedaccess/professor/edit-products"
              component={EditProfProducts}
            />
            <Route
              exact
              path="/limitedaccess/professor/edit-lessons"
              component={EditProfLessons}
            />{" "}
            <Route
              exact
              path="/limitedaccess/professor/edit-test"
              component={EditProfTest}
            />{" "}
            <Route
              exact
              path="/limitedaccess/professor/edit-other-service-subcategory"
              component={EditProfOtherServiceSubCategory}
            />{" "}
            <Route
              exact
              path="/limitedaccess/professor/edit-myaccounts"
              component={EditProfMyAccount}
            />{" "}
            <Route
              exact
              path="/limitedaccess/professor/edit-mycourses"
              component={EditProfMyCourses}
            />{" "}
            <Route
              exact
              path="/limitedaccess/professor/edit-mycart"
              component={EditProfMyCart}
            />
            <Route
              exact
              path="/limitedaccess/testimonials"
              component={Testimonial}
            />
            <Route
              exact
              path="/limitedaccess/add-testimonial"
              component={Addtestimonial}
            />
            <Route
              exact
              path="/limitedaccess/edit-testimonial"
              component={Edittestimonial}
            />
            <Route exact path="/limitedaccess/add-tax" component={AddTax} />
            <Route exact path="/limitedaccess/all-tax" component={allTax} />
            <Route exact path="/limitedaccess/edit-tax" component={editTax} />
            <Route
              exact
              path="/limitedaccess/add-promocode"
              component={AddPromoCode}
            />
            <Route
              exact
              path="/limitedaccess/promocode"
              component={AllPromoCode}
            />
            <Route
              exact
              path="/limitedaccess/edit-promocode"
              component={editPromo}
            />
            <Route exact path="/limitedaccess/add-exam" component={AddExam} />
            <Route exact path="/limitedaccess/all-exams" component={allExams} />
            <Route exact path="/limitedaccess/edit-exam" component={editExam} />
            <Route
              exact
              path="/limitedaccess/examinations"
              component={examinations}
            />
            <Route
              exact
              path="/limitedaccess/add-examination"
              component={addExamination}
            />
            <Route
              exact
              path="/limitedaccess/privacy-policy"
              component={AdminprivacyPolicy}
            />
            <Route
              exact
              path="/limitedaccess/cookie-policy"
              component={AdmincookiePolicy}
            />
            <Route
              exact
              path="/limitedaccess/edit-examination"
              component={editExamination}
            />
            <Route
              exact
              path="/limitedaccess/edit-otherservice"
              component={EditOtherService}
            />
            <Route exact path="/limitedaccess/lecture" component={Lecture} />
            <Route
              exact
              path="/limitedaccess/add-lecture"
              component={AddLecture}
            />
            <Route
              exact
              path="/limitedaccess/edit-lecture"
              component={Editlecture}
            />
            <Route
              exact
              path="/limitedaccess/add-subject"
              component={AddSubject}
            />
            <Route
              exact
              path="/limitedaccess/edit-subject"
              component={EditSubject}
            />
            <Route
              exact
              path="/limitedaccess/subject-list"
              component={SubjectPage}
            />
            <Route
              exact
              path="/limitedaccess/edit-aboutus"
              component={AdminEditAbouts}
            />
            <Route
              exact
              path="/limitedaccess/latest-updates"
              component={Latestupdates}
            />
            <Route
              exact
              path="/limitedaccess/forgot-password"
              component={AdminForgotPass}
            />
            <Route
              exact
              path="/limitedaccess/edit-latest-updates"
              component={EditlatestUpdates}
            />
            <Route
              exact
              path="/limitedaccess/add-latest-updates"
              component={Addlatestupdates}
            />
            <Route
              exact
              path="/limitedaccess/important-dates"
              component={Keydates}
            />
            <Route
              exact
              path="/limitedaccess/add-important-dates"
              component={Addkeydates}
            />
            <Route
              exact
              path="/limitedaccess/edit-important-dates"
              component={EditKeydates}
            />
            <Route exact path="/limitedaccess/Order" component={OrderPage} />
            <Route
              exact
              path="/limitedaccess/add-vertical"
              component={AddVerticals}
            />
            <Route
              exact
              path="/limitedaccess/edit-vertical"
              component={EditVerticals}
            />
            <Route
              exact
              path="/limitedaccess/vertical"
              component={allVerticals}
            />
            <Route
              exact
              path="/limitedaccess/evaluation/:type"
              component={EvaluationDetail}
            />
            <Route
              exact
              path="/limitedaccess/all-questions"
              component={AllQuestions}
            />
            <Route
              exact
              path="/limitedaccess/add-question"
              component={AddQuestion}
            />
            <Route
              exact
              path="/limitedaccess/edit-question"
              component={EditQuestion}
            />
            <Route
              exact
              path="/limitedaccess/edit-content"
              component={editContent}
            />
            <Route exact path="/limitedaccess/content" component={Content} />
            <Route
              exact
              path="/limitedaccess/lesson/:id"
              component={lessonsAdmin}
            />
            <Route exact path="/limitedaccess/add-test" component={AddTest} />
            <Route exact path="/limitedaccess/test" component={getTest} />
            <Route exact path="/limitedaccess/edit-test" component={EditTest} />
            <Route exact path="/limitedaccess/dashboard" component={Reports} />
            <Route exact path="/limitedaccess/quiz" component={Adminquiz} />
            <Route exact path="/limitedaccess/add-quiz" component={AddQuiz} />
            <Route exact path="/limitedaccess/edit-quiz" component={EditQuiz} />
            <Route
              exact
              path="/limitedaccess/historical-dates"
              component={HistoricalDates}
            />
            <Route
              exact
              path="/limitedaccess/edit-historical-dates"
              component={editHistoricalDates}
            />
            <Route
              exact
              path="/limitedaccess/add-historical-dates"
              component={addHistoricalDates}
            />
            {/*************************** CLIENT SIDE *********************/}
            <Route exact path="/" component={Homepage} />
            <Route exact path="/Sign-up" component={signUP} />
            <Route exact path="/product/:name" component={product} />
            <Route exact path="/my-account" component={AccountDetails} />
            <Route exact path="/my-cart" component={Cart} />
            <Route exact path="/my-courses" component={CourseComponent} />
            <Route exact path="/purchase-history" component={OrderHistory} />
            <Route exact path="/other-services" component={Otherservice} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/important-dates" component={mainKeyDates} />
            <Route exact path="/my-exams" component={Exams} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route
              exact
              path="/dashboard/:type"
              component={DashboardComponent}
            />
            <Route
              exact
              path="/other-service/:subcategory"
              component={Otherservicesubcategory}
            />
            <Route
              exact
              path="/other-service/service_category/:service"
              component={ProductService}
            />
            <Route exact path="/test_explanation" component={MyExplanation} />
            <Route exact path="/start-test" component={startTest} />
            <Route exact path="/newEvaluation" component={newEvaluation} />
            <Route exact path="/feedback" component={TestFeedback} />
            <Route exact path="/latest-updates" component={mainLatestUpdates} />
            <Route exact path="/lessons/:id?" component={Lesson} />
            <Route exact path="/explaination" component={Explaination} />
            <Route exact path="/about-us" component={Aboutus} />
            <Route exact path="/invoice" component={Invoice} />
            <Route
              exact
              path="/terms-of-purchase"
              component={TermsOfPurchase}
            />
            <Route
              exact
              path="/historical-dates"
              component={Historical_Dates}
            />
            <Route path="/*" component={Page404} />
          </Switch>
        </Scroll>
      </BrowserRouter>
    );
  }
}
export default Routes;
