import React from "react";
import "../../Components/commonStyles.css";
import styles from "./loginHeader.module.css";
import Logo from "../../Assets/images/Logo.png";
import { matchPath } from "react-router-dom";

import swal from "sweetalert";

import { 
  MDBIcon,
  MDBCollapse,
  MDBContainer,

} from "mdbreact";
import {NavLink} from 'react-router-dom'
import Searchbar from "./Searchbar/Searchbar";

class homepageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu_selected: false,
      examsMenu: [],
      showSubmenu: false,
      collapseID: "",
      subjects: [],
      language_id: "",
      cart: "",
      userdata: "",
      dashboards: [],
    };
  }

  getDashboard = () => {
    let details = {
      language_id: this.state.language_id,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/get_dashboard", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({ dashboards: responseJson }, () =>
            this.openDashboard(this.state.dashboards)
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  getCart = () => {
    if (this.state.userdata != null) {
      this.getCartApi();
    }
  };

  getCartApi = () => {
    let details = {
      user_id: this.state.userdata.user_id,
    };

    //  console.log(details,'fetching-details')
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log(responseJson.item,'neo')

          this.setState(
            {
              cart: JSON.parse(responseJson[0].items).length,
            },
            () => console.log(this.state.cart, "neo")
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  componentWillMount() {
    let languageid = localStorage.getItem("language_id");
    var userdata = window.localStorage.getItem("userdata");
    if (languageid == null) {
      localStorage.setItem("language_id", 0);
    }
    this.setState(
      { language_id: languageid, userdata: JSON.parse(userdata) },
      () => this.getCart()
    );
    this.getDetails();
    this.renderLogin();
    this.getExams();
    this.getVerticals();
    this.getSubjects();
  }

  selectVertical(item, index) {
    window.localStorage.removeItem("selected_vertical");
    window.localStorage.removeItem("collapse");
    window.localStorage.setItem("selected_vertical", JSON.stringify(item));
    //   window.localStorage.setItem('collapse',0)
    //   if (this.props.location.pathname.indexOf("dashboard") === 0 ) {
    //     document.location.reload();
    // }
    //  else{
    //    this.props.history.push('/dashboard');
    // }

    if (matchPath(this.props.location.pathname, "/dashboard")) {
      document.location.reload();
    } else {
      this.props.history.push("/dashboard");
    }
  }

  getVerticals = () => {
    this.setState({
      loading: true,
    });
    this.setState({
      response: fetch("https://10x10learning.com/get_verticals", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log(responseJson, "verticals");
          this.setState({
            // verticals:responseJson.reverse(),
            verticalsMenu: responseJson.reverse(),
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  getExams = async () => {
    // console.log("fetching exams");
    this.setState({
      response: fetch("https://10x10learning.com/get_exams", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log("Ëxams", responseJson);
          // console.log(JSON.parse(responseJson[0].verticals));
          this.setState({
            examsMenu: responseJson.reverse(),
          });
        })
        .catch((error) => {
          console.log(error);
        }),
    });
  };

  getSubjects = async () => {
    this.setState({
      loading: true,
    });
    this.setState({
      response: fetch("https://10x10learning.com/get_subjects", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            subjects: responseJson,

            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  renderSubjectsInExams = (item, exam_id) => {
    let subjectExams = JSON.parse(item.exams);
    let exam_ids = [];
    subjectExams.forEach((element) => {
      exam_ids.push(element.exam_id);
    });
    if (exam_ids.includes(exam_id)) {
      return (
        <li
          className="border-bottom pb-2 w-75 pt-2 ml-3"
          onClick={() => this.setSubject(item)}
        >
          {item.subject_name}
        </li>
      );
    }
  };
  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  };

  renderVerticalsSubMenu(vertical_id) {
    return this.state.examsMenu.map((examItem) => {
      var exam_verticals = JSON.parse(examItem.verticals);
      return exam_verticals.map((item, index) => {
        if (item.vertical_id === vertical_id && examItem.status == 1) {
          return (
            <React.Fragment>
              <li
                key={index}
                className="d-flex align-items-center justify-content-betweeen"
                style={{ marginBottom: "1rem" }}
              >
                <span onClick={() => this.selectVertical(item, index)}>
                  {examItem.exam_name}{" "}
                </span>
                <MDBIcon
                  icon="plus"
                  className="iconPlus"
                  onClick={this.toggleCollapse(examItem.exam_name)}
                  color="primary"
                  style={{ marginLeft: "0.5rem" }}
                />
              </li>
              <MDBCollapse
                id={examItem.exam_name}
                isOpen={this.state.collapseID}
              >
                <MDBContainer>
                  <ul className="pl-0">
                    {this.state.subjects.map((item, index) => {
                      return this.renderSubjectsInExams(item, examItem.exam_id);
                    })}
                  </ul>
                </MDBContainer>
              </MDBCollapse>
            </React.Fragment>
          );
        }
      });
    });
  }
  setSubject = (item) => {
    // console.log(item);
    window.localStorage.removeItem("selected_subject");
    window.localStorage.setItem("selected_subject", JSON.stringify(item));
    if (matchPath(this.props.location.pathname, "/product")) {
      document.location.reload();
    } else {
      this.props.history.push("/product");
    }
  };

  onScroll() {
    window.scrollTo(0, 2000);
  }

  openDashboard = (item) => {
    item.filter((item) => {
      if (item.url === "school") {
        localStorage.setItem("Dashboard id", JSON.stringify(item.id));
        localStorage.setItem("dashboard type", item.type);
        this.props.history.push(`/dashboard/${item.type}`);
      }
    });
  };

  renderMenu() {
    return (
      <div className="col-9 d-flex row align-item-center ml-5 pl-5 mt-2">
     <NavLink
          exact
          className={`${styles.ProfileMenuList} `}
          activeClassName={`${styles.activeLink}`}
          to="/"
        >
          {this.state.language_id != 1 ? `Home` : `मुख पृष्ठ`}
          </NavLink>
          <NavLink
          exact
          className={`${styles.ProfileMenuList} `}
          activeClassName={`${styles.activeLink}`}
          to="/about-us"
        >
          {this.state.language_id != 1 ? `About Us` : `हमारे बारे में`}
          </NavLink>
         
  
          <NavLink
        exact
        className={`${styles.ProfileMenuList} `}
        activeClassName={`${styles.activeLink}`}
        to="/dashboard/civil-services"
     
      >
         {this.state.language_id == 0 ? `Civil Services` : `
         सिविल सेवा`}
      </NavLink>


      <NavLink
      exact
      className={`${styles.ProfileMenuList} `}
      activeClassName={`${styles.activeLink}`}
      to="/dashboard/humanities"
   
    >
       {this.state.language_id == 0 ? `Humanities` : `मानविकी`}
    </NavLink>
    <NavLink
          exact
          className={`${styles.ProfileMenuList} `}
          activeClassName={`${styles.activeLink}`}
          to="/dashboard/CCA"
        >
        {this.state.language_id != 1 ? `Commerce/CA` : `
वाणिज्य / सीए`}
        </NavLink>
    
        <NavLink
          exact
          className={`${styles.ProfileMenuList} `}
          activeClassName={`${styles.activeLink}`}
          to="/dashboard/school"
        >
        {this.state.language_id != 1 ? `Classes` : `कक्षाएं`}
        </NavLink>

        {/* <NavLink
          exact
          className={`${styles.ProfileMenuList} `}
          activeClassName={`${styles.activeLink}`}
          to="/other-services"
       
        >
           {this.state.language_id == 0 ? `Other Services` : `अन्य सेवाएं`}

        </NavLink> */}
        <NavLink
          exact
          className={`${styles.ProfileMenuList} `}
          activeClassName={`${styles.activeLink}`}
          to="/my-exams"
        >
          {this.state.language_id != 1 ? `Practice` : `
          अभ्यास`}
          </NavLink>
      
        <p
    
          className={`${styles.ProfileMenuListContact}  ${styles.contactlogout}`}
        >
          {this.state.language_id != 1 ? `Contact Us` : `संपर्क करें`}
        </p>
      </div>
    );
  }

  logout() {
    window.localStorage.removeItem("userdata");
    window.localStorage.removeItem("user_token");
    this.props.history.push("/");
    window.location.reload();
  }

  renderMenuBar() {
    if (this.state.menu_selected) {
      // console.log("dadad");
      return (
        <div className={`${styles.HeaderToggleMenu}`}>
          <div
            className="opensansR rowDefault d-flex"
            style={{ alignItems: "center", marginBottom: 10, paddingLeft: 20 }}
          >
            {this.renderProfilePic()}
            <div className={`${styles.ToggleRow}`}>
              <div>
                {" "}
                <text>{this.state.userdata.name}</text>
              </div>

              <div>
                <text>{this.state.userdata.email}</text>
              </div>
            </div>
          </div>
          <p
            onClick={() => this.props.history.push("/my-account")}
            className={`${styles.ProfileMenuListToggle}`}
          >
            {this.state.language_id == 0 ? `My Account` : `मेरा खाता`}
          </p>
          <p
            onClick={() => this.props.history.push("/my-courses")}
            className={`${styles.ProfileMenuListToggle}`}
          >
            {this.state.language_id == 0 ? `My Courses` : `मेरे पाठ्यक्रम`}
          </p>
          <p
            onClick={() => this.props.history.push("/purchase-history")}
            className={`${styles.ProfileMenuListToggle}`}
          >
            {this.state.language_id == 0 ? `Purchase History` : `खरीद इतिहास`}
          </p>
         
          <p
            className={`${styles.ProfileMenuListToggle}`}
            onClick={() => this.logout()}
          >
            {this.state.language_id == 0 ? `Log Out` : `लॉग आउट`}
          </p>
        </div>
      );
    }
  }

  renderClassesMenu() {
    if (this.state.showSubmenu) {
      return (
        <div className={`${styles.ClassesSubMenuContainer}`}>
          <div className={`${styles.RowReverse}`}>
            <div
              className={`${styles.CrossDiv}`}
              id="up"
              onClick={() => this.setState({ showSubmenu: false })}
            >
              <img
                src={require("../../Assets/images/cross.png")}
                className={`${styles.CrossImg}`}
                alt="cross"
              />
            </div>

            <div className={`${styles.ClassesMenuLogo}`}>
              <img
                src="https://10x10learning.com/Logo.png"
                alt="10x10 Logo"
                className={`${styles.LogoImage}`}
                onClick={() => this.props.history.push("/")}
              />
            </div>
          </div>
          <div className={`${styles.ClassesSubMenu}`}>
            {this.state.verticalsMenu.map((item, index) => {
              if (item.status == 1)
                return (
                  <ul className="px-0 w-25" key={index}>
                    {item.vertical_name}
                    <hr
                      className="mt-1 mx-0 w-50"
                      style={{ border: "1px solid #d2d2d2" }}
                    />
                    {this.renderVerticalsSubMenu(item.vertical_id)}
                  </ul>
                );
            })}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderProfilePic() {
    if (this.state.userdata != null) {
      if (this.state.userdata.profile_pic == null) {
        return (
          <img
            onClick={() =>
              this.setState({ menu_selected: !this.state.menu_selected })
            }
            className={`${styles.HeaderProfilePic} `}
            style={{ height: "35px" }}
            src={
              this.state.userdata.gender == "Male"
                ? require("../../Assets/images/male.png")
                : require("../../Assets/images/female.png")
            }
          />
        );
      } else {
        return (
          <img
            className={`${styles.HeaderProfilePic} `}
            onClick={() =>
              this.setState({ menu_selected: !this.state.menu_selected })
            }
            src={this.state.userdata.profile_pic}
          />
        );
      }
    }
  }
  getDetails() {
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState(
        {
          userdata: JSON.parse(userdata),
        }
        // () => console.log(this.state.userdata, "uuuu")
      );
    }
  }

  renderLogin() {
    return (
      <div className="col-3  d-flex row justify-content-end px-0">
        <div
          className={`${styles.HeaderIconsDiv}  mr-4`}
          onClick={() => this.props.history.push("/my-cart")}
        >
          <MDBIcon icon="shopping-cart"></MDBIcon>

          <span class="flaticon-airplane49"></span>
          <span aria-label="2 items" class="badge">
            {this.state.cart != 0 ? this.state.cart : ""}
          </span>
        </div>
        <div className={`${styles.HeaderIconsDiv}  mr-4 d-none`}>
          <MDBIcon icon="bell" />
        </div>
        {this.renderProfilePic()}
      </div>
    );
  }

  renderSocialMediaTab() {
    return (
      <div className={`${styles.HeaderSocialMediaDiv} ml-2`}>
        <button className={`${styles.HeaderSocialMedia}`}>
          <MDBIcon fab icon="facebook-f" />
        </button>
      </div>
    );
  }
  handleSaveEnglish = () => {
    this.setState({ languageid: 0 });
    localStorage.setItem("language_id", 0);
    window.location.reload();
  };
  handleSaveHindi = () => {
    localStorage.setItem("language_id", 1);
    window.location.reload();
  };

  render() {
    return (
      <div
        id="homepageHeader-main "
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <div
          className={this.state.language_id == 0 ? "opensansR" : "opensansR"}
        >
          <div className={`${styles.LoginHeader} d-lg-flex  `}>
            <div
              className={`${styles.LogoPart} col-2 d-flex justify-content-start pr-0 pt-3`}
            >
              <img
                src="https://10x10learning.com/Logo.png"
                className={`${styles.LoginLogo} `}
                onClick={() => this.props.history.push("/")}
              />
            </div>

            <div className={`${styles.MenuPart} col-10 mr-0 p-0`}>
              <div className={`${styles.LoginHeaderTopPart} row py-3 `}>
                <Searchbar {...this.props} />
                <div className="d-flex justify-content-start">
                  {this.renderSocialMediaTab()}
                </div>
              </div>

              <hr className={`${styles.HrTag}`}></hr>
              <hr className={`${styles.HrTag}`}></hr>
              <div className={`${styles.LoginHeaderBottomPart}`}>
                <div className={`${styles.LoginHeaderMenus} col-12 px-0  pt-2`}>
                  {this.renderMenu()}
                  {this.renderLogin()}
                </div>
              </div>
            </div>

            <div></div>
          </div>

          {this.renderMenuBar()}
          {this.renderClassesMenu()}
        </div>

        <hr className={`${styles.HrTagTop}`}></hr>
        <hr className={`${styles.HrTag}`}></hr>
      </div>
    );
  }
}

export default homepageHeader;
