import React, { Component } from 'react'
import styles from './collapsible.module.css'
export default class collapsible extends Component {
    state={
        showpara:false,
    }
    render() {
        
        return (
            <div>
           <button style={{backgroundColor:'#c01825',color:'white',border:'1px solid #c01825', padding:'2px'}}
                 onClick={()=>this.setState({showpara:!this.state.showpara})}  >View Explanation</button>
                 {<p className={this.state.showpara?`${styles.isActive} ${styles.explaination}`:`${styles.isInactive}`} style={{marginTop:'10px'}}>
                     <span style={{fontFamily:'OpenSans-B'}}>Explanation - </span> <span style={{fontFamily:'OpenSans-R'}} dangerouslySetInnerHTML={{__html:this.props.explaination}} ></span></p>}
            </div>
        )
    }
}
