import React from "react";
import Fade from "react-reveal/Fade";
import "./courseComponent.css";
import CourseThumb from "../../Assets/images/course-thumb.png";
import NavArrow from "../../Assets/images/right-arrow-nav.svg";
import ProfTen from "../Prof10X10/prof10x10";
import {
  MDBIcon,
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from "mdbreact";
import Footer from "../../Reusable/Footer/footer";
import NewHeader from "../../Reusable/Header/homeHeader";
import AccountNav from "../../Reusable/AccountNavigation/AccountNav";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import swal from "sweetalert";
import MainFooterPage from "../../Reusable/Footer/footer.jsx";
import Select from "react-select";
// import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
const EXPIRY_WARNING = 14 * 24 * 60 * 60 * 1000;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

const TestLoadingCSS = css`
  display: block;
  margin: 10 auto;
  border-color: black;
`;
class CourseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language_id: "",
      cart: [],
      all_items: [],
      myCourses: [
        {
          courseName: `Health Insurance (IC-27)`,
          purchaseDate: `27 July 2019`,
          expiryDate: `26 July 2020`,
          courseThumb: `${CourseThumb}`,
          url: `/`,
        },
        {
          courseName: `Human Resources Management (IC-90)`,
          purchaseDate: `27 July 2019`,
          expiryDate: `26 July 2020`,
          courseThumb: `${CourseThumb}`,
          url: `/`,
        },
        {
          courseName: `Question Bank`,
          purchaseDate: `27 July 2019`,
          expiryDate: `26 July 2020`,
          courseThumb: `${CourseThumb}`,
          url: `/`,
        },
        {
          courseName: `Marketing and Public Relations (IC-88)`,
          purchaseDate: `27 July 2019`,
          expiryDate: `26 July 2020`,
          courseThumb: `${CourseThumb}`,
          url: `/`,
        },
      ],
      testModal: false,
      testLoading: true,
      tests: [],
      selectedTest: null,
      loading: true,
    };
  }

  getCart = () => {
    let details = {
      user_id: this.state.userdata.user_id,
    };

    //  console.log(details,'fetching-details')
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          //   console.log(responseJson,'cart')

          this.setState({
            cart: responseJson,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth();
    // console.log(dt.getMonth()+1)
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (
      dd +
      "/" +
      monthNames[Number(dt.getMonth())] +
      "/" +
      dt.getFullYear().toString()
    );
  }
  removeDuplicates(originalArray, objKey) {
    var trimmedArray = [];
    var values = [];
    var value;

    for (var i = 0; i < originalArray.length; i++) {
      value = originalArray[i][objKey];

      if (values.indexOf(value) === -1) {
        trimmedArray.push(originalArray[i]);
        values.push(value);
      }
    }

    this.setState({
      all_items: trimmedArray,
      loading: false,
    });
  }
  checkDuplicates(originalArray) {
    console.log("ORIGINAL ARRAY", originalArray);
    var trimmedArray = [];
    var all_courses = originalArray;
    const orders = {};
  
     [...originalArray].forEach(element => {
      console.log(element, 'reducing element')

      const index =  String(`subject_${element.subject_id}`)

      if(!orders[index]){
        console.log('element did not exist before')
        orders[index] = element
        if(orders[index].subject_included == 1){
          orders[index].subject_included_date = element.creation_time
        }
        else{
          orders[index].subject_included_date = null
        }
        if(orders[index].test_included == 1){
          orders[index].test_included_date = element.creation_time
        }
        else{
          orders[index].test_included_date = null
        }
      }
      else{
        console.log('element existed before')
        if(element.subject_included == 1){
          orders[index].subject_included = 1
          orders[index].subject_included_date = element.creation_time
        }
        if(element.test_included == 1){
          orders[index].test_included = 1
          orders[index].test_included_date = element.creation_time
        }
      }
     });

    console.log(orders, 'reducedArray')
    let trimmedOrders = []
    for(var key in orders){
      trimmedOrders.push(orders[key])
    }
    console.log(trimmedOrders)
    this.setState({
      all_items: trimmedOrders,
      loading: false,
    });
  }

  getOrders = async () => {
    let today = new Date().getTime();
    this.setState({
      loading: true,
    });
    let details = {
      user_id: this.state.userdata.user_id,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/get_my_orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson)
          var items = [];
          for (var v = 0; v < responseJson.length; v++) {
            for (
              var v1 = 0;
              v1 < JSON.parse(responseJson[v].items).length;
              v1++
            ) {
              var data = JSON.parse(responseJson[v].items)[v1];
              data.creation_time = responseJson[v].ts;
              let order_date = new Date(data.creation_time).getTime();
              let expiry = data.expires_in;
              console.log(expiry);
              let expirationTime = expiry * 24 * 60 * 60 * 1000;
              let expirationDate = new Date(
                order_date + expirationTime
              ).getTime();
              console.log(expirationDate, today);
              if (expirationDate > today) {
                items.push(data);
              }
            }
          }
          console.log(items, "orig");
          try{
          this.checkDuplicates(items);
          }
          catch(err){
            console.log(err)
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  componentWillMount() {
    let language_id = localStorage.getItem("language_id");
    console.log(language_id);
    this.setState({ language_id }, () => this.getDialogues());
  }

  getDialogues() {
    let formBody = new FormData();
    formBody.append("language_id", this.state.language_id);
    fetch("https://10x10learning.com/prof10_mycourse", {
      method: "POST",
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("Dialogues", responseJson);
        const dialogues = JSON.parse(responseJson[0].text).map(
          (item) => item.description
        );
        this.setState({ dialogues }, () => this.getDetails());
      })
      .catch((err) => console.log(err));
  }
  getDetails() {
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState(
        {
          userdata: JSON.parse(userdata),
          loading: true,
        },
        () => {
          this.getOrders();
          this.getCart();
        }
      );
    } else {
      this.props.history.push("/");
    }
  }

  buyCourseWOTest(item) {
    if (this.state.userdata == null) {
      this.setState({ loginToggle: true });
    } else {
      let details = {
        user_id: this.state.userdata.user_id,
        items: JSON.stringify([
          {
            subject_id: item.subject_id,
            test_included: 0,
            subject_included: 1,
          },
        ]),
      };

      console.log(details, "fetching-details");
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://10x10learning.com/update_cart", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson, "update_cart");
            this.setState(
              {
                showAdded: true,
                cart_status: responseJson.status,
                item_in_cart: true,
                loading: false,
              },
              () => this.selectiveTrigger()
            );
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            alert("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    }
  }

  buyTestWOSubject(item) {
    if (this.state.userdata == null) {
      this.setState({ loginToggle: true });
    } else {
      //   this.setState({
      //     loading:true
      // })
      let details = {
        user_id: this.state.userdata.user_id,
        items: JSON.stringify([
          {
            subject_id: item.subject_id,
            test_included: 1,
            subject_included: 0,
          },
        ]),
      };

      console.log(details, "fetching-details");
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://10x10learning.com/update_cart", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson, "update_cart");
            this.setState(
              {
                showAdded: true,
                cart_status: responseJson.status,
                item_in_cart: true,
                loading: false,
              },
              () => this.selectiveTrigger()
            );
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            alert("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    }
  }

  selectiveTrigger() {
    this.trigger();
    if (this.state.userdata) this.props.history.push("/my-cart");
  }

  trigger() {
    this.getCart();
    setTimeout(() => {
      this.setState({
        showAdded: false,
      });
    }, 2000);
  }

  getTests(subject_id) {
    // console.log(this.state.subjectid);
    let formBody = new FormData();
    formBody.append("subject_id", subject_id);
    fetch("https://10x10learning.com/get_test", {
      method: "POST",
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        responseJson.forEach((element) => {
          element.label = element.test_name;
          element.value = element.test_id;
        });
        this.setState({
          tests: responseJson,
          testLoading: false,
        });
      });
  }

  startTest() {
    // console.log(this.state.test_included);
    if (this.state.selectedTest == null || !this.state.selectedTest) {
      return swal("Select a test", "", "info");
    }
    // if (this.state.test_included == 1) {
    window.localStorage.removeItem("selected_test");
    window.localStorage.setItem(
      "selected_test",
      this.state.selectedTest.test_id
    );
    // console.log(this.state.selected_subject);
    this.props.history.push("/start-test");
    // } else {
    //   swal(
    //     "",
    //     "To use the test series for this course, please purchase it from the Course Page",
    //     "info"
    //   );
    // }
  }

  renderTestModal() {
    if (this.state.testModal) {
      return (
        <MDBContainer>
          <MDBBtn
            color="primary"
            onClick={() => this.setState({ testModal: false })}
          >
            MDBModal
          </MDBBtn>
          <MDBModal
            isOpen={this.state.testModal}
            toggle={() => this.setState({ testModal: false })}
            centered
          >
            <MDBModalHeader toggle={() => this.setState({ testModal: false })}>
              <strong>Choose Test</strong>
            </MDBModalHeader>
            <MDBModalBody>
              {this.state.testLoading ? (
                <div className="sweet-loading w-100 d-flex justify-content-center align-items-center">
                  <GridLoader
                    css={TestLoadingCSS}
                    sizeUnit={"px"}
                    size={5}
                    color={"#c01825"}
                    loading={this.state.testLoading}
                  />
                </div>
              ) : this.state.tests.length == 0 ? (
                <p>
                  We are currently adding test series for this subject. Please
                  come back in some time. Thank you!
                </p>
              ) : (
                <Select
                  options={this.state.tests}
                  value={this.state.selectedTest}
                  onChange={(e) => this.setState({ selectedTest: e })}
                />
              )}
            </MDBModalBody>
            <MDBModalFooter>
              <button
              className="edit-button1 btn-custom"
                onClick={() =>
                  this.setState({ selectedTest: null, testModal: false })
                }
              >
                Close
              </button>
              <button
              className="edit-button1 btn-custom"
                disabled={this.state.selectedTest == null ? true : false}
                onClick={() => this.startTest()}
              >
                Go to test
              </button>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      );
    }
  }

  handleChooseSubject(item) {
    let today = new Date().getTime();
    let order_date = new Date(item.creation_time).getTime();
    let expiry = item.expires_in;
    let expirationTime = expiry * 24 * 60 * 60 * 1000;
    let expirationDate = new Date(order_date + expirationTime).getTime();
    let shouldWarn = expirationDate - today > EXPIRY_WARNING ? false : true;

    console.log(shouldWarn);
    if (shouldWarn) {
      swal({
        text:
          "This course will expire soon. Dont't forget to buy the course again after it expires to continue learning!",
        icon: "info",
      }).then((renew) => {
        // if(renew){
        //   this.fetchSubject(item.subject_id, item)
        // }
        // else{

        // this.props.history.push(`/product/${item.subject_name}`)
        // }
        this.fetchSubject(item.subject_id, item);
      });
    } else {
      this.fetchSubject(item.subject_id, item);
    }
  }



  async checkTest(subject){
    let formData = new FormData();
    formData.append('id',subject.subject_id)
    let test_exists = await fetch("https://10x10learning.com/checktest", {
      method:"POST",
      body: formData
    })
    let result = await test_exists.json()
    if(result.length == 0){
      return swal("", "There aren't any tests available for this subject at the moment", "info")
    }
    else{
      this.buyTestWOSubject(subject)
    }
  }
  renderButton(item, index) {
    console.log(item);
    if (item.subject_included == 0 && item.test_included == 1) {
      return (
        <div className="col-12 col-lg-4 col-sm-12 render-button-div d-flex">
          <button
            onClick={() => this.buyCourseWOTest(item)}
            className="start-learning btn-custom"
            style={{ color: "black", background: "white" }}
          >
            {this.state.language_id == 0 ? (
              <React.Fragment>Buy Course</React.Fragment>
            ) : (
              <React.Fragment>पाठ्यक्रम खरीदें</React.Fragment>
            )}
          </button>
          <button
            className="start-learning bg-white ml-1"
            onClick={() =>
              this.setState({ testModal: true }, () =>
                this.getTests(item.subject_id)
              )
            }
          >
            Start Test
          </button>
        </div>
      );
    } else if (item.subject_included == 1 && item.test_included == 0) {
      return (
        <div
          className="col-12 col-lg-4 text-end py-3 py-md-auto  render-button-div d-flex"
          style={{
            flex: "0 0 53.333333%",
            flexDirection: "row",
            paddingRight: 0,
          }}
        >
         
          <button
            onClick={() => this.handleChooseSubject(item)}
            className="start-learning btn-test btn-custom"
            style={{ color: "white", marginTop: 15, marginLeft: 10 }}
          >
            {this.state.language_id == 0 ? (
              <React.Fragment>Start Learning</React.Fragment>
            ) : (
              <React.Fragment>सीखना शुरू करें</React.Fragment>
            )}
          </button>
          <button
            onClick={() => this.checkTest(item)}
            className="start-learning btn-custom"
            style={{ color: "black", background: "white", marginTop: "15px", marginLeft: "10px" }}
          >
            {this.state.language_id == 0 ? <React.Fragment>Buy Test</React.Fragment> : <React.Fragment>परीक्षण खरीदें</React.Fragment>}
          </button>
        </div>
      );
    } else {
      return (
        <div
          className="col-12 col-lg-4 text-end py-3 py-md-auto  render-button-div d-flex"
          style={{
            flex: "0 0 53.333333%",
            flexDirection: "row",
            paddingRight: 0,
          }}
        >
          <button
            onClick={() => this.handleChooseSubject(item)}
            className="start-learning btn-custom"
            style={{ color: "white" }}
          >
            {" "}
            {this.state.language_id == 0 ? (
              <React.Fragment>Start Learning</React.Fragment>
            ) : (
              <React.Fragment>सीखना शुरू करें</React.Fragment>
            )}
          </button>
          <button
            className="start-learning bg-white ml-1"
            onClick={() =>
              this.setState({ testModal: true }, () =>
                this.getTests(item.subject_id)
              )
            }
          >
            Start Test
          </button>
          {/* <button onClick={()=>this.startTest(item)} className="start-learning btn-test btn-custom" style={{color:'white',marginTop:15,marginLeft:10}}> Start Test</button> */}
        </div>
      );
    }
  }
  renderCourses() {
    console.log(this.state.all_items);

    if (this.state.loading) {
      return (
        <div className="sweet-loading">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      if (this.state.all_items.length == 0) {
        return (
          <div className="styles--sc-shopping-list--empty--1isYv">
            <MDBIcon
              style={{ color: "#e8e9eb", marginBottom: 20 }}
              size="5x"
              icon="book-open"
              className="OpenBook"
            />
            <p>Your course list is empty. Keep exploring to find a course!</p>
            <a
              className="btn "
              onClick={() => this.props.history.push("/")}
              data-purpose="keep-shopping-action"
              style={{
                backgroundColor: "#c01825",
                border: "1px solid #c01825",
                color: "white",
              }}
            >
              Keep Learning
            </a>
          </div>
        );
      } else {
        return this.state.all_items.map((item, index) => {
          if (item.service_included == true) {
            return (
              <div key={index} className="col-12 course-row">
                <div className="row outer-div">
                  <div className="col-12 col-sm-12 col-12 col-lg-2 course-thumb-div">
                    <img className="course-thumb " src={item.image} />
                  </div>
                  <div className="col-12 col-lg-6 col-sm-12 col-12 mycourse_width">
                    <p className="course-title">{item.service_name}</p>
                    <p className="course-dates">
                      <span className="course-purchase">
                        Purchased on {this.commentdate(item.creation_time)}
                      </span>{" "}
                    </p>
                  </div>
                  <div className="col-12 col-lg-4 col-sm-12 render-button-div pr-0 d-flex justify-content-end">
                    <button
                      className="start-learning btn-custom disabled"
                      style={{ color: "white", background: "grey" }}
                    >
                      {this.state.language_id == 0 ? (
                        <React.Fragment>Service Purchased</React.Fragment>
                      ) : (
                        <React.Fragment>पाठ्यक्रम खरीदें</React.Fragment>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            );
          }
          return (
            <div key={index} className="col-12 course-row">
              <div className="row outer-div">
                <div className="col-12 col-sm-12 col-12 col-lg-2 course-thumb-div">
                  <img className="course-thumb " src={item.image} />
                </div>
                <div
                  className={
                    item.test_included == 1 && item.subject_included == 1
                      ? "col-12 col-lg-6 col-sm-12 col-12 mycourse_width"
                      : "col-12 col-lg-6 col-sm-12 col-12 mycourse_width"
                  }
                >
                  <p className="course-title">{item.subject_name}</p>
                  <p className="course-dates">
                    <span className="course-purchase">
                      Purchased on {this.commentdate(item.creation_time)}
                    </span>{" "}
                  </p>
                </div>
                {this.renderButton(item, index)}
              </div>
            </div>
          );
        });
      }
    }
  }

  fetchSubject = (subjectid, item) => {
    window.localStorage.removeItem("selected_course_user");
    window.localStorage.setItem(
      "selected_course_user",
      JSON.stringify({ id: subjectid, test_included: item.test_included })
    );
    window.localStorage.removeItem("sample_mode");
    window.localStorage.setItem("sample_mode", JSON.stringify(false));
    this.props.history.push({
      pathname: "/lessons",
      state: {
        id: subjectid,
      },
    });
  };

  render() {
    if (this.state.loading) {
      return "loading...";
    } else {
      return (
        <div
          id="my-courses"
          className="bg-whitesmoke"
          style={{ overflow: "hidden" }}
        >
          <NewHeader
            cart={
              this.state.cart.length == 0
                ? 0
                : JSON.parse(this.state.cart[0].items).length
            }
            {...this.props}
            active="My Courses"
          />

          <div className="container-c">
            <div className="row bg-white account-components-border">
              <div className="col-12 col-md-3  px-0 account-nav-ipad">
                <AccountNav {...this.props} active="My Courses" />
              </div>
              <div className="col-12 col-md-9 ">
                <Fade>
                  <div className="my-courses-container">
                    <h1 className="my-courses-title mt-4 mb-0 text-center text-md-left">
                      {this.state.language_id == 0 ? (
                        <React.Fragment>My Courses</React.Fragment>
                      ) : (
                        <React.Fragment>मेरे पाठ्यक्रम </React.Fragment>
                      )}
                    </h1>
                    <p className="my-account-subtitle mb-4 text-center text-md-left">
                      {this.state.language_id == 0 ? (
                        <React.Fragment>
                          Let's start learning new things for exploring the new
                          world.
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          आइए नई दुनिया की खोज के लिए नई चीजें सीखना शुरू करें।
                        </React.Fragment>
                      )}
                    </p>

                    <hr />
                    {this.renderCourses()}
                  </div>
                </Fade>
              </div>
            </div>
          </div>
          <ProfTen dialogues={this.state.dialogues} />
          <div style={{ height: "auto" }}>
            <MainFooterPage {...this.props} />
          </div>
          {this.renderTestModal()}
        </div>
      );
    }
  }
}

export default CourseComponent;
