import React, { Component } from "react";
import GridLoader from "react-spinners/GridLoader";
import ExamImage from "../../Assets/images/testO.jpg";
import Select from "react-select";
import styles from "./Exams.module.css";
import "../commonStyles.css";
import HomeHeader from "../../Reusable/Header/homeHeader";
import MainFooterPage from "../../Reusable/Footer/footer";
import { css } from "@emotion/core";
import Login from "../../Reusable/Header/login";
export default class Exams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exam_data: [],
      selected_test: {},
      userid: "",
      selectedOption: null,
      testExplanation: [],
      showLogin: false,
      loading: true,
    };
  }
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
  };
  async getTestDetails() {
    // let details = {
    //     userid: this.state.userid
    //   };
    //   console.log(details, "userid-details");

    let formBody = new FormData();
    formBody.append("user_id", this.state.userid);

    const response = await fetch("https://10x10learning.com/get_test_details", {
      method: "POST",
      body: formBody,
    });

    const responseJson = await response.json();
    console.log(responseJson, "rrr");
    if (responseJson.message == "ok") {
      this.setState({ exam_data: responseJson.result, loading: false }, () =>
        this.getTestExplaiantion()
      );
    } else {
      this.setState({ exam_data: null, loading: false });
    }
  }

  async getTestExplaiantion() {
    // let details = {
    //     userid: this.state.userid
    //   };
    //   console.log(details, "userid-details");

    let formBody = new FormData();
    formBody.append("user_id", this.state.userid);

    const response = await fetch("https://10x10learning.com/get_explaination", {
      method: "POST",
      body: formBody,
    });

    const responseJson = await response.json();
    console.log(responseJson, "uuu");
    localStorage.setItem("test_explanation",JSON.stringify(responseJson));
    let options = [];
    for (let i = 0; i < responseJson.length; i++) {
      options.push({ value: responseJson[i].explaination_id, label: responseJson[i].test_name });
    }
    console.log(options);
    let obj = {};
   
    this.setState({ testExplanation: options });
  }

  componentDidMount = async () => {
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (!userdata) {
      this.setState({ userid: -1, loading: false });
    } else {
      this.setState({ userid: userdata.user_id }, () => this.getTestDetails());
    }
  };

  startTest(item) {
    console.log(item, "ttt");
    window.localStorage.removeItem("selected_test");
    window.localStorage.setItem("selected_test", item.test_id);
    localStorage.setItem("test_for_subject", item.subject_name);
    localStorage.setItem('test_name',item.test_name)
    this.props.history.push("start-test");
  }

  renderTest(data) {
    return data.tests.map((item) => {
      return (
        <div className={`${styles.TestData} d-flex py-2`} key={item.test_id}>
          <p className={`${styles.TestName}`}>{item.test_name}</p>
          <button
            className={`${styles.StartTestBtn}`}
            onClick={() => this.startTest(item)}
          >
            Start Test
          </button>
        </div>
      );
    });
  }

  renderExams() {
    return this.state.exam_data.map((data) => {
      return (
        <div className={`${styles.ExamData}`} key={data.subject_id}>
          <h2 className={`${styles.SubjectName}`}>{data.subject_name}</h2>

          {this.renderTest(data)}
        </div>
      );
    });
  }
  showExplanation=()=>{
    localStorage.setItem('explanation',JSON.stringify(this.state.selectedOption))
    this.props.history.push('test_explanation')
  }

  render() {
    const { selectedOption } = this.state;
    console.log(this.state.selected_subject);

    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div id="exams">
          <HomeHeader {...this.props} />
          <div className={`${styles.ExamsMainDiv}`}>
            <div className={`${styles.BannerImageDiv} `}>
              <img
                className={`${styles.BannerImage}`}
                src={ExamImage}
                alt="Exam-Image"
              />

              <h1 className={`${styles.ExamsMainHeading}`}>Practice</h1>
            </div>
            {console.log(this.state.testExplanation)}
            <div className="d-flex justify-content-center flex-column align-items-center">
              <span>Your Evaluations</span>
              <div className="selectSubjectExplanation d-flex mt-4">
                <Select
                  value={selectedOption}
                  onChange={this.handleChange}
                  options={this.state.testExplanation}
                />
                <button  className={`${styles.StartTestBtn}`} style={{marginLeft:'20px'}} onClick={()=>this.showExplanation()}> Show</button>
              </div>
            </div>
            {this.state.userid == -1 ? (
              <p style={NoExamsStyle}>Log in to see the evaluations!</p>
            ) : this.state.exam_data == null ? (
              <p style={NoExamsStyle}>You have not done any practice tests!</p>
            ) : (
              <div className={`${styles.ExamsSet} row`}>
                {this.renderExams()}
              </div>
            )}
          </div>

          <div style={{ height: "auto" }}>
            <MainFooterPage {...this.props} />
          </div>
        </div>
      );
    }
  }
}

const NoExamsStyle = {
  textAlign: "center",
  margin: "10%",
  fontSize: "22px",
  fontStyle: "italic",
  color: "grey",
};
