import React, { Component } from "react";

import styles from "./products.module.css";
import "../commonStyles.css";
// import { FulfillingBouncingCircleSpinner } from 'react-epic-spinners';

import MainFooterPage from "../../Reusable/Footer/footer";
import swal from "sweetalert";
import { Container, Button } from "reactstrap";

import { MDBCollapse, MDBIcon, MDBBtn, MDBInput } from "mdbreact";
import StarsRating from "stars-rating";
import CoreImg from "../../Assets/images/v1.PNG";
import ShareImg from "../../Assets/images/share-option.png";
import Banner from "../../Assets/images/Banner.jpg";
import ReviewImg from "../../Assets/images/lac.PNG";

import StarRatings from "react-star-ratings";
import ScrollAnimation from "react-animate-on-scroll";
import { MDBCloseIcon } from "mdbreact";
import subject from "./subjects.json";
import HomeHeader from "../../Reusable/Header/productHeader";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import Login from "../../Reusable/Header/login";
import Slider from "react-slick";
import ProfTen from "../Prof10X10/prof10x10";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const axios = require('axios').default;

var lectureInTopic;

class product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showContact: false,
      clicked: [],
      activeIndex: 0,
      collapseID: "",
      subjectstobedisplayed: [],
      expanded: false,
      shown: true,
      studentsEnrolled: "",
      subjectOrders: "",
      subjectSelected: "",
      ratings: "",
      avgRating: "",
      // expanded1: false,
      shown1: true,
      expandedYes: false,
      expandedNo: false,
      userdata: null,
      cart_status: 0,
      showAdded: false,
      banner: "",

      test_selected: true,
      course_purchase: 0,
      item_in_cart: false,
      subject_data: {},
      subjects: [],
      cart: [],
      all_items: [],
      test_cost_zero: false,
      course_selected: true,
      test_cost: 0,

      //CourseContent
      CourseContentArray: {
        courseImg: `${CoreImg}`,
        heading: ``,
        subheading: ``,
        rating: "",
        creatorName: ``,
        lastUpdateEnglish: `Last Updated 8/2019`,
        lastUpdateHindi: ` आखरी अपडेट 8/2019`,
        language: `English,Hindi,Panjabi,Tamil`,
        learnHeading: `what you'll learn`,
        para: {},
        costAfterDiscount: ``,
        actualCost: `₹ 11000 `,

        instructorName: ``,
        lectureInTopic: "",
        instructorSub: ``,
        instructorDescription: ``,
        instructorRating: ``,
        instructorTotalReviews: `183,129`,
        reviewrName: "Anass N. Alnajjar",
        reviewrCourseandReview: "14 courses, 5 reviews ",
        timeofReview: `one month age`,
        reviewContent: `it was a tough course because i have learned a lot of concepts in only 16 Hours but it was an awesome journey and it was 100% help full for beginners in react-native and am going to buy the advanced version of this course also and before i even do i will recommend it because after taking this course i know that the next one will be awesome too.`,

        RequrmentList: [],
      },
      CourseInformation: [],
      descriptionCourse: [],
      studentsAlsobought: [],
      language_id: "",

      selected_subject: {},
      isdisable:'',
      loginToggle: false,
      banner: "",
      loading: true,

      email: "",
      phone: "",
      name: "",
      message: "",
    };
  }

  renderSubjectSlide() {
    var settings = {
      dots: false,
      // nextArrow:this.renderNext(),
      // prevArrow:this.renderPrev(),
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 577,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="studentBought row">{this.renderStudentsAlsobought()}</div>
    );
  }

  // componentDidMount() {}

  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Novr",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd + "/" + mm + "/" + dt.getFullYear().toString();
  }

  checkCart() {
    if (this.state.cart.length != 0) {
      for (var v = 0; v < JSON.parse(this.state.cart[0].items).length; v++) {
        console.log(this.state.cart[v], subject.subject_id);
        if (
          JSON.parse(this.state.cart[0].items)[v].subject_id ==
          this.state.selected_subject.subject_id
        ) {
          this.setState({
            item_in_cart: true,
          });
        }
      }
    }
  }
  getSubjectOrders = () => {
    let subject = JSON.parse(localStorage.getItem("selected_subject"));
    let subjectId = subject.subject_id;
    let details = {
      subject_id: subjectId,
    };
    console.log(details, "fetching-details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/get_all_orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },

        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          this.setState({ subjectOrders: responseJson }, () =>
            this.setStudentsEntrolled()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error);
        }),
    });
  };

  setStudentsEntrolled = () => {
    if (this.state.ratings.length != 0) {
      let mapRating = this.state.ratings.map((item) => {
        return item.rating_value;
      });
      let totalRating = mapRating.reduce((a, b) => {
        return a + b;
      }, 0);
      let avgRating = totalRating / this.state.ratings.length;
      this.setState(
        { avgRating },
        () => console.log(this.state.avgRating),
        () => this.getDetails()
      );
      console.log(this.state.ratings.length);
      if (this.state.subjectOrders.length != 0) {
        this.setState({ studentsEnrolled: this.state.subjectOrders.length });
      }
    }
  };
  getratingsOfSubject = () => {
    let subject = JSON.parse(localStorage.getItem("selected_subject"));
    let subjectid = subject.subject_id;
    let details = {
      subject_id: subjectid,
    };
    console.log(details, "fetching-details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/get_subject_rating", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          this.setState({ ratings: responseJson }, () =>
            this.getSubjectOrders()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error);
        }),
    });
  };

  getCart = () => {
    this.getOrders();
    let details = {
      user_id: this.state.userdata.user_id,
    };

    console.log(details, "fetching-details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState(
            {
              cart: responseJson,
            },
            () => {
              this.checkCart();
              this.checkTest();
            }
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };
  checkTest=()=>{
    axios.post('https://10x10learning.com/checktest', {
      id: this.state.selected_subject.subject_id,
    })
    .then((response)=> {
if(response.data.length==0) this.setState({test_selected:0,isdisable:true})
else this.setState({test_selected:1,isdisable:false})
      console.log(response,'aszx');
    })
    .catch((error) =>{
      console.log(error);
    });
  }

  componentDidMount() {
    let banner = JSON.parse(localStorage.getItem("productBannerImage"));
    this.setState({ banner: banner[0].link });
    let subject_id = JSON.parse(localStorage.getItem("selected_subject"));
    // console.log(language_id);
    let subjectSelected = JSON.parse(localStorage.getItem("selected_subject"));

    let language_id = subject_id.language_id;
    this.setState({ language_id, subjectSelected }, () => this.getDialogues());

    // getSubjectTopics()
    // () =>this.getDetails(),

    // this.getDetails();
  }

  getDialogues = async () => {
    let formBody = new FormData();
    formBody.append("language_id", this.state.language_id);
    try {
      const dialogueResponse = await fetch(
        "https://10x10learning.com/prof10_products",
        {
          method: "POST",
          body: formBody,
        }
      );
      const result = await dialogueResponse.json();
      const dialogues = JSON.parse(result[0].text).map(
        (item) => item.description
      );
      this.setState({ dialogues }, () => this.getsubjects());
    } catch (err) {
      console.log(err);
    }
  };

  getsubjects = () => {
    this.setState({
      response: fetch("https://10x10learning.com/get_subjects", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState(
            {
              subjects: responseJson,
            },
            () => this.getDetails()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  calCulatedTest() {
    console.log(this.state.selected_subject);
    if (this.state.selected_subject.test_cost == 0) {
      this.setState(
        {
          test_cost_zero: true,
        },
        () => this.getTopicsForSubject()
      );
    } else {
      this.setState(
        {
          test_cost: this.state.selected_subject.test_cost,
          test_cost_zero: false,
        },
        () => this.getTopicsForSubject()
      );
    }
  }

  getTopicsForSubject() {
    let formBody = new FormData();
    formBody.append("subject_id", this.state.selected_subject.subject_id);
    fetch("https://www.10x10learning.com/get_subject_topics", {
      method: "POST",
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        let topicData = [];
        JSON.parse(responseJson[0].topics).forEach((element) => {
          topicData.push(element.topic_id);
        });
        this.setState({ selected_topics: topicData }, () =>
          this.getLecturesForTopics()
        );
      });
  }

  getLecturesForTopics() {
    let formBody = new FormData();
    formBody.append("topic_ids", this.state.selected_topics);
    fetch("https://www.10x10learning.com/get_all_topic_lectures", {
      method: "POST",
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        let sampleExists =
          responseJson.filter((item) => item.sample == 1)[0] == undefined
            ? false
            : true;
        console.log(sampleExists);
        this.setState({
          selected_subject_topics: responseJson,
          sampleExists,
          loading: false,
        });
      });
  }
  getTopic = (id) => {
    let details = {
      topic_id: id,
    };
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    console.log(this.state.subjectId);

    fetch("https://10x10learning.com/get_topic", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "max-age=31536000",
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // lectureInTopic = JSON.parse(responseJson[0].lectures);
        // console.log(lectureInTopic);
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        swal("Warning!", "Check your network!", "warning");
        console.log(error);
      });

    // else {

    //     let formData = new FormData()
    //    // var pdf_filename = (this.state.selected_products.pdf_file).substr(28,(this.state.selected_products.pdf_file).length)
    //     formData.append('subject_id', this.state.subjectId)
    //     formData.append('subject_name',this.state.subjectName)
    //     formData.append('sub_title',this.state.subjectSubtitle)
    //     formData.append('cost',this.state.subjectCost)
    //     formData.append('old_cost', this.state.old_subjectCost)
    //     formData.append('duration', this.state.subjectDuration )
    //     formData.append('description',JSON.stringify(this.state.subjectDescriptions))
    //     formData.append('test_id',JSON.stringify(this.state.selectedTestIDs))
    //     formData.append('exams',JSON.stringify(this.state.selectedExams))
    //     formData.append('topics',JSON.stringify(this.state.dragDropTopics))
    //     formData.append('related_subjects',JSON.stringify(this.state.selectedSubjectList))
    //     formData.append('learning_topic',JSON.stringify(this.state.learningTopics))
    //     formData.append('requirements',JSON.stringify(this.state.learningRequirements))
    //     formData.append('status',1);
    //     formData.append('image',this.state.inputLink);
    //     formData.append('student_type',JSON.stringify(this.state.selectedStudentType));
    //     formData.append('class',JSON.stringify(this.state.selectedClass));
    //     formData.append('filename', this.state.filename);

    //     for (let key of formData.entries()) {
    //   }

    //     this.setState({
    //     response: fetch('https://10x10learning.com/edit_subject', {
    //     method: 'POST',
    //     body: formData

    //     }).then((response) => response.json())
    //     .then((responseJson)=>{

    //       this.props.history.push('/limitedaccess/subject-list');

    //     }

    //     )
    //     .catch((error) => {
    //     this.setState({
    //     loading:false
    //     })
    //     swal("Warning!", "Check your network!", "warning");
    //      console.log(error)
    //         })
    //     })

    //   }
  };

  getDetails = () => {
    var userdata = window.localStorage.getItem("userdata");
    const subjects = this.state.subjects.find(
      (subject) => subject.subject_name == this.props.match.params.name
    );
    if (subjects == undefined || typeof subjects == undefined) {
      console.log(subjects);
      console.log(this.props.match.params.name);
      this.props.history.push("/error");
    } else {
      let topicid = JSON.parse(subjects.topics)[0].id;
      console.log(topicid);
      // this.setState({subjects})
      // let subject=JSON.parse(subjectdata)
      var subjectfinal = [];
      // console.log(subjects.related_subjects)

      let relatedsub = JSON.parse(subjects.related_subjects).map(
        (item) => item.value
      );
      this.state.subjects.forEach((element) => {
        if (relatedsub.includes(element.subject_id)) {
          console.log(element);
          subjectfinal.push(element);
        }
      });
      console.log(subjectfinal);

      if (userdata != null) {
        this.setState(
          {
            userdata: JSON.parse(userdata),
          },
          () => this.getCart()
        );
      } else {
        this.setState({
          course_purchase: 2,
        });
      }

      if (subjects == null) {
        this.props.history.push("/");
      } else {
        let data2 = subjects;
        // data2.test_included = data2.test_included == undefined?1:data2.test_included;
        // data2.subject_included = data2.subject_included == undefined?1:data2.subject_included;
        console.log(data2);
        this.setState({
          selected_subject: data2,
        });
      }
      this.setState({ subjectstobedisplayed: subjectfinal }, () =>
        this.calCulatedTest()
      );
    }
  };
  // showButton = () => {
  //   this.setState({ expanded: !this.state.expanded, shown: !this.state.shown });
  // };
  // showButtonYes = () => {
  //   this.setState({
  //     expandedYes: !this.state.expandedYes,
  //     shown: !this.state.shown
  //   });
  // };
  // showButtonNo = () => {
  //   this.setState({
  //     expandedNo: !this.state.expanded,
  //     shown: !this.state.shown
  //   });
  // };
  // showButton1 = () => {
  //   this.setState({
  //     // expanded1: !this.state.expanded1,
  //     shown1: !this.state.shown1
  //   });
  // };

  toggleCollapse(id) {
    console.log(this.state.collapseID, "cccccccc");
    if (this.state.collapseID === id) {
      this.setState({
        collapseID: 0,
      });
    } else {
      this.setState({
        collapseID: id,
      });
    }
  }

  showcourseduration = (time) => {
    var num = time;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hours " + rminutes + " minutes";
  };

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.name !== this.props.match.params.name) {
      // Load Funcition....
      window.location.reload();
    }
  }
  handleStudentAlsoBought(subject) {
    console.log(subject);
    window.localStorage.removeItem("selected_subject");
    window.localStorage.setItem("selected_subject", JSON.stringify(subject));
    this.props.history.push(`/product/${subject.subject_name}`);
    // window.location.reload();
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.match.params.name !== this.props.match.params.name) {
  //     // Load Funcition....
  //     window.location.reload();
  //   }
  // }
  renderStudentsAlsobought() {
    return this.state.subjectstobedisplayed.map((modal) => {
      if (modal != undefined) {
        return (
          <React.Fragment>
            <div
              className={`${styles.StudentAlsoCardDiv} col-12 col-lg-3 col-md-6 `}
              onClick={() => this.handleStudentAlsoBought(modal)}
            >
              <div className={`${styles.StudentAlsoCardInnerDiv}`}>
                <div className={`${styles.picAndTime}`}>
                  <div>
                    <img
                      src={modal.image}
                      alt="10x10-logo"
                      className={`${styles.logoHeight1}`}
                    />
                  </div>
                  <div
                    style={{ backgroundColor: "#E5E5E5", textAlign: "center" }}
                  >
                    {" "}
                    <p className={`${styles.StudentsAlsoboughtHeadP}`}>
                      {" "}
                      {this.showcourseduration(modal.duration)}
                    </p>{" "}
                  </div>
                </div>
                <div className={`${styles.StudentsAlsoboughtHead}`}>
                  <h3 className={`${styles.StudentsAlsoboughtHeadH3}`}>
                    {modal.subject_name}
                  </h3>

                  <div className={`${styles.StudentsAlsoboughtBody}`}></div>

                  <div className={`${styles.StudentsAlsoboughtCosts}`}>
                    <h4 className={`${styles.StudentsAlsoboughtCostH4}`}>
                      Rs. {modal.cost}
                    </h4>
                    <strike>
                      <h4 className={`${styles.StudentsAlsoboughtCostP} ml-4`}>
                        Rs. {modal.old_cost}
                      </h4>
                    </strike>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }
    });
  }
  renderX() {
    const { expandedYes } = this.state;
    return (
      <div key={this.state.CourseContentArray.id}>
        <div>
          <h5 className={`${styles.review2H5}`}>
            {this.state.CourseContentArray.reviewrName}
            <span className={`${styles.review2H5P}`}>
              ( {this.state.CourseContentArray.reviewrCourseandReview} )
            </span>
          </h5>
        </div>
        <div className={`${styles.review2P}`}>
          <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <div>
              <span class="fa fa-star checked"></span>
              <span class="fa fa-star checked"></span>
              <span class="fa fa-star checked"></span>
              <span class="fa fa-star"></span>
              <span class="fa fa-star"></span>
            </div>
            <div>
              {" "}
              <p
                style={{ margin: "0% 0% 0% 5%" }}
                className={`${styles.productMainSecondTextp}`}
              >
                3.0{" "}
              </p>
            </div>
          </div>{" "}
          <br />
          <p className={`${styles.Review2P}`}>
            {this.state.CourseContentArray.timeofReview}{" "}
          </p>
        </div>
        <div>
          <p className={`${styles.review2P1}`}>
            {this.state.CourseContentArray.reviewContent}{" "}
          </p>
        </div>

        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <div>
            <text className={`${styles.review2P}`}>
              {this.state.language_id == 0 ? (
                <React.Fragment>Was this review helpful?</React.Fragment>
              ) : (
                <React.Fragment>क्या यह समीक्षा उपयोगी थी</React.Fragment>
              )}
            </text>
          </div>
          <div style={{ marginRight: ".5%", marginLeft: "2%" }}>
            <button
              className={`${styles.addYes}`}
              type="button"
              onclick="alert('Hello world!')"
              onClick={this.showButtonYes}
            >
              {this.state.language_id == 0 ? (
                <React.Fragment>Yes</React.Fragment>
              ) : (
                <React.Fragment>हाँ</React.Fragment>
              )}
            </button>
          </div>
          <div style={{ marginRight: "3%", marginLeft: ".5%" }}>
            <button
              className={`${styles.addNo}`}
              type="button"
              onclick="alert('Hello world!')"
              onClick={this.showButtonYes}
            >
              {this.state.language_id == 0 ? (
                <React.Fragment>No</React.Fragment>
              ) : (
                <React.Fragment>नहीं</React.Fragment>
              )}
            </button>
          </div>
          <div>
            {" "}
            <text className={`${styles.review2P}`}> Report </text>{" "}
          </div>
        </div>
        <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <div>
            <div>
              <Container
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                {expandedYes && ( //show if expanded is true
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <p className={`${styles.DescriptionP}`}>
                        Thank you! You have successfully submitted feedback for
                        this review
                      </p>
                    </div>
                  </div>
                )}

                {/* 
         <a> <p style={{color:'#0093D9',fontWeight:'500',fontSize:'18px'}} >
         {this.state.shown ? "+ See more" : "- See less"} </p>  </a> */}
              </Container>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDescription() {
    const { expanded } = this.state;

    return JSON.parse(this.state.selected_subject.description).map((modal) => {
      return (
        <React.Fragment>
          <p
            className={
              this.state.language_id == 0
                ? `${styles.DescriptionP} opensansRLine`
                : `${styles.DescriptionP} opensansRLine`
            }
          >
            {modal.description}
          </p>
        </React.Fragment>
      );
    });
  }
  renderNumberOfLecture = () => {
    console.log(lectureInTopic);
    if (lectureInTopic != undefined)
      return <text>{lectureInTopic.length} lectures </text>;
  };
  renderRequirement() {
    return JSON.parse(this.state.selected_subject.requirements).map((modal) => {
      return (
        <React.Fragment>
          <ul style={{ listS: "disc" }} className={`${styles.RequrmentList}`}>
            <li
              className={
                this.state.language_id == 0
                  ? `${styles.RequrmentList1} opensansRLine`
                  : `${styles.RequrmentList1} opensansRLine`
              }
            >
              {modal.requirement}
            </li>
          </ul>
        </React.Fragment>
      );
    });
  }

  renderCollapse() {
    let topics = this.state.topics;

    return JSON.parse(this.state.selected_subject.topics).map(
      (modal, index) => {
        console.log(modal);
        // this.getTopic(modal.topic_id);
        const lectures = JSON.parse(
          this.state.selected_subject_topics.filter(
            (item) => item.topic_id === modal.id
          )[0].lectures
        );

        return (
          <React.Fragment>
            <MDBBtn
              className={`${styles.CollapseBtn} `}
              color="primary"
              onClick={() => this.toggleCollapse(modal.id)}
              style={{ marginBottom: "1rem", paddingTop: "20px" }}
            >
              <div
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <div style={{ float: "left", marginRight: "4%" }}>
                  <i
                    style={{ color: "black" }}
                    className={
                      this.state.collapseID == modal.id
                        ? "fas fa-minus"
                        : "fas fa-plus"
                    }
                  ></i>
                </div>
                <div className={`${styles.CollapseBtnIntroduction}`}>
                  {" "}
                  <text>{modal.name} </text>{" "}
                </div>
                <div className={`${styles.CollapseBtnLectures}`}>
                  {" "}
                  {/* <text>{this.state.lectureInTopic.length} lectures </text>{" "} */}
                  {this.renderNumberOfLecture()}
                </div>
                <div className={`${styles.CollapseBtnTime} d-none`}>
                  {" "}
                  <text>
                    {" "}
                    20{" "}
                    {this.state.language_id == 0 ? (
                      <React.Fragment>min</React.Fragment>
                    ) : (
                      <React.Fragment>मिनट</React.Fragment>
                    )}
                  </text>{" "}
                </div>
              </div>
            </MDBBtn>

            <MDBCollapse
              className={`${styles.CollapseInside}`}
              id={modal.id}
              isOpen={this.state.collapseID}
            >
              <ul>
                {lectures
                  .sort((a, b) => a.sortOrder - b.sortOrder)
                  .map((item) => {
                    return <li>{item.lecture_name}</li>;
                  })}
              </ul>
            </MDBCollapse>
          </React.Fragment>
        );
      }
    );
  }

  // window.localStorage.removeItem('selected_course_user')
  // window.localStorage.setItem('selected_course_user',JSON.stringify({id:subjectid,}))
  // window.localStorage.removeItem('sample_mode');
  // window.localStorage.setItem("sample_mode", JSON.stringify(false))
  // this.props.history.push({
  //     pathname: "/lessons",
  //     state: {
  //         id:subjectid
  //     }
  //   });

  fetchSubject = (subjectid) => {
    window.localStorage.removeItem("selected_course_user");
    window.localStorage.setItem(
      "selected_course_user",
      JSON.stringify({ id: subjectid })
    );
    window.localStorage.removeItem("sample_mode");
    window.localStorage.setItem("sample_mode", JSON.stringify(false));
    this.props.history.push({
      pathname: "/lessons",
      state: {
        id: subjectid,
      },
    });
  };

  renderAddToCart() {
    console.log(this.state.loading, this.state.course_purchase);
    if (this.state.course_purchase == 1) {
      return (
        <React.Fragment>
          <div
            className={`${styles.productCourseContentPic6}`}
            style={{ marginTop: "2%", marginBottom: "2%" }}
          >
            <p className={`text-muted ${styles.alreadyPurchasedText}`}>
              {this.state.language_id == 0 ? (
                <React.Fragment>
                  You have purchased this course and its test series
                </React.Fragment>
              ) : (
                <React.Fragment>
                  आपने यह पाठ्यक्रम और इसकी परीक्षण श्रृंखला खरीदी है
                </React.Fragment>
              )}
            </p>
            <button
              onClick={() =>
                this.fetchSubject(this.state.selected_subject.subject_id)
              }
              className={`${styles.addCart}`}
              type="button"
            >
              {this.state.language_id == 0 ? (
                <React.Fragment>Go to Course</React.Fragment>
              ) : (
                <React.Fragment>पाठ्यक्रम में जाओ</React.Fragment>
              )}
            </button>
          </div>
        </React.Fragment>
      );
    } else if (this.state.course_purchase == 2) {
      if (this.state.item_in_cart) {
        return (
          <div
            className={`${styles.productCourseContentPic6}`}
            style={{ marginTop: "2%", marginBottom: "2%", height: "40px" }}
          >
            <button
              onClick={() => this.props.history.push("/my-cart")}
              className={`${styles.addCart}`}
              type="button"
            >
              {this.state.language_id == 0 ? (
                <React.Fragment>Go to Cart</React.Fragment>
              ) : (
                <React.Fragment>कार्ट में जाएँ</React.Fragment>
              )}
            </button>
          </div>
        );
      } else {
        return (
          <div
            className={`${styles.productCourseContentPic6}`}
            style={{ marginTop: "2%", marginBottom: "2%", height: "40px" }}
          >
            <button
              onClick={() => this.addToCart()}
              className={`${styles.addCart}`}
              type="button"
              style={{ fontSize: "18px" }}
            >
              {this.state.language_id == 0 ? (
                <React.Fragment>Add to Cart</React.Fragment>
              ) : (
                <React.Fragment>कार्ट में डालें</React.Fragment>
              )}
            </button>
          </div>
        );
      }
    } else if (this.state.course_purchase == 3) {
      return (
        <React.Fragment>
          <div
            className={`${styles.productCourseContentPic6}`}
            style={{ marginTop: "2%", marginBottom: "2%", height: "auto" }}
          >
            <p className={`text-muted ${styles.alreadyPurchasedText}`}>
              You have purchased the study material for this course.
            </p>

            <button
              onClick={() =>
                this.fetchSubject(this.state.selected_subject.subject_id)
              }
              className={`${styles.addCart}`}
              type="button"
            >
              Go to Course
            </button>

            <button
              onClick={() => this.buyTestWOSubject()}
              className={`${styles.addCart} mt-2`}
              type="button"
            >
              Buy Test
            </button>
          </div>
        </React.Fragment>
      );
    } else if (this.state.course_purchase == 4) {
      return (
        <React.Fragment>
          <div
            className={`${styles.productCourseContentPic6}`}
            style={{ marginTop: "2%", marginBottom: "2%", height: "auto" }}
          >
            <p className={`text-muted ${styles.alreadyPurchasedText}`}>
              You have purchased the test series for this course
            </p>
            <button
              onClick={() => this.props.history.push("/start-test")}
              className={`${styles.addCart}`}
              type="button"
            >
              Start Test
            </button>

            <button
              onClick={() => this.buyCourseWOTest()}
              style={{ backgroundColor: "white", color: "black" }}
              className={`${styles.addCart} mt-2`}
              type="button"
            >
              Buy Course
            </button>
          </div>
        </React.Fragment>
      );
    }
  }

  changeSubjectID() {
    let { selected_subject } = this.state;
    if (this.state.course_selected == false) {
      selected_subject.subject_included = 0;
    } else {
      selected_subject.subject_included = 1;
    }
    this.setState({ selected_subject }, () => console.log(selected_subject));
  }
  handleCheckComplete() {
    if (this.state.test_cost == 0) {
    } else {
      if (
        this.state.course_selected == true &&
        this.state.test_selected == false
      ) {
      } else {
        this.setState({ course_selected: !this.state.course_selected }, () =>
          this.changeSubjectID()
        );
      }
    }
  }
  changeTestID() {
    let { selected_subject } = this.state;
    if (this.state.test_selected == false) {
      selected_subject.test_included = 0;
    } else {
      selected_subject.test_included = 1;
    }
    this.setState({ selected_subject }, () => console.log(selected_subject));
  }
  handleCheckQuestion() {
    if (this.state.test_cost == 0) {
    } else {
      if (
        this.state.course_selected == false &&
        this.state.test_selected == true
      ) {
      } else {
        this.setState({ test_selected: !this.state.test_selected }, () =>
          this.changeTestID()
        );
      }
    }
  }
  renderCostTest() {
    if (this.state.test_cost == 0) {
      return " Free";
    } else {
      return ` Rs.${this.state.test_cost}`;
    }
  }

  handleSampleCourseContent(subjectid) {
    console.log(this.state);
    window.localStorage.removeItem("selected_course_user");
    window.localStorage.setItem(
      "selected_course_user",
      JSON.stringify({ id: subjectid })
    );
    window.localStorage.removeItem("sample_mode");
    window.localStorage.setItem("sample_mode", JSON.stringify(true));

    window.open(
      `https://10x10learning.com/lessons/${btoa(subjectid)}`,
      "_blank",
      "width=1366,height=768"
    );
    // this.props.history.push({
    //   pathname: "/lessons",
    //   state: {
    //     sampleMode: true
    //   }
    // });
  }

  renderOptions() {
    if (this.state.course_purchase == 2) {
      return (
        <div
          style={{ width: "100%", alignItems: "center" }}
          className="colDefault"
        >
          <div
            className={`${styles.productCourseContentBoxQuestionBank}`}
            style={{ marginTop: "5%" }}
          >
            <MDBInput
              filled
              type="checkbox"
              id="checkbox1"
              className="align-items-center position-static"
              containerClass="mr-0 align-items-center d-flex"
              checked={this.state.course_selected}
              // disabled
              onChange={() => this.handleCheckComplete()}
            />

            <div
              className="rowDefault px-3"
              style={{
                width: "90%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <text
                style={{
                  marginLeft: "0%",
                  fontFamily: "Ubuntu-r",
                  fontSize: "18px",
                }}
              >
                {this.state.language_id == 0 ? (
                  <React.Fragment>Course</React.Fragment>
                ) : (
                  <React.Fragment>कोर्स</React.Fragment>
                )}
              </text>
              <text>{` Rs.${this.state.selected_subject.cost}`}</text>
            </div>
          </div>

          <div
            className={`${styles.productCourseContentBoxQuestionBank}`}
            style={{ marginTop: "5%" }}
          >
            <MDBInput
              filled
              type="checkbox"
              id="checkbox2"
              className="position-static"
              containerClass="align-items-center mr-0 d-flex"
              onChange={() => this.handleCheckQuestion()}
              checked={this.state.test_selected}
              disabled={this.state.isdisable}
            />

            <div
              className="rowDefault px-3"
              style={{
                width: "90%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <text
                style={{
                  marginLeft: "0%",
                  fontFamily: "Ubuntu-r",
                  fontSize: "18px",
                }}
              >
                {this.state.language_id == 0 ? (
                  <React.Fragment>Question Bank</React.Fragment>
                ) : (
                  <React.Fragment>प्रश्न बैंक</React.Fragment>
                )}
              </text>
              <text>{this.renderCostTest()}</text>
            </div>
          </div>

          <div
            className={`${styles.productCourseContentPic5}`}
            style={{ marginTop: "7%" }}
          >
            {this.state.userdata != null ? (
              <React.Fragment>
                <p
                  className={
                    !this.state.sampleExists
                      ? "d-none"
                      : `${styles.POffproductCourseContentPic5} text-center`
                  }
                  onClick={() =>
                    this.handleSampleCourseContent(
                      this.state.selected_subject.subject_id
                    )
                  }
                >
                  View Sample Course Content
                </p>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ display: "none" }}>
          <div className="productCourseContent-box" style={{ marginTop: "5%" }}>
            <MDBInput
              filled
              type="checkbox"
              id="checkbox1"
              className="align-items-center position-static"
              containerClass="mr-0 align-items-center d-flex"
              checked={this.state.course_selected}
              onChange={() => this.handleCheckComplete()}
            />{" "}
            <span style={{ marginLeft: "15%", fontFamily: "Ubuntu-r" }}>
              {this.state.language_id == 0 ? (
                <React.Fragment>Complete Course</React.Fragment>
              ) : (
                <React.Fragment>पूर्ण कोर्स</React.Fragment>
              )}
            </span>
          </div>
          <div
            className={`${styles.productCourseContentPic4}`}
            style={{ marginTop: "5%" }}
          >
            <MDBInput
              filled
              type="checkbox"
              id="checkbox2"
              className="align-items-center position-static"
              containerClass="mr-0 align-items-center d-flex "
              onChange={() => this.handleCheckQuestion()}
              // checked={this.state.test_selected}
              checked={this.state.test_selected}
              disabled={this.state.isdisable}
            />{" "}
            <span style={{ marginLeft: "15%", fontFamily: "Ubuntu-r" }}>
              {this.state.language_id == 0 ? (
                <React.Fragment>Question Bank</React.Fragment>
              ) : (
                <React.Fragment>प्रश्न बैंक</React.Fragment>
              )}
            </span>
          </div>

          <div
            className={`${styles.productCourseContentPic5}`}
            style={{ marginTop: "7%" }}
          >
            {this.state.userdata != null ? (
              <React.Fragment>
                <p
                  className={
                    !this.state.sampleExists
                      ? "d-none"
                      : `${styles.POffproductCourseContentPic5} text-center`
                  }
                  onClick={() =>
                    this.handleSampleCourseContent(
                      this.state.selected_subject.subject_id
                    )
                  }
                >
                  View Sample Course Content
                </p>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }
  }
  buyNow() {
    this.addToCart();
    if (this.state.userdata) this.props.history.push("/my-cart");
  }

  addToCart = async () => {
    if (this.state.userdata == null) {
      this.setState({ showLogin: true });
    } else {
      let details = {
        user_id: this.state.userdata.user_id,
        items: JSON.stringify([
          {
            subject_id: this.state.selected_subject.subject_id,
            test_included: this.state.test_selected ? 1 : 0,
            subject_included: this.state.course_selected ? 1 : 0,
          },
        ]),
      };
      console.log(details, "fetching-details");

      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://10x10learning.com/update_cart", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson, "update_cart");
            this.setState(
              {
                showAdded: true,
                cart_status: responseJson.status,
                item_in_cart: true,
              },
              () => this.trigger()
            );
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            alert("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    }
  };

  selectiveTrigger() {
    this.trigger();
    if (this.state.userdata) this.props.history.push("/my-cart");
  }

  buyCourseWOTest() {
    if (this.state.userdata == null) {
      this.setState({ loginToggle: true });
    } else {
      let details = {
        user_id: this.state.userdata.user_id,
        items: JSON.stringify([
          {
            subject_id: this.state.selected_subject.subject_id,
            test_included: 0,
            subject_included: 1,
          },
        ]),
      };

      console.log(details, "fetching-details");
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://10x10learning.com/update_cart", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson, "update_cart");
            this.setState(
              {
                showAdded: true,
                cart_status: responseJson.status,
                item_in_cart: true,
              },
              () => this.selectiveTrigger()
            );
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            alert("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    }
  }

  buyTestWOSubject() {
    if (this.state.userdata == null) {
      this.setState({ loginToggle: true });
    } else {
      //   this.setState({
      //     loading:true
      // })
      let details = {
        user_id: this.state.userdata.user_id,
        items: JSON.stringify([
          {
            subject_id: this.state.selected_subject.subject_id,
            test_included: 1,
            subject_included: 0,
          },
        ]),
      };

      console.log(details, "fetching-details");
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://10x10learning.com/update_cart", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson, "update_cart");
            this.setState(
              {
                showAdded: true,
                cart_status: responseJson.status,
                item_in_cart: true,
              },
              () => this.selectiveTrigger()
            );
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            alert("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    }
  }
  renderBuy() {
    if (this.state.course_purchase == 2) {
      return (
        <div
          className={`${styles.productCourseContentPic7}`}
          style={{ marginTop: "2%", marginBottom: "2%" }}
        >
          <button
            onClick={() => this.buyNow()}
            className={`${styles.buyNow}`}
            type="button"
            onclick="alert('Hello world!')"
          >
            {this.state.language_id == 0 ? (
              <React.Fragment>Buy Now</React.Fragment>
            ) : (
              <React.Fragment>अभी खरीदें</React.Fragment>
            )}
          </button>
        </div>
      );
    } else {
      return (
        <button
          className={`${styles.buyNow} d-none`}
          type="button"
          onclick="alert('Hello world!')"
        >
          Already Purchased
        </button>
      );
    }
  }
  renderPercent(old_cost, new_cost) {
    return;
  }
  renderPrice() {
    if (this.state.selected_subject.old_cost == 0) {
      if (this.state.test_cost == 0) {
        return (
          <div
            className={`${styles.productCourseContentPic2}`}
            style={{
              marginTop: "6%",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: "",
              paddingRight: "",
            }}
          >
            <text
              className={`${styles.productCourseContentPic2Text}`}
              style={{}}
            >
              Rs.{this.state.selected_subject.cost}
            </text>
          </div>
        );
      } else {
        if (
          this.state.test_selected == false &&
          this.state.course_selected == true
        ) {
          return (
            <div
              className={`${styles.productCourseContentPic2}`}
              style={{
                marginTop: "6%",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "",
                paddingRight: "",
              }}
            >
              <text
                className={`${styles.productCourseContentPic2Text}`}
                style={{}}
              >
                Rs.{this.state.selected_subject.cost}
              </text>
            </div>
          );
        } else if (
          this.state.test_selected == true &&
          this.state.course_selected == false
        ) {
          return (
            <div
              className={`${styles.productCourseContentPic2}`}
              style={{
                marginTop: "6%",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "",
                paddingRight: "",
              }}
            >
              <text
                className={`${styles.productCourseContentPic2Text}`}
                style={{}}
              >
                Rs.{this.state.test_cost}
              </text>
            </div>
          );
        } else if (
          this.state.test_selected == true &&
          this.state.course_selected == true
        ) {
          return (
            <div
              className={`${styles.productCourseContentPic}`}
              style={{
                marginTop: "6%",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "",
                paddingRight: "",
              }}
            >
              <text
                className={
                  this.state.course_purchase == 1
                    ? "d-none"
                    : `${styles.productCourseContentPic2Text}`
                }
                style={{}}
              >
                Rs.
                {this.state.test_cost +
                  Number(this.state.selected_subject.cost)}
              </text>
            </div>
          );
        }
      }
    } else {
      if (this.state.test_cost == 0) {
        return (
          <div
            className={`${styles.productCourseContentPic2}`}
            style={{ marginTop: "6%" }}
          >
            <text
              className={`${styles.productCourseContentPic2Text}`}
              style={{}}
            >
              Rs.{this.state.selected_subject.cost}
              <span
                className={`${styles.productCourseContentPic2TextSpan}`}
                style={{}}
              >
                <strike> Rs.{this.state.selected_subject.old_cost} </strike>{" "}
              </span>
              <span
                className={`${styles.productCourseContentPic2TextSpan}`}
                style={{}}
              >
                {" "}
                {100 -
                  Math.round(
                    (this.state.selected_subject.cost /
                      this.state.selected_subject.old_cost) *
                      100
                  )}
                % off
              </span>{" "}
            </text>
          </div>
        );
      } else {
        if (
          this.state.test_selected == false &&
          this.state.course_selected == true
        ) {
          return (
            <div
              className={`${styles.productCourseContentPic2}`}
              style={{
                marginTop: "6%",
                display: "",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              <text
                className={`${styles.productCourseContentPic2Text}`}
                style={{}}
              >
                Rs.{this.state.selected_subject.cost}
                <span
                  className={`${styles.productCourseContentPic2TextSpan}`}
                  style={{}}
                >
                  <strike> Rs.{this.state.selected_subject.old_cost} </strike>{" "}
                </span>
                <span
                  className={`${styles.productCourseContentPic2TextSpan}`}
                  style={{}}
                >
                  {" "}
                  {100 -
                    Math.round(
                      (this.state.selected_subject.cost /
                        this.state.selected_subject.old_cost) *
                        100
                    )}
                  % off
                </span>{" "}
              </text>
            </div>
          );
        } else if (
          this.state.test_selected == true &&
          this.state.course_selected == false
        ) {
          return (
            <div
              className={`${styles.productCourseContentPic2}`}
              style={{
                marginTop: "6%",
                display: "",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              <text
                className={`${styles.productCourseContentPic2Text}`}
                style={{}}
              >
                Rs.{this.state.test_cost}
              </text>
            </div>
          );
        } else if (
          this.state.test_selected == true &&
          this.state.course_selected == true
        ) {
          console.log();
          return (
            <div
              className={`${styles.productCourseContentPic2}`}
              style={{
                marginTop: "6%",
                display: "",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              <text
                className={
                  this.state.course_purchase == 1
                    ? "d-none"
                    : `${styles.productCourseContentPic2Text}`
                }
                style={{}}
              >
                Rs.
                {Number(this.state.selected_subject.cost) +
                  Number(this.state.test_cost)}
                <span
                  className={`${styles.productCourseContentPic2TextSpan}`}
                  style={{}}
                >
                  <strike>
                    {" "}
                    Rs.
                    {Number(this.state.selected_subject.old_cost) +
                      Number(this.state.test_cost)}{" "}
                  </strike>{" "}
                </span>
                <span
                  className={`${styles.productCourseContentPic2TextSpan}}`}
                  style={{}}
                >
                  {" "}
                  {100 -
                    Math.round(
                      ((Number(this.state.selected_subject.cost) +
                        Number(this.state.test_cost)) /
                        (Number(this.state.selected_subject.old_cost) +
                          Number(this.state.test_cost))) *
                        100
                    )}
                  % off
                </span>{" "}
              </text>
            </div>
          );
        }
      }
    }
  }
  rendeCourseCard() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        key={this.state.CourseContentArray.id}
      >
        <img
          src={this.state.selected_subject.image}
          alt="10x10-logo"
          className={`${styles.logoHeight} ${styles.productCourseContentPic1}`}
        />

        {this.renderPrice()}

        {this.renderOptions()}

        {this.renderAddToCart()}

        {this.renderBuy()}
        {/* 
        <div
          className={`${styles.productCourseContentPic8}`}
          style={{ marginTop: "2%", marginBottom: "2%" }}
        >
          <button
            className={`${styles.shareIt}`}
            type="button"
            onclick="alert('Hello world!')"
          >
            <div style={{ float: "left", width: "100%" }}>
              <img
                src={ShareImg}
                alt="google-logo"
                className=""
                height="20px"
                color="#fff"
              />{" "}
              <text style={{ marginLeft: "" }}>Share</text>{" "}
            </div>{" "}
          </button>
        </div> */}
      </div>
    );
  }

  renderlearn() {
    let columns = [];

    JSON.parse(this.state.selected_subject.learning_topic).forEach(
      (item, key) => {
        columns.push(
          <div className={`${styles.productCourseContentText1}`}>
            <div className={`${styles.productCourseContentTextInner1}`}>
              <div>
                <i class="fas fa-check"></i>{" "}
              </div>

              <div style={{ marginLeft: "2%" }}>
                <text
                  className={
                    this.state.language_id == 0
                      ? "opensansRLine"
                      : "opensansRLine"
                  }
                >
                  {" "}
                  {item.topic}{" "}
                </text>
              </div>
            </div>
          </div>
        );
        if ((key + 1) % 2 === 0) {
          columns.push(<div className="w-100"></div>);
        }
      }
    );

    return (
      <div>
        <div className={`${styles.productCourseContentTextHeading}`}>
          <h1
            className={
              this.state.language_id == 0
                ? `${styles.productCourseContentTextHeadingH1} opensansB`
                : `${styles.productCourseContentTextHeadingH1} opensansR`
            }
          >
            {this.state.language_id == 0 ? (
              <React.Fragment>What will you learn</React.Fragment>
            ) : (
              <React.Fragment>आप क्या सीखेंगे</React.Fragment>
            )}
          </h1>
        </div>
        <div className="row row_mobile">
          <p
            className={
              this.state.language_id == 0 ? ` opensansB` : `opensansRLine`
            }
          >
            {columns}
          </p>
        </div>
      </div>
    );
  }

  rendeCourseContent() {
    console.log(this.state.language_id);
    return (
      <div key={this.state.selected_subject.subject_id}>
        <h2
          className={
            this.state.language_id == 0
              ? `${styles.productMainSecondTextH2} opensansB`
              : `${styles.productMainSecondTextH2} opensansB`
          }
        >
          {" "}
          {this.state.selected_subject.subject_name}{" "}
        </h2>
        <h5
          className={
            this.state.language_id == 0
              ? `${styles.productMainSecondTextH5} opensansB`
              : `${styles.productMainSecondTextH5} opensansB`
          }
        >
          {this.state.selected_subject.sub_title}{" "}
        </h5>
        {/* 
        <div className="product-page-course-rating d-flex align-items-center">
          {this.state.avgRating.length != 0 ? (
            <StarsRating
              count={5}
              edit={false}
              size={21}
              value={this.state.avgRating}
              color2={"#ffd700"}
            />
          ) : (
            ""
          )}

          <p
            style={{ color: "white" }}
            className={`${styles.productPageReviewFont} mb-0`}
          >
            {this.state.avgRating.length != 0 ? this.state.avgRating : ""}
            {this.state.avgRating.length != 0 ? "," : ""}
            {this.state.studentsEnrolled.length != 0
              ? this.state.studentsEnrolled
              : ""}
            {this.state.studentsEnrolled.length != 0 ? "Students Enrolled" : ""}
          </p>
        </div> */}

        <p
          className={`${styles.productMainSecondTextp}`}
          style={{ marginBottom: 0 }}
        >
          {this.state.language_id == 0 ? (
            <React.Fragment>Created by Team 10x10 Learning</React.Fragment>
          ) : (
            <React.Fragment>टीम 10x10learning द्वारा बनाया गया द्वारा बनाया गया</React.Fragment>
          )}
        </p>
      </div>
    );
  }

  trigger() {
    this.getCart();
    setTimeout(() => {
      this.setState({
        showAdded: false,
      });
    }, 2000);
  }

  showUpdateBar() {
    if (this.state.showAdded) {
      if (this.state.cart_status == 240) {
        return (
          <ScrollAnimation
            animateOnce={false}
            animateIn="slideInLeft"
            className={`slideInLeft ${styles.Toast2}`}
            animationOut="slideOutLeft"
            isVisible={true}
          >
            <div id="snackbar">Added to cart</div>
          </ScrollAnimation>
        );
      } else {
        return (
          <ScrollAnimation
            animateOnce={false}
            animateIn="slideInLeft"
            className={`slideInLeft ${styles.Toast2}`}
            animationOut="slideOutLeft"
            isVisible={true}
          >
            <div id="snackbar">Added to cart </div>
          </ScrollAnimation>
        );
      }
    }
  }
  getOrders = () => {
    //   this.setState({
    //     loading:true
    // })
    let today = new Date().getTime();

    let details = {
      user_id: this.state.userdata.user_id,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      // loading: true,
      response: fetch("https://10x10learning.com/get_my_orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var items = [];
          for (var v = 0; v < responseJson.length; v++) {
            for (
              var v1 = 0;
              v1 < JSON.parse(responseJson[v].items).length;
              v1++
            ) {
              var data = JSON.parse(responseJson[v].items)[v1];
              data.creation_time = responseJson[v].ts;
              let order_date = new Date(data.creation_time).getTime();
              let expiry = data.expires_in;
              console.log(expiry);
              let expirationTime = expiry * 24 * 60 * 60 * 1000;
              let expirationDate = new Date(
                order_date + expirationTime
              ).getTime();
              console.log(expirationDate, today);
              if (expirationDate > today) {
                items.push(data);
              }
            }
          }
          var data_to_send = items;

          this.checkDuplicates(data_to_send);
          //count = 0 NOT PURCHASED
          //count = 1 COURSE AND TEST PURCHASED
          //count = 2 ONLY COURSE PURCHASED
          //count = 3 ONLY TEST PURCHASED
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  checkDuplicates(originalArray) {
    var trimmedArray = [];

    var all_courses = originalArray;
    if (originalArray.length != 0) {
      for (var i = 0; i < originalArray.length; i++) {
        for (var i1 = 0; i1 < originalArray.length; i1++) {
          if (i != i1) {
            console.log(originalArray[i], i, originalArray[i1], i1);
            if (originalArray[i].subject_id == originalArray[i1].subject_id) {
              //   console.log(originalArray[i],originalArray[i1],'iii')
              var matched_object = originalArray[i];

              matched_object.subject_included = 1;
              matched_object.test_included = 1;
              trimmedArray.push(matched_object);
            }
          }
        }
      }
    }
    console.log(all_courses, "iii");

    for (var i3 = 0; i3 < all_courses.length; i3++) {
      for (var i4 = 0; i4 < trimmedArray.length; i4++) {
        if (all_courses[i3].subject_id == trimmedArray[i4].subject_id) {
          all_courses.splice(i3, 1);
          all_courses.splice(i3, 0, trimmedArray[i4]);
        }
      }
    }
    this.removeDuplicates(all_courses, "subject_id");
  }

  removeDuplicates(originalArray, objKey) {
    var trimmedArray = [];
    var values = [];
    var value;

    for (var i = 0; i < originalArray.length; i++) {
      value = originalArray[i][objKey];

      if (values.indexOf(value) === -1) {
        trimmedArray.push(originalArray[i]);
        values.push(value);
      }
    }

    this.setState(
      {
        all_items: trimmedArray,
      },
      () => {
        var items = [];
        var count = 0;
        for (var v = 0; v < this.state.all_items.length; v++) {
          for (var v1 = 0; v1 < this.state.all_items.length; v1++) {
            if (
              this.state.all_items[v].subject_id ==
              this.state.selected_subject.subject_id
            ) {
              if (this.state.all_items[v].course_status == "active") {
                if (
                  this.state.all_items[v].subject_included == 0 &&
                  this.state.all_items[v].test_included == 1
                ) {
                  count = 3;
                } else if (
                  this.state.all_items[v].subject_included == 1 &&
                  this.state.all_items[v].test_included == 0
                ) {
                  count = 2;
                } else if (
                  this.state.all_items[v].subject_included == 1 &&
                  this.state.all_items[v].subject_included == 1
                ) {
                  count = 1;
                }
              }
            }
          }
        }
        if (count == 0) {
          this.setState({
            course_purchase: 2,
          });
        } else if (count == 1) {
          this.setState({
            course_purchase: 1,
            test_selected: true,
            course_selected: true,
          });
        } else if (count == 2) {
          this.setState({
            course_purchase: 3,
            test_selected: true,
            course_selected: false,
          });
        } else if (count === 3) {
          this.setState({
            course_purchase: 4,
            test_selected: false,
            course_selected: true,
          });
        }
      }
    );
  }

  validateEmail = (text) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      this.setState({ email: text, valid_email: false });
      return false;
    } else {
      this.setState({ email: text, valid_email: true });
    }
  };

  addContact = async () => {
    if (this.state.valid_email == false) {
      swal("Warning!", "Please enter valid Email Id", "warning");
    } else {
      if (
        this.state.email.length === 0 ||
        this.state.phone.length === 0 ||
        this.state.name.length === 0 ||
        this.state.message.length === 0
      ) {
        swal("Warning!", "all fields are mandatory", "warning");
      } else {
        this.setState({
          loading: true,
        });
        let details = {
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          message: this.state.message,
        };

        console.log(details, "add-details");
        let formBody = [];
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        this.setState({
          response: fetch("https://10x10learning.com/add_contact_query", {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Cache-Control": "max-age=31536000",
            },
            body: formBody,
          })
            .then((response) => response.json())
            .then((responseJson) => {
              console.log(responseJson);
              this.setState(
                {
                  name: "",
                  email: "",
                  message: "",
                  phone: "",
                },
                () =>
                  swal("Success!", "We will contact you shortly!", "success")
              );
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });

              console.log(error);
            }),
        });
      }
    }
  };

  openContact() {
    document.getElementById("myContact").style.width = "450px";
  }

  closeContact() {
    document.getElementById("myContact").style.width = "125px";
  }

  renderContactUs() {
    return (
      <div
        className={`${styles.contactUsContainer}`}
        id="myContact"
        style={{ width: "125px" }}
      >
        <div className={`${styles.contactUsButtonContainer}`}>
          <button
            className={`${styles.contactUsButton}`}
            onClick={
              this.state.showContact === true
                ? () => {
                    this.closeContact();
                    this.setState({ showContact: false });
                  }
                : () => {
                    this.openContact();
                    this.setState({ showContact: true });
                  }
            }
          >
            {" "}
            {this.state.language_id == 0 ? (
              <React.Fragment>Contact Us</React.Fragment>
            ) : (
              <React.Fragment>संपर्क करें</React.Fragment>
            )}
          </button>
        </div>
        <div className={`${styles.contactUsForm}`}>
          <MDBCloseIcon
            className={`${styles.contactUsCloseBtn}`}
            onClick={this.closeContact}
          />
          <h2 className="Wcolor contact_header client_heading_font">
            {this.state.language_id == 0 ? (
              <React.Fragment>Get in Touch</React.Fragment>
            ) : (
              <React.Fragment>संपर्क में रहो</React.Fragment>
            )}
          </h2>
          <input
            placeholder={`${this.state.language_id == 0 ? "Name" : "नाम"}`}
            group
            type="text"
            validate
            className="contact_input_text px-2 py-1 border-rounded form mb-2"
            value={this.state.name}
            onChange={(text) => this.setState({ name: text.target.value })}
          />
          <input
            placeholder={`${this.state.language_id == 0 ? "Email" : "ईमेल"}`}
            group
            type="text"
            validate
            error="wrong"
            success="right"
            value={this.state.email}
            onChange={(e) => this.validateEmail(e.target.value)}
            className={`${styles.contactInputEmail}  shadow-none px-2 py-1 border-rounded form mb-2 bg-white`}
          />
          <input
            placeholder={`${this.state.language_id == 0 ? "Phone" : "फ़ोन"}`}
            group
            type="phone"
            validate
            className="contact_input_text px-2 py-1 border-rounded form mb-2"
            value={this.state.phone}
            // maxlength="10" pattern="\d{10}"
            onChange={(text) => this.setState({ phone: text.target.value })}
          />
          <textarea
            rows="3"
            placeholder={`${this.state.language_id == 0 ? "Message" : "संदेश"}`}
            className={`${styles.contactInputText} px-2 py-1 border-rounded form mb-2`}
            value={this.state.message}
            onChange={(text) => this.setState({ message: text.target.value })}
          />
          <button
            className="contact_button contact_send_btn "
            onClick={() => this.addContact()}
          >
            {this.state.language_id == 0 ? (
              <React.Fragment>Send</React.Fragment>
            ) : (
              <React.Fragment>प्रश्न भेजें</React.Fragment>
            )}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      console.log(this.state.subjects);
      return (
        <div
          id="product-page"
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <HomeHeader
            cart={
              this.state.cart.length == 0
                ? 0
                : JSON.parse(this.state.cart[0].items).length
            }
            {...this.props}
            // backgroundColor="#FFFFFF"
            // textcolor="#000"
            // width="100%"
            // height="auto"
            // showLogin={this.state.loginToggle}
          />
          <div className={`${styles.productMain}`}>
            <div className={`${styles.productMainFirst}`}>
              <text className={`${styles.productMainFirstText}`}>
                {/* Home > Exams > Insurance Institute Exams > Associateship >{" "} */}
                {/* {this.state.selected_subject.subject_name}{" "} */}
              </text>
            </div>

            <div
              className={`${styles.productMainSecond}`}
              style={{
                backgroundImage: `url(${this.state.banner})`,
                opacity: 0.8,
              }}
            >
              <div className={`${styles.productMainSecondText}`}>
                {this.rendeCourseContent()}
              </div>
            </div>

            <div className={`${styles.productCourseContent}`}>
              <div className={`${styles.productCourseContentText}`}>
                {this.renderlearn()}
              </div>

              <div className={`${styles.productCourseContentPic}`}>
                {this.rendeCourseCard()}
              </div>
            </div>

            <div className={`${styles.productCourseContentFull}`}>
              <div className={`${styles.productCourseContentFullHeading}`}>
                <h2
                  className={`${styles.productCourseContentFullHeadingH1}`}
                  className={
                    this.state.language_id == 0
                      ? `${styles.productCourseContentFullHeadingH1} opensansR`
                      : `${styles.productCourseContentFullHeadingH1} opensansRR`
                  }
                >
                  {this.state.language_id == 0 ? (
                    <React.Fragment>Course content</React.Fragment>
                  ) : (
                    <React.Fragment>अध्य्यन विषयवस्तु</React.Fragment>
                  )}
                </h2>

                <p
                  className={`${styles.productCourseContentFullHeadingp} mb-0`}
                  style={{ color: "#fff" }}
                >
                  <a onClick={() => this.toggleCollapse()}>Expand All</a>
                </p>

                <p
                  className={`${styles.productCourseContentFullHeadingp} mb-0 opacity-0`}
                >
                  64{" "}
                  {this.state.language_id == 0 ? (
                    <React.Fragment>Lectures</React.Fragment>
                  ) : (
                    <React.Fragment>व्याख्यान</React.Fragment>
                  )}
                </p>

                <p
                  className={`${styles.productCourseContentFullHeadingp} mb-0 opacity-0`}
                >
                  04:56:00
                </p>
              </div>
            </div>

            <div className={`${styles.CollapseMain}`}>
              {this.renderCollapse()}
            </div>

            <div className={`${styles.RequrmentMain}`}>
              <h2
                className={
                  this.state.language_id == 0
                    ? `${styles.RequrmentMainH2} opensansB`
                    : `${styles.RequrmentMainH2} opensansRR`
                }
              >
                {this.state.language_id == 0 ? (
                  <React.Fragment>Requirements</React.Fragment>
                ) : (
                  <React.Fragment> आवश्यकताएं</React.Fragment>
                )}
              </h2>
              {this.renderRequirement()}
              <hr className={`${styles.productHR}`}></hr>
            </div>

            <div className={`${styles.Description}`}>
              <h1
                className={
                  this.state.language_id == 0
                    ? `${styles.DescriptionH1} opensansB`
                    : `${styles.DescriptionH1} opensansRR`
                }
              >
                {this.state.language_id == 0 ? (
                  <React.Fragment>Description</React.Fragment>
                ) : (
                  <React.Fragment>विवरण</React.Fragment>
                )}
              </h1>
              <span
                className={
                  this.state.language_id == 0 ? "opensansRLine" : "KrutiRLine"
                }
              >
                {" "}
                {this.renderDescription()}{" "}
              </span>

              <hr className={`${styles.productHR}`}></hr>
            </div>
            <div className={`${styles.review} d-none`}>
              <div>
                <h2 className={`${styles.review1}`}>Featured review</h2>
              </div>

              <div className={`${styles.review2}`}>{this.renderX()}</div>
            </div>

            <div className={`${styles.StudentsAlsobought}`}>
              <h1
                style={{ marginBottom: "10px" }}
                className={
                  this.state.language_id == 0
                    ? `${styles.StudentsAlsoboughtH1} opensansB`
                    : `${styles.StudentsAlsoboughtH1} opensansB`
                }
              >
                {this.state.language_id == 0 ? (
                  <React.Fragment>Students also bought</React.Fragment>
                ) : (
                  <React.Fragment>अन्य छात्रों ने भी खरीदा</React.Fragment>
                )}
              </h1>

              {/* <div class="row">{this.renderStudentsAlsobought()}</div>  */}

              {this.renderSubjectSlide()}

              {/* <a onClick={this.showButton1}> */}
              <a>
                {" "}
                <p
                  style={{
                    color: "#0093D9",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {/* {this.state.shown1 ? "+ See more" : "- See less"}{" "} */}
                  {/* {'See more'} */}
                </p>{" "}
              </a>
            </div>

            <div className={`${styles.InstructorRating} d-none`}>
              <div className={`${styles.InstructorRating1}`}>
                <div className={`${styles.InstructorRating1RoundPic}`}>
                  <img
                    src={ReviewImg}
                    alt="10x10-logo"
                    className={`${styles.logoHeight} ${styles.logoHeight12}`}
                  />
                </div>

                <div className={`${styles.InstructorRating1A}`}>
                  <div class>
                    <i class="fas fa-star"></i>
                  </div>{" "}
                  <div style={{ marginLeft: "5%" }}>
                    <p className={`${styles.InstructorRating1P}`}>
                      <span className={`${styles.InstructorRating1PSpan}`}>
                        {" "}
                      </span>{" "}
                      {this.state.CourseContentArray.instructorRating}{" "}
                    </p>
                  </div>{" "}
                </div>
                <div className={`${styles.InstructorRating1B}`}>
                  <div>
                    <i class="fas fa-comment"></i>{" "}
                  </div>{" "}
                  <div style={{ marginLeft: "5%" }}>
                    <p className={`${styles.InstructorRating1P}`}>
                      {" "}
                      <span className={`${styles.InstructorRating1PSpan}`}>
                        {" "}
                        {
                          this.state.CourseContentArray.instructorTotalReviews
                        }{" "}
                      </span>{" "}
                      Reviews{" "}
                    </p>
                  </div>{" "}
                </div>
              </div>

              <div className={`${styles.InstructorRating2}`}>
                <h4 className={`${styles.InstructorRating2h4}`}>
                  {" "}
                  {this.state.CourseContentArray.instructorName}
                </h4>
                <p className={`${styles.InstructorRating2P}`}>
                  {" "}
                  {this.state.CourseContentArray.instructorSub}
                </p>
                <p className={`${styles.InstructorRating2P1}`}>
                  {this.state.CourseContentArray.instructorDescription}
                </p>
              </div>
            </div>
          </div>
          <ProfTen dialogues={this.state.dialogues} />
          <div>
            <MainFooterPage {...this.props} />
          </div>

          {this.showUpdateBar()}

          {this.renderContactUs()}

          <Login showLogin={this.state.showLogin} {...this.props} />
        </div>
      );
    }
  }
}
export default product;
