import React, { Component } from "react";
import styles from "./Searchbar.module.css";
import SearchIcon from "../../../Assets/images/search.png";
import Autosuggest from "react-autosuggest";
import Fade from "react-reveal/Fade";
import swal from "sweetalert";

export default class Searchbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectsToBeFind: [],
      suggestions: [],
      text: "",
      subjectDetails: [],
    };
  }
  componentWillMount() {
    let languageid = localStorage.getItem("language_id");
    var userdata = window.localStorage.getItem("userdata");

    if (languageid == null) {
      localStorage.setItem("language_id", 0);
    }
    this.setState({ language_id: languageid, userdata: JSON.parse(userdata) });
    this.getAllSubjects();
  }
  getAllSubjects = async () => {
    let details = {
      language_id: this.state.language_id,
    };

    this.setState({
      response: fetch("https://10x10learning.com/get_subjects", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          let res=responseJson.filter(item=>item.status==1)
          this.setState(
            {
              subjects: res,
            },
            () => {
              let subjectsToBeFind = this.state.subjects.map((item) => {
                return { id: item.subject_id, name: item.subject_name };
              });
              this.setState({ subjectsToBeFind });
            }
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  onTextChanged = (e) => {
    const value = e.target.value.toLowerCase();
    let suggestions = [];
    if (value.length > 0) {
      suggestions = this.state.subjectsToBeFind
        .sort()
        .filter((v) => v.name.toLowerCase().indexOf(value) >= 0);
      this.setState({ suggestions: [], text: e.target.value }, () =>
        console.log(this.state.suggestions)
      );
    }
    this.setState({ suggestions, text: e.target.value });
  };
  renderSuggestions() {
    const { suggestions } = this.state;
    if (suggestions.length == 0) {
      return null;
    }
    return (
      <Fade>
        <ul
          style={{
            position: "absolute",
            top: "40px",
            backgroundColor: "white",
            width: "100%",
            zIndex: 9999,
            border: "1px solid black",
            borderTop: "none",
          }}
          className="searchUL"
        >
          {suggestions.map((item) => (
            <React.Fragment>
              <li
                key={item.name}
                style={{ cursor: " pointer" }}
                onClick={() => this.suggestionSelected(item)}
                className="searchLI"
              >
                {item.name}
              </li>
            </React.Fragment>
          ))}
        </ul>
      </Fade>
    );
  }

  suggestionSelected = (value) => {
    this.setState({ text: value.name, suggestions: [] }, () =>
      this.getSubject(value)
    );
  };

  getSubject = async (value) => {
    let formData = new FormData();
    formData.append("subject_id", value.id);
    this.setState({
      response: fetch("https://10x10learning.com/get_subject", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "aaa");
          this.setState({ subjectDetails: responseJson[0] });
        })

        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  onSearch = () => {
    console.log("aaaa");
    let item = this.state.subjectDetails;
    localStorage.setItem("selected_subject", JSON.stringify(item));
    const { history } = this.props;
    history.push(`/product/${item.subject_name}`);
    //   this.props.history.push(`/product/${item.subject_name}`);
  };

  render() {
    {
      console.log(this.state.subjectsToBeFind, this.state.text);
    }
    return (
      <div className={`${styles.InputHeaderSearchDiv} `}>
        <input
          value={this.state.text}
          onChange={this.onTextChanged}
          placeholder={this.state.language_id == 0 ? "Search" : "खोजें"}
          className={`${styles.InputHeaderSearch}`}
        />
        {this.renderSuggestions()}
        <div className={`${styles.SearchIcon}`}>
          <img src={SearchIcon} alt="search" onClick={() => this.onSearch()} />
        </div>
      </div>
    );
  }
}
