import React, { Component } from "react";
import styles from "../About Us/Aboutus.module.css";
import "../commonStyles.css";
import swal from "sweetalert";
import { MDBCollapse, MDBIcon, MDBBtn, MDBInput } from "mdbreact";
import GridLoader from "react-spinners/GridLoader";
import HomeHeader from "../../Reusable/Header/homeHeader";
import MainFooterPage from "../../Reusable/Footer/footer";
import { css } from "@emotion/core";
import VisionImg from "../../Assets/images/Our_vision.jpg";
import MissionImg from "../../Assets/images/Our_mission.jpg";

import BannerImage from "../../Assets/images/10x10-About-Banner.jpg";

export default class PrivacyPolicy extends Component {
  state = {
    aboutus: [],
    loading: false,
    bannerImages: [],
    privacyPolicy: `<div class="iub_header" style='border-bottom: 1px dotted rgb(223, 223, 223); padding-bottom: 25px; position: relative; color: rgb(107, 107, 107); font-family: "Helvetica Neue", Helvetica, Arial, FreeSans, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial;'>
    <div class="one_line_col" style='zoom: 1; float: none; width: 740px; border-bottom: 1px dotted rgb(223, 223, 223); border-top: 0px; padding-bottom: 21px; color: rgb(107, 107, 107); font-family: "Helvetica Neue", Helvetica, Arial, FreeSans, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial;'>
        <h2 style="margin: 0px; padding: 21px 0px 0px; border: 0px; font-weight: bold; font-style: normal; font-size: 17px; line-height: 21px; font-family: inherit; color: rgb(63, 63, 63);">Owner and Data Controller</h2>
        <p style="margin: 0px; padding: 5px 0px 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">10x10 Learning and Associates, Flat No 603, Tower 5, The Palms Apartments, Sushant Lok I, Sector 30, Gurgaon, Haryana, 122007, Indian</p>
        <p style="margin: 0px; padding: 11px 0px 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;"><strong>Owner contact email:</strong> helpdesk@10x10learning.com</p>
    </div>
    <div class="one_line_col" style='zoom: 1; float: none; width: 740px; border-bottom: 1px dotted rgb(223, 223, 223); border-top: 0px; padding-bottom: 21px; color: rgb(107, 107, 107); font-family: "Helvetica Neue", Helvetica, Arial, FreeSans, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial;'>
        <h2 style="margin: 0px; padding: 21px 0px 0px; border: 0px; font-weight: bold; font-style: normal; font-size: 17px; line-height: 21px; font-family: inherit; color: rgb(63, 63, 63);">Types of Data collected</h2>
        <p style="margin: 0px; padding: 5px 0px 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">The owner does not provide a list of Personal Data types collected.</p>
        <p style="margin: 0px; padding: 11px 0px 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.<br>Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Application.<br>Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this Data may make it impossible for this Application to provide its services. In cases where this Application specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.<br>Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.<br>Any use of Cookies &ndash; or of other tracking tools &ndash; by this Application or by the owners of third-party services used by this Application serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available.</p>
        <p style="margin: 0px; padding: 11px 0px 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">Users are responsible for any third-party Personal Data obtained, published or shared through this Application and confirm that they have the third party&#39;s consent to provide the Data to the Owner.</p>
    </div>
    <div class="one_line_col" style='zoom: 1; float: none; width: 740px; border-bottom: 1px dotted rgb(223, 223, 223); border-top: 0px; padding-bottom: 21px; color: rgb(107, 107, 107); font-family: "Helvetica Neue", Helvetica, Arial, FreeSans, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial;'>
        <h2 style="margin: 0px; padding: 21px 0px 0px; border: 0px; font-weight: bold; font-style: normal; font-size: 17px; line-height: 21px; font-family: inherit; color: rgb(63, 63, 63);">Mode and place of processing the Data</h2>
        <h3 style="margin: 0px; padding: 24px 0px 0px; border: 0px; font-weight: bold; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; color: rgb(63, 63, 63);">Methods of processing</h3>
        <p style="margin: 0px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.<br>The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Application (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.</p>
        <h3 style="margin: 0px; padding: 24px 0px 0px; border: 0px; font-weight: bold; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; color: rgb(63, 63, 63);">Legal basis of processing</h3>
        <p style="margin: 0px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">The Owner may process Personal Data relating to Users if one of the following applies:</p>
        <ul style="margin: 0px; padding: 5px 0px 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 16px; line-height: 1; font-family: inherit; list-style: disc;">
            <li style="margin: 2px 0px 0px 30px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; list-style: disc;">Users have given their consent for one or more specific purposes. Note: Under some legislations the Owner may be allowed to process Personal Data until the User objects to such processing (&ldquo;opt-out&rdquo;), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to European data protection law;</li>
            <li style="margin: 2px 0px 0px 30px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; list-style: disc;">provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;</li>
            <li style="margin: 2px 0px 0px 30px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; list-style: disc;">processing is necessary for compliance with a legal obligation to which the Owner is subject;</li>
            <li style="margin: 2px 0px 0px 30px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; list-style: disc;">processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner;</li>
            <li style="margin: 2px 0px 0px 30px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; list-style: disc;">processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party.</li>
        </ul>
        <p style="margin: 0px; padding: 11px 0px 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</p>
        <h3 style="margin: 0px; padding: 24px 0px 0px; border: 0px; font-weight: bold; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; color: rgb(63, 63, 63);">Place</h3>
        <p style="margin: 0px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">The Data is processed at the Owner&#39;s operating offices and in any other places where the parties involved in the processing are located.<br><br>Depending on the User&#39;s location, data transfers may involve transferring the User&#39;s Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data.</p>
        <p style="margin: 0px; padding: 11px 0px 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.<br><br>If any such transfer takes place, Users can find out more by checking the relevant sections of this document or inquire with the Owner using the information provided in the contact section.</p>
        <h3 style="margin: 0px; padding: 24px 0px 0px; border: 0px; font-weight: bold; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; color: rgb(63, 63, 63);">Retention time</h3>
        <p style="margin: 0px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">Personal Data shall be processed and stored for as long as required by the purpose they have been collected for.</p>
        <p style="margin: 0px; padding: 11px 0px 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">Therefore:</p>
        <ul style="margin: 0px; padding: 5px 0px 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 16px; line-height: 1; font-family: inherit; list-style: disc;">
            <li style="margin: 2px 0px 0px 30px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; list-style: disc;">Personal Data collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed.</li>
            <li style="margin: 2px 0px 0px 30px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; list-style: disc;">Personal Data collected for the purposes of the Owner&rsquo;s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of this document or by contacting the Owner.</li>
        </ul>
        <p style="margin: 0px; padding: 11px 0px 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.<br><br>Once the retention period expires, Personal Data shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.</p>
    </div>
    <div class="one_line_col" style='zoom: 1; float: none; width: 740px; border-bottom: 1px dotted rgb(223, 223, 223); border-top: 0px; padding-bottom: 21px; color: rgb(107, 107, 107); font-family: "Helvetica Neue", Helvetica, Arial, FreeSans, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial;'>
        <h2 style="margin: 0px; padding: 21px 0px 0px; border: 0px; font-weight: bold; font-style: normal; font-size: 17px; line-height: 21px; font-family: inherit; color: rgb(63, 63, 63);">The rights of Users</h2>
        <p style="margin: 0px; padding: 5px 0px 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">Users may exercise certain rights regarding their Data processed by the Owner.</p>
        <p style="margin: 0px; padding: 11px 0px 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">In particular, Users have the right to do the following:</p>
        <ul style="margin: 0px; padding: 5px 0px 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 16px; line-height: 1; font-family: inherit; list-style: disc;">
            <li style="margin: 2px 0px 0px 30px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; list-style: disc;"><strong>Withdraw their consent at any time.</strong> Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data.</li>
            <li style="margin: 2px 0px 0px 30px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; list-style: disc;"><strong>Object to processing of their Data.</strong> Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below.</li>
            <li style="margin: 2px 0px 0px 30px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; list-style: disc;"><strong>Access their Data.</strong> Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.</li>
            <li style="margin: 2px 0px 0px 30px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; list-style: disc;"><strong>Verify and seek rectification.</strong> Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.</li>
            <li style="margin: 2px 0px 0px 30px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; list-style: disc;"><strong>Restrict the processing of their Data.</strong> Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it.</li>
            <li style="margin: 2px 0px 0px 30px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; list-style: disc;"><strong>Have their Personal Data deleted or otherwise removed.</strong> Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.</li>
            <li style="margin: 2px 0px 0px 30px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; list-style: disc;"><strong>Receive their Data and have it transferred to another controller.</strong> Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User&#39;s consent, on a contract which the User is part of or on pre-contractual obligations thereof.</li>
            <li style="margin: 2px 0px 0px 30px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; list-style: disc;"><strong>Lodge a complaint.</strong> Users have the right to bring a claim before their competent data protection authority.</li>
        </ul>
        <h3 style="margin: 0px; padding: 24px 0px 0px; border: 0px; font-weight: bold; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; color: rgb(63, 63, 63);">Details about the right to object to processing</h3>
        <p style="margin: 0px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their particular situation to justify the objection.</p>
        <p style="margin: 0px; padding: 11px 0px 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">Users must know that, however, should their Personal Data be processed for direct marketing purposes, they can object to that processing at any time without providing any justification. To learn, whether the Owner is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this document.</p>
        <h3 style="margin: 0px; padding: 24px 0px 0px; border: 0px; font-weight: bold; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; color: rgb(63, 63, 63);">How to exercise these rights</h3>
        <p style="margin: 0px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month.</p>
    </div>
    <div class="one_line_col" style='zoom: 1; float: none; width: 740px; border-bottom: 1px dotted rgb(223, 223, 223); border-top: 0px; padding-bottom: 21px; color: rgb(107, 107, 107); font-family: "Helvetica Neue", Helvetica, Arial, FreeSans, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial;'>
        <h2 style="margin: 0px; padding: 21px 0px 0px; border: 0px; font-weight: bold; font-style: normal; font-size: 17px; line-height: 21px; font-family: inherit; color: rgb(63, 63, 63);">Cookie Policy</h2>
        <p style="margin: 0px; padding: 5px 0px 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">This Application uses Trackers. To learn more, the User may consult the&nbsp;<a class="show_comp_link iframe-preserve an-preserve" href="https://www.iubenda.com/privacy-policy/gdpr/28385914/cookie-policy" style="margin: 0px; padding: 0px; border-width: 0px 0px 1px; border-top-style: initial; border-right-style: initial; border-bottom-style: solid; border-left-style: initial; border-top-color: initial; border-right-color: initial; border-bottom-color: rgb(240, 240, 240); border-left-color: initial; border-image: initial; font-weight: normal; font-style: normal; font-size: 13px; line-height: 1; font-family: inherit; text-decoration: none; color: rgb(51, 59, 67);" title="Cookie Policy">Cookie Policy</a>.</p>
    </div>
    <div class="one_line_col" style='zoom: 1; float: none; width: 740px; border-bottom: 1px dotted rgb(223, 223, 223); border-top: 0px; padding-bottom: 21px; color: rgb(107, 107, 107); font-family: "Helvetica Neue", Helvetica, Arial, FreeSans, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial;'>
        <h2 style="margin: 0px; padding: 21px 0px 0px; border: 0px; font-weight: bold; font-style: normal; font-size: 17px; line-height: 21px; font-family: inherit; color: rgb(63, 63, 63);">Additional information about Data collection and processing</h2>
        <h3 style="margin: 0px; padding: 24px 0px 0px; border: 0px; font-weight: bold; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; color: rgb(63, 63, 63);">Legal action</h3>
        <p style="margin: 0px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">The User&#39;s Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of this Application or the related Services.<br>The User declares to be aware that the Owner may be required to reveal personal data upon request of public authorities.</p>
        <h3 style="margin: 0px; padding: 24px 0px 0px; border: 0px; font-weight: bold; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; color: rgb(63, 63, 63);">Additional information about User&#39;s Personal Data</h3>
        <p style="margin: 0px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">In addition to the information contained in this privacy policy, this Application may provide the User with additional and contextual information concerning particular Services or the collection and processing of Personal Data upon request.</p>
        <h3 style="margin: 0px; padding: 24px 0px 0px; border: 0px; font-weight: bold; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; color: rgb(63, 63, 63);">System logs and maintenance</h3>
        <p style="margin: 0px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">For operation and maintenance purposes, this Application and any third-party services may collect files that record interaction with this Application (System logs) use other Personal Data (such as the IP Address) for this purpose.</p>
        <h3 style="margin: 0px; padding: 24px 0px 0px; border: 0px; font-weight: bold; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; color: rgb(63, 63, 63);">Information not contained in this policy</h3>
        <p style="margin: 0px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">More details concerning the collection or processing of Personal Data may be requested from the Owner at any time. Please see the contact information at the beginning of this document.</p>
        <h3 style="margin: 0px; padding: 24px 0px 0px; border: 0px; font-weight: bold; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; color: rgb(63, 63, 63);">How &ldquo;Do Not Track&rdquo; requests are handled</h3>
        <p style="margin: 0px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">This Application does not support &ldquo;Do Not Track&rdquo; requests.<br>To determine whether any of the third-party services it uses honor the &ldquo;Do Not Track&rdquo; requests, please read their privacy policies.</p>
        <h3 style="margin: 0px; padding: 24px 0px 0px; border: 0px; font-weight: bold; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit; color: rgb(63, 63, 63);">Changes to this privacy policy</h3>
        <p style="margin: 0px; padding: 0px; border: 0px; font-weight: normal; font-style: normal; font-size: 13px; line-height: 19px; font-family: inherit;">The Owner reserves the right to make changes to this privacy policy at any time by notifying its Users on this page and possibly within this Application and/or - as far as technically and legally feasible - sending a notice to Users via any contact information available to the Owner. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom.<br><br>Should the changes affect processing activities performed on the basis of the User&rsquo;s consent, the Owner shall collect new consent from the User, where required.</p>
    </div>
</div>
<div class="one_line_col" style='zoom: 1; float: none; width: 740px; border-bottom: 1px dotted rgb(223, 223, 223); border-top: 0px; padding-bottom: 21px; color: rgb(107, 107, 107); font-family: "Helvetica Neue", Helvetica, Arial, FreeSans, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial;'><br></div>`
  };

  componentWillMount() {
    // this.getBannerImages();
    let language_id = localStorage.getItem("language_id")
      ? localStorage.getItem("language_id")
      : 0;
    console.log(language_id);
    this.setState({ language_id });
  }
  componentDidMount() {
    // this.getAboutus();
  }

  getAboutus = () => {
    let details = {
      language_id: this.state.language_id,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/get_aboutLanguage", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "xxx");
          this.setState({ aboutus: responseJson[0], loading: false }, () =>
            console.log(this.state.aboutus)
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  getBannerImages = async () => {
    this.setState({
      response: fetch("https://10x10learning.com/get_about_banner_image", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "xxx");
          this.setState({ bannerImages: responseJson[0].link }, () =>
            console.log(this.state.bannerImages)
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  render() {
    console.log(this.state.selected_subject);

    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div className={`${styles.AboutUs}`} id="about-us">
          <HomeHeader {...this.props} />

          <div
            className={
              this.state.language_id == 0
                ? `${styles.AboutUsMain} opensansR`
                : `${styles.AboutUsMain} opensansR`
            }
          >
            {/* <div className={`${styles.BannerImageDiv} `}>
              <img
                className={`${styles.BannerImage} d-none`}
                src={this.state.bannerImages}
                alt="10x10-logo"
              />

              <span
                className={
                  this.state.language_id == 0
                    ? `${styles.BannerText} opensansR`
                    : `${styles.BannerText} opensansR`
                }
                dangerouslySetInnerHTML={{
                  __html: "Privacy Policy",
                }}
              ></span>
            </div> */}


            <h1 className="text-center mt-3">Privacy Policy</h1>

            <div className={`${styles.AboutUsDiscription} mt-5`} dangerouslySetInnerHTML={{__html:`${this.state.privacyPolicy}`}}/>



          </div>

          <div style={{ height: "auto" }}>
            <MainFooterPage {...this.props} />
          </div>
        </div>
      );
    }
  }
}
