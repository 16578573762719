import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../Sidebar/sidebarPage";
import GridLoader from "react-spinners/GridLoader";
import Select from "react-select";
import Fade from "react-reveal";
import swal from "sweetalert";
// import "./Addlecture.css";
import "../Lectures/Addlecture.css";
import { MDBIcon } from "mdbreact";
import MediaComponent from "../MediaComponent/chooseMediaFile";
import { css } from "@emotion/core";
var md5 = require("md5");
const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

export default class OrderPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldpassword:'',
      password:'',
      newpassword:'',
      confirmpassword:''
    };
  }
  
  componentDidMount(){
    this.getOrderPassword();
  }

  getOrderPassword = () => {
    this.setState({
      // response: fetch("http://localhost:8000/get_lectures", {
      response: fetch("https://10x10learning.com/order_password", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);

          this.setState({
            password:responseJson[0].password,
            loading: false,
          });
        })
        .catch((error) => {
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  handlechangeurl = (text) =>
    this.setState({ inputLink: text.target.value, errorFile: false });

  renderoldpassword = () => {
    return (
      <div className="col-12  subject-name-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-name-label subject-labels"
        >
          Enter old password:
        </label>
        <input
          value={this.state.oldpassword}
          type="text"
          id="defaultFormRegisterNameEx"
          // className="form-control subject-name-form custom-form"
          className={`form-control subject-name-form custom-form ${
            this.state.errorName ? "errorClass" : ""
          }`}
          onChange={(text) =>
            this.setState({ oldpassword: text.target.value, errorName: false })
          }
        />
      </div>
    );
  };

  rendernewpassword = () => {
    return (
      <div className="col-12  subject-name-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-name-label subject-labels"
        >
          Enter new password:
        </label>
        <input
          value={this.state.newpassword}
          type="text"
          id="defaultFormRegisterNameEx"
          // className="form-control subject-name-form custom-form"
          className={`form-control subject-name-form custom-form ${
            this.state.errorDescription ? "errorClass" : ""
          }`}
          onChange={(text) =>
            this.setState({
              newpassword: text.target.value,
              errorDescription: false,
            })
          }
        />
      </div>
    );
  };

  renderconfirmpassword = () => {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="course-duration-label subject-labels"
        >
          Enter password again to confirm:
        </label>

        <input
          autoFocus
          type="text"
          value={this.state.confirmpassword}
          onChange={(text) => this.setState({confirmpassword:text.target.value})}
          id="defaultFormRegisterNameEx"
          // className="form-control duration-form custom-form "
          className={`form-control subject-name-form custom-form ${
            this.state.errorFile ? "errorClass" : ""
          }`}
        />
      </div>
    );
  };

  changepassword() {
    
    if (this.state.oldpassword.length == 0 || this.state.password!=md5(this.state.oldpassword)) {
      return swal(
        "Check Old password !",
        "Password you entered does not match the old password",
        "error"
      );
    } else if (this.state.newpassword.length === 0) {
      this.setState({ errorName: true });
      return swal("Check new password ", "Please enter new password", "error");
    } else if (this.state.confirmpassword.length === 0) {
      this.setState({ errorDescription: true });
      return swal("Check confirm password!", "Please enter confirm password", "error");
    } 
    else if (this.state.newpassword!=this.state.confirmpassword) {
        return swal("Check password!", "New password and confirm password do not match", "error");
      } 
    
console.log('fff')
    let formData = new FormData();
    // formData.append("oldpass", this.state.oldpassword);
    formData.append("password", this.state.newpassword);
    formData.append("id",1)
    // formData.append("confirmpass", this.state.confirmpassword);
    this.setState({
      response: fetch("https://10x10learning.com/change_password_for_order", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "QWERTy");
          swal("Success", "Password succesfullly updated", "success");
        //   this.props.history.push("/limitedaccess/Order");
        })
        .catch((error) => {
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }

  //PART OF MEDIA COMPONENT
  handleMediaChange = (item) => {
    console.log(item, "handleMediaChange");
    this.setState({ inputLink: `https://10x10learning.com/${item.file}` });
  };

  render() {
    console.log(this.state, "state");
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={11} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#c01825"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <div className="row my-3 px-4">
                  <div className="col-12 top_button_bar d-flex align-items-center">
                    <h3>Change Password for orders</h3>

                    <button
                      className="admin-button-style ml-auto"
                      onClick={() => this.changepassword()}
                    >
                      {" "}
                      Save &amp; Publish
                    </button>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="subject-content-container ml-4 fit-content">
                    <div className="row px-0 mx-0">
                      {this.renderoldpassword()}
                      {this.rendernewpassword()}
                      {this.renderconfirmpassword()}
                    </div>
                  </div>
                  <div className="subject-select-fields-container ml-2 row align-self-start flex-column mr-4 h-100"></div>
                </div>

                {/* <button className='add-subject my-5 float-right mr-4' onClick={()=>this.addlectures()}>Save &amp; Publish</button>  */}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}
