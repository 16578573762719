import React, { Component } from "react";
import swal from "sweetalert";
import styles from "./Homepage.module.css";
import styles1 from "../Products/products.module.css";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import { MDBCollapse, MDBIcon, MDBBtn, MDBInput, MDBCloseIcon } from "mdbreact";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Bullets from "../../Assets/images/bullets.png";
import SliderImage from "../Slider/sliderPage";
import Image1 from "../../Assets/images/Layer1.jpg";
import Image2 from "../../Assets/images/Layer2.jpg";
import Image3 from "../../Assets/images/Layer3.jpg";
import HomeHeader from "../../Reusable/Header/homeHeader";
import MainFooterPage from "../../Reusable/Footer/footer";
import "../commonStyles.css";
import ProfTen from "../Prof10X10/prof10x10";
import GridLoader from "react-spinners/GridLoader";
import { css } from "@emotion/core";
import { Link } from "react-router-dom";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export default class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboards: [],
      latestUpdates: [],
      loading: true,
      aboutus: [],
      name: "",
      email: "",
      phone: "",
      query: "",
      content: [],
      bannerTexts: "",
      historicalDates: [],
      keyDates: [],
      language_id: "",
      bannerImages: [],
    };
    this.myRef = React.createRef();
  }
  componentWillMount() {
    let language_id = localStorage.getItem("language_id");
    console.log(language_id);
    if (language_id == null) {
      language_id = 0;
    }
    this.setState({ language_id, loading: true });
  }

  renderContactUs() {
    return (
      <div
        className={`${styles1.contactUsContainer}`}
        id="myContact"
        style={{ width: "125px" }}
      >
        <div className={`${styles1.contactUsButtonContainer}`}>
          <button
            className={`${styles1.contactUsButton}`}
            onClick={
              this.state.showContact === true
                ? () => {
                    this.closeContact();
                    this.setState({ showContact: false });
                  }
                : () => {
                    this.openContact();
                    this.setState({ showContact: true });
                  }
            }
          >
            {" "}
            {this.state.language_id == 0 ? (
              <React.Fragment>Contact Us</React.Fragment>
            ) : (
              <React.Fragment>संपर्क करें</React.Fragment>
            )}
          </button>
        </div>
        <div className={`${styles1.contactUsForm}`}>
          <MDBCloseIcon
            className={`${styles1.contactUsCloseBtn}`}
            onClick={this.closeContact}
          />
          <h2 className="Wcolor contact_header client_heading_font">
            {this.state.language_id == 0 ? (
              <React.Fragment>Get in Touch</React.Fragment>
            ) : (
              <React.Fragment>संपर्क में रहो</React.Fragment>
            )}
          </h2>
          <input
            placeholder={`${this.state.language_id == 0 ? "Name" : "नाम"}`}
            group
            type="text"
            validate
            className="contact_input_text px-2 py-1 border-rounded form mb-2"
            value={this.state.name}
            onChange={(text) => this.setState({ name: text.target.value })}
          />
          <input
            placeholder={`${this.state.language_id == 0 ? "Email" : "ईमेल"}`}
            group
            type="text"
            validate
            error="wrong"
            success="right"
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
            className={`${styles1.contactInputEmail}  shadow-none px-2 py-1 border-rounded form mb-2 bg-white`}
          />
          <input
            placeholder={`${this.state.language_id == 0 ? "Phone" : "फ़ोन"}`}
            group
            type="phone"
            validate
            className="contact_input_text px-2 py-1 border-rounded form mb-2"
            value={this.state.phone}
            // maxlength="10" pattern="\d{10}"
            onChange={(text) => this.setState({ phone: text.target.value })}
          />
          <textarea
            rows="3"
            placeholder={`${this.state.language_id == 0 ? "Message" : "संदेश"}`}
            className={`${styles1.contactInputText} px-2 py-1 border-rounded form mb-2`}
            value={this.state.message}
            onChange={(text) => this.setState({ message: text.target.value })}
          />
          <button
            className="contact_button contact_send_btn  queryButton"
            onClick={() => this.addContact()}
          >
            {this.state.language_id == 0 ? (
              <React.Fragment>Send</React.Fragment>
            ) : (
              <React.Fragment>प्रश्न भेजें</React.Fragment>
            )}
          </button>
        </div>
      </div>
    );
  }

  addContact = async () => {
    if (this.state.valid_email == false) {
      swal("Warning!", "Please enter valid Email Id", "warning");
    } else {
      if (
        this.state.email.length === 0 ||
        this.state.phone.length === 0 ||
        this.state.name.length === 0 ||
        this.state.message.length === 0
      ) {
        swal("Warning!", "all fields are mandatory", "warning");
      } else {
        this.setState({
          loading: true,
        });
        let details = {
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          message: this.state.message,
        };

        console.log(details, "add-details");
        let formBody = [];
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        this.setState({
          response: fetch("https://10x10learning.com/add_contact_query", {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Cache-Control": "max-age=31536000",
            },
            body: formBody,
          })
            .then((response) => response.json())
            .then((responseJson) => {
              console.log(responseJson);
              this.setState(
                {
                  name: "",
                  email: "",
                  message: "",
                  phone: "",
                },
                () => {
                  swal("Success!", "We will contact you shortly!", "success");
                  this.setState({ loading: false });
                }
              );
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });

              console.log(error);
            }),
        });
      }
    }
  };
  openContact() {
    document.getElementById("myContact").style.width = "450px";
  }

  closeContact() {
    document.getElementById("myContact").style.width = "125px";
  }

  componentDidMount = async () => {
    this.getBannerContent();
    this.getBannerImages();
    this.getAboutus();
    this.getDashboard();
    this.getLatestUpdates();
    this.getHistoricalUpdates();
    this.getKeyDates();
    this.getProductBannerImage();
    this.getVerticalBannerImage();
    // this.mainContent();
  };

  getBannerContent = () => {
    this.setState({
      response: fetch("https://10x10learning.com/getBannerContent", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "xxxxxx");
          let bannerTexts = JSON.parse(responseJson[0].content);
          this.setState({ bannerTexts }, () =>
            console.log(this.state.bannerTexts)
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  getProductBannerImage = () => {
    this.setState({
      response: fetch("https://10x10learning.com/get_product_banner", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "xxx");
          localStorage.setItem(
            "productBannerImage",
            JSON.stringify(responseJson)
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  getVerticalBannerImage = () => {
    this.setState({
      response: fetch("https://10x10learning.com/get_vertical_banner", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "xxx");
          localStorage.setItem(
            "verticalBannerImage",
            JSON.stringify(responseJson)
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  getBannerImages = async () => {
    let details = {
      language_id: this.state.language_id,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/get_banner_by_languageID", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "xxx");
          this.setState({ bannerImages: responseJson }, () =>
            console.log(this.state.bannerImages)
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  mainContent = () => {
    let details = {
      language_id: this.state.language_id,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/get_main_content", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "xxx");
          this.setState({ aboutus: responseJson[0] }, () =>
            console.log(this.state.aboutus)
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  getAboutus = () => {
    let details = {
      language_id: this.state.language_id,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/get_aboutLanguage", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "xxx");
          this.setState({ aboutus: responseJson[0] }, () =>
            console.log(this.state.aboutus)
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  getDashboard = () => {
    let details = {
      language_id: this.state.language_id,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/get_dashboard", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "xxx");
          let sortedDashboard = responseJson.sort(
            (a, b) => a.order_number - b.order_number
          );
          console.log(sortedDashboard);
          this.setState({ dashboards: sortedDashboard });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  getLatestUpdates = () => {
    let details = {
      language_id: this.state.language_id,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/get_latest_updates", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "xxx");
          let res = responseJson.filter((item) => item.status == 1);
          this.setState({ latestUpdates: res });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  getHistoricalUpdates = () => {
    let details = {
      language_id: this.state.language_id,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/get_historical_updates", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "xxx");
          let res = responseJson.filter((item) => item.status == 1);
          this.setState({ historicalDates: res });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  getKeyDates = () => {
    let details = {
      language_id: this.state.language_id,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/get_key_dates", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "keyx");
          let res = responseJson.filter((item) => item.status == 1);
          this.setState({ keyDates: res }, () => this.getDialogues());
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  getDialogues = async () => {
    let formBody = new FormData();
    formBody.append("language_id", this.state.language_id);
    try {
      const dialogueResponse = await fetch(
        "https://10x10learning.com/prof10_homepage",
        {
          method: "POST",
          body: formBody,
        }
      );
      const result = await dialogueResponse.json();
      const dialogues = JSON.parse(result[0].text).map(
        (item) => item.description
      );
      this.setState({ dialogues, loading: false });
    } catch (err) {
      console.log(err);
    }
  };

  setIDLatestUpdate(id) {
    this.props.history.push({
      pathname: "/latest-updates",
      state: { listItemId: id },
    });
  }
  setIDHistoricalUpdate(id) {
    this.props.history.push({
      pathname: "/historical-dates",
      state: { listItemId: id },
    });
  }

  latestUpdates() {
    console.log(this.state.latestUpdates);
    return this.state.latestUpdates.map((item) => {
      return (
        <li
          key={item.id}
          onClick={() => this.setIDLatestUpdate(item.id)}
          className={
            this.state.language_id == 0
              ? "opensansRLine latestUpdateKeyPoints"
              : "opensansRLine latestUpdateKeyPoints"
          }
        >
          <img src={Bullets} className={`${styles.Bullets}`} />{" "}
          {item.latest_updates_title}
        </li>
      );
    });
  }
  about() {
    return (
      <div
        className={
          this.state.language_id == 0 ? "opensansRLine" : "opensansRLine"
        }
        dangerouslySetInnerHTML={{
          __html: this.state.aboutus.main_description,
        }}
      ></div>
    );
  }

  goToKeyDatesItem(id) {
    this.props.history.push({
      pathname: "/important-dates",
      state: { listItemId: id },
    });
  }
  goToHistoryDatesItem(id) {
    this.props.history.push({
      pathname: "/historical-dates",
      state: {},
    });
  }

  keyDates() {
    return this.state.keyDates.map((item) => {
      return (
        <li
          className={
            this.state.language_id == 0 ? "opensansRLine" : "opensansRLine"
          }
          key={item.id}
          onClick={() => this.goToKeyDatesItem(item.id)}
        >
          <img src={Bullets} className={`${styles.Bullets}`} />{" "}
          {item.key_dates_title}
        </li>
      );
    });
  }
  HistoryDates() {
    return this.state.historicalDates.map((item) => {
      return (
        <li
          className={
            this.state.language_id == 0 ? "opensansRLine" : "opensansRLine"
          }
          key={item.id}
          onClick={() => this.setIDHistoricalUpdate(item.id)}
        >
          <img src={Bullets} className={`${styles.Bullets}`} /> {item.title}
        </li>
      );
    });
  }

  saveDashboardName = (item) => {
    console.log(item);
    localStorage.setItem("Dashboard id", JSON.stringify(item.id));
    localStorage.setItem("dashboard type", item.type);
    if (item.type === "other-service") {
      console.log("dagwwehgjhewghhjdshjsdghiohgosdhg");
      return this.props.history.push(`/other-service`);
    }
    console.log("aaa");
    this.props.history.push(`/dashboard/${item.url}`);
  };

  pushAboutus() {
    this.props.history.push("/about-us");
  }

  pushlatestUpdates() {
    this.props.history.push("/latest-updates");
  }

  pushkeyDates() {
    this.props.history.push("/important-dates");
  }
  pushHistoryDates() {
    this.props.history.push("/historical-dates");
  }

  handleLanguage = (id) => {
    console.log(id);
    this.setState({ language_id: id });
  };
  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweetwe">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div>
          <HomeHeader
            onSelectLanguage={this.handleLanguage}
            {...this.props}
            backgroundColor="#FFFFFF"
            textcolor="#000"
            width="100%"
            height="auto"
          />
          <div className={`${styles.Homepage}`} id="homepage">
            <div style={{ minHeight: "100vh" }}>
              <div className={`${styles.Banner} `}>
                <span
                  className={
                    this.state.language_id == 0
                      ? `${styles.BannerHeading} opensansR`
                      : `${styles.BannerHeading} opensansR`
                  }
                >
                  {/* {this.state.content.main_title} */}

                  {this.state.language_id == 0
                    ? `${this.state.bannerTexts.englishAbove}`
                    : `${this.state.bannerTexts.hindiAbove}`}
                </span>

                <div className={`${styles.BannerImage} `}>
                  <Carousel
                    additionalTransfrom={0}
                    autoPlay
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    customTransition="all 1.5s"
                    transitionDuration={2000}
                    containerClass="container-with-dots"
                    dotListClass=""
                    draggable={false}
                    focusOnSelect={false}
                    infinite
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                    itemClass=""
                    keyBoardControl={false}
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={responsive}
                  >
                    {this.state.bannerImages.map((item) => (
                      <div>
                        <img
                          src={item.link}
                          className={`w-100 ${styles.carouselImage}`}
                          alt="10x10 Logo"
                        />
                      </div>
                    ))}
                  </Carousel>{" "}
                </div>
                <div className={`${styles.TextBelowImages}`}>
                  {this.state.language_id == 0
                    ? `${this.state.bannerTexts.englishBelow}`
                    : `${this.state.bannerTexts.hindiBelow}`}
                </div>
                <div className={`${styles.downArrow} mt-4`}>
                  <MDBIcon
                    icon="angle-down"
                    className={`${styles.angleDown}`}
                    onClick={() =>
                      window.scrollTo(0, this.myRef.current.offsetTop)
                    }
                  />
                </div>
                <div style={{ position: "relative" }}>
                  <ProfTen dialogues={this.state.dialogues} />

                  <div className={`${styles.Hr}`} />
                </div>
              </div>

              <div className={`${styles.sliderImage}`}>
                <SliderImage />
              </div>
              <div ref={this.myRef} className={`${styles.Content}  mb-lg-5 `}>
                <div className={`${styles.ContentAbout}  `}>
                  <div className={`${styles.BoxDiv1}`}>
                    <h3 className={`${styles.AboutTitle}`}>
                      {this.state.language_id == 0 ? (
                        <h3
                          className={
                            this.state.language_id == 0
                              ? "opensansH"
                              : "KrutiDevH"
                          }
                        >
                          About Us
                        </h3>
                      ) : (
                        <h3
                          className={
                            this.state.language_id == 0
                              ? "opensansH"
                              : "KrutiDevH"
                          }
                        >
                          हमारे बारे में
                        </h3>
                      )}
                    </h3>
                    <div className={`${styles.AboutDescription}`}>
                      {this.about()}
                    </div>
                  </div>

                  <div className={`${styles.ContentButton}`}>
                    <button
                      className={`${styles.BtnContent} `}
                      onClick={() => this.pushAboutus()}
                    >
                      {this.state.language_id == 0 ? (
                        <span
                          className={
                            this.state.language_id == 0
                              ? "opensansR"
                              : "KrutiDev"
                          }
                        >
                          Read More
                        </span>
                      ) : (
                        <span
                          className={
                            this.state.language_id == 0
                              ? "opensansR"
                              : "KrutiDev"
                          }
                        >
                          अधिक जानकारी
                        </span>
                      )}
                    </button>
                  </div>
                </div>

                <div className={`${styles.ContentUpdates}  custom-cur `}>
                  <h3 className={`${styles.UpdateTitle}`}>
                    {this.state.language_id == 0 ? (
                      <span
                        className={
                          this.state.language_id == 0
                            ? "opensansH"
                            : "KrutiDevH"
                        }
                      >
                        Latest Updates
                      </span>
                    ) : (
                      <span
                        className={
                          this.state.language_id == 0
                            ? "opensansH"
                            : "KrutiDevH"
                        }
                      >
                        नवीनतम अपडेट
                      </span>
                    )}
                  </h3>
                  <div className={`${styles.BoxDiv}`}>
                    {/* <h6 className={`${styles.AboutDescription}`}>
              An Online Learning platform to provide quality learning material,
              in English and
            </h6> */}
                    <ul className={`${styles.UpdatePoints}`}>
                      {this.latestUpdates()}
                    </ul>
                  </div>

                  <div className={`${styles.ContentButton}`}>
                    <button
                      onClick={() => this.pushlatestUpdates()}
                      className={`${styles.BtnContent} `}
                    >
                      {this.state.language_id == 0 ? (
                        <span
                          className={
                            this.state.language_id == 0
                              ? "opensansR"
                              : "KrutiDev"
                          }
                        >
                          View All
                        </span>
                      ) : (
                        <span
                          className={
                            this.state.language_id == 0
                              ? "opensansR"
                              : "KrutiDev"
                          }
                        >
                          अधिक जानकारी
                        </span>
                      )}
                    </button>
                  </div>
                </div>

                <div className={`${styles.ContentDates}  `}>
                  <h3 className={`${styles.DateTitle}`}>
                    {this.state.language_id == 0 ? (
                      <span
                        className={
                          this.state.language_id == 0
                            ? "opensansH"
                            : "KrutiDevH"
                        }
                      >
                        Important Dates
                      </span>
                    ) : (
                      <span
                        className={
                          this.state.language_id == 0
                            ? "opensansH"
                            : "KrutiDevH"
                        }
                      >
                        महत्वपूर्ण तिथियाँ
                      </span>
                    )}
                  </h3>
                  <div className={`${styles.BoxDiv} ${styles.Partition}`}>
                    <ul className={`${styles.UpdatePoints}`}>
                      <li>{this.keyDates()}</li>
                    </ul>
                  </div>
                  <div className={`${styles.ContentButton}`}>
                    <button
                      onClick={() => this.pushkeyDates()}
                      className={`${styles.BtnContent} mb-5`}
                    >
                      {this.state.language_id == 0 ? (
                        <span
                          className={
                            this.state.language_id == 0
                              ? "opensansR"
                              : "KrutiDev"
                          }
                        >
                          View All
                        </span>
                      ) : (
                        <span
                          className={
                            this.state.language_id == 0
                              ? "opensansR"
                              : "KrutiDev"
                          }
                        >
                          अधिक जानकारी
                        </span>
                      )}
                    </button>
                  </div>
                  <h3 className={`${styles.DateTitle}`}>
                    {this.state.language_id == 0 ? (
                      <span
                        className={
                          this.state.language_id == 0
                            ? "opensansH"
                            : "KrutiDevH"
                        }
                      >
                        Historical Dates
                      </span>
                    ) : (
                      <span
                        className={
                          this.state.language_id == 0
                            ? "opensansH"
                            : "KrutiDevH"
                        }
                      >
                        ऐतिहासिक तिथियां
                      </span>
                    )}
                  </h3>
                  <div className={`${styles.BoxDiv} ${styles.Partition}`}>
                    <ul className={`${styles.UpdatePoints}`}>
                      <li>{this.HistoryDates()}</li>
                    </ul>
                  </div>
                  <div className={`${styles.ContentButton}`}>
                    <button
                      onClick={() => this.pushHistoryDates()}
                      className={`${styles.BtnContent} `}
                    >
                      {this.state.language_id == 0 ? (
                        <span
                          className={
                            this.state.language_id == 0
                              ? "opensansR"
                              : "KrutiDev"
                          }
                        >
                          View All
                        </span>
                      ) : (
                        <span
                          className={
                            this.state.language_id == 0
                              ? "opensansR"
                              : "KrutiDev"
                          }
                        >
                          अधिक जानकारी
                        </span>
                      )}
                    </button>
                  </div>
                </div>

                <div className={`${styles.ContentCourses}   `}>
                  {this.state.dashboards.map((item, index) => {
                    const isOtherServices = item.id == 26 || item.id == 35;
                    console.log(item);
                    if (item.status != 0) {
                      if (this.state.dashboards.length % 2 == 0) {
                        return (
                          <div
                            className={`${styles.OuterContentCourse}`}
                            style={{ border: `1px solid ${item.color}` }}
                          >
                            {" "}
                            <Link
                              style={{ all: "none" }}
                              to={
                                !isOtherServices
                                  ? `/dashboard/${item.url}`
                                  : `/${item.url}`
                              }
                            >
                              <div
                                className={`${styles.ContentCourse}`}
                                style={{ border: `1px solid ${item.color}` }}
                              >
                                <img
                                  src={item.image}
                                  className={`${styles.ContentCourseImage}`}
                                />
                                <span
                                  className={`${styles.ContentCourseText}`}
                                  dangerouslySetInnerHTML={{
                                    __html: `${item.name}`,
                                  }}
                                ></span>
                              </div>
                            </Link>
                          </div>
                        );
                      } else {
                        var my_array = this.state.dashboards;
                        var last_element = my_array[my_array.length - 1];
                        {
                          console.log(last_element);
                        }

                        return (
                          <div
                            className={
                              // index == 0
                              //   ? `${styles.OuterContentCourseOdd}`
                              //   :
                              `${styles.OuterContentCourse}`
                            }
                            style={{ border: `1px solid ${item.color}` }}
                            // onClick={() => this.saveDashboardName(item)}
                          >
                            <Link
                              style={{ all: "none" }}
                              to={
                                !isOtherServices
                                  ? `/dashboard/${item.url}`
                                  : `/${item.url}`
                              }
                            >
                              <div
                                className={`${styles.ContentCourse}`}
                                style={{ border: `1px solid ${item.color}` }}
                              >
                                <img
                                  src={item.image}
                                  className={
                                    index == my_array.length - 1
                                      ? `${styles.ContentCourseImageOdd}`
                                      : `${styles.ContentCourseImage}`
                                  }
                                />
                                <span
                                  className={`${styles.ContentCourseText}`}
                                  dangerouslySetInnerHTML={{
                                    __html: `${item.name}`,
                                  }}
                                ></span>
                              </div>
                            </Link>
                          </div>
                        );
                      }
                    }
                  })}
                </div>
              </div>

              <div className={`${styles.Courses}  mb-5 `}>
                <p className={`${styles.CourseTitle}`}>
                  {" "}
                  {this.state.language_id == 0 ? (
                    <span
                      className={
                        this.state.language_id == 0 ? "opensansB" : "KrutiDevB"
                      }
                    >
                      Courses
                    </span>
                  ) : (
                    <span
                      className={
                        this.state.language_id == 0 ? "opensansB" : "KrutiDevB"
                      }
                    >
                      पाठ्यक्रम
                    </span>
                  )}
                </p>

                <div className={`${styles.Cards}`}>
                  {this.state.dashboards.map((item) => {
                    const isOtherServices = item.id == 26 || item.id == 35;
                    if (item.status != 0) {
                      return (
                        <div className={`${styles.Card}   px-0 mx-2`}>
                          <Flippy
                            flipOnHover={false}
                            flipOnClick={true}
                            flipDirection="horizontal"
                            ref={(r) => (this.flippy = r)}
                          >
                            <FrontSide>
                              <img
                                src={item.image}
                                className={`${styles.CardImage}`}
                              />
                              <div
                                className={`${styles.TextCardFlag}`}
                                style={{ backgroundColor: `${item.color}` }}
                              >
                                <span
                                  className={
                                    this.state.language_id == 0
                                      ? "opensansB"
                                      : "KrutiDevB"
                                  }
                                  dangerouslySetInnerHTML={{
                                    __html: `${item.name}`,
                                  }}
                                ></span>
                              </div>
                            </FrontSide>

                            <BackSide
                              className={`${styles.BackCard}`}
                              style={{ backgroundColor: `${item.color}` }}
                            >
                              <p
                                className={`${styles.BackCardTitle}`}
                                dangerouslySetInnerHTML={{
                                  __html: `${item.name}`,
                                }}
                              ></p>
                              <p className={`${styles.BackCardText}`}>
                                {item.info}
                              </p>
                              <Link
                                style={{ all: "none" }}
                                to={
                                  !isOtherServices
                                    ? `/dashboard/${item.url}`
                                    : `/${item.url}`
                                }
                              >
                                <button
                                  className={`${styles.BackCardButton} btn btn-sm`}
                                  // onClick={() => this.saveDashboardName(item)}
                                >
                                  {this.state.language_id == 0 ? (
                                    <span
                                      className={
                                        this.state.language_id == 0
                                          ? "opensansR"
                                          : "KrutiDev"
                                      }
                                    >
                                      Know More
                                    </span>
                                  ) : (
                                    <span
                                      className={
                                        this.state.language_id == 0
                                          ? "opensansR"
                                          : "KrutiDev"
                                      }
                                    >
                                      अधिक जानिए
                                    </span>
                                  )}
                                </button>
                              </Link>
                            </BackSide>
                          </Flippy>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
            {this.renderContactUs()}
            <div style={{ height: "auto" }}>
              <MainFooterPage {...this.props} />
            </div>
          </div>
        </div>
      );
    }
  }
}
