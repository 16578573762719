import React, { Component } from "react";
import MainFooterPage from "../../Reusable/Footer/footer";
import HomeHeader from "../../Reusable/Header/homeHeader";

import swal from "sweetalert";

import Countdown from "react-countdown-now";
import Timer from "react-compound-timer";
import { Prompt } from "react-router-dom";
import Fade from "react-reveal";
import ProfTen from '../Prof10X10/prof10x10'
import "../commonStyles.css";

export default class newEvaluation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      correctAnswers: 0,
      wrongAnswers: 0,
      currentSubjectiveAnswer: "",
      subjectiveAnswers: [],
      skippedAnswers: 0,
      selected_answer: null,
      count: "0",
      questions:[],
      shouldBlockNavigation: true,
      abc: false
    };
  }

  componentWillMount() {
    console.log(this.props.history.location.state);
    if (this.props.history.location.state == undefined) {
      this.props.history.push("/start-test");
    } else {
      this.setState({
        questions: this.props.history.location.state.questions.filter(
          item => item.question_id
        ),
        count: `${(
          this.props.history.location.state.duration * 60000
        ).toString()}`,
        currentIndex: 0
      },()=>this.getDialogues());
    }
  }

  getDialogues = async () => {
    let formBody = new FormData()
    formBody.append('language_id', localStorage.getItem('language_id'))
    try {
      const dialogueResponse = await fetch(
        "https://10x10learning.com/prof10_test",{
          method:'POST',
          body:formBody
        }
      );
      const result = await dialogueResponse.json();
      const dialogues = JSON.parse(result[0].text).map(
        (item) => item.description
      );
      this.setState({ dialogues });
    } catch (err) {
      console.log(err);
    }
  };
  renderAnswers(allanswers) {
    console.log(allanswers);

    let testType = localStorage.getItem("test_type");
    testType = testType.replace(/^"(.*)"$/, "$1");
    console.log(testType);
    let answers;
    if (testType == "Objective") {
      answers = JSON.parse(allanswers);
      return answers.map(item => {
        return (
          <div
            key={item.id}
            className="d-flex"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              padding: "10px 15px"
            }}
          >
            <div>
              <input
                type="radio"
                checked={
                  this.state.selected_answer
                    ? item.id === this.state.selected_answer.id
                      ? true
                      : false
                    : false
                }
                onChange={() => this.setState({ selected_answer: item })}
              />
            </div>

            <div>
              <span style={{ marginLeft: "5px" }}>{item.answer}</span>
            </div>
          </div>
        );
      });
    } else {
      return (
        <React.Fragment>
          <textarea
            id="form7"
            class="md-textarea form-control"
            rows="3"
            placeholder="Write your Answer here !"
            value={this.state.currentSubjectiveAnswer}
            onChange={text =>
              this.setState({ currentSubjectiveAnswer: text.target.value })
            }
          ></textarea>
        </React.Fragment>
      );
    }
  }

  renderQuestion() {
    console.log(this.state)
    //console.log(this.props.history.location.state)
    if (this.state.questions.length!= 0) {
      let question = this.state.questions[this.state.currentIndex];
      question.visited = true;
      return (
        <div className="question-container">
          <p className="question-container-question">
            Q{this.state.currentIndex + 1}. {question.question_title}
          </p>
          {this.renderAnswers(question.answers)}
        </div>
      );
    }
    else if(this.state.questions.length==0){
      return swal('Currently no questions are available in the test.').then(()=>{
      this.props.history.push('/my-courses')
      window.location.reload()

      })

    }
  }

  handleChangeBox(question, index) {
    console.log(question, index);
    if (question.visited) {
      if (!question.selected_answer) {
        this.setState({
          selected_answer: null,
          currentIndex: index
        });
      } else {
        var sel_answer = question.selected_answer;
        this.setState({
          selected_answer: sel_answer,
          currentIndex: index
        });
      }
    } else {
      this.setState({
        selected_answer: null,
        currentIndex: index
      });
    }
  }
  renderQuestionBoxes() {
    if (this.state.questions != undefined) {
      return this.state.questions.map((question, index) => {
        return (
          <div
            className={
              this.state.currentIndex == index
                ? "question-box bg-red-box"
                : question.visited
                ? question.review
                  ? "question-box review-box"
                  : question.selected_answer == null
                  ? "question-box white-box"
                  : "question-box orange-box"
                : "question-box"
            }
            onClick={() => this.handleChangeBox(question, index)}
          >
            <span>Q{index + 1}</span>
          </div>
        );
      });
    }
  }
  //HANDLE FUNTION
  handleSaveAndNext() {
    console.log(this.state)
   
    
    // console.log(this.state.currentSubjectiveAnswer);

    // if(this.state.currentSubjectiveAnswer.length<20){
    //   return    swal("Warning!", "!", "warning");
    // }
    let testType = localStorage.getItem("test_type");
    testType = testType.replace(/^"(.*)"$/, "$1");
    console.log(testType);
    if (testType == "Objective") {
      let questions = this.state.questions;
      let question = questions[this.state.currentIndex];
      let correct = 0;
      let wrong = 0;
      let skipped = 0;
      if (this.state.selected_answer == null) {
        swal({
          title: "PLease select an option",
          text: "",
          icon: "warning",
          dangerMode: true
        }).then(() => {
          return;
        });
      } else {
        question.selected_answer = this.state.selected_answer;
        questions.forEach(element => {
          if (element.selected_answer) {
            if (element.selected_answer.value == 0) {
              wrong++;
            }
            if (element.selected_answer.value == 1) {
              correct++;
            }
          }
        });
        if (question.review) {
          delete question["review"];
        }

        if (this.state.currentIndex < questions.length - 1) {
          let selected_answer =
            typeof questions[this.state.currentIndex + 1].selected_answer ===
            "undefined"
              ? null
              : questions[this.state.currentIndex + 1].selected_answer;
          this.setState({
            correctAnswers: correct,
            wrongAnswers: wrong,
            selected_answer,
            currentIndex: this.state.currentIndex + 1
          },()=>console.log(this.state.selected_answer));
        } else {
          let checkIfNotAttempted=this.state.questions
         let toCheck= checkIfNotAttempted.map(item=>{
           if(item.selected_answer==null){
            return item
           }
          })
          console.log(toCheck)
        for(let i=0;i<toCheck.length;i++){
          if(toCheck[i]!=undefined){
            return swal(
              "Warning!",
              "Please do not leave any questions unattempted !",
              "warning"
            );
          }
        }
          this.setState({
            abc: true
          });
          window.localStorage.setItem(
            "test_questions",
            JSON.stringify(this.state.questions)
          );
          this.props.history.push({
            pathname: "/feedback",
            state: {
              correct: this.state.correctAnswers,
              wrong: this.state.wrongAnswers,
              size: this.state.questions.length,
              questions: this.state.questions
            }
          });
        }
      }
    } else if (testType == "Subjective") {
      let calculateAnswersInWords = this.state.currentSubjectiveAnswer.split(
        " "
      );
      console.log(calculateAnswersInWords);

      if (calculateAnswersInWords.length < 10) {
        return swal(
          "Warning!",
          "Answers should be more than 10 words!",
          "warning"
        );
      }
      console.log(this.state);
      console.log(this.state.currentSubjectiveAnswer);
      if (this.state.currentSubjectiveAnswer.length == 0) {
        swal({
          title: "PLease write an answer",
          text: "",
          icon: "warning",
          dangerMode: true
        }).then(() => {
          return;
        });
      } else {
        let questions = this.state.questions;
        console.log(questions);

        let question = questions[this.state.currentIndex];
        question.answers = this.state.currentSubjectiveAnswer;
        console.log(this.state);
        console.log(question);

        if (this.state.currentIndex < questions.length - 1) {
          let answers =
            questions[this.state.currentIndex + 1].answers == null
              ? null
              : questions[this.state.currentIndex + 1].answers;
          this.setState({
            currentSubjectiveAnswer: "",
            currentIndex: this.state.currentIndex + 1
          });
        } else {
          this.setState({
            abc: true
          });
          window.localStorage.setItem(
            "test_questions",
            JSON.stringify(this.state.questions)
          );
          this.props.history.push({
            pathname: "/feedback",
            state: {
              size: this.state.questions.length,
              questions: this.state.questions
            }
          });
        }
        //       window.localStorage.setItem("test_questions", JSON.stringify(this.state.questions))
        //       this.props.history.push({
        //         pathname: "/feedback",
        //         state: {
        //           correct: this.state.correctAnswers,
        //           wrong: this.state.wrongAnswers,
        //           size: this.state.questions.length,
        //           questions:this.state.questions
        //         }
        //       });

        //     console.log(question)
        //     let subjectiveAnswers=[]
        //     subjectiveAnswers.push(this.state.currentSubjectiveAnswer)
        // this.setState({ currentIndex: this.state.currentIndex + 1,subjectiveAnswers})

        //   }
        //   }
      }
    }
  }

  //(typeof my_obj.someproperties === "undefined")

  handleSkip() {
    let questions = this.state.questions;
    let question = questions[this.state.currentIndex];
    let skipped = 0;
    question.selected_answer = null;

    if (question.review) {
      delete question["review"];
    }
    if (this.state.currentIndex < questions.length - 1) {
      let selected_answer =
        typeof questions[this.state.currentIndex + 1].selected_answer ===
        "undefined"
          ? null
          : questions[this.state.currentIndex + 1].selected_answer;
      this.setState({
        skippedAnswers: skipped,
        selected_answer,
        currentIndex: this.state.currentIndex + 1
      });
    } else {
      window.localStorage.setItem(
        "test_questions",
        JSON.stringify(this.state.questions)
      );
      this.props.history.push({
        pathname: "/feedback",
        state: {
          correct: this.state.correctAnswers,
          wrong: this.state.wrongAnswers,
          size: this.state.questions.length
        }
      });
    }
  }

  handleBack() {
    let testType = localStorage.getItem("test_type");
    testType = testType.replace(/^"(.*)"$/, "$1");
    console.log(testType);
    let index = this.state.currentIndex;
    let { questions } = this.state;
    let previous = questions[index - 1];
    if (testType == "Objective") {
      this.setState({
        selected_answer: previous.selected_answer,
        currentIndex: index - 1
      });
    } else {
      this.setState({
        currentSubjectiveAnswer: previous.answers,
        currentIndex: index - 1
      });
    }
  }
  renderTimer() {
    return (
      <Timer
        initialTime={this.state.count}
        direction="backward"
        checkpoints={[
          {
            time: 600,
            callback: () => swal("You have 10 mins left !")
          },
          {
            time: 0,
            callback: () => {
              swal("Time over !");
              window.localStorage.setItem(
                "test_questions",
                JSON.stringify(this.state.questions)
              );
              this.props.history.push({
                pathname: "/feedback",
                state: {
                  correct: this.state.correctAnswers,
                  wrong: this.state.wrongAnswers,
                  size: this.state.questions.length
                }
              });
            }
          }
        ]}
      >
        {() => (
          <React.Fragment>
            <Timer.Hours /> :&nbsp;
            <Timer.Minutes /> :&nbsp;
            <Timer.Seconds />
          </React.Fragment>
        )}
      </Timer>
    );
  }

  handleReviewAnswer() {
    let questions = this.state.questions;
    let question = this.state.questions[this.state.currentIndex];
    question.selected_answer = this.state.selected_answer;
    question.review = true;
    this.setState({
      questions,
      currentIndex: this.state.currentIndex + 1,
      selected_answer: null
    });
  }

  renderLegend() {
    return (
      <div className="legend_container">
        {" "}
        <div className="single_legend_container">
          {" "}
          <div
            style={{ backgroundColor: "#c01825" }}
            className="legend_color_div"
          ></div>
          <div className="legend_text">Current Question</div>{" "}
        </div>{" "}
        <div className="single_legend_container legend_container2">
          <div
            style={{ border: "3px solid #c01825" }}
            className="legend_color_div"
          ></div>
          <div className="legend_text">Not Attempted</div>
        </div>{" "}
        <div className="single_legend_container legend_container2">
          {" "}
          <div
            style={{ backgroundColor: "#f17a0b" }}
            className="legend_color_div"
          ></div>
          <div className="legend_text">Attempted & Saved</div>{" "}
        </div>{" "}
        <div className="single_legend_container legend_container2">
          <div
            style={{ border: "3px solid rgb(101, 172, 76)" }}
            className="legend_color_div"
          ></div>
          <div className="legend_text">Review Later</div>
        </div>{" "}
      </div>
    );
  }

  componentDidMount() {
    //  window.onbeforeunload = function () {
    //       return  <Prompt message={this.state.abc?'Do you want to submit? ':'All your saved answers will be discarded, are you sure you want to leave?'}  />
    //     }
  }

  componentDidUpdate = () => {
    let { shouldBlockNavigation } = this.state;
    if (shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  };

  checkSubmit() {
    if (this.state.questions != undefined)
      if (this.state.questions.length - 1 == this.state.currentIndex) {
        return false;
      }

    return true;
  }

  deleteAlert = () => {
    swal({
      title: "Are you sure?",
      text: "No data will be saved if you leave the test,",
      buttons: true,
      dangerMode: true
    }).then(willAdd => {
      if (willAdd) {
        this.props.history.push("/my-courses");
      } else return;
    });
  };
  render() {
    console.log(this.state, "state");
    return (
      <div
        style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
      >
        {/* <Prompt  when={this.checkSubmit()}   message='All your saved answers will be discarded, are you sure you want to leave?'    /> */}

        <div>
          <HomeHeader {...this.props} width="100%" height="auto" />
        </div>
        <div id="test-page-container">
          <div className="timer-row d-flex">
            <div className="evalutaion-legend w-100">{this.renderLegend()}</div>
            <div className="countdown_btn_1">
              <div className="subTimerDiv">
                <div className="testTimer">
                  {/* <Countdown date={Date.now() + 3600000} 
              renderer={renderer} /> */}
                  {this.renderTimer()}
                </div>
              </div>
              <div className="btn_clock">
                <img
                  src={require("../../Assets/images/clock.png")}
                  alt="clockImage"
                />
              </div>
            </div>
          </div>
          <div className="evaluation-heading-container">
            <h2 className="pl-1">Evaluation</h2>
          </div>

          <div className="row questinBoxQuestion">
            <Fade>
              <div className="col-7 pr-0 questionOnly">{this.renderQuestion()}</div>
            </Fade>
            <div className="col-5  question-boxes-container">
              {this.renderQuestionBoxes()}
            </div>
          </div>
          {/* <button className={this.state.currentIndex==0?"d-none":"btn btn-lg btn-warning rounded"} onClick={()=>this.handleBack()}>Back</button>
            <button className="btn btn-lg btn-primary rounded" onClick={()=>this.handleSaveAndNext()}>Save and {this.state.questions.length-1==this.state.currentIndex?"Submit":"Next"}</button> */}

          <div className="evaluation_buttons_div">
            <div className="d-flex">
              <button
                className="quit-evaluation-button btn p-0"
                onClick={() => this.deleteAlert()}
              >
                Leave Test
              </button>

              <button
                className={
                  this.state.currentIndex == 0
                    ? "d-none"
                    : "evalutaion-back-button btn mx-2 p-0"
                }
                onClick={() => this.handleBack()}
              >
                Back
              </button>
            </div>

            <div className="d-flex">
              <button
                className={
                  this.state.questions != undefined
                    ? this.state.questions.length - 1 == this.state.currentIndex
                      ? "d-none"
                      : "review-answer-button btn mx-2 p-0"
                    : ""
                }
                onClick={() => this.handleReviewAnswer()}
              >
                Review later
              </button>

              <button
                className="saveBtnDiv btn mx-2 p-0"
                onClick={() => this.handleSaveAndNext()}
              >
                Save and{" "}
                {this.state.questions != undefined
                  ? this.state.questions.length - 1 == this.state.currentIndex
                    ? "Submit"
                    : "Next"
                  : ""}
              </button>
            </div>
          </div>
        </div>
        <ProfTen dialogues={this.state.dialogues} />
        <div>
          <MainFooterPage {...this.props} />
        </div>
      </div>
    );
  }
}
