import React, { Component, Fragment } from "react";
import styles from '../DashboardComponent/DashboardComponent.module.css'
import time from "../../../Assets/images/time.png";
import lessons from "../../../Assets/images/lessons.png";
import grad from "../../../Assets/images/grade.png";
import swal from "sweetalert";
import stars from "../../../Assets/images/star.png";
import courseDuration from "../../../Assets/images/course-duration.png";
import  './otherservicesubcategory.css';
import HomeHeader from "../../../Reusable/Header/homeHeader";
import MainFooterPage from "../../../Reusable/Footer/footer";
import GridLoader from "react-spinners/GridLoader";
import { css } from "@emotion/core";
import Login from "../../../Reusable/Header/login";
import ProfTen from '../../Prof10X10/prof10x10'


var subjectsToDisplay = [];

export default class Otherservicesubcategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      choosenSubject: null,
      dashboard_item: [],
      selectedClass: "",
      finalSubject: [],
      language_id: "",
      subcategory:'',
      subcategories:'',
      subjects: [],
      subjectsToDisplay: [],
      subjectsFiltered: [],
      schoolSubjects: [],
      banner:'',
      loginToggle: false,
      title:'',
      subtitle:'',
      allItems: [],
      userdata: null,
      showLogin:
        this.props.showLogin === undefined ? false : this.props.showLogin
    };
  }
  componentWillMount() {
    let banner = JSON.parse(localStorage.getItem("verticalBannerImage"));
    this.setState({ banner: banner[0].link });
    let language_id = localStorage.getItem("language_id");
    let subcategory = localStorage.getItem("other_service_type");
    console.log(subcategory)
    this.setState({language_id,subcategory},()=>this.getDialogues())
  }
  getSubcateories = async () => {
    console.log(this.state.language_id);
    let details = {
      language_id: this.state.language_id,
      category_type:this.state.subcategory
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/get_subcategories", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson);
          let data=responseJson.filter(item=>item.status==1)
          this.setState({subcategories:data},()=>this.getServicesText())
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };
 
   
  getServicesText() {
    let formData = new FormData();
    formData.append("language_id", this.state.language_id);
    fetch("https://10x10learning.com/get_other_services_text", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("ServicesText", responseJson);
        const result = responseJson[0];
        this.setState({ title: result.name, subtitle: result.subtitle, loading:false });
      });
  }




 






  

  productDetail = item => {
    console.log(item);
    localStorage.setItem("selected_subject", JSON.stringify(item));
    this.props.history.push(`service_category/${item.service_name}`);
  };

 
  


  

  

  displayCourseCards = () => {
   

    return (
      <Fragment>
        {this.state.subcategories.map(item => {
              console.log(item);
              if (item.status != 0) {
                return (
                  <div className="col-12 col-lg-3 col-md-6">
                    <div className={`${styles.OuterContentCourse}`}>
                      <div className={`${styles.ContentCourse}`} onClick={() => this.productDetail(item)}>
                        <img
                          src={item.image}
                          className={`${styles.CardImage}`}
                        />
                        <div className={`${styles.CardBody}`}>
                          <p
                            className={`${styles.CardTitle}`}
                            
                          >
                            {item.service_name}{" "}
                          </p>
                         
                          <p className={`${styles.Ratings}`}>
                          
                            
                            <p className={`${styles.Duration}`}>
                             
                             
                            </p>
                            <div className={`${styles.Costs}`}>
                              <span></span>
                              <span></span>
                              <span className={`${styles.OldCost}`}>
                                <strike>&#8377; {item.old_cost}</strike>
                              </span>
                              <span className={`${styles.NewCost}`}>
                                &#8377; {item.cost}
                              </span>
                            </div>
                            <div className='ButtonCourse mt-5'> <button
                            className={`${styles.AddCart}`}
                            
                          >
                            {this.state.language_id == 0 ? (
                              <span>Go to Service</span>
                            ) : (
                              <span>
                              सेवा पर जाएं</span>
                            )}
                          </button></div>
                          </p>
                        </div>
                     

                      </div>
                    </div>
                  </div>
                );
              }
            })}
      </Fragment>
    );
  };

  bannerDashboard = () => {
    return (
      <Fragment>
        <span className={`${styles.BannerText}`}>
          {this.state.title}
        </span>
        <span className={`${styles.BannerTextBelow}`}>
          {this.state.subtitle}
        </span>
      </Fragment>
    );
  };

  showSubjects = () => {
    this.setState({ choosenSubject: 1 });
    var finalSubject = [];
    for (let i = 0; i < subjectsToDisplay.length; i++) {
      console.log(subjectsToDisplay[i]);
      console.log(JSON.parse(subjectsToDisplay[i].class));
      let subjectClass = JSON.parse(subjectsToDisplay[i].class);
      for (let i2 = 0; i2 < subjectClass.length; i2++) {
        if (
          subjectClass[i2].label.toString() == this.state.inputState.toString()
        ) {
          console.log(subjectClass[i2]);
          console.log(subjectsToDisplay[i]);

          finalSubject.push(subjectsToDisplay[i]);
        }
      }
      this.setState({ finalSubject }, () =>
        console.log(this.state.finalSubject)
      );
    }
  };
  selectClass = e => {
    let classRelated = "Class " + e.target.value;
    this.setState({ inputState: classRelated });
  };

  getDialogues = async () => {
    let formBody = new FormData()
    formBody.append('language_id', this.state.language_id)
    try {
      const dialogueResponse = await fetch(
        "https://10x10learning.com/prof10_other_service_subcategory",{
          method:'POST',
          body:formBody
        }
      );
      const result = await dialogueResponse.json();
      const dialogues = JSON.parse(result[0].text).map(
        (item) => item.description
      );
      this.setState({ dialogues },()=>this.getSubcateories());
    } catch (err) {
      console.log(err);
    }
  };
  render() {
    const override = css`
    display: block;
    margin: 0 auto;
    border-color: black;
    margin: 30% 45%;
  `;

  if (this.state.loading) {
    return (
      <div className="sweet-loading-main">
        <GridLoader
          css={override}
          sizeUnit={"px"}
          size={5}
          color={"#c01825"}
          loading={this.state.loading}
        />
      </div>
    );
  } else {
    return (
      <div className={`${styles.CivilServices}`} id="civil-services">
        <HomeHeader
          {...this.props}
          backgroundColor="#FFFFFF"
          textcolor="#000"
          width="100%"
          height="auto"
        />
        <div className={`${styles.BannerImage}`} style={{
          backgroundImage: `url(${this.state.banner})`,
          opacity: 0.8,
          background: "linear-gradient(#e66465, #9198e5);" 
        }}>{this.bannerDashboard()}</div>
        
        <div className={`${styles.Courses}`}>
          {this.state.loading ? (
            <div className="sweet-loading-main">
              <GridLoader
                css={override}
                sizeUnit={"px"}
                size={5}
                color={"#c01825"}
                loading={this.state.loading}
              />
            </div>
          ) : (
            <div class="row">{this.displayCourseCards()}</div>
          )}
        </div>
        <ProfTen dialogues={this.state.dialogues} />
        <div style={{ height: "auto" }}>
          <MainFooterPage {...this.props} />
        </div>

        <Login showLogin={this.state.showLogin} {...this.props} />
      </div>
    );

          }
  }
}
