import React, { Component } from "react";

import "./myCart.css";

import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import ReactToPrint from "react-to-print";
import ReactTooltip from "react-tooltip";

// import ProfTen from "../Prof10X10/prof10x10";

// import { HollowDotsSpinner } from "react-epic-spinners";
import {
  MDBIcon,
  MDBModal,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdbreact";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import Template from "./Template";

const override = css`
  display: flex;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

let count = 0;

const buttonStyle = {
  backgroundColor: "#c01825",
  color: "white",
  padding: "8px 30px",
  border: "none",
  marginTop: "15px",
};
export default class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    console.log(this.props);
    if (this.props.location.state !== undefined) {
      this.setState({
        ...this.props.location.state,
        loading: false,
      });
    } else {
      let invoice_data = window.localstorage.getItem("invoice_state");
      if (invoice_data != null || invoice_data !== undefined) {
        this.setState({
          ...JSON.parse(invoice_data),
          loading: false,
        });
      } else {
        this.props.history.push("/my-cart");
      }
    }
    //   this.setState({...this.props.match.location.state})
  }

  renderCess2(data, index) {
    if (data.cess != null) {
      return JSON.parse(data.cess).map((item, index) => {
        return (
          <tr style={{ fontSize: "14px" }}>
            <td></td>
            <td>
              {item.name} @ {item.value}%
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ textAlign: "center" }}>
              Rs.{" "}
              {(((Number(this.state.old_total) * data.tax_value) / 100) *
                Number(item.value)) /
                (100).toFixed(2)}
            </td>
          </tr>
        );
      });
    }
  }

  exportPDF = () => {
    //  var promise= new Promise((resolve, reject) => {
    //     this.resume.save()

    //   })
    //   promise.then(()=>)
    this.resume.save();

    //this.props.history.push('/my-courses')

    //window.location.reload()
  };
  invoiceData() {
    let toWords = require("to-words");
    console.log(
      parseFloat(
        this.state.old_total +
          (this.state.old_total * this.state.calculated_tax) / 100
      ).toFixed(2)
    );
    let { orderData } = this.state;
    var fileData = {
      order_id: this.state.new_order_id,
      invoice_number: this.state.invoice_number,
      email: this.state.userdata.email,
      donwload: this.state.download_invoice,
    };
    // let count = 0

    // let count = this.state.myCart.reduce((initial_count, item) => {
    //   console.log(item);
    //   if (item.test_included && item.subject_included) {
    //     initial_count = initial_count + 2;
    //   } else {
    //     initial_count += 1;
    //   }
    //   return initial_count;
    // }, 0);
    // console.log(count)

    let seperatedCartItems = [];
    this.state.myCart.forEach((item) => {
      if (item.test_included && item.subject_included) {
        seperatedCartItems.push({...item, test_included:false, subject_included:true});
        seperatedCartItems.push({...item, test_included:true, subject_included:false});
      } else {
        seperatedCartItems.push(item);
      }
    });

    console.log(seperatedCartItems);

    return (
      <React.Fragment>
        <div className="invoice_container" id="save_button">
          <div className="cart-invoice-modal">
            {/* <MDBModalBody style={{ padding: 10 }}> */}
            <div className="save_pdf_button_container justify-content-between align-items-center">
              <button
                style={buttonStyle}
                onClick={() => {
                  this.resume.save();
                //   this.props.history.push("/my-courses");
                }}
              >
                {" "}
                Generate Invoice
              </button>
              <div>
                <MDBIcon
                  style={{
                    color: "#000",
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState({ download_invoice: 1 }, this.exportPDF)
                  }
                  icon="save"
                  data-tip="Download Invoice"
                  size="lg"
                />
                <ReactTooltip place="bottom" />
                <ReactToPrint
                  trigger={() => (
                    <MDBIcon
                      style={{
                        color: "#000",
                        marginRight: 10,
                        cursor: "pointer",
                      }}
                      pageStyle="@page { size: auto; margin: 100mm; } }"
                      icon="print"
                      data-tip="Print Invoice"
                      size="lg"
                    />
                  )}
                  content={() => this.resume}
                />
                <MDBIcon
                  style={{
                    color: "#000",
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState({ download_invoice: 0 }, this.exportPDF)
                  }
                  icon="times"
                  data-tip="Close"
                  size="lg"
                />
              </div>
            </div>
            <PDFExport
              paperSize={"A4"}
              scale={0.7}
              pageTemplate={Template}
              fileName={JSON.stringify(fileData)}
              title=""
              subject=""
              proxyTarget={this.state.download_invoice == 1 ? "_blank" : ""}
              keywords=""
              crossorigin="anonymous"
              forceProxy={true}
              proxyURL="https://10x10learning.com/add_order_invoice"
              ref={(r) => (this.resume = r)}
            >
              <div
                id="invoice"
                style={{ margin: 10, marginTop: 200, padding: 10 }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <text
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      textAlign: "center",
                    }}
                  >
                    TAX INVOICE
                  </text>
                </div>

                <hr
                  style={{
                    display: "block",
                    borderStyle: "inset",
                    borderWidth: 0.5,
                    marginLeft: 0,
                    marginRight: 0,
                    borderColor: "grey",
                    marginBottom: 5,
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <text
                    style={{ fontWeight: "bold", fontSize: "16px" }}
                  >{`M/s ${this.state.userdata.name}`}</text>
                </div>
                <hr
                  style={{
                    display: "block",
                    borderStyle: "inset",
                    borderWidth: 0.5,
                    marginLeft: 0,
                    marginRight: 0,
                    borderColor: "grey",
                    marginTop: 5,
                    marginBottom: "5px",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                    justifyContent: "space-between",
                    padingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <div style={{ marginTop: "0px", fontSize: "12px" }}>
                    <strong>Address</strong>&nbsp;:
                    <span>{this.state.address.state}</span>
                  </div>
                  <div style={{ fontSize: "12px" }}>
                    Invoice No : &nbsp;
                    <text style={{ color: "black", fontWeight: "bold" }}>
                      {this.state.invoice_number}
                    </text>
                    <br />
                    Date : &nbsp;
                    <text style={{ color: "black", fontWeight: "bold" }}>
                      {this.state.date.getDate()}-
                      {this.state.date.getMonth() + 1}-
                      {this.state.date.getFullYear()}
                    </text>
                    <br />
                  </div>
                </div>
                <p
                  style={{
                    marginBottom: 0,
                    marginTop: "10px",
                    fontSize: "12px",
                    letterSpacing: "-0.8px",
                  }}
                >
                  <strong>GSTIN</strong>:
                </p>
                <MDBTable className="table  table-bordered table-sm dataTable">
                  <MDBTableHead color="invoice-table-head">
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th style={{ width: "10%", textAlign: "center" }}>
                        Sl. No
                      </th>
                      <th style={{ width: "40%", textAlign: "center" }}>
                        Particulars
                      </th>
                      <th style={{ width: "10%", textAlign: "center" }}>
                        HSN / SAC Code
                      </th>
                      <th style={{ width: "10%", textAlign: "center" }}>Qty</th>
                      <th style={{ width: "10%", textAlign: "center" }}>UOM</th>
                      <th style={{ width: "10%", textAlign: "center" }}>
                        Rate
                      </th>
                      <th style={{ width: "10%", textAlign: "center" }}>
                        Amount (Rs)
                      </th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {seperatedCartItems.map((data, key) => {
                      let index = 0;
                      if (data.service_included == false) {
                        return (
                          <React.Fragment>
                            {this.renderSujectCostRow(data, key)}
                            {/* {this.renderTestCostRow(data, key)} */}
                          </React.Fragment>
                        );
                      } else {
                        return (
                          <React.Fragment>
                            {this.renderExamCostRow(data, key)}
                          </React.Fragment>
                        );
                      }
                    })}
                    {this.renderDiscountRow()}
                    {this.renderTaxColumn()}
                    <tr style={{ fontSize: "14px" }}>
                      <td></td>
                      <td
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px !important",
                        }}
                      >
                        TOTAL TAXABLE INVOICE AMOUNT
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style={{ textAlign: "center" }}>
                        Rs.&nbsp;
                        {(
                          this.state.old_total +
                          (this.state.old_total * this.state.calculated_tax) /
                            100
                        ).toFixed(2)}
                      </td>
                    </tr>
                    {/* <tr style={{fontSize:15}}>
    
    <td style={{fontWeight:'bold'}}>Grand Total</td>
    <td ></td>
    <td style={{textAlign:'end'}}>Rs. {this.state.total_cost}</td>
    </tr> */}
                    <tr>
                      <td colSpan="12" style={{ fontSize: "14px" }}>
                        <strong>In Words </strong>: Rs. &nbsp;
                        <span style={{ letterSpacing: "1px" }}>
                          {toWords(
                            Number(
                              parseFloat(
                                this.state.old_total +
                                  (this.state.old_total *
                                    this.state.calculated_tax) /
                                    100
                              ).toFixed(2)
                            )
                          )}
                          &nbsp;Only
                        </span>
                      </td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>

                <table className="table  table-bordered table-sm dataTable">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center", borderBottom: "0px" }}>
                        {" "}
                        HSN / SAC
                      </th>
                      <th style={{ textAlign: "center", borderBottom: "0px" }}>
                        Taxable Value
                      </th>
                      <th
                        style={{ textAlign: "center", borderBottom: "0px" }}
                        colspan="2"
                      >
                        CGST
                      </th>
                      <th
                        style={{ textAlign: "center", borderBottom: "0px" }}
                        colspan="2"
                      >
                        SGST
                      </th>
                      <th
                        style={{ textAlign: "center", borderBottom: "0px" }}
                        colspan="2"
                      >
                        IGST
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td style={{ textAlign: "center" }}>Rate&nbsp;%</td>
                      <td style={{ textAlign: "center" }}>Amount</td>
                      <td style={{ textAlign: "center" }}>Rate&nbsp;%</td>
                      <td style={{ textAlign: "center" }}>Amount</td>
                      <td style={{ textAlign: "center" }}>Rate&nbsp;%</td>
                      <td style={{ textAlign: "center" }}>Amount</td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "center" }}>998431</td>
                      <td style={{ textAlign: "center" }}>
                        Rs.&nbsp;{Number(this.state.old_total).toFixed(2)}
                      </td>
                      {this.renderTaxColumn3()}
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <text
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    textAlign: "end",
                    marginRight: "20px",
                  }}
                >
                  This is a computer generated Invoice and does not required
                  signature.
                </text>
              </div>
            </PDFExport>
            {/* </MDBModalBody> */}
            {/* <MDBModalFooter> */}
            {/* <button
                onClick={() =>
                  this.setState({ download_invoice: 0 }, this.exportPDF)
                }
                className="view-details-button btn-custom"
                type="button"
              >
                Close
              </button> */}
            {/* </MDBModalFooter> */}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderSujectCostRow(data, key) {
    if (data.test_included == 1 && data.subject_included == 0) {
      count = count + 1;
      const cost = data.test_cost
      return (
        <tr style={{ fontSize: "14px" }}>
          <td style={{ width: "10%", textAlign: "center" }}>{count}</td>
          <td style={{ width: "40%", textAlign: "left" }}>
            {data.test_included
              ? data.subject_name + " Test"
              : data.subject_name}{" "}
          </td>
          <td style={{ width: "10%", textAlign: "center" }}>998431</td>
          <td style={{ width: "10%", textAlign: "center" }}>1</td>
          <td style={{ width: "10%", textAlign: "center" }}>Nos.</td>
          <td style={{ width: "10%", textAlign: "center" }}>
            Rs.&nbsp;{Number(cost).toFixed(2)}{" "}
          </td>
          <td style={{ textAlign: "right" }}>
            Rs.&nbsp;{Number(cost).toFixed(2)}
          </td>
        </tr>
      );
    }

    if (data.test_included == 0 && data.subject_included == 1) {
      count = count + 1;
      return (
        <>
          <tr style={{ fontSize: "14px" }}>
            <td style={{ width: "10%", textAlign: "center" }}>{count}</td>
            <td style={{ width: "40%", textAlign: "left" }}>
              {data.subject_name}{" "}
            </td>
            <td style={{ width: "10%", textAlign: "center" }}>998431</td>
            <td style={{ width: "10%", textAlign: "center" }}>1</td>
            <td style={{ width: "10%", textAlign: "center" }}>Nos.</td>
            <td style={{ width: "10%", textAlign: "center" }}>
              Rs.&nbsp;{Number(data.cost).toFixed(2)}{" "}
            </td>
            <td style={{ textAlign: "right" }}>
              Rs.&nbsp;{Number(data.cost).toFixed(2)}
            </td>
          </tr>
        </>
      );
    }

    if (data.test_included == 1 && data.subject_included == 1) {
      console.log("this condition shoud run")
      count = count + 2;
      return (
        <>
          <tr style={{ fontSize: "14px" }}>
            <td style={{ width: "10%", textAlign: "center" }}>{count - 1}</td>
            <td style={{ width: "40%", textAlign: "left" }}>
              {data.subject_name}{" "}
            </td>
            <td style={{ width: "10%", textAlign: "center" }}>998431</td>
            <td style={{ width: "10%", textAlign: "center" }}>1</td>
            <td style={{ width: "10%", textAlign: "center" }}>Nos.</td>
            <td style={{ width: "10%", textAlign: "center" }}>
              Rs.&nbsp;{Number(data.cost).toFixed(2)}{" "}
            </td>
            <td style={{ textAlign: "right" }}>
              Rs.&nbsp;{Number(data.cost).toFixed(2)}
            </td>
          </tr>
          <tr style={{ fontSize: "14px" }}>
            <td style={{ width: "10%", textAlign: "center" }}>{count}</td>
            <td style={{ width: "40%", textAlign: "left" }}>
              {data.subject_name + " Test"}{" "}
            </td>
            <td style={{ width: "10%", textAlign: "center" }}>998431</td>
            <td style={{ width: "10%", textAlign: "center" }}>1</td>
            <td style={{ width: "10%", textAlign: "center" }}>Nos.</td>
            <td style={{ width: "10%", textAlign: "center" }}>
              Rs.&nbsp;{Number(data.test_cost).toFixed(2)}{" "}
            </td>
            <td style={{ textAlign: "right" }}>
              Rs.&nbsp;{Number(data.test_cost).toFixed(2)}
            </td>
          </tr>
        </>
      );
    }
  }

  renderTaxColumn() {
    if (this.state.state.toLowerCase() != "haryana") {
      return [...this.state.tax].reverse().map((tax_data, tax_data_key) => {
        if (tax_data.tax_id == 8) {
          return (
            <React.Fragment>
              <tr style={{ fontSize: "14px" }}>
                <td></td>
                <td>
                  {" "}
                  {tax_data.tax_name}&nbsp;@&nbsp;{tax_data.tax_value}%
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td style={{ textAlign: "center" }}>
                  Rs.&nbsp;
                  {(
                    (Number(this.state.old_total) * tax_data.tax_value) /
                    100
                  ).toFixed(2)}
                </td>
              </tr>
              {this.renderCess2(tax_data, tax_data_key)}
            </React.Fragment>
          );
        }
        if (tax_data.tax_id == 1 || tax_data.tax_id == 2) {
          return (
            <React.Fragment>
              <tr style={{ fontSize: "14px" }}>
                <td></td>
                <td>
                  {" "}
                  {tax_data.tax_name}&nbsp;@&nbsp;{tax_data.tax_value}%{" "}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ textAlign: "center" }}>-</td>
              </tr>
            </React.Fragment>
          );
        }
      });
    } else {
      return [...this.state.tax].reverse().map((tax_data, tax_data_key) => {
        if (tax_data.tax_id == 1 || tax_data.tax_id == 2) {
          return (
            <React.Fragment>
              <tr style={{ fontSize: "14px" }}>
                <td></td>
                <td>
                  {tax_data.tax_name}&nbsp;@&nbsp;{tax_data.tax_value}%
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ textAlign: "center" }}>
                  Rs.&nbsp;
                  {(Number(this.state.old_total) * tax_data.tax_value) / 100}
                </td>
              </tr>
              {this.renderCess2(tax_data, tax_data_key)}
            </React.Fragment>
          );
        }
        if (tax_data.tax_id == 8) {
          return (
            <React.Fragment>
              <tr style={{ fontSize: "14px" }}>
                <td></td>
                <td>
                  {" "}
                  {tax_data.tax_name}&nbsp;@ &nbsp;{tax_data.tax_value}%
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ textAlign: "center" }}>-</td>
              </tr>
            </React.Fragment>
          );
        }
      });
    }
  }

  renderTaxColumn3() {
    if (this.state.state.toLowerCase() != "haryana") {
      return [...this.state.tax].reverse().map((tax_data, tax_data_key) => {
        if (tax_data.tax_id == 8) {
          return (
            <React.Fragment>
              <td style={{ textAlign: "center" }}>
                {tax_data.tax_value}&nbsp;%
              </td>
              <td style={{ textAlign: "center" }}>
                Rs.&nbsp;
                {(
                  (Number(this.state.old_total) * tax_data.tax_value) /
                  100
                ).toFixed(2)}
              </td>
            </React.Fragment>
          );
        }
        if (tax_data.tax_id == 1 || tax_data.tax_id == 2) {
          return (
            <React.Fragment>
              <td style={{ textAlign: "center" }}>
                {" "}
                {tax_data.tax_value}&nbsp;%{" "}
              </td>
              <td style={{ textAlign: "center" }}> - </td>
            </React.Fragment>
          );
        }
      });
    } else {
      return [...this.state.tax].reverse().map((tax_data, tax_data_key) => {
        if (tax_data.tax_id == 1 || tax_data.tax_id == 2) {
          return (
            <React.Fragment>
              <td style={{ textAlign: "center" }}>
                {tax_data.tax_value}&nbsp;%
              </td>
              <td style={{ textAlign: "center" }}>
                Rs.&nbsp;
                {(Number(this.state.old_total) * tax_data.tax_value) / 100}
              </td>
            </React.Fragment>
          );
        }
        if (tax_data.tax_id == 8) {
          return (
            <React.Fragment>
              <td style={{ textAlign: "center" }}>
                {tax_data.tax_value}&nbsp;%
              </td>
              <td style={{ textAlign: "center" }}>-</td>
            </React.Fragment>
          );
        }
      });
    }
  }



  renderDiscountRow() {
    if (this.state.promoApplied) {
      return (
        <tr style={{ fontSize: "14px" }}>
          <td></td>
          <td>Discount </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td style={{ textAlign: "center" }}>
            -Rs.&nbsp;{this.state.discount}
          </td>
        </tr>
      );
    }
  }

  renderExamCostRow(data, key) {
    return (
      <tr style={{ fontSize: "14px" }}>
        <td style={{ textAlign: "center" }}>{key + 1}</td>
        <td style={{ textAlign: "left" }}>{data.service_name}</td>
        <td style={{ textAlign: "center" }}>998431</td>
        <td style={{ textAlign: "center" }}>1</td>
        <td style={{ textAlign: "center" }}>Nos</td>
        <td style={{ textAlign: "center" }}>
          Rs.&nbsp;{Number(data.cost).toFixed(2)}{" "}
        </td>
        <td style={{ textAlign: "center" }}>
          Rs.&nbsp;{Number(data.cost).toFixed(2)}
        </td>
      </tr>
    );
  }
  printDiv() {
    var printContents = document.getElementById("invoice").innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="sweet-loading">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return <div>{this.invoiceData()}</div>;
    }
  }
}
