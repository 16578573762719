import React from "react";
import UsrImg from "../../Assets/images/user.png";
import RightArrow from "../../Assets/images/buttons-arrow.png";
import "./accountNav.css";
import LeftArrow from "../../Assets/images/left-arrow.png";
import "../../Components/commonStyles.css";

class AccountNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language_id: "",
      buttons: [
        {
          nameEnglish: "My Account",
          nameHindi:"मेरा खाता",
          url: "/my-account"
        },
        {
          nameEnglish: "My Cart",
          nameHindi:'मेरी टोकरी',
          url: "/my-cart"
        },
        {
          nameEnglish: "My Courses",
          nameHindi:'मेरे पाठ्यक्रम',
          url: "/my-courses"
        },
        {
          nameEnglish:"Purchase History",
          nameHindi:'खरीद इतिहास',
          url: "/purchase-history"
        }
      ],

      activeButton: props.active,
      userImage: null,
      userName: `Priyanka Singh`,
      userEmail: `priyankasingh@gmail.com`,
      viewForm: ""
    };
  }

  componentWillMount() {
    this.getDetails();
    let language_id = localStorage.getItem("language_id");
    console.log(language_id);
    this.setState({ language_id });
  }

  componentDidMount() {
    this.setState({
      viewForm: "My Account",
      userName: this.state.userdata.name,
      userEmail: this.state.userdata.email
    });
  }

  componentWillReceiveProps() {}

  getDetails() {
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState(
        {
          userdata: JSON.parse(userdata)
        },
        () => this.getUserDetails()
      );
    }
  }

  getUserDetails = () => {
    this.setState({
      loading: true
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      user_id: this.state.userdata.user_id
    };
    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/get_user_details", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "Forgot");
          this.setState({
            userdata: responseJson[0]
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });

          console.log(error);
        })
    });
  };

  setActiveButton(data) {
    this.setState({ activeButton: data.name }, () =>
      this.props.history.push(data.url)
    );
  }

  renderButtons() {
    console.log(this.state.language_id)
    return this.state.buttons.map((data, index) => {
      return (
        <button
          className={
            this.state.activeButton === data.name
              ? "navigation-button bg-active"
              : "navigation-button"
          }
          value={data.name}
          onClick={() => this.setActiveButton(data)}
          key={index}
        >
          {this.state.language_id==0?`${data.nameEnglish}`:`${data.nameHindi}`}{" "}
          <span className="">
            <img className="arrow-image d-none d-sm-block" src={RightArrow} />
          </span>
        </button>
      );
    });
  }

  closeMobileNav() {
    document.getElementById("account-mobile-nav").style.width = "0";
  }

  render() {
    {
      console.log(this.state.language_id);
    }
    return (
      <React.Fragment>
        <div className="account-navigation-container px-0 d-none d-md-block account-nav-ipad ">
          <div className="user-details-container text-center py-3">
            <div className="user-image-container col px-0">
              <img
                className="user-image bg-dark rounded-circle"
                src={this.state.userdata.profile_pic}
              />

              <input
                type="file"
                accept="image/png, image/jpeg"
                ref={ref => {
                  this.fileUploadRef = ref;
                }}
                className="d-none"
              />
            </div>
            <p className="user-name">{this.state.userName}</p>
            <p className="user-email">{this.state.userEmail}</p>
          </div>
          <div className="navigation-options-container">
            {this.renderButtons()}
          </div>
        </div>
        <div id="account-mobile-nav">
          <div className="user-details-container text-center">
            <div className="user-image-container col px-0">
              <img
                className="user-image bg-dark rounded-circle"
                src={this.state.userImage}
                onClick={() => {
                  this.fileUploadRef.click();
                }}
              />

              <input
                type="file"
                accept="image/png, image/jpeg"
                ref={ref => {
                  this.fileUploadRef = ref;
                }}
                className="d-none"
              />
            </div>
            <p className="user-name">{this.state.userName}</p>
            <p className="user-email">{this.state.userEmail}</p>
          </div>
          <button
            class="close-mob-menu-button btn-custom"
            onClick={() => this.closeMobileNav()}
          >
            <img src={LeftArrow} alt="Close Account Menu" />
          </button>
          {this.renderButtons()}
        </div>
      </React.Fragment>
    );
  }
}

export default AccountNav;
