import React, { ReactDOM, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import StickyFooter from "react-sticky-footer";
import styles from "./footer.module.css";
import swal from "sweetalert";
import {
  MDBCardFooter,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBIcon,
  MDBInput,
  MDBTable,
  MDBTableBody,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

class StickyFooterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: false,
      email: "",
      name: "",
      phone: "",
      message: "",
      menu_data: [],
      clicked: [],
      activeIndex: 0,
    };
  }

  addContact = async () => {
    if (this.state.valid_email == false) {
      swal("Warning!", "Please enter valid Email Id", "warning");
    } else {
      if (
        this.state.email.length === 0 ||
        this.state.phone.length === 0 ||
        this.state.name.length === 0 ||
        this.state.message.length === 0
      ) {
        swal("Warning!", "all fields are mandatory", "warning");
      } else {
        this.setState({
          loading: true,
        });
        let details = {
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          message: this.state.message,
        };

        console.log(details, "add-details");
        let formBody = [];
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        this.setState({
          response: fetch("https://10x10learning.com/add_contact_query", {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Cache-Control": "max-age=31536000",
            },
            body: formBody,
          })
            .then((response) => response.json())
            .then((responseJson) => {
              console.log(responseJson);
              swal("Success!", "We will contact you shortly!", "success").then(
                (val) => {
                  this.setState({
                    name: "",
                    email: "",
                    message: "",
                    phone: "",
                    loading: false,
                  });
                }
              );
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });

              console.log(error);
            }),
        });
      }
    }
  };

  validateEmail = (text) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      this.setState({ email: text, valid_email: false });
      return false;
    } else {
      this.setState({ email: text, valid_email: true });
    }
  };

  toggle_contact = () => {
    this.setState({
      contact: !this.state.contact,
    });
  };

  renderModal() {
    if (this.state.contact) {
      return (
        <MDBModal isOpen={this.state.contact} toggle={this.toggle_contact}>
          <MDBModalHeader toggle={this.toggle_contact}>
            <p className="h5 text-center">Get in touch</p>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol md="6">
                <div className="mobile-footer-contact-us-form">
                  <div className="grey-text d-flex flex-column align-items-start">
                    <MDBInput
                      label="Your name"
                      icon="user"
                      group
                      type="text"
                      validate
                      error="wrong"
                      success="right"
                      value={this.state.name}
                      onChange={(text) =>
                        this.setState({ name: text.target.value })
                      }
                    />
                    <MDBInput
                      label="Your email"
                      icon="envelope"
                      group
                      type="email"
                      validate
                      error="wrong"
                      success="right"
                      value={this.state.email}
                      onChange={(e) => this.validateEmail(e.target.value)}
                    />
                    <MDBInput
                      label="Phone"
                      icon="phone-alt"
                      group
                      type="number"
                      validate
                      error="wrong"
                      success="right"
                      value={this.state.phone}
                      // maxlength="10" pattern="\d{10}"
                      onChange={(text) =>
                        this.setState({ phone: text.target.value })
                      }
                    />
                    <MDBInput
                      type="textarea"
                      rows="2"
                      label="Your message"
                      icon="pencil-alt"
                      value={this.state.message}
                      onChange={(text) =>
                        this.setState({ message: text.target.value })
                      }
                    />
                  </div>

                  <div className="text-center ">
                    <button
                      className="contact-us-sticky-footer-button"
                      outline
                      onClick={(e) => this.addContact(e)}
                    >
                      Send <MDBIcon far icon="paper-plane" className="ml-1" />
                    </button>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }

  homeSet() {
    this.props.history.push("/");
    this.setState({ activeIndex: 1 });
  }

  render() {
    return (
      <div>
        <StickyFooter
          bottomThreshold={50}
          normalStyles={{
            backgroundColor: "white",
          }}
          stickyStyles={{
            backgroundColor: "white",
            position: "fixed",
            bottom: "0px",
            width: "100%",
          }}
        >
          <MDBCard>
            <div className="d-flex">
              {/* <MDBCardFooter
                style={{
                  backgroundColor: this.state.activeIndex != 1 ? "" : "black",
                  color: this.state.activeIndex != 1 ? "" : "#fff"
                }}
             
                onClick={() => this.homeSet()}
              >
                
              </MDBCardFooter> */}

              <NavLink
                exact
                className={` ${styles.CardFooter} d-flex w-50`}
                activeClassName={`${styles.activeStickyLink}`}
                to="/"
              >
                <MDBIcon icon="home" size="lg" />
              </NavLink>

              <NavLink
                exact
                className={` ${styles.CardFooter} d-flex w-50`}
                activeClassName={`${styles.activeStickyLink}`}
                to="/latest-updates"
              >
                <MDBIcon icon="newspaper" size="lg" />
              </NavLink>

              <NavLink
                exact
                className={` ${styles.CardFooter} d-flex w-50`}
                activeClassName={`${styles.activeStickyLink}`}
                to="/important-dates"
              >
                <MDBIcon far icon="calendar-alt" size="lg" />
              </NavLink>
              {/* 
        <NavLink
          exact
          className={` ${styles.CardFooter} d-flex w-50`}
          activeClassName={`${styles.activeStickyLink}`}
          to="/"
          onClick={() => this.setState({ contact: true })}
        >
     
        <MDBIcon far icon="calendar-alt" size="lg" />
              
        </NavLink> */}

              <MDBCardFooter
                style={{
                  backgroundColor: this.state.contact != true ? "" : "#c01825",
                  color: this.state.contact != true ? "" : "#fff",
                }}
                className={` ${styles.CardFooter} d-flex w-50`}
              >
                <div>
                  <MDBIcon
                    size="lg"
                    icon="headset"
                    onClick={() => this.setState({ contact: true })}
                  />
                </div>
              </MDBCardFooter>
            </div>
          </MDBCard>
        </StickyFooter>
        {this.renderModal()}
      </div>
    );
  }
}

export default StickyFooterPage;
