import React, { Component } from "react";
import GridLoader from "react-spinners/GridLoader";
import ExamImage from "../../Assets/images/testO.jpg";
import Select from "react-select";
import styles from "../Exams/Exams.module.css";
import "../commonStyles.css";
import HomeHeader from "../../Reusable/Header/homeHeader";
import MainFooterPage from "../../Reusable/Footer/footer";
import { css } from "@emotion/core";
import Login from "../../Reusable/Header/login";
export default class Exams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exam_data: [],
      toBeShown: [],
      selected_test: {},
      userid: "",
      selectedOption: null,
      testExplanation: [],
      showLogin: false,
      loading: false,
    };
  }

  componentDidMount = async () => {
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    let currentEvaluation = JSON.parse(localStorage.getItem("explanation"));
    let allEvalaution = JSON.parse(localStorage.getItem("test_explanation"));
    console.log(currentEvaluation, allEvalaution);
    for (let i = 0; i < allEvalaution.length; i++) {}
    let toBeShown = allEvalaution.filter(
      (item) => item.explaination_id == currentEvaluation.value
    );
    let data = toBeShown[0].explainations;
    console.log(data, JSON.parse(data));
    // console.log(JSON.parse(toBeShown[0].explainations))
    this.setState({ toBeShown: JSON.parse(data) });
  };

  showEvaluation = () => {
    let data = this.state.toBeShown;
    console.log(data);
    return data.map((item) => {
      return (
        <div className="explanation mt-5">
          <span className="evalQuestion">
            Question .{item.question_title}
          </span>
          <span className="evalAnswer"><span className="evalAnswerText">Your Answer - </span>{item.answers}</span>
          <span className="evalExplanation"><span className="evalExplanationText">Explanation -</span> {item.explanation}</span>
        </div>
      );
    });
  };

  render() {
    const { selectedOption } = this.state;
    console.log(this.state.toBeShown);

    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div id="exams">
          <HomeHeader {...this.props} />
          <div className={`${styles.ExamsMainDiv}`}>
            <div className={`${styles.BannerImageDiv} `}>
              <img
                className={`${styles.BannerImage}`}
                src={ExamImage}
                alt="Exam-Image"
              />


              <div>
                <h4 style={{ marginLeft: "20px",fontWeight:'600' }} className="text-center">
                  Evaluation for the test {this.state.toBeShown.subject_name}
                </h4>
                <div>{this.showEvaluation()}</div>
              </div>
            </div>
          </div>

          <div style={{ height: "auto" }}>
            <MainFooterPage {...this.props} />
          </div>
        </div>
      );
    }
  }
}

const NoExamsStyle = {
  textAlign: "center",
  margin: "10%",
  fontSize: "22px",
  fontStyle: "italic",
  color: "grey",
};
