import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../Sidebar/sidebarPage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Select from "react-select";
import {
  MDBNavbar,
  MDBBtn,
  MDBNavItem,
  MDBNavbarNav,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBInput,
} from "mdbreact";
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import swal from "sweetalert";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import Fade from "react-reveal";
import CKEditor from "ckeditor4-react";

const topicsList = [];
for (var i = 1; i <= 100; i++) {
  topicsList.push({ id: i, name: `Topic ${i}` });
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

class addKeyDates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errorName: false,
      Description: "<p></p>",
    };
    this.onEditorChange = this.onEditorChange.bind(this);
  }

  componentWillMount() {}

  componentDidMount() {
    axios.get("https://10x10learning.com/get_cookie_policy").then((res) => {
        let data=res.data[0].data
        console.log(data)
      this.setState({ Description:data ,loading:false},()=>console.log(this.state));
    });
  }

  //ADD FORM

  onEditorChange(e) {
    // console.log(e);
    this.setState({
      Description: e.editor.getData(),
    });
  }

  //HANDLE CHANGE FUNCTIONS

  //RENDER FUNCTIONS

  renderDescription() {
    return (
      <div className="col-12 my-2 cost-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="cost-label subject-labels"
        >
          Description :
        </label>

        <CKEditor
          data={this.state.Description}
          style={{ width: "60%" }}
          onChange={this.onEditorChange}
          className="textFontSize"
        />
      </div>
    );
  }

  addKeyDates = () => {
    console.log(this.state.Description);
    let data = {
      id: 1,
      data: this.state.Description,
    };

    axios
      .post("https://10x10learning.com/edit_cookie_policy", {
        data,
      })
      .then((response) => {
        console.log(response);
        swal("Cookie Policy updated");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    console.log(this.props.history);
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={13} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <Fade>
                  <div className="row my-3 px-4">
                    <div className="col-12"></div>
                    <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                      <button
                        className="admin-button-style"
                        onClick={() => this.addKeyDates()}
                      >
                        {" "}
                        Save &amp; Publish
                      </button>
                    </div>
                  </div>

                  <div className="row px-2 my-2 mx-0">
                    {this.renderDescription()}
                  </div>
                </Fade>
              </React.Fragment>
            )}
          </div>

          {console.log(this.state)}
        </div>
      </div>
    );
  }
}

export default addKeyDates;
