import React from "react";
import "../../Components/commonStyles.css";
import styles from "./footer.module.css";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import publicIp from "public-ip";

class MainFooterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      language_id: "",
      message: "",
      phone: "",
      email: "",
    };
  }

  setIDLatestUpdate() {
    this.props.history.push({
      pathname: "/latest-updates",
      state: { listItemId: 41 },
    });
  }
  componentWillMount() {
    let languageid = localStorage.getItem("language_id");
    if (languageid == null) {
      localStorage.setItem("language_id", 0);
    }
    this.setState({ language_id: languageid });
  }

  componentDidMount() {
    let token = window.localStorage.getItem("user_token");
    let user_data = window.localStorage.getItem("userdata");
    if (!token || !user_data) {
      console.log("does not exist");
    } else {
      this.verifyLogin(token, user_data);
    }
  }

  async resumeSession() {
    let userDetails = null;
    if (window.localStorage.getItem("userdata")) {
      userDetails = JSON.parse(window.localStorage.getItem("userdata"));
      console.log(userDetails);
      let ipAddress = await publicIp.v4();
      let formBody = new FormData();
      formBody.append("email", userDetails.email);
      formBody.append("ipAddress", ipAddress);

      fetch("https://10x10learning.com/resume_session", {
        method: "POST",
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status == 200) {
            const user = responseJson.user;
            const user_token = responseJson.token;
            window.localStorage.removeItem("userdata");
            window.localStorage.setItem("userdata", JSON.stringify(user));

            window.localStorage.setItem("user_token", user_token);
            window.location.reload();
          }
        });
    } else {
      window.localStorage.removeItem("userdata");
      window.localStorage.removeItem("user_token");
      this.props.history.push("/");
      window.location.reload();
    }
  }

  async verifyLogin(token, user) {
    console.log("verifying...");
    let formBody = new FormData();
    formBody.append("user_token", token);
    formBody.append("email", JSON.parse(user).email);
    try {
      let verificationResponse = await fetch(
        "https://10x10learning.com/verify_user",
        {
          method: "POST",
          body: formBody,
        }
      );
      let response = await verificationResponse.json();
      console.log(response, "verification status");
      if (response.status == 200) {
      } else {
        swal(
          "",
          "It seems you are currently logged in on another device. Would you like to logout from those devices and continue here?",
          "info",
          {
            buttons: ["No", "Yes"],
          }
        ).then((value) => {
          if (!value) {
            window.localStorage.removeItem("userdata");
            window.localStorage.removeItem("user_token");
            this.props.history.push("/");
            window.location.reload();
          } else {
            try {
              this.resumeSession();
            } catch (err) {
              console.log(err);
            }
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  validateEmail = (text) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      this.setState({ email: text, valid_email: false });
      return false;
    } else {
      this.setState({ email: text, valid_email: true });
    }
  };

  addContact = async () => {
    if (this.state.valid_email == false) {
      return swal("Warning!", "Please enter valid Email Id", "warning");
    } else {
      if (
        this.state.email.length === 0 ||
        this.state.phone.length === 0 ||
        this.state.name.length === 0 ||
        this.state.message.length === 0
      ) {
        return swal("Warning!", "all fields are mandatory", "warning");
      } else if (this.state.message.length >= 100) {
        return swal("Warning!", "message length exceed 100 word", "warning");
      } else {
        this.setState({
          loading: true,
        });
        let details = {
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          message: this.state.message,
        };

        console.log(details, "add-details");
        let formBody = [];
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        this.setState({
          response: fetch("https://10x10learning.com/add_contact_query", {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Cache-Control": "max-age=31536000",
            },
            body: formBody,
          })
            .then((response) => response.json())
            .then((responseJson) => {
              console.log(responseJson);
              swal("Success!", "We will contact you shortly!", "success").then(
                (val) => {
                  this.setState({
                    name: "",
                    email: "",
                    message: "",
                    phone: "",
                    loading: false,
                  });
                }
              );
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });

              console.log(error);
            }),
        });
      }
    }
  };

  render() {
    return (
      <div id="footer">
        <div className={`${styles.WindowView}`}>
          <div className={`${styles.FooterContainer}  py-4`}>
            <div className={`${styles.FooterTop}`}>
              <div className="row text-left">
                <div className="col-lg-2 col-md-2  px-4">
                  <h3
                    className={
                      this.state.language_id == 0 ? `opensansH` : `opensansRH`
                    }
                  >
                    {this.state.language_id == 0
                      ? `QUICK LINKS`
                      : `त्वरित कड़ियाँ`}
                  </h3>
                  <hr className={`${styles.FooterContainerHr}`} />
                  <ul>
                    <li onClick={() => this.props.history.push("/")}>
                      <Link to="/">
                        {this.state.language_id == 0 ? `Home` : `मुख पृष्ठ`}
                      </Link>
                    </li>
                    <li>
                      <Link to="/about-us">
                        {this.state.language_id == 0
                          ? `About`
                          : `हमारे बारे में`}
                      </Link>
                    </li>
                    <li>
                      <Link to="/my-exams">
                        {this.state.language_id == 0 ? `Practice` : `अभ्यास`}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/dashboard/school">
                        {this.state.language_id == 0 ? `Classes` : `कक्षाएं`}
                      </Link>
                    </li> */}

                    {/* <li>
                      <Link to="/other-services">
                        {this.state.language_id == 0
                          ? `Other Services`
                          : `अन्य सेवाएं`}
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/privacy-policy">
                        {" "}
                        {this.state.language_id == 0
                          ? `Privacy Policy`
                          : `गोपनीयता नीति`}
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms-of-purchase">
                        {" "}
                        {this.state.language_id == 0
                          ? `Terms of Purchase`
                          : `खरीदने
                          की शर्तें`}
                      </Link>
                    </li>
                    <li onClick={() => this.setIDLatestUpdate()}>
                      <Link to="/terms-of-purchase">
                        {" "}
                        {this.state.language_id == 0
                          ? `How to Register ?`
                          : `
                          
कैसे पंजीकृत करें ?`}
                      </Link>
                    </li>
                    <li className="d-none">
                      {this.state.language_id == 0 ? `Commerce` : `वाणिज्य`}
                    </li>
                    <li className="d-none">
                      {this.state.language_id == 0
                        ? `Contact Us`
                        : `संपर्क करें`}
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-4  px-4">
                  <h3
                    className={
                      this.state.language_id == 0 ? `opensansH` : `opensansRH`
                    }
                  >
                    {this.state.language_id == 0 ? `COURSES` : `पाठ्यक्रम`}
                  </h3>
                  <hr className={`${styles.FooterContainerHr}`} />
                  <ul>
                    <li>
                      <Link to="/dashboard/civil-services">
                        {this.state.language_id == 0
                          ? `Civil Services`
                          : `नागरिक सेवाएं`}
                      </Link>
                    </li>
                    <li>
                      <Link to="/dashboard/humanities">
                        {this.state.language_id == 0
                          ? `University-Humanities and Languages`
                          : `
विश्वविद्यालय-मानविकी और भाषाएँ`}
                      </Link>
                    </li>
                    <li className="opensansR">
                      <Link to="/dashboard/school">
                        {this.state.language_id == 0 ? (
                          <span>
                            School Classes 5<sup>th</sup> to 12<sup>th</sup>
                          </span>
                        ) : (
                          `स्कूल की कक्षाएं (5th से 12th)`
                        )}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/other-services">
                        {" "}
                        {this.state.language_id == 0
                          ? `Other Services`
                          : `अन्य सेवाएं`}
                      </Link>
                    </li> */}

                    <li className="opensansR">
                      <Link to="/dashboard/CCA">
                        {this.state.language_id == 0
                          ? "Commerce and Charted Accountancy"
                          : `वाणिज्य और चार्टेड अकाउंटेंसी`}
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-4 col-md-3   px-4">
                  <h3
                    className={
                      this.state.language_id == 0 ? `opensansH` : `opensansRH`
                    }
                  >
                    {this.state.language_id == 0
                      ? `GET IN TOUCH`
                      : `संपर्क में रहो`}
                  </h3>
                  <hr className={`${styles.FooterContainerHr}`} />
                  <ul>
                    {/* <li>
                      {this.state.language_id == 0 ? `Phone` : `फ़ोन`}
                      <a href="tel:00911244363775">
                        <span className="opensansR">
                          :&nbsp;+91-124-4363775
                        </span>
                      </a>
                    </li> */}
                    <li>
                      {this.state.language_id == 0 ? `E-mail` : `ई-मेल`}
                      <a href="mailto:helpdesk@10x10learning.com">
                        <span className="opensansR">
                          :&nbsp;helpdesk@10x10learning.com
                        </span>
                      </a>
                    </li>
                    <li className="d-none">
                      {this.state.language_id == 0 ? `E-mail` : `ई-मेल`}
                      <a href="mailto:10into10learning@gmail.com">
                        <span className="opensansR">
                          :&nbsp;10into10learning@gmail.com
                        </span>
                      </a>
                    </li>
                    <li>
                      {this.state.language_id == 0
                        ? `Complaint cell`
                        : `Complaint cell`}
                      <a href="mailto:Grievancecell@10x10learning.com">
                        <span className="opensansR">
                          :&nbsp;Grievancecell@10x10learning.com
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="d-fle flex-column col-lg-3 col-md-3 pl-5">
                  <h3
                    className={
                      this.state.language_id == 0 ? `opensansH` : `opensansRH`
                    }
                  >
                    {this.state.language_id == 0
                      ? `SEND US A QUERY`
                      : `हमें एक प्रश्न भेजें`}
                  </h3>

                  <input
                    placeholder={this.state.language_id == 0 ? "Name" : `नाम`}
                    // value={this.state.name}
                    type="text"
                    value={this.state.name}
                    className={`${styles.SendQueryInput} w-100  mb-2`}
                    onChange={(text) =>
                      this.setState({ name: text.target.value })
                    }
                  />

                  <input
                    placeholder={
                      this.state.language_id == 0 ? "E-mail" : "ई-मेल"
                    }
                    // value={this.state.name}
                    type="text"
                    value={this.state.email}
                    className={`${styles.SendQueryInput} w-100 mb-2`}
                    onChange={(text) =>
                      this.setState({ email: text.target.value })
                    }
                  />

                  <input
                    placeholder={
                      this.state.language_id == 0
                        ? "Phone No. with STD Code"
                        : "एसटीडी कोड के साथ फोन नं"
                    }
                    // value={this.state.name}
                    type="text"
                    className={`${styles.SendQueryInput} w-100 mb-2`}
                    value={this.state.phone}
                    onChange={(text) =>
                      this.setState({ phone: text.target.value })
                    }
                  />
                  <textarea
                    placeholder={
                      this.state.language_id == 0
                        ? "Type message here.."
                        : "संदेश यहां लिखें"
                    }
                    // value={this.state.name}
                    type="textarea"
                    value={this.state.message}
                    className={`${styles.SendQueryTextArea} w-100 mb-2`}
                    onChange={(text) =>
                      this.setState({ message: text.target.value })
                    }
                  />

                  <button
                    className={`${styles.SendBtn} w-100`}
                    onClick={() => this.addContact()}
                  >
                    {this.state.language_id == 0 ? `Send` : `संदेश`}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.FooterBottom}`}>
            <hr className={`${styles.HrTag}`}></hr>
            <hr className={`${styles.HrTag}`}></hr>
            <p className="mb-0 px-1 py-2 text-center">
              {" "}
              {/* {this.state.language_id == 0 ? `Copyright ` : `कॉपीराइट`}{" "} */}
              <span style={{ color: "#c01825" }}>
                &copy;10x10 Learning®, 2017, All Rights Reserved.
              </span>{" "}
            </p>
            <hr className={`${styles.HrTag}`}></hr>
            <hr className={`${styles.HrTag}`}></hr>
          </div>
        </div>
        <div className={`${styles.MobView}`}>
          <StickyFooterPage
            {...this.props}
            activeIndex={this.state.activeIndex}
          />
        </div>
      </div>
    );
  }
}

export default MainFooterPage;
