import React from "react";
import "./feedback.css";
import "../Evaluation/evaluation.css";
import HomeHeader from "../../Reusable/Header/homeHeader";
import MainFooterPage from "../../Reusable/Footer/footer.jsx";
import { FulfillingBouncingCircleSpinner } from "react-epic-spinners";
import Collapsible from "../collapsible/collapsible";
import {
  MDBIcon,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from "mdbreact";
import { FormGroup, Label, Input } from "reactstrap";
import ProfTen from '../Prof10X10/prof10x10'
import swal from "sweetalert";

class TestFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_ques: 0,
      selected_time: 0,
      selected_lang: 0,
      id1: 1,
      id2: 2,
      id3: 3,
      suggestion: "",
      testType: "",
      right: 0,
      wrong: 0,
      cannotEvaluate: false,
      explaination: [],
    };
  }

  componentWillMount() {
    let testType = localStorage.getItem("test_questions");
    testType = testType.replace(/^"(.*)"$/, "$1");
    console.log(testType);
    this.setState({ testType });
    if (testType != "Subjective") {
      this.setState({
        questions: JSON.parse(window.localStorage.getItem("test_questions")),
        test_id: JSON.parse(window.localStorage.getItem("test_id")),
      });
    }
  }

  componentDidMount() {
    let right = 0;
    this.state.questions.forEach((item) => {
      if (item.selected_answer) {
        if (item.selected_answer.value == 1) {
          right = right + 1;
        }
      }
    });

    this.setState({ right },()=>this.getDialogues());
  }

  getDialogues = async () => {
    let formBody = new FormData()
    formBody.append('language_id', localStorage.getItem('language_id'))
    try {
      const dialogueResponse = await fetch(
        "https://10x10learning.com/prof10_feedback",{
          method:'POST',
          body:formBody
        }
      );
      const result = await dialogueResponse.json();
      const dialogues = JSON.parse(result[0].text).map(
        (item) => item.description
      );
      this.setState({ dialogues });
    } catch (err) {
      console.log(err);
    }
  };
  getClients = async () => {
    this.setState({
      loading: true,
    });
    this.setState({
      response: fetch("https://10x10learning.com/get_clients", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "clients");
          this.setState({ clients: responseJson, loading: false });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  renderEmojis(id) {
    if (id == 1) {
      return (
        <div className="feedEmojiFont">
          <MDBIcon
            far
            icon="smile"
            style={{
              color: this.state.selected_ques == 1 ? "green" : "#d2d2d2",
            }}
            onClick={() => this.setState({ selected_ques: 1 })}
          />
          <MDBIcon
            far
            icon="meh"
            style={{
              color: this.state.selected_ques == 2 ? "purple" : "#d2d2d2",
            }}
            onClick={() => this.setState({ selected_ques: 2 })}
          />
          <MDBIcon
            far
            icon="frown"
            style={{ color: this.state.selected_ques == 3 ? "red" : "#d2d2d2" }}
            onClick={() => this.setState({ selected_ques: 3 })}
          />
        </div>
      );
    } else if (id == 2) {
      return (
        <div className="feedEmojiFont">
          <MDBIcon
            far
            icon="smile"
            style={{
              color: this.state.selected_time == 1 ? "green" : "#d2d2d2",
            }}
            onClick={() => this.setState({ selected_time: 1 })}
          />
          <MDBIcon
            far
            icon="meh"
            style={{
              color: this.state.selected_time == 2 ? "purple" : "#d2d2d2",
            }}
            onClick={() => this.setState({ selected_time: 2 })}
          />
          <MDBIcon
            far
            icon="frown"
            style={{ color: this.state.selected_time == 3 ? "red" : "#d2d2d2" }}
            onClick={() => this.setState({ selected_time: 3 })}
          />
        </div>
      );
    } else if (id == 3) {
      return (
        <div className="feedEmojiFont">
          <MDBIcon
            far
            icon="smile"
            style={{
              color: this.state.selected_lang == 1 ? "green" : "#d2d2d2",
            }}
            onClick={() => this.setState({ selected_lang: 1 })}
          />
          <MDBIcon
            far
            icon="meh"
            style={{
              color: this.state.selected_lang == 2 ? "purple" : "#d2d2d2",
            }}
            onClick={() => this.setState({ selected_lang: 2 })}
          />
          <MDBIcon
            far
            icon="frown"
            style={{ color: this.state.selected_lang == 3 ? "red" : "#d2d2d2" }}
            onClick={() => this.setState({ selected_lang: 3 })}
          />
        </div>
      );
    }
  }

  renderSkip_SubmitButton() {
    return (
      <div className="feedBtnMainDiv">
        <div
          className="skipBtnDiv"
          onClick={() => this.props.history.push("/product-details")}
          style={{ cursor: "pointer" }}
        >
          Skip
        </div>
        {this.renderSubmitButton()}
      </div>
    );
  }

  renderSubmitButton() {
    return (
      <div
        className="saveBtnDiv"
        style={{ cursor: "pointer" }}
        onClick={() => this.addReview()}
      >
        Submit
      </div>
    );
  }

  renderAnswers(question) {
    console.log(question);
    let answers = JSON.parse(question.answers);
    let selected_answer = question.selected_answer;
    console.log(selected_answer);

    if (selected_answer == null) {
      return answers.map((item) => {
        return (
          <li
            className={
              item.value == 1
                ? "p-1 mt-2 ml-5 bg-green w-25"
                : "p-1 mt-2 ml-5 w-25"
            }
            key={item.id}
          >
            {item.answer}
          </li>
        );
      });
    } else {
      return answers.map((item) => {
        if (selected_answer.value == 0 && selected_answer.id == item.id) {
          return (
            <React.Fragment>
              <li className="p-1 mt-2 ml-5 bg-red w-25" key={item.id}>
                {item.answer}
              </li>
            </React.Fragment>
          );
        } else if (
          selected_answer.value == 1 &&
          selected_answer.id == item.id
        ) {
          return (
            <li className="p-1 mt-2 ml-5 bg-green w-25" key={item.id}>
              {item.answer}
            </li>
          );
        } else {
          return (
            <li
              className={
                item.value == 1
                  ? "p-1 mt-2 ml-5 bg-green w-25"
                  : "p-1 mt-2 ml-5 w-25"
              }
              key={item.id}
            >
              {item.answer}
            </li>
          );
        }
      });
    }
  }
  renderExplaination(item) {
    console.log(item);
  }

  renderStatus(question) {
    if (question.selected_answer == null)
      return (
        <i className="ml-4">
          <span style={{ fontFamily: "OpenSans-B" }}>
            {" "}
            &#91;unattempted &#93;{" "}
          </span>
        </i>
      );
  }

  renderQuestionsAndAnswers() {
    let testType = localStorage.getItem("test_type");
    testType = testType.replace(/^"(.*)"$/, "$1");
    console.log(testType);
    if (testType == "Objective") {
      return this.state.questions.map((item, index) => {
        return (
          <div className="question-container">
            <ul>
              <span
                className={item.selected_answer == null ? "bg-yellow" : ""}
              >{`${index + 1}. ${item.question_title}`}</span>
              <br />
              {this.renderStatus(item)}
              {this.renderAnswers(item)}
              <p className="ml-5 mt-3 mb-5 ">
                <Collapsible explaination={item.explaination_for_objective} />
              </p>
            </ul>
          </div>
        );
      });
    } else {
      return this.state.questions.map((item, index) => {
        return (
          <div className="question-container mt-3">
            <ul>
              <span style={{ fontFamily: "OpenSans-B" }}>
                {" "}
                Question - {`${index + 1}. ${item.question_title}`}
              </span>
              <br />
              <span style={{ fontFamily: "OpenSans-R" }}>
                {" "}
                <b>Your Answer - </b> : {item.answers}
              </span>
            </ul>
          </div>
        );
      });
    }
  }

  renderTestScore() {
    let testType = localStorage.getItem("test_type");
    testType = testType.replace(/^"(.*)"$/, "$1");
    if (testType == "Objective") {
      return (
        <div className="answer_output">
          <h2 className="quiz_answer_Result_font">Result</h2>
          <hr style={{ color: "#fff !important" }}></hr>
          <span className="quiz_answer_font">
            {" "}
            {this.state.right} / {this.state.questions.length}
          </span>
        </div>
      );
    } else return "";
  }

  calculateAttemptsForEvaluation() {
    let userdata = JSON.parse(localStorage.getItem("userdata"));

    let details = {};
    this.setState({
      loading: true,
    });
    let test_id = localStorage.getItem("test_id");
    details = {
      user_id: userdata.user_id,
      test_id: test_id,
    };

    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch(
        "https://10x10learning.com/cal_evaluation",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "max-age=31536000",
          },
          body: formBody,
        },
        () => this.props.history.push("/")
      )
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "123");

          if (responseJson.length == 0) {
            swal({
              text: `Sent for Evaluation! You have only 1 evaluations left for this test.`,
            });
          } else if (responseJson.length == 1) {
            swal({
              text: `Sent for Evaluation! You have now no evaluations left for this test.`,
            });
          } else if (responseJson.length == 2) {
            swal({
              text: `You cannot send the test for evaluation now as you have exceed the limit i,e 2.`,
            });
            this.setState({ cannotEvaluate: true }, () =>
              this.props.history.push("/")
            );
          }
          this.setState(
            {
              // add_modal:false
            },
            () => this.addEvaluation()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }

  addAdminEvaluation() {
    if (this.state.cannotEvaluate == true) {
      return swal(
        "Warning!",
        "You have exceeded the total number of evaluations given to you in this test!",
        "warning"
      );
    }
    let testQuestions = localStorage.getItem("test_questions");
    let testType = localStorage.getItem("test_type");
    testType = testType.replace(/^"(.*)"$/, "$1");
    console.log(testQuestions);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    let details = {};

    let test_id = localStorage.getItem("test_id");
    details = {
      test_questions: testQuestions,
      test_type: testType,
      user_id: userdata.user_id,
      test_id: test_id,
      user_name: userdata.name,
      user_email: userdata.email,
      test_name:localStorage.getItem('test_name_evaluataion'),
      subject_name: localStorage.getItem("test_for_subject"),
    };

    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/add_admin_evaluation", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "123");
          this.setState({ loading: false });
          this.props.history.push("/");
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }
  addEvaluation() {
    if (this.state.cannotEvaluate == true) {
      return swal(
        "Warning!",
        "You have exceeded the total number of evaluations given to you in this test!",
        "warning"
      );
    }
    let testQuestions = localStorage.getItem("test_questions");
    let testType = localStorage.getItem("test_type");
    testType = testType.replace(/^"(.*)"$/, "$1");
    console.log(testQuestions);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    let details = {};

    let test_id = localStorage.getItem("test_id");
    details = {
      test_questions: testQuestions,
      user_name: userdata.name,
      user_email: userdata.email,
      test_type: testType,
      user_id: userdata.user_id,
      test_id: test_id,
      test_name:localStorage.getItem("test_name_evaluation"),
      subject_name: localStorage.getItem("test_for_subject"),
    };

    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/add_evaluation", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.addAdminEvaluation();
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }

  // rendercheckExplaination(){
  //   let userdata = JSON.parse(localStorage.getItem("userdata"));
  //   let test_id=localStorage.getItem('test_id')
  // let  details = {
  //     user_id:userdata.user_id,
  //     test_id:test_id
  //   };

  //   console.log(details, "add-without photo");
  //   let formBody = [];
  //   for (let property in details) {
  //     let encodedKey = encodeURIComponent(property);
  //     let encodedValue = encodeURIComponent(details[property]);
  //     formBody.push(encodedKey + "=" + encodedValue);
  //   }
  //   formBody = formBody.join("&");
  //     this.setState({
  //         loading:true
  //       })
  //           this.setState({
  //           response: fetch('https://10x10learning.com/get_explaination', {
  //           method: 'POST',
  //           headers: {
  //             "Content-Type": "application/x-www-form-urlencoded",
  //             "Cache-Control": "max-age=31536000"
  //           },
  //           body: formBody
  //           }).then((response) => response.json())
  //           .then((responseJson)=>{
  //               console.log(responseJson,'xxx')

  //             this.setState({
  //                 explaination:responseJson,
  //                 loading:false
  //                 })
  //                }
  //           )
  //           .catch((error) => {
  //           this.setState({
  //           loading:false
  //           })
  //           swal("Warning!", "Check your network!", "warning");
  //         console.log(error)
  //               })
  //           })

  // }

  renderSubmitEvaluationButton() {
    return (
      <div
        className="saveBtnDiv"
        style={{ cursor: "pointer" }}
        onClick={() => this.calculateAttemptsForEvaluation()}
      >
        Submit
      </div>
    );
  }

  render() {
    let test_type = JSON.parse(localStorage.getItem("test_type"));
    console.log(test_type);
    console.log(this.state, "satet");
    if (this.state.loading) {
      return (
        <div className="loadingSpin">
          <FulfillingBouncingCircleSpinner color="#FF6600" />
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <HomeHeader {...this.props} active="My Dashboard" />
          </div>

          <div style={{ width: "100%" }}>
            <div className="test-questions-with-answers-container p-2">
              {this.renderTestScore()}
              <hr />
              {this.renderQuestionsAndAnswers()}
              <div className="feedBtnMainDiv ml-5">
                <div
                  className="skipBtnDiv"
                  onClick={() => this.props.history.push("/")}
                  style={{ cursor: "pointer" }}
                >
                  Skip
                </div>
                {test_type != "Objective"
                  ? this.renderSubmitEvaluationButton()
                  : ""}
                {/* {this.rendercheckExplaination()} */}
              </div>
            </div>
            {/* 
            <div className="testTimerMainDiv windowView">
              <p className="testMainHeadingFont">
                Provide your valuable feedback for the quiz:
              </p>
            </div>
            <div className="marginLeftFeedMainDiv">
              <div className="feedbackFlex">
                <p className="testMainHeadingFont feedbackHeadingW">
                  Questions's difficulty:
                </p>
                <div className="feedEmojiDiv">
                  {this.renderEmojis(this.state.id1)}
                </div>
              </div>
              <div className="feedbackFlex">
                <p className="testMainHeadingFont feedbackHeadingW">
                  Quiz Timings:
                </p>
                <div className="feedEmojiDiv">
                  {this.renderEmojis(this.state.id2)}
                </div>
              </div>
              <div className="feedbackFlex">
                <p className="testMainHeadingFont feedbackHeadingW">
                  Questions's Language:
                </p>
                <div className="feedEmojiDiv">
                  {this.renderEmojis(this.state.id3)}
                </div>
              </div>

              <div className="d-flex">
                <FormGroup className="locationForm">
                  <Input
                    type="textarea"
                    name="city"
                    id="exampleCity"
                    placeholder="Any other suggestions..."
                    value={this.state.suggestion}
                    style={{ width: "400px", marginTop: "20px" }}
                    onChange={text =>
                      this.setState({ suggestion: text.target.value })
                    }
                    className="textFontSize"
                  />
                </FormGroup>
              </div>
            </div>
          </div>
          <div className="feedbackSubmitBtnDiv">
            {this.renderSkip_SubmitButton()}
          </div> */}
          </div>
          <ProfTen dialogues={this.state.dialogues} />
          <div>
            <MainFooterPage {...this.props} />
          </div>
        </div>
      );
    }
  }
}

export default TestFeedback;
