import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import HeaderPage from "../../Reusable/Header/homeHeader";
import "../../Components/commonStyles.css";
import MainFooterPage from "../../Reusable/Footer/footer";
import Login from "../../Reusable/Header/login";
import swal from "sweetalert";

// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import { GoogleLogin } from "react-google-login";
// import DatePicker from "react-datepicker";

import styles from "./SignUP.module.css";

import "../commonStyles.css";
import { RegionDropdown } from "react-country-region-selector";
// import moment from 'moment';

import RangePicker from "react-range-picker";

import { MDBIcon } from "mdbreact";

import GridLoader from "react-spinners/GridLoader";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: calc(100vh / 2) auto;
`;

class signUP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit_image: null,
      image: null,

      name: "",
      email: "",
      password: "",

      confirmPassword: "",
      gender: "",
      date_of_birth: null,
      company: "",
      designation: "",
      // profile_pic:'',
      qualifications: "",
      mobile: "",
      loading: false,

      login: false,
      status: 0,
      isChecked: false,
      date: null,
      loginToggle: false,
      state: null,
      pincode: null,
      city: null,
      address: null,
      showLogin: false,
      language_id: "",
      loading: true,
    };
    // this.handleChecked = this.handleChecked.bind(this); // set this, because you need get methods from CheckBox
  }

  componentWillMount() {
    let languageid = localStorage.getItem("language_id");
    if (languageid == null) {
      localStorage.setItem("language_id", 0);
    }
    this.setState({ language_id: languageid });
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { password, confirmPassword } = this.state;
    let isOk = true;
    console.log(isOk, "Ms12");
    if (isOk == false) {
      swal(
        "Password must contain",
        "1. Minimum length should be 8\n 2. Must contains atleast one capital letter\n3. Must contains atleast one small letter\n4. Must contains atleast one special character (!@#$%^&*)",
        "warning"
      );
    } else if (this.state.isChecked == false) {
      swal(
        "Terms and Conditions",
        "You have not ticked the the terms and conditions",
        "warning"
      );
    } else {
      if (password !== confirmPassword) {
        swal("info!", "Passwords do not match", "warning");
      } else {
        this.addForm();
      }
    }
  };

  addForm = () => {
    console.log(this.state.qualification);
    var now = new Date().getFullYear();
    if (
      this.state.name.length === 0 ||
      this.state.mobile.length === 0 ||
      this.state.email.length === 0 ||
      this.state.qualification.length === 0 ||
      this.state.password.length == 0 ||
      this.state.confirmPassword.length == 0
    ) {
      swal("Check all fields!", "Fields with * are mandatory", "warning");
    } else if (this.state.valid_mobile == false) {
      swal("Check Mobile!", "Invalid Mobile", "warning");
    } else if (this.state.valid_email == false) {
      swal("Check email!", "Invalid email", "warning");
    } else if (this.state.valid_company == false) {
      swal("Check Company!", "Invalid Company name", "warning");
    } else if (
      this.state.mobile.length != 10 &&
      this.state.mobile.length != 11
    ) {
      swal("Check mobile number! ", "Invalid mobile number", "warning");
    } else if (this.state.date == null) {
      swal("Check date of birth!", "Invalid date of birth", "warning");
    } else if (this.state.isChecked == false) {
      swal(
        "Accept terms & conditions!",
        "Please accept terms & conditons",
        "warning"
      );
    } else if (now - this.state.date.getFullYear() <= 10) {
      swal(
        "Below age!",
        "You have to be 10 years or above to register an account.",
        "info"
      );
    } else {
      this.setState({
        loading: true,
        // add_modal:false
      });
      if (this.state.image == null) {
        let details = {
          // product_id:this.state.selected_products.product_id,
          name: this.state.name,
          phone: this.state.mobile,
          email: this.state.email,
          password: this.state.password,
          // pincode:this.state.pincode,
          gender: this.state.gender,
          state: this.state.state,
          city: this.state.city,
          address: this.state.address,
          company: this.state.company,
          designation: this.state.designation,
          qualifications: this.state.qualification,
          status: this.state.status,
          // qualifications: JSON.stringify([
          //   {
          //     academics: this.state.aqualification,
          //     professional: this.state.pqualification
          //   }
          // ]),

          date_of_birth: this.commentdate(this.state.date),
        };
        console.log(details, "add-without photo");
        let formBody = [];
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        this.setState({
          response: fetch("https://10x10learning.com/user_signup", {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Cache-Control": "max-age=31536000",
            },
            body: formBody,
          })
            .then((response) => response.json())
            .then((responseJson) => {
              console.log(responseJson, "rrrr");
              if (responseJson.status == 400) {
                this.setState({
                  loading: false,
                  // add_modal:false
                });
                swal("Warning!", responseJson.message, "warning");
              } else {
                this.addAddress(responseJson.user_id);
              }
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });
              swal("Warning!", "Check your network!", "warning");
              console.log(error);
            }),
        });
      } else {
        console.log(this.state.image, "imageSign");
        let formData = new FormData();
        // var filename = (this.state.selected_products.product_image).substr(28,(this.state.selected_products.product_image).length)
        // formData.append('product_id',this.state.selected_products.product_id)
        formData.append("name", this.state.name);
        formData.append("phone", this.state.mobile);
        formData.append("gender", this.state.gender);
        formData.append("file", this.state.image);
        formData.append("date_of_birth", this.commentdate(this.state.date));
        formData.append("state", this.state.state);

        formData.append("city", this.state.city);
        formData.append("address", this.state.address);
        formData.append("email", this.state.email);
        formData.append("status", this.state.status);

        // formData.append(
        //   "qualifications",
        //   JSON.stringify([
        //     {
        //       academics: this.state.aqualification,
        //       professional: this.state.pqualification
        //     }
        //   ])
        // );

        formData.append("password", this.state.password);

        formData.append("qualifications", this.state.qualification);

        // formData.append('filename',filename)

        for (let key of formData.entries()) {
          console.log(key[0] + ",with photo " + key[1]);
        }

        this.setState({
          response: fetch("https://10x10learning.com/user_signup", {
            method: "POST",
            body: formData,
          })
            .then((response) => response.json())
            .then((responseJson) => {
              console.log(responseJson, "rrrr");
              if (responseJson.status == 400) {
                this.setState({
                  loading: false,
                  // add_modal:false
                });
                swal("Warning!", responseJson.message, "warning");
              } else {
                this.addAddress(responseJson.user_id);
              }
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });
              swal("Warning!", "Check your network!", "warning");
              console.log(error);
            }),
        });
      }
    }
  };

  handleChecked = (e) => {
    this.setState({ isChecked: !this.state.isChecked });

    console.log(e.target.value, "xax");
  };

  renderEditImage() {
    let imageUrl = "";

    if (this.state.image == null) {
      return (
        <div className="custom-file">
          <input
            type="file"
            className=""
            className={` ${styles.CustomFileInput}`}
            id="inputGroupFile01"
            accept="image/*"
            aria-describedby="inputGroupFileAddon01"
            onChange={(event) => this.handleImageUpload(event)}
          />
          <label
            className={` ${styles.CustomFileUpload} `}
            htmlFor="inputGroupFile01"
          >
            {this.state.language_id == 0 ? `Choose file` : `फ़ाइल का चयन`}
          </label>
        </div>
      );
    } else {
      return (
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-around",
            margin: "0 10px",
            width: "45%",
          }}
          className="show_image"
        >
          <img
            className="imgSize"
            src={this.state.imageUrl}
            className="slider_div_image"
            style={{ marginLeft: "70px", width: "150px" }}
            alt="news_image"
          />
          <MDBIcon
            className="cross_icon"
            onClick={() => this.setState({ image: null })}
            icon="times-circle"
          />
        </div>
      );
    }
  }

  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Novr",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd + "/" + mm + "/" + dt.getFullYear().toString();
  }

  addAddress(user_id) {
    let details = {
      // product_id:this.state.selected_products.product_id,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      pincode: this.state.pincode,
      user_id: user_id,
    };
    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/add_address", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "rrrr");
          if (responseJson.status == 400) {
            this.setState({
              loading: false,
              // add_modal:false
            });
            swal("Warning!", responseJson.message, "warning");
          } else {
            this.setState({
              name: "",
              phone: "",
              email: "",
              password: "",
              gender: "",
              city: "",
              address: "",
              company: "",
              designation: "",
              city: "",
              pinocde: "",
              state: null,
              // add_modal:false
            });
            swal({
              title: "Congratulations",
              text: responseJson.message,
              icon: "success",
            }).then(() => {
              this.setState({ showLogin: true, loading: false });
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }

  selectRegion(val) {
    console.log(val);
    this.setState({ state: val });
  }

  // validateName = text => {
  //   var reg = "^[\\p{L} .'-]+$";
  //   if (reg.test(text) === false) {
  //     this.setState({ name: text, valid_name: false });
  //     return false;
  //   } else {
  //     this.setState({ name: text, valid_name: true });
  //   }
  // };

  validateMobile = (text) => {
    var reg = /^[6-9]\d{9}$/;
    if (reg.test(text) === false) {
      this.setState({ mobile: text, valid_mobile: false });
      return false;
    } else {
      this.setState({ mobile: text, valid_mobile: true });
    }
  };

  validateEmail = (text) => {
    var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      this.setState({ email: text, valid_email: false });
      return false;
    } else {
      this.setState({ email: text, valid_email: true });
    }
  };

  validateCompany = (text) => {
    var reg = /^[\w]+([-_\s]{1}[a-z0-9]+)*$/i;

    if (reg.test(text) === false) {
      this.setState({ company: text, valid_company: false });

      return false;
    } else {
      this.setState({ company: text, valid_company: true });
    }
  };

  handleImageUpload(event) {
    if (event.target.files[0].size > 500000) {
      swal(
        "Image exceeds size limit!",
        "Please choose an image less than 500Kb",
        "info"
      );
    } else {
      this.setState({
        image: event.target.files[0],
        imageUrl: URL.createObjectURL(event.target.files[0]),
      });
    }
  }

  cancelCourse = () => {
    // document.getElementById("create-course-form").reset();
    // // window.location.reload()
    // this.setState({
    //   name:'',
    //   date:'',
    //   image:null,
    //   email: "",
    //   password: "",
    //   confirmPassword: "",
    //   gender: "",
    //   date_of_birth: null,
    //   company: "",
    //   designation: "",
    //   // profile_pic:'',
    //   qualifications: "",
    //   qualification:'',
    //   mobile: "",
    //   loading: false,
    //   login: false,
    //   status:1,
    //   isChecked: false,
    //   date: '',
    //   loginToggle: false,
    //   state: '',
    //   pincode: '',
    //   city: '',
    //   address: '',
    // })
  };
  // changePQ = event => {
  //   console.log(event);
  //   if (event.target.value !== 0)
  //     this.setState({ pqualification: event.target.value });
  //   else {
  //     swal("Please Choose vald qualification", "", "info");
  //   }
  // };
  // changeAQ = event => {
  //   if (event.target.value !== 0)
  //     this.setState({ aqualification: event.target.value });
  //   else {
  //     swal("Please Choose vald qualification", "", "info");
  //   }
  // };

  handleDate(e) {
    this.setState({ date: e });
  }
  render() {
    if (this.state.loading == true) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div id="sign-up" style={{ display: "flex", flexDirection: "column" }}>
          <div className="WindowView">
            <HeaderPage
              {...this.props}
              backgroundColor="#FFFFFF"
              textcolor="#000"
              width="100%"
              height="auto"
              // showLogin={this.state.loginToggle}
            />
          </div>

          {/* <div
            className="vsMobView"
            style={{ height: "auto", width: "98%", margin: "2%" }}
          >
            <MobMenuBar
              {...this.props}
              backgroundColor="white"
              textcolor="#000"
              height="auto"
              width="100%"
            />
          </div> */}
          <div>
            <form id="create-course-form">
              <div className={`${styles.RegistrationBackground} py-lg-4 `}>
                <div
                  className={`${styles.MainRegistration} w-100 p-lg-4 p-md-4 px-3  py-4`}
                >
                  <h2>
                    {this.state.language_id == 0 ? (
                      <h2>Sign Up</h2>
                    ) : (
                      <h2>साइन अप करें</h2>
                    )}
                  </h2>

                  <p>
                    {" "}
                    {this.state.language_id == 0 ? (
                      <p>Sign Up and Start learning with 10x10 Learning.</p>
                    ) : (
                      <p>
                        साइन अप करें और <span className="opensansR">10x10</span>{" "}
                        लर्निंग के साथ सीखना शुरू करें।
                      </p>
                    )}
                  </p>

                  <hr className={`${styles.SignUPContainerHr} pr-5`} />

                  <div className={`${styles.SignUPForm} d-flex row`}>
                    <div className="col-lg-6 col-md-12 col-12 d-flex flex-column justify-content-start pr-md-5 pr-lg-5">
                      <div className="mb-3">
                        <label
                          className={`${styles.LableText}  col-3 text-left px-0`}
                          for="name"
                        >
                          {this.state.language_id == 0 ? (
                            <label>Name*</label>
                          ) : (
                            <label>
                              {" "}
                              नाम <span className="opensansR">*</span>{" "}
                            </label>
                          )}
                        </label>
                        <input
                          placeholder={
                            this.state.language_id == 0
                              ? "Full Name"
                              : "पूरा नाम"
                          }
                          value={this.state.name}
                          type="text"
                          className={`${styles.RegistrationInputType} col-9`}
                          onChange={(text) =>
                            this.setState({ name: text.target.value })
                          }
                          // onKeyDown={(e)=>handleKeyPress(e)} >
                        ></input>
                      </div>

                      <div className="mb-3">
                        <label
                          className={`${styles.LableText} col-3 text-left px-0`}
                          for="name"
                        >
                          {this.state.language_id == 0 ? (
                            <label>Mobile*</label>
                          ) : (
                            <label>
                              {" "}
                              मोबाइल<span className="opensansR">*</span>{" "}
                            </label>
                          )}
                        </label>
                        <input
                          placeholder={
                            this.state.language_id == 0 ? "Mobile" : "मोबाइल"
                          }
                          type="text"
                          className={`${styles.RegistrationInputType} col-9`}
                          value={this.state.mobile}
                          onChange={(text) =>
                            this.validateMobile(text.target.value)
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          className={`${styles.LableText} col-3 text-left px-0`}
                          for="name"
                        >
                          {this.state.language_id == 0 ? (
                            <label>Email*</label>
                          ) : (
                            <label>
                              {" "}
                              ईमेल<span className="opensansR">*</span>{" "}
                            </label>
                          )}
                        </label>
                        <input
                          placeholder={
                            this.state.language_id == 0 ? "Email" : "ईमेल"
                          }
                          type="text"
                          className={`${styles.RegistrationInputType} col-9`}
                          value={this.state.email}
                          onChange={(text) =>
                            this.validateEmail(text.target.value)
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          className={`${styles.LableText} col-3 text-left px-0`}
                          for="name"
                        >
                          {this.state.language_id == 0 ? (
                            <label>Password*</label>
                          ) : (
                            <label>
                              {" "}
                              कुंजिका<span className="opensansR">*</span>{" "}
                            </label>
                          )}
                        </label>
                        <input
                          placeholder={
                            this.state.language_id == 0 ? "Password" : "कुंजिका"
                          }
                          type="password"
                          className={`${styles.RegistrationInputType} col-9`}
                          value={this.state.password}
                          onChange={(text) =>
                            this.setState({ password: text.target.value })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          className={`${styles.LableText} col-3 text-left px-0`}
                          for="name"
                        >
                          {this.state.language_id == 0 ? (
                            <label>Confirm Password*</label>
                          ) : (
                            <label>
                              कुंजिका की पुष्टि
                              <span className="opensansR">*</span>{" "}
                            </label>
                          )}
                        </label>
                        <input
                          placeholder={
                            this.state.language_id == 0
                              ? "Confirm Your Password"
                              : "अपने कुंजिका की पुष्टि करें"
                          }
                          type="password"
                          className={`${styles.RegistrationInputType} col-9`}
                          value={this.state.confirmPassword}
                          onChange={(text) =>
                            this.setState({
                              confirmPassword: text.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          className={`${styles.LableText} col-3 text-left px-0`}
                          for="name"
                        >
                          {this.state.language_id == 0 ? (
                            <label>Gender*</label>
                          ) : (
                            <label>
                              लिंग <span className="opensansR">*</span>{" "}
                            </label>
                          )}
                        </label>
                        <input
                          placeholder={
                            this.state.language_id == 0 ? "Gender" : "लिंग"
                          }
                          type="text"
                          list="gender"
                          className={`${styles.RegistrationInputTypeList} col-9`}
                          value={this.state.gender}
                          onChange={(text1) =>
                            this.setState({ gender: text1.target.value })
                          }
                        />

                        <datalist id="gender">
                          <option value="Male" />
                          <option value="Female" />
                          <option value="Other" />
                        </datalist>
                      </div>

                      <div className="mb-3">
                        <label
                          className={`${styles.LableText} col-3 text-left px-0`}
                          for="name"
                        >
                          State
                        </label>

                        <RegionDropdown
                          country="India"
                          className={`${styles.RegistrationInputTypeState} col-9`}
                          value={this.state.state}
                          style={{ borderRadius: 0 }}
                          onChange={(val) => this.selectRegion(val)}
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          className={`${styles.LableText} col-3 text-left px-0`}
                          for="name"
                        >
                          {this.state.language_id == 0 ? (
                            <label>City</label>
                          ) : (
                            <label>
                              शहर<span className="opensansR">*</span>{" "}
                            </label>
                          )}
                        </label>
                        <input
                          placeholder={
                            this.state.language_id == 0 ? "City" : "शहर"
                          }
                          type="text"
                          className={`${styles.RegistrationInputType} col-9`}
                          value={this.state.city}
                          onChange={(text) =>
                            this.setState({ city: text.target.value })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          className={`${styles.LableText} col-3 text-left px-0`}
                          for="name"
                        >
                          {this.state.language_id == 0 ? (
                            <label>Address*</label>
                          ) : (
                            <label>
                              पता<span className="opensansR">*</span>{" "}
                            </label>
                          )}
                        </label>
                        <input
                          placeholder={
                            this.state.language_id == 0 ? "Address" : "पता"
                          }
                          type="text"
                          className={`${styles.RegistrationInputType} col-9`}
                          value={this.state.address}
                          onChange={(text) =>
                            this.setState({ address: text.target.value })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          className={`${styles.LableText}  col-3 text-left px-0`}
                          for="name"
                        >
                          {this.state.language_id == 0 ? (
                            <label>Pincode</label>
                          ) : (
                            <label>
                              {" "}
                              पिन कोड <span className="opensansR">*</span>{" "}
                            </label>
                          )}
                        </label>
                        <input
                          placeholder={
                            this.state.language_id == 0
                              ? "Pincode"
                              : "पिन कोड "
                          }
                          value={this.state.pincode}
                          type="tel"
                          className={`${styles.RegistrationInputType} col-9`}
                          onChange={(text) =>
                            this.setState({ pincode: text.target.value })
                          }
                          // onKeyDown={(e)=>handleKeyPress(e)} >
                        ></input>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12  d-flex flex-column justify-content-start pr-md-5 pl-lg-5 pr-lg-3">
                      <div className="mb-3">
                        <label
                          className={`${styles.LableText} col-3 text-left px-0`}
                          for="name"
                        >
                          {this.state.language_id == 0 ? (
                            <label>Qualification*</label>
                          ) : (
                            <label>
                              योग्यता<span className="opensansR">*</span>{" "}
                            </label>
                          )}
                        </label>

                        <input
                          placeholder={
                            this.state.language_id == 0
                              ? "Academic Qualification"
                              : "शैक्षिक योग्यता"
                          }
                          type="text1"
                          list="qualification"
                          className={`${styles.RegistrationInputTypeList} col-9`}
                          value={this.state.qualification}
                          onChange={(text1) =>
                            this.setState({ qualification: text1.target.value })
                          }
                        />

                        <datalist id="qualification">
                          <option value="B.Tech" />
                          <option value="B.Sc" />
                          <option value="B.E." />
                          <option value="MCA" />
                          <option value="B.A" />
                          <option value="10th" />
                          <option value="12th" />
                        </datalist>
                      </div>

                      <div className={`${styles.SignUpDate} mb-3 text-left d-flex`}>
                        <label
                          className={`${styles.LableText} col-3 text-left px-0`}
                          for="name"
                        >
                          {this.state.language_id == 0 ? (
                            <label> Date of Birth*</label>
                          ) : (
                            <label>
                              जन्म की तारीख<span className="opensansR">*</span>{" "}
                            </label>
                          )}
                        </label>

                        <div className="col-9 px-0">
                          <input
                            type="date"
                            className={`${styles.RegistrationInputTypeList} ${styles.NoSpinner} w-100 bg-white text-dark p-3`}
                            name="dateofbirth"
                            style={{borderRadius:0}}
                            id="dateofbirth"
                           // max={moment().format("YYYY-MM-DD")}
                            onChange={date =>
                              this.setState({
                                date: new Date(date.target.value)
                              })
                            }
                          />
                     
                        </div>
                      </div>

                      <div className="mb-3 text-left d-flex flex-row ">
                        <label
                          className={`${styles.LableText} col-3 px-0 `}
                          for="name"
                        >
                          {this.state.language_id == 0
                            ? `Upload photo`
                            : `फोटो अपलोड करें`}

                          <br />
                          <i className={`${styles.FileSizeLimit}`}>
                            <span className="opensansR">
                              (JPG/PNG upto 1MB)
                            </span>
                          </i>
                        </label>

                        <div
                          className={`${styles.RegistrationInputTypeImage} col-9 px-0`}
                        >
                          {this.renderEditImage()}
                        </div>
                      </div>
                    </div>

                    <div className="col-12 px-3  d-flex flex-column mb-3">
                      <span>
                        <hr className={`${styles.SignUPContainerHr}`} />
                      </span>
                      <div className="d-flex align-items-center justify-content-start">
                        <input
                          type="checkbox"
                          checked={this.state.isChecked}
                          onChange={this.handleChecked}
                          className="filled-in"
                          id="filled-in-box"
                        />

                        {this.state.language_id == 0 ? (
                          <text className={`${styles.SignUPAcceptAll} `}>
                            Accept all
                            <span style={{ fontWeight: "bold" }}>
                              &nbsp; Terms & Conditions
                            </span>
                          </text>
                        ) : (
                          <text className={`${styles.SignUPAcceptAll} `}>
                            सभी नियम और शर्तें स्वीकार करें
                          </text>
                        )}
                      </div>
                      <div className={`${styles.RegistrationTextDiv} d-flex`}>
                        <div>
                          <p>
                            <span className={`${styles.RegistrationText}`}>
                              {this.state.language_id == 0 ? (
                                <label> Already have an Account ? </label>
                              ) : (
                                <label>
                                  पहले से ही एक खाता है{" "}
                                  <span className="opensansR">?</span>
                                </label>
                              )}
                            </span>
                            <a
                              onClick={() => this.setState({ showLogin: true })}
                            >
                              <span
                                className={`${styles.RegistrationTextLogIn} ml-lg-1 ml-md-3 ml-3`}
                              >
                                {this.state.language_id == 0
                                  ? `Log in`
                                  : `लॉग इन`}
                              </span>
                            </a>
                          </p>
                        </div>

                        <div className="d-flex justify-content-start">
                          {/* <button className={`${styles.BtnReset}`}
                     
                     onClick={() =>
                        this.setState({ image: null }, () =>
                          this.cancelCourse()
                        )
                      }
                     
                     >
                     {this.state.language_id == 0 ? `Reset`  :`रीसेट`}
                     
                     
                     </button>   */}
                          <button
                            className={`${styles.BtnConfirm}`}
                            onClick={(e) => this.handleSubmit(e)}
                          >
                            {this.state.language_id == 1
                              ? `पुष्टि करें`
                              : `Confirm`}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div style={{ height: "auto" }}>
            <MainFooterPage {...this.props} />
          </div>
          <Login showLogin={this.state.showLogin} {...this.props} />
        </div>
      );
    }
  }
}

export default signUP;
