import React, {Fragment} from "react";
import Fade from "react-reveal/Fade";
import '../commonStyles.css'
import "./accountDetails.css";
import Footer from "../../Reusable/Footer/footer";
import HomeHeader from "../../Reusable/Header/homeHeader";
import AccountNav from "../../Reusable/AccountNavigation/AccountNav";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import swal from "sweetalert";
import Swal from "sweetalert2";
import moment from "moment";
import { MDBIcon } from "mdbreact";
import { RegionDropdown } from 'react-country-region-selector';
import MainFooterPage from "../../Reusable/Footer/footer.jsx";

var md5 = require("md5");

class AccountDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      language_id:'',
      cart: [],
      userDetails: [
        {
          name: ``,
          user_id: 0,
          phone: "",
          email: ``,
          company:'',
          designation:'',
          gender: "",
          city: "",
          date_of_birth: "",
          qualifications: '',
          company: ``,
          designation: ``,
          address: ``
        }
      ],
      editpage: false,
      qualifications: "",
      email: "",
      phone: Number,
      valid_email: false,
      valid_email_t: false,
      valid_phone: false,
      password: "",
      profile_pic_url: "",
      editedPicture: null,
      profile_pic: null,
      city:'',
      gender:null,
      state:null,
      address:null,
      pincode:null
    };
  }


  onsave = async () => {
   
    let checkuser = this.state.password;
    // var passwordHash = require('password-hash');

    //     var hashedPassword = passwordHash.generate(checkuser);
console.log(this.state.phone,'phone')
    if (this.state.email.length == 0) {
      return swal("Warning!", "Enter email!", "warning");
    } else if (this.state.valid_email == false) {
      return swal("Warning!", "Enter valid email!", "warning");
    } 
    else if(this.state.phone==null){
      return swal("Warning!", "Enter phone number!", "warning");
    }
    else if (this.state.phone.length==0) {
      return swal("Warning!", "Enter phone number!", "warning");
    } 
    else if (this.state.phone.length != 10) {
      return swal("Warning!", "Enter valid phone!", "warning");
    } else if (this.state.valid_phone == false) {
      return swal("Warning!", "Enter valid phone!", "warning");
    }
    // else if(this.state.gender==null){
    //   return  swal("Warning!", "Please choose a Gender!", "warning");
    // }
   else{
     
    let login_type = JSON.parse(window.localStorage.getItem('userdata')).type


 if(login_type==0 || login_type==1){
  const { value: password } = await Swal.fire({
    title: "Enter your password",
    input: "password",
    inputPlaceholder: "Enter your password",
    showCancelButton:true,
    backdrop:false,
    inputAttributes: {
      maxLength: 40,
      autoCapitalize: "off",
      autoCorrect: "off",
      autoComplete: "off"
    }
  })  

   
  if (password) {
    let val = md5(password);
    if (val != checkuser) {
      return Swal.fire("Wrong Password");
    }  
    swal("Success!", "Successfully changed !", "success");    
  }
else{
 return
}
 }
   

    // if (tenure!==this.) {
    //     alert("You have entered " + tenure + " years" );
    // }
    let details = {
      user_id: this.state.user_id,
      name: this.state.userDetails.name,
      phone: this.state.phone,
      email: this.state.email,
      password: this.state.password,
      gender: this.state.gender,
      city: this.state.userDetails.city,
      address: this.state.userDetails.address,
      company: this.state.company,
      designation: this.state.designation,
      // qualifications:this.state.aqualification,
      // qualifications:this.state.pqualification,
      qualifications: this.state.qualifications,

      date_of_birth: this.state.userDetails.date_of_birth
    };
    console.log(details)
    // this.validateemail(details.email)
    // window.location.reload()

    //  console.log(details,'fetching-details')
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/edit_user_details", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          window.localStorage.removeItem('userdata')
            window.localStorage.setItem('userdata',JSON.stringify(responseJson))
          this.editAddress();
        })
        .catch(error => {
          this.setState({
            loading: false
          });

          console.log(error);
        })
    });

   }

    
  };
  componentWillMount() {
    var user = window.localStorage.getItem("userdata");
    console.log(user, "json user");
    let language_id = localStorage.getItem("language_id");
    console.log(language_id);
    this.setState({ language_id });
    //console.log(user);
    if (user != null) {
      this.setState({ user_id: JSON.parse(user).user_id, loading: true }, () =>
        this.getUserDetails()
      );
    } else {
      this.props.history.push("/");
    }
  }

  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Novr",
      "Dec"
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd + "/" + mm + "/" + dt.getFullYear().toString();
  }

  commenttime(timestamp) {
    var convertdLocalTime = new Date(timestamp);
    var hours = convertdLocalTime.getHours();
    var minutes = convertdLocalTime.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  getCart = () => {
    let details = {
      user_id: this.state.user_id
    };

    //  console.log(details,'fetching-details')
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          //   console.log(responseJson,'cart')

          this.setState({
            cart: responseJson
          }, ()=>this.getAddress());
        })
        .catch(error => {
          this.setState({
            loading: false
          });

          console.log(error);
        })
    });
  };

  getUserDetails = async () => {
    let details = {
      user_id: this.state.user_id
    };

    //  console.log(details,'fetching-details')
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/get_user_details", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },

        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          // console.log(JSON.parse(responseJson[0].qualifications))
          this.setState(
            {
              userDetails: responseJson[0],
             // user_id:responseJson[0].user_id,
             qualifications: responseJson[0].qualifications,
              email: responseJson[0].email,
              designation:responseJson[0].designation,
              company:responseJson[0].company,
              phone: responseJson[0].phone,
              password: responseJson[0].password,
              profile_pic_url: responseJson[0].profile_pic,
              gender:responseJson[0].gender,

            },
            () => this.getCart()
          );
        })
        .catch(error => {
          this.setState({
            loading: true
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };

  hanglechangename = e => {
    let userdetails = { ...this.state.userDetails };
    userdetails.name = e.target.value;
    this.setState({ userDetails: userdetails });
  };

  editname() {

 
    if (this.state.editpage === true) {


      return (
        <input
          placeholder="Name"
          style={{
            height: "33px",
            borderRadius: "5px",
            border: "1.2px solid #E4E4E4",
            backgroundColor: "#fff"
          }}
          type="text"
          value={this.state.userDetails.name}
          onChange={this.hanglechangename}
          className="p-1"
        />
      );
    } else {
      return <Fragment>{this.state.userDetails.name}</Fragment>;
    }
  }
 
  validatemobile = text => {
    console.log(text);
    let reg = /^[0-9]{10}$/;
    if (reg.test(text) === false) {
      this.setState({ phone: text, valid_phone: false });
      return false;
    } else {
      this.setState({ phone: text, valid_phone: true });
    }
  };

  editmobile() {
    if (this.state.editpage === true) {
      return (
        <input
          placeholder="Mobile"
          style={{
            height: "33px",
            borderRadius: "5px",
            border: "1.2px solid #E4E4E4",
            backgroundColor: "#fff"
          }}
          type="number"
          value={this.state.phone}
          onChange={text => this.validatemobile(text.target.value)}
          className="p-1"
        />
      );
    } else {
      if(this.state.phone!=null){
        return <Fragment>{this.state.phone}</Fragment>
      }
      else{
        return <Fragment>---</Fragment>
      }
    }
  }

  validateemail = text => {
    console.log(text);
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      this.setState({ email: text, valid_email: false });
      return false;
    } else {
      this.setState({ email: text, valid_email: true });
    }
  };
  // handlechangeemail = e => {
  //   // this.validateemail(e.target.value)
  //      let userdetails = { ...this.state.userDetails };

  //   userdetails.email = this.state.email;
  //   console.log(e.target.value)
  //   this.setState({ userDetails: userdetails });
  // };
  editemail() {
    if (this.state.editpage === true) {
      return (
        <input
          placeholder="Email"
          style={{
            height: "33px",
            borderRadius: "5px",
            border: "1.2px solid #E4E4E4",
            backgroundColor: "#fff"
          }}
          type="text"
          value={this.state.email}
          onChange={text => this.validateemail(text.target.value)}
          className="p-1"
        />
      );
    } else {
      return <Fragment>{this.state.userDetails.email}</Fragment>;
    }
  }

  handlechangegender = e => {
    let userdetails = this.state.userDetails;
    // console.log(this.state.userDetails)
    userdetails.gender = e.target.value;
    console.log(userdetails)
   // this.setState({ userDetails: userdetails });
  };
  editgender() {
    if (this.state.editpage === true) {
      return (
        <React.Fragment>
          {" "}
          <select
            value={this.state.gender}
            onChange={(e)=>this.setState({gender:e.target.value})}
            className="p-1"
          >
            <option value="none">Not selected</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </React.Fragment>
      );
    } else {
      return <React.Fragment>{this.state.userDetails.gender}</React.Fragment>;
    }
  }

  handlechangecity = e => {
    let userdetails = { ...this.state.userDetails };
    userdetails.city = e.target.value;
    this.setState({ userDetails: userdetails });
  };

  editcity() {
    if (this.state.editpage === true) {
      return (
        <input
          placeholder="City"
          style={{
            height: "33px",
            borderRadius: "5px",
            border: "1.2px solid #E4E4E4",
            backgroundColor: "#fff"
          }}
          type="text"
          value={this.state.userDetails.city}
        // value={this.state.userDetails.city}
          onChange={this.handlechangecity}
          className="p-1"
        />
      );
    } else {
      return <Fragment>{this.state.userDetails.city}</Fragment>;
    }
  }
  handleDobChange = e => {
    let userdetails = { ...this.state.userDetails };
    userdetails.date_of_birth = e.target.value;
    console.log(userdetails);
    this.setState({ userDetails: userdetails });
  };

  getDobValue = () => {
    console.log(this.state.userDetails.date_of_birth);
  };

  editdob() {
    let data = this.state.userDetails;
    if (this.state.editpage === true) {
      return (
        <input
          placeholder="dd/mm/yyy"
          className="editDateInput"
          type="text"
          className="RegistrationInputType placeholder-fix p-1"
          name="dateofbirth"
          id="dateofbirth"
          defaultValue={data.date_of_birth}
          onChange={this.handleDobChange}
        />
      );
    } else {
      return <Fragment>{this.state.userDetails.date_of_birth}</Fragment>;
    }
  }

  handlechangequalidate = e => {
    //   console.log(this.state.userDetails)
    let userdetails = { ...this.state.userDetails };
    userdetails.qualifications = e.target.value;

    this.setState({ userDetails: userdetails ,qualifications:e.target.value});
  };
  editqualidate() {
    let data = this.state.userDetails;
    console.log(this.state.userDetails)
   
    if (this.state.editpage === true) {
      return (
        <Fragment>
          <select
            value={data.qualifications}
            onChange={this.handlechangequalidate}
            className="p-1"
          >
   <option value="B.Tech" >B.Tech</option>
                    <option value="B.Sc" >B.Sc</option>
                    <option value="B.E." >B.E.</option>
                    <option value="MCA" >M.C.A</option>
                    <option value="B.A" >B.A.</option>
                    <option value="10th" >10th</option>
                    <option value="12th" >12th</option>
          </select>
        </Fragment>
      );
    }
     
       else{
        return <Fragment>{data.qualifications}</Fragment>;
       
     
    }
    
    
  }

  // handlechangequalidegree = e => {





  //   let userdetails = { ...this.state.userDetails };

  //   userdetails.qualifications = e.target.value;
  //   this.setState({ userDetails: userdetails });
  // };
  // editqualidegree() {
  //   let data = this.state.userDetails;
   
  
   

  //   if (this.state.editpage === true) {
  //     return (
  //       <React.Fragment>
  //         <select
  //               value={this.state.userDetails.qualifications}
  //         onChange={this.handlechangequalidegree}
  //           className="p-1"
  //         >
  //           <option value="B.Tech">B.Tech</option>
  //           <option value="B.S./B.Sc. Engineering">
  //             B.S./B.Sc. Engineering
  //           </option>
  //           <option value="B.E.">B.E.</option>
  //           <option value="M.C.A">M.C.A</option>
  //         </select>
  //       </React.Fragment>
  //     );
  //   } 
  //      else{
  //       return <React.Fragment>---</React.Fragment>;
  //      }
     
    

  
  // }
  handlechangecompany = e => {
    let userdetails = { ...this.state.userDetails };
   
    this.setState({ company: e.target.value });
  };

  editcompany() {
    console.log(this.state.userDetails.company)
    let data = this.state.userDetails;
    if (this.state.editpage === true) {
      return (
        <input
          placeholder="Your Current Company"
          style={{
            height: "33px",
            borderRadius: "5px",
            width: "100%",
            border: "1.2px solid #E4E4E4",
            backgroundColor: "#fff"
          }}
          type="text"
          value={this.state.company!=null ?this.state.company:'---'}
          onChange={this.handlechangecompany}
          className="p-1"
        />
      );
    } else {
      if(this.state.company){
        return <Fragment>{this.state.company}</Fragment>
      }
      else{
        return <Fragment>---</Fragment>
      }
    }
  }
  selectCountry (val) {
    console.log(val)
    this.setState({ country: val });
  }
 
  selectRegion (val) {
    console.log(val)
    this.setState({ state: val });
  }
  hanglechangeedit = e => {
   
    
    this.setState({ designation:  e.target.value });
  };
  editdesignation() {
    let data = this.state.userDetails;
    if (this.state.editpage === true) {
      return (
        <input
          placeholder="Your Designation"
          style={{
            width: "100%",
            height: "33px",
            borderRadius: "5px",
            border: "1.5px solid #E4E4E4",
            backgroundColor: "#fff"
          }}
          value={this.state.designation!=null?this.state.designation:'--'}
          onChange={this.hanglechangeedit}
          type="text"
          className="p-1"
        />
      );
    } else {
      if(this.state.designation){
        return <React.Fragment>{this.state.designation}</React.Fragment>
      }
      else{
        return <React.Fragment>---</React.Fragment>
      }
    }
  }

  editpasstext() {
    if (this.state.editpage === true) {
      return null;
    } else {
      return        <React.Fragment>{this.state.language_id==0?<React.Fragment>Password</React.Fragment>:<React.Fragment>

कुंजिका</React.Fragment>}</React.Fragment>
    }
  }

  editpass() {
    if (this.state.editpage === true) {
      return null;
    } else {
      return <Fragment>*******</Fragment>;
    }
  }
  // handlechangeaddress = e => {
  //   let userdetails = { ...this.state.userDetails };
  //   userdetails.address = e.target.value;
  //   this.setState({ userDetails: userdetails });
  // };
  renderEditAddress() {
    let data = this.state.userDetails;
    if (this.state.editpage === true) {
      return (
        <div>
        <input
          placeholder="address"
          style={{
            width: "100%",
            height: "33px",
            borderRadius: "5px",
            border: "1.2px solid #E4E4E4",
            backgroundColor: "#fff"
          }}
          type="text"
          value={this.state.address}
          onChange={(e)=>this.setState({address:e.target.value})}
          className="p-1"
        />
              {/* <input
          placeholder="City"
          style={{
            width: "100%",
            height: "33px",
            borderRadius: "5px",
            border: "1.2px solid #E4E4E4",
            backgroundColor: "#fff"
          }}
          type="text"
          value={this.state.city==null?'':this.state.city}
          onChange={(e)=>this.setState({city:e.target.value})}
          className="p-1 mt-1"
        /> */}
              
        <div className='rowDefault ' style={{justifyContent:'space-between'}}>
        {/* <RegionDropdown
          country="India"
          className='region_select'
          value={this.state.state}
          onChange={(val) => this.selectRegion(val)} />
          <input
        autofocus
        value={this.state.pincode==null?'':this.state.pincode}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control region_select"
          placeholder="Enter pincode"

          onChange={(text)=>this.setState({pincode:text.target.value})}
        /> */}
        </div>
        </div>
      );
    } else {
    return <p>{`${this.state.address?this.state.address:''}`}</p>;
    }
  }


  getAddress = () => {
    this.setState({loading:true})
    let details = {
      user_id: this.state.user_id
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/get_address", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          this.setState({
            address_id:responseJson.address_id,
            address: responseJson.address,
            state: responseJson.state,
            pincode: responseJson.pincode,
            city:responseJson.city,
            address_loading: false,
            loading:false
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });

          console.log(error);
        })
    });
  };
  
  editAddress = () => {
    // if (this.state.state == null || this.state.state == "") {
    //   swal("Please fill the mandatory fields", "", "info");
    // } else {
      this.setState({
        address_loading: true
      });
      let details = {
        address_id: this.state.address_id,
        address: this.state.address,
        // state: this.state.state,
        city:this.state.city,
        pincode: this.state.pincode
      };

      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      console.log(formBody)
      this.setState({
        response: fetch("https://10x10learning.com/edit_address", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "max-age=31536000"
          },
          body: formBody
        })
          .then(response => response.json())
          .then(responseJson  => {
              this.addImage()
          })
          .catch(error => {
            this.setState({
              loading: false
            });

            console.log(error);
          })
      });
    // }
  };


  showbutton() {
    if (this.state.editpage === false) {
      return (
        <button
          className="edit-button btn-custom"
          onClick={() =>
            this.setState({
              editpage: true,
              valid_email: true,
              valid_phone: true
            })
          }
        >
    {this.state.language_id==0?<React.Fragment>Edit details</React.Fragment>:<React.Fragment>विवरण संपादित करें</React.Fragment>}
        </button>
      );
    } else {
      return (
        <Fragment>
          <button className="edit-button btn-custom" onClick={this.onsave}>
            Save
          </button>
          <button
            className="edit-button btn-custom reset"
            onClick={() =>
              this.setState(
                {
                  user_id: JSON.parse(window.localStorage.getItem("userdata"))
                    .user_id,
                  editpage: false,
                  loading: true
                },
                () => this.getUserDetails()
              )
            }
          >
            Cancel
          </button>
        </Fragment>
      );
    }
  }

  // onsave = async () => {
  //   let checkuser = this.state.password;
  //   // var passwordHash = require('password-hash');

  //   //     var hashedPassword = passwordHash.generate(checkuser);

  //   if (this.state.email.length == 0) {
  //     return swal("Warning!", "Enter email!", "warning");
  //   } else if (this.state.valid_email == false) {
  //     return swal("Warning!", "Enter valid email!", "warning");
  //   } 
  //   else if (this.state.phone.length==0) {
  //     return swal("Warning!", "Enter phone number!", "warning");
  //   } 
  //   else if (this.state.phone.length != 10) {
  //     return swal("Warning!", "Enter valid phone!", "warning");
  //   } else if (this.state.valid_phone == false) {
  //     return swal("Warning!", "Enter valid phone!", "warning");
  //   }


  //   const { value: password } = await Swal.fire({
  //     title: "Enter your password",
  //     input: "password",
  //     inputPlaceholder: "Enter your password",
  //     inputAttributes: {
  //       maxLength: 40,
  //       autoCapitalize: "off",
  //       autoCorrect: "off",
  //       autoComplete: "off"
  //     }
  //   });

  //   let val = md5(password);

  //   if (val != checkuser) {
  //     return Swal.fire("Wrong Password");
  //   }
  //   swal("Success!", "Successfully changed !", "success");

  //   // if (tenure!==this.) {
  //   //     alert("You have entered " + tenure + " years" );
  //   // }
  //   let details = {
  //     user_id: this.state.user_id,
  //     name: this.state.userDetails.name,
  //     phone: this.state.phone,
  //     email: this.state.email,
  //     password: this.state.password,
  //     gender: this.state.gender,
  //    // city: this.state.userDetails.city,
  //   //  address: this.state.userDetails.address,
  //     company: this.state.userDetails.company,
  //     designation: this.state.userDetails.designation,
  //     // qualifications:this.state.aqualification,
  //     // qualifications:this.state.pqualification,
  //     qualifications: this.state.userDetails.qualifications,

  //     date_of_birth: this.state.userDetails.date_of_birth
  //   };
  //   // this.validateemail(details.email)
  //   // window.location.reload()

  //   //  console.log(details,'fetching-details')
  //   let formBody = [];
  //   for (let property in details) {
  //     let encodedKey = encodeURIComponent(property);
  //     let encodedValue = encodeURIComponent(details[property]);
  //     formBody.push(encodedKey + "=" + encodedValue);
  //   }
  //   formBody = formBody.join("&");

  //   this.setState({
  //     response: fetch("https://10x10learning.com/edit_user_details", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //         "Cache-Control": "max-age=31536000"
  //       },
  //       body: formBody
  //     })
  //       .then(response => response.json())
  //       .then(responseJson => {
  //         window.localStorage.removeItem('userdata')
  //           window.localStorage.setItem('userdata',JSON.stringify(responseJson))
  //         this.addImage();
  //       })
  //       .catch(error => {
  //         this.setState({
  //           loading: false
  //         });

  //         console.log(error);
  //       })
  //   });


    
  // };
  addImage = async () => {
    if (this.state.profile_pic != null) {
      let formData = new FormData();
      formData.append("user_id", this.state.user_id);
      formData.append("file", this.state.profile_pic);
      for (let key of formData.entries()) {
        console.log(key[0] + ", " + key[1]);
      }
      this.setState({
        response: fetch("https://10x10learning.com/add_profile_pic", {
          method: "POST",
          body: formData
        })
          .then(response => response.json())
          .then(responseJson => {
            console.log(responseJson);
            window.localStorage.removeItem('userdata')
            window.localStorage.setItem('userdata',JSON.stringify(responseJson))
            window.location.reload()
            
          })
          .catch(error => {
            this.setState({
              loading: false
            });
            swal("Warning!", "Check your network!", "warning");
            console.log(error);
          })
      });
    }
    else{
      window.location.reload();
    }
  };

  editPicture() {
    let imageUrl = "";
    if (this.state.editpage === true) {
      if (this.state.profile_pic_url == null) {
        return (
          <React.Fragment>
            <hr />
            <div className="col-9 pl-2">
              <label
                htmlFor="defaultFormRegisterNameEx"
                className="image-label subject-labels"
              >
                Profile Picture:
              </label>
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="inputGroupFile01"
                  aria-describedby="inputGroupFileAddon01"
                  onChange={event =>
                    this.setState({
                      profile_pic: event.target.files[0],
                      profile_pic_url: URL.createObjectURL(
                        event.target.files[0]
                      )
                    })
                  }
                />
                <label className="custom-file-label" htmlFor="inputGroupFile01">
                  Choose file
                </label>
              </div>
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              margin: "10px 10px",
              width: "45%"
            }}
            className="col-3 pl-1"
          >
            <label
              htmlFor="defaultFormRegisterNameEx"
              className="image-label subject-labels"
            >
              Profile picture:
            </label>
            <img
              src={this.state.profile_pic_url}
              className=""
              style={{ margin: "0", width: "100px" }}
              alt="news_image"
            />
            <MDBIcon
              className="cross_icon"
              onClick={() => {
                this.setState({ profile_pic: null, profile_pic_url: null });
              }}
              icon="times-circle"
              style={{ position: "absolute", top: "20px", right: "-5px" }}
            />
          </div>
        );
      }
    }
  }

  renderData() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    let data = this.state.userDetails;

    if (this.state.loading) {
      return (
        <div className="sweet-loading">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <Fade>
          <div className="account-details-container">
            <h1 className="my-account-title mt-4 mb-0 text-center text-md-left">
              {" "}
              {this.state.language_id==0?<React.Fragment>My Account</React.Fragment>:<React.Fragment>
मेरा खाता</React.Fragment>}
            </h1>
            <p className="my-account-subtitle mb-4 text-center text-md-left">
              {" "}
              {this.state.language_id==0?<React.Fragment>Edit your account details here</React.Fragment>:<React.Fragment>अपना खाता विवरण यहां संपादित करें</React.Fragment>}
            </p>

            <hr />

            <div className="row">
              <div className="col-12 col-sm-6">
                <table className="table table-responsive">
                  <tbody>
                    <tr>
                      <td>{this.state.language_id==0?<React.Fragment>User Name</React.Fragment>:<React.Fragment>उपयोगकर्ता नाम</React.Fragment>}</td>
                      <td>{this.editname()}</td>
                    </tr>

                    <tr>
              <td>{this.state.language_id==0?<React.Fragment>Mobile</React.Fragment>:<React.Fragment>
मोबाइल</React.Fragment>}</td>
                      <td>{this.editmobile()}</td>
                    </tr>

                    <tr>
                      <td>{this.state.language_id==0?<React.Fragment>Email</React.Fragment>:<React.Fragment>
ईमेल</React.Fragment>}</td>
                      <td>{this.editemail()}</td>
                    </tr>

                    <tr>
                      <td> {this.editpasstext()}</td>
                      <td>{this.editpass()}</td>
                    </tr>

                    <tr>
                    <td>{this.state.language_id==0?<React.Fragment>Gender</React.Fragment>:<React.Fragment>

                      लिंग</React.Fragment>}</td>
                      <td>{this.editgender()}</td>
                    </tr>

                    <tr>
                    <td>{this.state.language_id==0?<React.Fragment>Date of Birth</React.Fragment>:<React.Fragment>

                      जन्म की तारीख</React.Fragment>}</td>
                      <td>{this.editdob()}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-12 col-sm-6">
                <table class="table table-responsive">
                  <tbody>
                    <tr>
                      <td>{this.state.language_id==0?<React.Fragment>Qualifications</React.Fragment>:<React.Fragment>


                        योग्यता</React.Fragment>}</td>

                      <td>
                        <p className="academic-title">
                         <span>{this.editqualidate()}</span>{" "}
                        </p>
                        {/* <p className="professional-title">
                          
                          <span>{this.editqualidegree()}</span>{" "}
                        </p> */}
                      </td>
                    </tr>
{/* 
                    <tr>
                      <td>Company</td>
                      <td>{this.editcompany()}</td>
                    </tr>

                    <tr>
                      <td>Designation</td>
                      <td>{this.editdesignation()}</td>
                    </tr> */}

                    <tr>
                      <td>{this.state.language_id==0?<React.Fragment>Billing Address</React.Fragment>:<React.Fragment>
                        बिल भेजने का पता</React.Fragment>}</td> 

                      <td>{this.renderEditAddress()}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
            {this.editPicture()}
          </div>
        </Fade>
      );
    }
  }
 
  // onsave = e => {
  //   e.preventDefault();
  //   let userdata = this.state.userDetails;
  //   fetch("/", {
  //     method: "post",
  //     headers: { "Content-Type": "application/json" },
  //     body: {
  //       userdata
  //     }
  //   });
  // };
  render() {
    console.log(this.state,"state")
    return (
      <div id="my-account" className="bg-whitesmoke" style={{overflow:'hidden'}}>
        <HomeHeader
          cart={
            this.state.cart.length == 0
              ? 0
              : JSON.parse(this.state.cart[0].items).length
          }
          {...this.props}
          active="My Account"
        />

        <div className="container-account-details container-my-mobile-ipad-div">
          <div className="row bg-white account-components-border">
            <div className="col-12 col-md-3 px-0 account-nav-ipad">
              <AccountNav
                {...this.props}
                toggleEdit={this.state.editpage}
                profile_pic_url={this.state.profile_pic_url}
                active="My Account"
              />
            </div>

            <div className="col-12 col-md-9  align-self-start">
              {this.renderData()}
            </div>
          </div>
        </div>
        <div className=" ResetButtonDiv">
          <div className="row flex-row-reverse mx-0">
            {this.showbutton()}

            <button className="reset-button btn-custom d-none">Reset</button>
          </div>
        </div>
        <div style={{ height: "auto" }} >
            <MainFooterPage {...this.props} />
          </div>
        
      </div>
    );
  }
}

export default AccountDetails;
