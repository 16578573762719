import React from "react";
import "./explainations.css";
// import "../Evaluation/evaluation.css";
import HomeHeader from "../../Reusable/Header/homeHeader";
import MainFooterPage from "../../Reusable/Footer/footer.jsx";
import { FulfillingBouncingCircleSpinner } from "react-epic-spinners";
import Collapsible from '../collapsible/collapsible'
import {
  MDBIcon,
  MDBBtn, MDBCollapse ,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle
} from "mdbreact";
import { FormGroup, Label, Input } from "reactstrap";

import swal from "sweetalert";

class TestFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        explainations:[],
        showexplaination:false,
         collapseID: ""
    };
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  }
  
  componentWillMount() {
    this.getExplaination();
  }

  componentDidMount() {}


  renderQuestionAnswer(){
      console.log(this.state.explainations)
      let explainations=JSON.parse(this.state.explainations[0].explainations)
      let testQuestions=JSON.parse(explainations.test_questions)
      let allOptions=['a','b','c','d']
      console.log(this.state.explainations[0].test_type)
if(this.state.explainations[0].test_type=='Objective'){
      return testQuestions.map((item,index)=>{
          console.log(item)
        let options=JSON.parse(item.answers)
        console.log(options)
        return (
       <>
        <p>{index+1}. {item.question_title}</p>
         { options.map((item,index)=><p>   {allOptions[index]}) <span className={item.value==1?'right ':'wrong'} style={{padding:'5px'}}>{item.answer}</span></p>)}
         
       <Collapsible explaination={item.explaination}/>
         <br/><br/>
        </>
     
      )
      })
    }
    else{
        let explainations=JSON.parse(this.state.explainations[0].explainations)
        let testQuestions=JSON.parse(explainations.test_questions)
        console.log(testQuestions)
        return testQuestions.map((item,index)=>{
            console.log(item)
          return (
         <>
          <p style={{fontFamily:'OpenSans-B'}}>{index+1}. {item.question_title}</p>
           <p><span style={{fontFamily:'OpenSans-B'}}>Answer - </span><span style={{fontFamily:'OpenSans-R'}}>{item.answers}</span></p>
           
           {/* <MDBBtn style={{backgroundColor:'#c01825',color:'white',border:'1px solid #c01825', padding:'2px'}}
                   onClick={this.toggleCollapse(index)}  >View Explanation</MDBBtn> */}
         {/* <MDBCollapse id={index} isOpen={this.state.collapseID} style={{fontFamily:'OpenSans-B'}}><p>{item.explaination}</p></MDBCollapse> */}
         <Collapsible explaination={item.explaination}/>
           <br/><br/>
          </>
       
        )
        })
    }
  }


  getExplaination() {
    let test_id = localStorage.getItem("test_id");
    let userdata = JSON.parse(localStorage.getItem("userdata"));

    this.setState({
      loading: true
    });

    let details = {
      // product_id:this.state.selected_products.product_id,
      user_id: userdata.user_id,
      test_id: test_id
     
    };
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/get_explaination", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "123");
        this.setState({explainations:responseJson,loading:false})
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  }

  render() {
    console.log(this.state, "satet");
    if (this.state.loading) {
      return (
        <div className="loadingSpin">
          <FulfillingBouncingCircleSpinner color="#FF6600" />
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <HomeHeader {...this.props} active="My Dashboard" />
          </div>

          <div style={{ width: "100%" }}>
            <div className="test-questions-with-answers-container p-5">
                {this.renderQuestionAnswer()}
             
              <hr />
              <div className="feedBtnMainDiv ml-5"></div>
            </div>
          </div>
          <div>
            <MainFooterPage {...this.props} />
          </div>
        </div>
      );
    }
  }
}

export default TestFeedback;
