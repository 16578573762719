import React, { Component } from "react";
import styles from "./Aboutus.module.css";
import "../commonStyles.css";
import swal from "sweetalert";
import { MDBCollapse, MDBIcon, MDBBtn, MDBInput } from "mdbreact";
import GridLoader from "react-spinners/GridLoader";
import HomeHeader from "../../Reusable/Header/homeHeader";
import MainFooterPage from "../../Reusable/Footer/footer";
import { css } from "@emotion/core";
import VisionImg from "../../Assets/images/Our_vision.jpg";
import MissionImg from "../../Assets/images/Our_mission.jpg";

import BannerImage from "../../Assets/images/10x10-About-Banner.jpg";

export default class Aboutus extends Component {
  state = {
    aboutus: [],
    loading: true,
    bannerImages: [],
  };

  componentWillMount() {
    this.getBannerImages();
    let language_id = localStorage.getItem("language_id")
      ? localStorage.getItem("language_id")
      : 0;
    console.log(language_id);
    this.setState({ language_id });
  }
  componentDidMount() {
    this.getAboutus();
  }

  getAboutus = () => {
    let details = {
      language_id: this.state.language_id,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/get_aboutLanguage", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "xxx");
          this.setState({ aboutus: responseJson[0], loading: false }, () =>
            console.log(this.state.aboutus)
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  getBannerImages = async () => {
    this.setState({
      response: fetch("https://10x10learning.com/get_about_banner_image", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "xxx");
          this.setState({ bannerImages: responseJson[0].link }, () =>
            console.log(this.state.bannerImages)
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  render() {
    console.log(this.state.selected_subject);

    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div className={`${styles.AboutUs}`} id="about-us">
          <HomeHeader {...this.props} />

          <div
            className={
              this.state.language_id == 0
                ? `${styles.AboutUsMain} opensansR`
                : `${styles.AboutUsMain} opensansR`
            }
          >
            <div className={`${styles.BannerImageDiv} `}>
              <img
                className={`${styles.BannerImage}`}
                src={this.state.bannerImages}
                alt="10x10-logo"
              />

              <span
                className={
                  this.state.language_id == 0
                    ? `${styles.BannerText} opensansR`
                    : `${styles.BannerText} opensansR`
                }
                dangerouslySetInnerHTML={{
                  __html: this.state.aboutus.banner_title,
                }}
              ></span>
            </div>

            <div className={`${styles.AboutUsDiscription}`}>
              <h3> {this.state.aboutus.main_title}</h3>

              <span
                className="opensansRLine"
                dangerouslySetInnerHTML={{
                  __html: this.state.aboutus.main_description,
                }}
              ></span>
            </div>

            {/* <div className={`${styles.OurVisionDiv}`}>
              <h3> {this.state.aboutus.vision_title}</h3>
              <div className={`${styles.OurVisionInnerDiv}`}>
                <img
                  className={`col-lg-6 ${styles.visionImage}`}
                  src={this.state.aboutus.vision_image}
                  alt="10x10-logo"
                />

                <div
                  className="col-lg-6 opensansRLine"
                  dangerouslySetInnerHTML={{
                    __html: this.state.aboutus.vision_description,
                  }}
                />
              </div>
            </div>

            <div className={`${styles.OurMissionDiv}`}>
              <h3> {this.state.aboutus.mission_title}</h3>

              <div className={`${styles.OurMissionInnerDiv}`}>
                <div
                  className="col-lg-6 opensansRLine"
                  dangerouslySetInnerHTML={{
                    __html: this.state.aboutus.mission_description,
                  }}
                />
                <img
                  className="col-lg-6"
                  src={this.state.aboutus.mission_image}
                  alt="10x10-logo"
                />
              </div>
            </div> */}
          </div>

          <div style={{ height: "auto" }}>
            <MainFooterPage {...this.props} />
          </div>
        </div>
      );
    }
  }
}
