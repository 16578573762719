import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../Sidebar/sidebarPage";
import "../AddSubject/AddSubject";
import Select from "react-select";
import {
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBInput,
} from "mdbreact";
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import swal from "sweetalert";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import CKEditor from "ckeditor4-react";
import Fade from "react-reveal";
const axios = require("axios").default;
// const topicsList=[];
// for(var i=1;i<=100;i++){
//     topicsList.push({id:i,name:`Topic ${i}`})
// }

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

class HomepageProf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadng: true,
      subjectId: ``,
      prof10: [],
      subjectDescriptions: [
        {
          id: `1`,
          description: "",
        },
      ],
    };
  }
  componentDidMount() {
    let item = JSON.parse(localStorage.getItem("prof1010"));
    this.setState({ prof10: item }, () => {
      console.log(JSON.parse(this.state.prof10.text));
      let setItem = JSON.parse(this.state.prof10.text);
      // this.state.subjectDescriptions.push({id:})
      // setItem.map(item=>this.)
      this.setState({ subjectDescriptions: setItem });
    });
  }

  addSubject() {
    if (this.state.subjectDescriptions.length === 0) {
      return swal("Check dialogue box", "Please do not leave blank", "error");
    } else if (this.state.subjectDescriptions[0].description === "") {
      return swal("Check dialogue box", "Please do not leave blank", "error");
    } else {
      axios
        .post("https://10x10learning.com/edit_prof10_other_service", {
          id: this.state.prof10.id,
          text: JSON.stringify(this.state.subjectDescriptions),
          language_id: this.state.prof10.language_id,
        })
        .then(function (response) {
          console.log(response);
          swal("Success", "Prof 10x10 dialogue updated", "success");
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    //   this.setState({
    //     response: fetch("https://10x10learning.com/edit_prof10x10_homepage", {
    //       method: "POST",
    //       body: formData,
    //     })
    //       .then((response) => response.json())
    //       .then((responseJson) => {
    //         if (responseJson.status == 200) {
    //           swal(
    //             "Success",
    //             "Prof 10x10 dialogue succesfullly updated",
    //             "success"
    //           );
    //           window.location.reload();
    //         } else {
    //           swal("Error", `Please try again`, "info").then(
    //             this.setState({ loading: false })
    //           );
    //         }
    //       })
    //       .catch((error) => {
    //         this.setState({
    //           loading: false,
    //         });
    //         swal("Warning!", "Check your network!", "warning");
    //         console.log(error);
    //       }),
    //   });
  }

  //HANDLE CLICK EVENTS
  addSubjectDescription() {
    let { subjectDescriptions } = this.state;
    subjectDescriptions.push({
      id: `${subjectDescriptions.length + 1}`,
      description: "",
    });
    this.setState({ subjectDescriptions });
  }

  deleteSubjectDescription(){
    let { subjectDescriptions } = this.state;
    subjectDescriptions.pop();
    this.setState({ subjectDescriptions });
  }
  removeSubjectDescription(index) {
    console.log(index);
    let { subjectDescriptions } = this.state;
    if (subjectDescriptions.length > 0) {
      subjectDescriptions.splice(index, 1);
      console.log(subjectDescriptions);
    }
    for (var i = 0; i < subjectDescriptions.length; i++) {
      subjectDescriptions[i].id = i + 1;
    }
    console.log(subjectDescriptions);
    this.setState({ subjectDescriptions });
  }

  handleSubjectDescription(item, index) {
    console.log(item.target.value);
    let subjectDescriptions = this.state.subjectDescriptions;
    let descriptionObject = subjectDescriptions[index];
    descriptionObject.description = item.target.value;
    this.setState({ subjectDescriptions });
    console.log(this.state.subjectDescriptions);
  }

  renderSubjectDescriptions() {
    return this.state.subjectDescriptions.map((item, index) => {
      return (
        <React.Fragment key={item.id}>
          <div className="d-flex align-items-start mt-3">
            <label className="p-1"> {index + 1}.&nbsp; </label>
            <div className="position-relative w-100 mr-4">
              <textarea
                label="subject-description"
                className="subject-description-textarea w-100"
                type="text"
                value={item.description}
                onChange={(text) => this.handleSubjectDescription(text, index)}
              />
            </div>
          </div>
          <div className="w-100" />
        </React.Fragment>
      );
    });
  }

  renderSubjectDescriptionContainer() {
    return (
      <React.Fragment>
        <div className="col-12 subject-description-container  d-flex align-items-center">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="subject-labels h-100"
          >
            Subject Description(s):
          </label>
          <div className="descriptions-container">
            {this.renderSubjectDescriptions()}
            <button
              className="admin-button-style ml-30px float-right mr-4 mb-3"
              onClick={() => this.addSubjectDescription()}
            >
              Add para
            </button>

            <button
              className="admin-button-style ml-30px float-right mr-4 mb-3"
              onClick={() => this.deleteSubjectDescription()}
            >
              Delete para
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={3} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <Fade>
                  <div className="row my-3 px-4">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                      <button
                        className="admin-button-style"
                        onClick={() =>
                          this.props.history.push(
                            "/limitedaccess/professor/other-service"
                          )
                        }
                      >
                        Professor Ten Ten Other Service
                      </button>
                      <button
                        className="admin-button-style"
                        onClick={() => this.addSubject()}
                      >
                        {" "}
                        Save &amp; Publish
                      </button>
                    </div>
                    <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                      <h3> Edit Professor Ten Ten Other service</h3>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="subject-content-container ml-4">
                      <div className="row px-0 mx-0">
                        {this.renderSubjectDescriptionContainer()}
                      </div>
                    </div>
                  </div>

                  <hr />

                  <button
                    className="add-subject my-5 float-right mr-4"
                    onClick={() => this.addSubject()}
                  >
                    Save &amp; Publish
                  </button>
                </Fade>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default HomepageProf;
