import React, { Component } from "react";
import styles from "../About Us/Aboutus.module.css";
import "../commonStyles.css";
import swal from "sweetalert";
import { MDBCollapse, MDBIcon, MDBBtn, MDBInput } from "mdbreact";
import GridLoader from "react-spinners/GridLoader";
import HomeHeader from "../../Reusable/Header/homeHeader";
import MainFooterPage from "../../Reusable/Footer/footer";
import { css } from "@emotion/core";
import VisionImg from "../../Assets/images/Our_vision.jpg";
import MissionImg from "../../Assets/images/Our_mission.jpg";
import BannerImage from "../../Assets/images/10x10-About-Banner.jpg";

export default class TermsOfPurchase extends Component {
  constructor() {
      super()
    this.state = {
      aboutus: [],
      loading: false,
      bannerImages: [],
      termsOfPurchase: `<p>Welcome, and thank you for your interest in 10x10learning.com, a website owned by 10x10 Learning Associates (&quot;10x10 Learning,&quot; &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;), which operates the website located at http://10x10learning.com (the &quot;Website&quot;) and related application programming interfaces, mobile applications and online services, and any other products and services that we may provide now or in the future (collectively, the &quot;10x10 Learning Products&quot;).&nbsp;</p>
      <p><br></p>
      <p>The following Terms of Service are a legal contract between you (&quot;you&quot; and &quot;your&quot;) and 10x10 Learning Associates regarding your use of the 10x10 Learning Products. Visitors and users of the 10x10 Learning Products are referred to individually as &quot;User&quot; and collectively as &quot;Users&quot;.</p>
      <p>These Terms of Use constitute an electronic record in accordance with the provisions of the Information Technology Act, 2000 and the Information Technology (Intermediaries guidelines) Rules, 2011 thereunder, as amended from time to time.</p>
      <p>PLEASE READ THE FOLLOWING TERMS OF SERVICE CAREFULLY. BY REGISTERING FOR, ACCESSING, BROWSING, OR USING THE WEBSITE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THE FOLLOWING TERMS AND CONDITIONS, INCLUDING THE 10x10 Learning Associates&rsquo; PRIVACY POLICY AND ANY ADDITIONAL GUIDELINES (AS DEFINED BELOW) (COLLECTIVELY, THE &quot;TERMS&quot;).</p>
      <p>IF YOU ARE A PARENT OR GUARDIAN AND YOU PROVIDE CONSENT FOR YOUR CHILD TO REGISTER WITH THE WEBSITE, YOU AGREE TO BE BOUND BY THESE TERMS OF SERVICE IN RESPECT OF SUCH CHILD&#39;S USE OF THE WEBSITE. IF YOU ARE SCHOOL PERSONNEL (AS DEFINED BELOW) AND YOU REGISTER A SCHOOL USER (AS DEFINED BELOW), YOU AGREE, ON BEHALF OF YOUR INSTITUTION, THAT THE SCHOOL USER IS BOUND BY THE TERMS, UNLESS YOUR INSTITUTION HAS A SEPARATE WRITTEN SERVICE AGREEMENT WITH 10x10 Learning Associates THAT ACCEPTS THESE TERMS ON BEHALF OF SCHOOL USERS.</p>
      <p>1. <strong>Eligibility; Accounts</strong>. THE WEBSITE IS NOT AVAILABLE TO (A) ANY USERS PREVIOUSLY SUSPENDED OR REMOVED FROM THE WEBSITE BY 10x10 Learning Associates OR (B) ANY PERSONS UNDER THE AGE OF 18 WHOSE REGISTRATION HAS NOT BEEN APPROVED BY A LEGAL PARENT OR GUARDIAN (OR, FOR SCHOOL ACCOUNTS, BY SCHOOL PERSONNEL AS PERMITTED UNDER THE TERMS).</p>
      <p>1.1 By clicking the &quot;I Agree&quot; button or by otherwise using or registering an account for the 10x10 Learning Products, you represent that (i) you are a resident of India who is at least 18 years of age, or (ii) you are not a resident of India, and are of legal age of consent to open an account under the laws of your country of residence, or (iii) your use of the 10x10 Learning Products has been approved by your parent or legal guardian, or by your school or teacher for school use. You also represent that you have not been previously suspended or removed from the 10x10 Learning Products by 10x10 Learning Associates, and that your registration and your use of the 10x10 Learning Products is in compliance with any and all applicable laws.</p>
      <p>1.2 Account. In order to use certain features of 10x10 Learning Products, you must register for an account. You may be asked to provide a password in connection with your account. You are solely responsible for maintaining the confidentiality of your account and password, and you agree to accept responsibility for all activities that occur under your account or password. You agree that the information you provide to 10x10 Learning Associates, whether at registration or at any other time, will be true, accurate, current, and complete. You also agree that you will ensure that this information is kept accurate and up-to-date at all times. You may be liable for the losses incurred by 10x10 Learning Associates or others due to any unauthorized use of your account with 10x10learning.com.</p>
      <p><br></p>
      <p>1.4 Child User. If you are under the age of 18 (a &quot;Child User&quot;), you may not create or register an account for the 10x10 Learning Products without consent and approval from your legal parent or guardian, or from your school as provided in Section 1.6 (&quot;School Use&quot;). Except for accounts created for School Use as provided in Section 1.6, a Child User that begins the registration process for himself or herself without a Parent User (as defined below) may have the registration process restricted until a parent approves or assumes responsibility for the Child User account. A Child User may use the 10x10 Learning Products if registered through certain educational organizations or, in certain cases, by School Personnel that have entered into a relationship directly with 10x10 Learning Associates, so long as the education organization has obtained parent or guardian consent, or has complied with an exemption to parent consent requirements under applicable law. A Child User will only be permitted to use the 10x10 Learning Products for so long as 10x10 Learning Associates reasonably believes that such access has been consented to by the Child User&#39;s parent or guardian, or by School Consent (as discussed in Section 1.6, &quot;School Use&quot;).</p>
      <p><br></p>
      <p>1.5 Parent User. If you are at least 18 years of age and you are the legal parent or guardian of a child that seeks to register as a Child User of the 10x10 Learning Products, you may register a parent account on the 10x10 Learning Products (&quot;Parent User&quot;). Through a Parent User account, you may create, register, manage and approve Child User accounts only for your own child(ren) or child(ren) for whom you are a legal guardian. IF YOU REGISTER, APPROVE OR CONSENT TO THE REGISTRATION OF, OR OTHERWISE ASSUME RESPONSIBILITY FOR ANY CHILD USER, YOU REPRESENT AND WARRANT THAT YOU ARE SUCH CHILD USER&#39;S LEGAL PARENT OR GUARDIAN AND YOU AGREE TO BE BOUND BY THE TERMS ON BEHALF OF SUCH CHILD USER, INCLUDING WITHOUT LIMITATION BEING LIABLE FOR ALL USE OF THE WEBSITE BY THE CHILD USER. You acknowledge that 10x10 Learning Associates may choose, but is not obligated, to make any inquiries, either directly or through third parties, that 10x10 Learning Associates deems necessary to validate your registration information, including without limitation engaging third parties to provide verification services. 10x10 Learning Associates reserves all rights to take legal actions against anyone who misrepresents personal information or is otherwise untruthful about their identity. NOTWITHSTANDING THE FOREGOING, YOU ACKNOWLEDGE THAT 10X10 LEARNING ASSOCIATES CANNOT GUARANTEE THE ACCURACY OF ANY INFORMATION SUBMITTED BY ANY USER AND 10X10 LEARNING ASSOCIATES IS NOT OBLIGATED TO VERIFY THE IDENTITY OF ANY USER, INCLUDING ANY USER&#39;S CLAIM TO BE A PARENT USER, OR TO VERIFY THAT A PARENT IDENTIFIED BY A CHILD USER OR OTHER PARTY DURING REGISTRATION IS SUCH CHILD USER&#39;S ACTUAL PARENT OR GUARDIAN. A Parent User account, along with all associated Child User accounts, may be terminated by 10x10 Learning Associates at any time and without warning for any failure to abide by these Terms.</p>
      <p><br></p>
      <p>1.6 School Use. 10x10 Learning Associates may make available certain features and tools that permit Users registered as teachers, school leaders, aides, or other similar school personnel (&quot;School Personnel&quot;) to work with students and other Users through the 10x10 Learning Products in order to provide such students with tutorial, educational and other education-related services as part of the school&#39;s curriculum, and to review and evaluate educational achievement and progress of such students. If you are School Personnel accessing the 10x10 Learning Products on behalf of a school, school district, or other similar educational institution (an &quot;Institution&quot;), the following terms apply to you:</p>
      <p>(a) Limitations on Use. The 10x10 Learning Products and Website are provided to you for educational purposes as part of the school curriculum. You must use the 10x10 Learning Products and the Website in compliance with all applicable laws, rules, and regulations. You agree not to reproduce, duplicate, copy, sell, resell or otherwise exploit for any commercial purpose, any portion of the 10x10 Learning Products, the Website, or access to the 10x10 Learning Products or Website.</p>
      <p>(b) Responsibility for Consent and Notices. You and/or the Institution assume sole responsibility for obtaining any consents required from parents or guardians, and for providing appropriate disclosures to School Users and their parents regarding the School Users&#39; use of the 10x10 Learning Products, our Terms, and our Privacy Policy. You agree to be bound by these Terms on behalf of the School User, for so long as the School User account is not transferred to or assumed by a valid Parent User account or personal account for a Student of eligible age. IF YOU ARE SCHOOL PERSONNEL AND YOU REGISTER AN ACCOUNT FOR A CHILD USER OR OTHER STUDENT (EITHER, A &quot;SCHOOL USER&quot;) OR DIRECT A SCHOOL USER TO CREATE AN ACCOUNT FOR SCHOOL USE, YOU REPRESENT AND WARRANT THAT YOU HAVE EITHER RECEIVED EXPRESS CONSENT FROM SUCH SCHOOL USER&#39;S PARENT OR LEGAL GUARDIAN OR THAT YOU (OR YOUR INSTITUTION) HAVE COMPLIED AND WILL COMPLY WITH ALL APPLICABLE REQUIREMENTS OF AN EXEMPTION FROM OR EXCEPTION TO PARENTAL CONSENT REQUIREMENTS, NECESSARY FOR YOU TO REGISTER THE SCHOOL USER FOR THE WEBSITE AND PROVIDE TO 10X10 LEARNING ASSOCIATES THE INFORMATION YOU DISCLOSE IN CONNECTION WITH THE REGISTRATION OF SUCH SCHOOL USER.&nbsp;</p>
      <p><br></p>
      <p>(c) Under no circumstances will 10x10 Learning Associates be liable for the School Personnel&#39;s failure to consult their school&#39;s authorities and administrators or for failing to obtain School Consent when required.</p>
      <p><br></p>
      <p><br></p>
      <p>2. <strong>Privacy Policy</strong>. Your privacy is important to 10x10 Learning Associates. Please read the 10x10 Learning Associates Privacy Policy, which is hereby incorporated into these Terms (and which are part of our contract with you), carefully for information relating to 10x10 Learning Associates&#39; collection, use, and disclosure of your personal information.&nbsp;</p>
      <p><br></p>
      <p>3. <strong>Modification of the Terms</strong>. Upon opening an account, you accept the Terms in the form posted on our website. 10x10 Learning Associates reserves the right, at our discretion, to change, modify, add, or remove portions of the Terms at any time. Please check the Terms periodically for changes that are made after you open your account. Your continued use of the 10x10 Learning Products after the posting of changes constitutes your binding acceptance of such changes.&nbsp;</p>
      <p><br></p>
      <p>4. <strong>Proprietary Materials; Licenses</strong>. The 10x10 Learning Products are owned and operated by 10x10 Learning Associates. The visual interfaces, graphics, design, compilation, information, computer code (including source code or object code), software, services, content, educational videos and exercises, and all other elements of the 10x10 Learning Products (the &quot;10x10 Learning Products Materials&quot;) are protected by applicable copyright, and trademark laws, and other applicable laws governing intellectual property and proprietary rights. All 10x10 Learning Products Materials, and all trademarks, service marks, and trade names, contained on or available through the 10x10 Learning Products are owned by or licensed to 10x10 Learning Associates, and 10x10 Learning Associates reserves all rights therein and thereto not expressly granted by these Terms.</p>
      <p><br></p>
      <p><br></p>
      <p>5. <strong>Prohibited Conduct.</strong></p>
      <p>YOU AGREE NOT TO:</p>
      <p>5.1 use the 10x10 Learning Products for any commercial use or purpose unless expressly permitted by 10x10 Learning Associates in writing, it being understood that the 10x10 Learning Products and related services are intended for personal, non-commercial use only;</p>
      <p>5.2 rent, lease, loan, sell, resell, sublicense, distribute or otherwise transfer the licenses for any 10x10 Learning Products Materials.</p>
      <p>6. <strong>Term and Termination.</strong></p>
      <p><br></p>
      <p>Term. These Terms shall remain in full force and effect while you use the 10x10 Learning Products unless your account is terminated as provided in these Terms, in which case you no longer have the right to use the 10x10 Learning Products.</p>
      <p><br></p>
      <p>Termination by 10x10 Learning Associates. 10x10 Learning Associates, in its sole discretion, for any or no reason, and without penalty, may suspend or terminate any account (or any part thereof) you may have with 10x10 Learning Associates or your use of the 10x10 Learning Products and remove and discard all or any part of your account, User profile, at any time. 10x10 Learning Associates may also in its sole discretion and at any time discontinue providing access to the 10x10 Learning Products, or any part thereof, with or without notice. You agree that any termination of your access to the 10x10 Learning Products or any account you may have, or portion thereof, may be affected without prior notice, and you agree that 10x10 Learning Associates will not be liable to you or any third party for any such termination. Any suspected fraudulent, abusive or illegal activity may be referred to appropriate law enforcement authorities. These remedies are in addition to any other remedies 10x10 Learning Associates may have at law or in equity. As discussed herein, 10x10 Learning Associates does not permit copyright, trademarks, or other intellectual property infringing activities on the 10x10 Learning Products, and will terminate access to the 10x10 Learning Products, by any Users who are found to be repeat infringers.</p>
      <p><br></p>
      <p>Termination by You. Your only remedy with respect to any dissatisfaction with (i) the 10x10 Learning Products, (ii) any term of these Terms of Service, (iii) Guidelines, (iv) any policy or practice of 10x10 Learning Associates in operating the 10x10 Learning Products, or (v) any content or information transmitted through the 10x10 Learning Products, is to terminate the Terms and your account. You may terminate these Terms at any time (prospectively only) by deleting your login account with the 10x10 Learning Products and discontinuing use of any and all parts of the 10x10 Learning Products.</p>
      <p><br></p>
      <p>Responsibility for Pre-Termination activity. Termination of the Terms as to any User account will not limit 10x10 Learning Associates&#39; rights and remedies regarding any breach of these Terms occurring prior to such termination.</p>
      <p><br></p>
      <p>7. <strong>Representations and Warranties</strong>. You warrant, represent and agree that you will comply with Applicable Laws in connection with your use of the Service; and if you are School Personnel, you understand that you are solely responsible for providing notices and obtaining consents required by Applicable Laws for students to use the 10x10 Learning Products.</p>
      <p><br></p>
      <p>8. <strong>Indemnification</strong>. You agree to indemnify, defend, and hold harmless 10x10 Learning Associates, and its parent, successors, affiliated companies, contractors, officers, directors, employees, agents and its third-party suppliers, licensors, and partners (&quot;10x10 Learning Associates Parties&quot;) from and against all losses, damages, liabilities, demands, judgments, settlements, costs and expenses of any kind (including legal fees and expenses), from any claim or demand made by any third-party relating to or arising out of (i) your access to, use or misuse of the 10x10 Learning Products; (ii) your breach or alleged breach of these Terms, or any violation of the Terms; (iii) any breach of the representations, warranties, and covenants made herein, whether by you or by any Child User or School User whose account you have approved as a Parent User or School Personnel; (iv) your failure to comply with Applicable Laws (including any failure to obtain or provide any necessary consent or notice); (v) the infringement by you or any third-party using your account of any intellectual property, privacy, or other right of any person or entity, or (vi) your breach or alleged breach of any interaction, agreement, or policy between you and any other Users. 10x10 Learning Associates reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify 10x10 Learning Associates, and you agree to cooperate with 10x10 Learning Associates&#39; defense of these claims. You agree not to settle any such matter without the prior written consent of 10x10 Learning Associates. 10x10 Learning Associates will use reasonable efforts to notify you of any such claim, action, or proceeding upon becoming aware of it.</p>
      <p><br></p>
      <p>9. <strong>Disclaimers; No Warranties</strong></p>
      <p>9.1 No Warranties. THE WEBSITE, AND ALL DATA, INFORMATION, SOFTWARE, WEBSITE MATERIALS, CONTENT (WHETHER OWNED OR LICENSED), REFERENCE SITES, SERVICES, OR APPLICATIONS MADE AVAILABLE IN CONJUNCTION WITH OR THROUGH THE WEBSITE, ARE PROVIDED ON AN &quot;AS IS,&quot; &quot;AS AVAILABLE,&quot; AND &quot;WITH ALL FAULTS&quot; BASIS. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, 10X10 LEARNING ASSOCIATES DISCLAIMS ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER STATUTORY, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ALL IMPLIED WARRANTIES OF MERCHANTABILITY, QUALITY, AVAILABILITY, QUIET ENJOYMENT, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM 10X10 LEARNING ASSOCIATES OR THROUGH THE WEBSITE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN.</p>
      <p><br></p>
      <p>9.2 Content. (a) The information contained in this Site is merely directional for general information purposes only. This Site is not a substitute for independent professional and educational guidance and WE recommend that you obtain appropriate professional and educational guidance relevant to your particular course. No person should act solely on the basis of these materials</p>
      <p><br></p>
      <p>(b) 10X10 LEARNING ASSOCIATES DOES NOT WARRANT THAT THE WEBSITE OR ANY DATA, FUNCTIONS, OR ANY OTHER INFORMATION OFFERED ON OR THROUGH THE WEBSITE WILL BE UNINTERRUPTED, OR FREE OF ERRORS, VIRUSES OR OTHER HARMFUL COMPONENTS, AND DO NOT WARRANT THAT ANY OF THE FOREGOING WILL BE CORRECTED. 10X10 LEARNING ASSOCIATES MAKES NO REPRESENTATION OR WARRANTY THAT (1) THE CONTENT ON THE WEBSITE WILL (A) MEET YOUR REQUIREMENTS OR EXPECTATIONS, OR BE TO YOUR LIKING, OR (B) WILL BE TIMELY, SECURE, ACCURATE, FREE FROM ERRORS OR LOSS, OR UNINTERRUPTED, (2) THAT THE SERVICES ARE FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS, OR (3) THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED. SOME FEATURES MAY BE NEW OR EXPERIMENTAL AND MAY NOT HAVE BEEN TESTED IN ANY MANNER.</p>
      <p><br></p>
      <p>9.3 Harm to Your Computer. YOU UNDERSTAND AND AGREE THAT YOUR USE, ACCESS, DOWNLOAD, OR OTHERWISE OBTAINING OF CONTENT, WEBSITE MATERIALS, SOFTWARE, OR DATA THROUGH THE WEBSITE IS AT YOUR OWN DISCRETION AND RISK, AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM) OR LOSS OF DATA THAT RESULTS THEREFROM.</p>
      <p><br></p>
      <p>10. <strong>Limitation of Liability and Damages.</strong></p>
      <p>10.1 Limitation of Liability. UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, WILL 10X10 LEARNING ASSOCIATES BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, RELIANCE, OR EXEMPLARY DAMAGES (INCLUDING WITHOUT LIMITATION DAMAGES ARISING FROM ANY UNSUCCESSFUL COURT ACTION OR LEGAL DISPUTE, LOST BUSINESS, LOST REVENUES OR PROFITS, LOSS OF DATA, OR ANY OTHER PECUNIARY OR NON-PECUNIARY LOSS OR DAMAGE OF ANY NATURE WHATSOEVER) EVEN IF 10X10 LEARNING ASSOCIATES HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES ARISING OUT OF OR RELATING (i) TO THE TERMS; (ii) YOUR USE OF (OR INABILITY TO USE) THE WEBSITE, OR (iii) ANY OTHER INTERACTIONS WITH 10X10 LEARNING ASSOCIATES OR ANY THIRD-PARTY THROUGH OR IN CONNECTION WITH THE WEBSITE, INCLUDING OTHER USERS.&nbsp;</p>
      <p><br></p>
      <p>10.2 Limitation of Damages. IN NO EVENT WILL 10X10 LEARNING ASSOCIATES&#39; TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION ARISING OUT OF OR RELATING TO THE TERMS OR YOUR USE OF THE WEBSITE OR YOUR INTERACTION WITH OTHER WEBSITE USERS (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), WARRANTY, OR OTHERWISE), EXCEED ONE THOUSAND RUPEES.</p>
      <p><br></p>
      <p>10.3 Basis of the Bargain. YOU ACKNOWLEDGE AND AGREE THAT 10X10 LEARNING ASSOCIATES HAS OFFERED THE WEBSITE AND ENTERED INTO THE TERMS IN RELIANCE UPON THE DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN, THAT THE DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU AND 10X10 LEARNING ASSOCIATES, AND THAT THE DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN YOU AND 10X10 LEARNING ASSOCIATES. 10X10 LEARNING ASSOCIATES WOULD NOT BE ABLE TO PROVIDE THE WEBSITE TO YOU ON AN ECONOMICALLY REASONABLE BASIS WITHOUT THESE LIMITATIONS.</p>
      <p><br></p>
      <p>11. <strong>Miscellaneous (Including Dispute Resolution and Arbitration).</strong></p>
      <p>11.1 Notice. 10x10 Learning Associates may provide you with notices, including those regarding changes to the Terms, by email, regular mail, postings on the 10x10 Learning Products, or other reasonable means. Notice will be deemed given twenty-four hours after email is sent, unless 10x10 Learning Associates is notified that the email address is invalid. Alternatively, we may give you legal notice by mail to a postal address, if provided by you through the 10x10 Learning Products. In such case, notice will be deemed given three days after the date of mailing.&nbsp;</p>
      <p><br></p>
      <p>11.2 Waiver. The failure of 10x10 Learning Associates to exercise or enforce any right or provision of the Terms will not constitute a waiver of such right or provision. Any waiver of any provision of the Terms will be effective only if in writing and signed by 10x10 Learning Associates.</p>
      <p><br></p>
      <p>11.3 Governing Law. These terms and conditions shall be governed by and construed in accordance with the Indian Laws. Any dispute arising under these terms and conditions shall be subject to the exclusive jurisdiction of the courts of India</p>
      <p><br></p>
      <p>11.5 Severability. If any provision of the Terms or any Guidelines is held to be unlawful, void, or for any reason unenforceable, then that provision will be limited or eliminated from the Terms to the minimum extent necessary and will not affect the validity and enforceability of any remaining provisions.</p>
      <p><br></p>
      <p>11.7 Survival. Upon termination of the Terms, any provision which, by its nature or express terms should survive, will survive such termination or expiration, including, but not limited to, Clauses 2, 8, 9, 10 and 11.</p>
      <p><br></p>
      <p>11.8 Headings. The heading references herein are for convenience purposes only, do not constitute a part of the Terms, and will not be deemed to limit or affect any of the provisions hereof.</p>
      <p><br></p>
      <p>11.9 Entire Agreement. The Terms, the Privacy Policy and Guidelines constitute the entire agreement between you and 10x10 Learning Associates relating to the subject matter herein and will not be modified except in writing, signed by both parties, or by a change to the Terms, Privacy Policy or Guidelines made by 10x10 Learning Associates as set forth in Section 4 above.</p>
      <p class="text-center">&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;.</p>
      <p class="text-center">10x10 LEARNING PRIVACY POLICY</p>
      <p>10x10 Learning Associates operates the website www.10x10learning.com. This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of our products. We use your Personal Information only for providing a better experience to the user and improving the website. By accessing this website, you agree to the collection and use of information in accordance with this policy.</p>
      <p>By using the Site, you consent to the collection and use of your personal information as described in this Privacy Policy.&nbsp;</p>
      <p><strong>Information Collection and Use</strong></p>
      <p>While using our website, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may be included, but is not limited to your Name, Email Address, Phone Numbers &amp; Address and Billing Information, such as credit card number and billing address through secure payment gateway providers. We do not store any card information (other than the last 4 digits of your card) in our servers.</p>
      <p><strong>Children&#39;s Personal Information</strong></p>
      <p>10x10learning.com does not knowingly collect any personal information from children under the age of 18. If you are under the age of 18, please do not submit any personal information through our Site or service(s). We encourage parents and legal guardians to monitor their children&rsquo;s Internet usage and to help enforce this Policy by instructing their children never to provide personal information through our Site without their permission. If you have reason to believe that a child under the age of 18 has provided personal information to us through our Site, please contact us and we will endeavour to delete that information and terminate the child&#39;s account from our databases.</p>
      <p><strong>Log Data and Analytics</strong></p>
      <p>Like most website operators, 10x10learning.com collects information that your browser sends whenever you visit our website. This Log Data may include information such as your computer&#39;s Internet Protocol (IP) address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics. In addition, we may use third party services such as Google Analytics that collect, monitor and analyse this information. These services use Data Collection Tools to help us analyse your use of the Site and services, including information like the third-party website you arrive from, how often you visit, events within the services, usage and performance data, and where the application was downloaded from. We use this data to improve the services, better understand how the services perform on different devices, and provide information that may be of interest to you.</p>
      <p><strong>Communications&nbsp;</strong></p>
      <p>We may use your Personal Information to contact you through emails, SMS, newsletters and telephonic support. This may be used to share marketing or promotional materials and other product related information such as new feature announcement etc.&nbsp;</p>
      <p><strong>Single Sign-On &amp; Other social Media Features</strong></p>
      <p>Our Site includes social media features, such as the Facebook &ldquo;Like&rdquo; button, the &ldquo;Share This&rdquo; button or interactive mini-programs. Where we have your consent, these features may collect your IP address, which page you are visiting on our Site, and may set a cookie to enable the feature to function properly. Social media features and widgets are either hosted by a third party or hosted directly on our Websites. Your interactions with these features are governed by the privacy policy of the company providing them. You can log in to our Websites using sign-in services by Facebook/LinkedIn/Gmail. The services will authenticate your identity and provide you the option to share certain personal information with us such as your name and email address.</p>
      <p><br></p>
      <p><br></p>
      <p><strong>Online Advertising</strong></p>
      <p>We use third-party advertising services like Facebook, Google&rsquo;s ad services, and other ad networks and ad servers to deliver advertising about our services on other websites and applications you use. The ads may be based on things we know about you, like your usage data and system data, and things that these ad service providers know about you based on their tracking data. The ads can be based on your recent activity or activity over time and across other sites and services, and may be tailored to your interests.</p>
      <p>Depending on the types of advertising services we use, they may place cookies or other tracking technologies on your computer, phone, or other device to collect data about your use of our services, and may access those tracking technologies in order to serve these tailored advertisements to you. To help deliver tailored advertising, we may provide these service providers with a hashed, anonymized version of your email address (in a non-human-readable form) and content that you share publicly on the Site.</p>
      <p>When using mobile applications you may also receive tailored in-app advertisements. Apple iOS, Android OS, and Microsoft Windows each provide their own instructions on how to control in-app tailored advertising. For other devices and operating systems, you should review your privacy settings or contact your platform operator.</p>
      <p><strong>Cookies</strong></p>
      <p>Cookies are small text files stored by your browser as you browse the internet. They can be used to collect, store, and share data about your activities across websites, including on the Site. Cookies also allow us to remember things about your visits to the Site, like your preferred language, and to make the site easier to use.</p>
      <p>We use both session cookies, which expire after a short time or when you close your browser, and persistent cookies, which remain stored in your browser for a set period of time. We use session cookies to identify you during a single browsing session, like when you log into the Site. We use persistent cookies where we need to identify you over a longer period, like when you request that we keep you signed in.</p>
      <p>Most browsers automatically accept cookies, but you can change your browser settings to decline cookies by consulting your browser&rsquo;s support articles. If you decide to decline cookies, please note that you may not be able to sign in, customize, or use some interactive features in the services</p>
      <p><strong>Security</strong></p>
      <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security. Your password is an important part of our security system, and it is your responsibility to protect it. You should not share your password with any third party, and if you believe your password or account has been compromised, you should change it immediately and contact helpdesk@10x10learning.com with any concerns.</p>
      <p><strong>Changes to this Privacy Policy</strong></p>
      <p>This Privacy Policy is effective as of 01st October 2020 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page. We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy. If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.</p>
      <p><strong>&nbsp;Contact Us</strong></p>
      <p>If you have any questions about this Privacy Policy, please contact us at helpdesk@10x10learning.com.&nbsp;</p>
      <p><br></p>`,
    };
  }

  componentDidMount() {}

  componentWillMount() {
    // this.getBannerImages();
    let language_id = localStorage.getItem("language_id")
      ? localStorage.getItem("language_id")
      : 0;
    console.log(language_id);
    this.setState({ language_id });
  }

  render() {
    console.log(this.state.selected_subject);

    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div className={`${styles.AboutUs}`} id="about-us">
          <HomeHeader {...this.props} />

          <div
            className={
              this.state.language_id == 0
                ? `${styles.AboutUsMain} opensansR`
                : `${styles.AboutUsMain} opensansR`
            }
          >
            <h1 className="text-center mt-4">
              10x10learning.com Terms of Purchase
            </h1>
            <div
              className={`${styles.AboutUsDiscription} mt-2`}
              dangerouslySetInnerHTML={{
                __html: `${this.state.termsOfPurchase}`,
              }}
            />
          </div>
          <div style={{ height: "auto" }}>
            <MainFooterPage {...this.props} />
          </div>
        </div>
      );
    }
  }
}
