import React from 'react';

export default class Template extends React.Component {
    render() {
        return (
            
            <div style={{position: "absolute", top: "10px", width:'100%', margin:'0 10px', padding: '0 10px' }}>
            <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="logoDiv">
              <img
                className="logo"
                style={{ width: 170 }}
                src={require("../../Assets/images/Logo.png")}
              />
            </div>
            <div
              style={{
                padding: 5,
                marginLeft: 0,
                fontSize:'14px',
                letterSpacing: "-0.8px",
                textAlign: "right",
              }}
            >
              <strong style={{ fontSize: 16 }}>
                10x10 Learning and Associates
              </strong>
              <br />
              <strong>Flat 603, Tower 5,</strong>
              <br />
              <strong>The Palms Apartments</strong>
              <br />
              <strong>South City I, Sector 30 </strong>
              <br />
              <strong>Gurgaon, Haryana, 122007</strong>
              <br />
              <strong>Web: www.10x10learning.com</strong>
              <br />
              <strong>GSTIN: 06AACFZ4679E1ZW</strong>
              <br />
              <strong>PAN: AACFZ4679E</strong>
              <br />
            </div>
          </div>
          </div>
        );
    }
}