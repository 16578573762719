import React from "react";

import "./sidebar.css";

import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBInput,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavLink,
  MDBIcon,
  MDBNavItem,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

class SidebarPage extends React.Component {
  constructor(props) {
    super(props);

    //
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: "rgb(48,51,54)",
          minHeight: "100vh",
          width: "15%",
        }}
      >
        <h4
          style={{
            fontSize: "15px",
            backgroundColor: "rgb(74, 62, 36)",
            textAlign: "center",
            padding: "5% 0% 5% 0%",
          }}
        >
          {" "}
          <a
            style={{ color: "#fff" }}
            href="https://10x10learning.com/"
            target="_blank"
          >
            Open Website
          </a>
        </h4>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/limitedaccess/dashboard")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon far icon="list-alt" />
              </div>
              <div className="sidebar_dropdown_name">Dashboard</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="tools" />
              </div>
              <div className="sidebar_dropdown_name">Homepage</div>
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="sidebar_dropdown_Menu_Item">
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() => this.props.history.push("/limitedaccess/about-us")}
            >
              About-us
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/banner-images")
              }
            >
              Banner Images
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/latest-updates")
              }
            >
              Latest Updates
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/important-dates")
              }
            >
              Important Dates
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/historical-dates")
              }
            >
              Historic Dates
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="tools" />
              </div>
              <div className="sidebar_dropdown_name">Professor Ten Ten</div>
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="sidebar_dropdown_Menu_Item">
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/professor/homepage")
              }
            >
              Homepage
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/professor/dashboard")
              }
            >
              Dashboard page
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push(
                  "/limitedaccess/professor/other-service"
                )
              }
            >
              Other services
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/professor/products")
              }
            >
              Products page
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/professor/lessons")
              }
            >
              Lessons page
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/professor/test")
              }
            >
              Test page
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push(
                  "/limitedaccess/professor/other-service-subcategory"
                )
              }
            >
              Other services Subcategory
            </MDBDropdownItem>

            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/professor/mycourses")
              }
            >
              My Courses
            </MDBDropdownItem>

            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/professor/mycart")
              }
            >
              My Cart
            </MDBDropdownItem>

            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/professor/feedback")
              }
            >
              Test Feedback
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>

        {/* 
   <MDBDropdown dropright  className='sidebar_dropdown'    >
      <MDBDropdownToggle onClick={()=>this.props.history.push('/limitedaccess/verticals')}  color="gray" className='sidebar_dropdown_btn' style={{ color: '#fff' }}  >
      
      <div className='sidebar_dropdown_name_icon_main'> <div  className='sidebar_dropdown_icon'><MDBIcon icon="align-center" /></div>
        <div className='sidebar_dropdown_name' >Verticals </div></div>
 
      </MDBDropdownToggle>
      
    </MDBDropdown>  */}

        {/* <MDBDropdown dropright   className='sidebar_dropdown' style={{width:'100%'}}  >
      <MDBDropdownToggle  color="gray" className='sidebar_dropdown_btn' style={{ color: '#fff' }}  >
      
      <div className='sidebar_dropdown_name_icon_main'> <div  className='sidebar_dropdown_icon'><MDBIcon icon="align-justify" /></div>
        <div className='sidebar_dropdown_name' >Exams</div></div>
 
      
      </MDBDropdownToggle>
      <MDBDropdownMenu  className='sidebar_dropdown_Menu_Item'>
 

      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/limitedaccess/all-exams')} >All Exams</MDBDropdownItem>
      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/limitedaccess/add-exam')} >Add Exams</MDBDropdownItem>
     
     
     </MDBDropdownMenu>
    </MDBDropdown> */}

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="anchor" />
              </div>
              <div className="sidebar_dropdown_name">Lectures</div>
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="sidebar_dropdown_Menu_Item">
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() => this.props.history.push("/limitedaccess/lecture")}
            >
              All Lectures
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/add-lecture")
              }
            >
              Add Lectures
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="bookmark" />
              </div>
              <div className="sidebar_dropdown_name">Topics</div>
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="sidebar_dropdown_Menu_Item">
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/all-topics")
              }
            >
              All Topic
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/add-topic")
              }
            >
              Add Topic
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="book" />
              </div>
              <div className="sidebar_dropdown_name">Subjects</div>
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="sidebar_dropdown_Menu_Item">
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/subject-list")
              }
            >
              All Subject
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/add-subject")
              }
            >
              Add Subject
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="award" />
              </div>
              <div className="sidebar_dropdown_name">Test / Practice</div>
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="sidebar_dropdown_Menu_Item">
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() => this.props.history.push("/limitedaccess/test")}
            >
              All Test / Practice
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() => this.props.history.push("/limitedaccess/add-test")}
            >
              Add Test / Practice
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>

        {/*   
   


  



    <MDBDropdown dropright   className='sidebar_dropdown' style={{width:'100%'}}  >
      <MDBDropdownToggle  color="gray" className='sidebar_dropdown_btn' style={{ color: '#fff' }}  >
      
      <div className='sidebar_dropdown_name_icon_main'> <div  className='sidebar_dropdown_icon'><MDBIcon icon="book-open" /></div>
        <div className='sidebar_dropdown_name' >Examinations</div></div>
 
      
      </MDBDropdownToggle>
      <MDBDropdownMenu  className='sidebar_dropdown_Menu_Item'>
 

      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/limitedaccess/examinations')} >All Examinations</MDBDropdownItem>
      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/limitedaccess/add-examination')} >Add Examinations</MDBDropdownItem>
     
     
     </MDBDropdownMenu>
    </MDBDropdown>
      <MDBDropdownItem className='sidebar_dropdown_Item'   onClick={()=>this.props.history.push('/limitedaccess/content')} >Main Content</MDBDropdownItem>


  
     */}

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="question" />
              </div>
              <div className="sidebar_dropdown_name">Questions</div>
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="sidebar_dropdown_Menu_Item">
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/all-questions")
              }
            >
              All Questions
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/add-question")
              }
            >
              Add Questions
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>

        <MDBDropdown dropright className="sidebar_dropdown">
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/limitedaccess/Order")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="archive" />
              </div>
              <div className="sidebar_dropdown_name">Orders</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown dropright className="sidebar_dropdown">
          <MDBDropdownToggle
            onClick={() =>
              this.props.history.push("/limitedaccess/users-with-other-service")
            }
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="archive" />
              </div>
              <div className="sidebar_dropdown_name">
                Orders(Other services)
              </div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown dropright className="sidebar_dropdown">
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/limitedaccess/evaluation")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="check-square" />
              </div>
              <div className="sidebar_dropdown_name">Evaluation</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="barcode" />
              </div>
              <div className="sidebar_dropdown_name">PromoCode</div>
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="sidebar_dropdown_Menu_Item">
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/promocode")
              }
            >
              All Promocode
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/add-promocode")
              }
            >
              Add Promocode
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
        {/* <MDBDropdown dropright   className='sidebar_dropdown' style={{width:'100%'}}  >
      <MDBDropdownToggle  color="gray" className='sidebar_dropdown_btn' style={{ color: '#fff' }}  >
      
      <div className='sidebar_dropdown_name_icon_main'> <div  className='sidebar_dropdown_icon'><MDBIcon icon="star" /></div>
        <div className='sidebar_dropdown_name' onClick={()=>this.props.history.push('/limitedaccess/ratings')}>Ratings</div></div>
 
      
      </MDBDropdownToggle>
      <MDBDropdownMenu  className='sidebar_dropdown_Menu_Item'>
 

     
     
     </MDBDropdownMenu>
    </MDBDropdown> */}

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="barcode" />
              </div>
              <div className="sidebar_dropdown_name">Verticals</div>
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="sidebar_dropdown_Menu_Item">
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() => this.props.history.push("/limitedaccess/vertical")}
            >
              All Verticals
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/add-vertical")
              }
            >
              Add Verticals
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="barcode" />
              </div>
              <div className="sidebar_dropdown_name">Other-services</div>
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="sidebar_dropdown_Menu_Item">
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push(
                  "/limitedaccess/other-service/categories"
                )
              }
            >
              All Categories
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push(
                  "/limitedaccess/other-service/add-category"
                )
              }
            >
              Add Categories
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/all-subcategories")
              }
            >
              All Services
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() =>
                this.props.history.push("/limitedaccess/add-subcategories")
              }
            >
              {" "}
              Add Services
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="hand-holding-usd" />
              </div>
              <div className="sidebar_dropdown_name">Tax</div>
            </div>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="sidebar_dropdown_Menu_Item">
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() => this.props.history.push("/limitedaccess/all-tax")}
            >
              All Tax
            </MDBDropdownItem>
            <MDBDropdownItem
              className="sidebar_dropdown_Item"
              onClick={() => this.props.history.push("/limitedaccess/add-tax")}
            >
              Add Tax
            </MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>

        {/* <MDBDropdown dropright   className='sidebar_dropdown' style={{width:'100%'}}  >
      <MDBDropdownToggle  color="gray" className='sidebar_dropdown_btn' style={{ color: '#fff' }}  >
      
      <div className='sidebar_dropdown_name_icon_main'> <div  className='sidebar_dropdown_icon'><MDBIcon icon="question-circle" /></div>
        <div className='sidebar_dropdown_name' >Quiz</div></div>
 
      
      </MDBDropdownToggle>
      <MDBDropdownMenu  className='sidebar_dropdown_Menu_Item'>
 

      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/limitedaccess/quiz')} >All</MDBDropdownItem>
      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/limitedaccess/add-quiz')} >Add Quiz</MDBDropdownItem>
     
     
     </MDBDropdownMenu>
    </MDBDropdown>
  
      <MDBDropdown dropright   className='sidebar_dropdown' style={{width:'100%'}}  >
      <MDBDropdownToggle onClick={()=>this.props.history.push('/limitedaccess/all-downloads')}  color="gray" className='sidebar_dropdown_btn' style={{ color: '#fff' }}  >
      
      <div className='sidebar_dropdown_name_icon_main'> <div  className='sidebar_dropdown_icon'><MDBIcon  icon="arrow-circle-down" /></div>
        <div className='sidebar_dropdown_name' >Downloads</div></div>
 
      
      </MDBDropdownToggle>
      
    </MDBDropdown>
    <MDBDropdown dropright   className='sidebar_dropdown' style={{width:'100%'}}  >
      <MDBDropdownToggle  color="gray" className='sidebar_dropdown_btn' style={{ color: '#fff' }}  >
      
      <div className='sidebar_dropdown_name_icon_main'> <div  className='sidebar_dropdown_icon'><MDBIcon  icon="receipt" /></div>
        <div className='sidebar_dropdown_name' >FAQ</div></div>
 
      
      </MDBDropdownToggle>
    
      <MDBDropdownMenu  className='sidebar_dropdown_Menu_Item'>
 

      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/limitedaccess/all-faqs')} >All</MDBDropdownItem>
      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/limitedaccess/add-faqs')} >Add FAQ</MDBDropdownItem>
     
     
     </MDBDropdownMenu>
    </MDBDropdown> */}

        {/* <MDBDropdown dropright   className='sidebar_dropdown' style={{width:'100%'}}  >
      <MDBDropdownToggle  onClick={()=>this.props.history.push('/limitedaccess/pages')} color="gray" className='sidebar_dropdown_btn' style={{ color: '#fff' }}  >
      
      <div className='sidebar_dropdown_name_icon_main'> <div  className='sidebar_dropdown_icon'><MDBIcon icon="file" /></div>
        <div className='sidebar_dropdown_name' >Pages</div></div>
 
      
      </MDBDropdownToggle>
     
    </MDBDropdown> */}

        <MDBDropdown dropright className="sidebar_dropdown">
          <MDBDropdownToggle
            onClick={() =>
              this.props.history.push("/limitedaccess/user-details")
            }
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="user-alt" />
              </div>
              <div className="sidebar_dropdown_name">User Detail </div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown dropright className="sidebar_dropdown">
          <MDBDropdownToggle
            onClick={() =>
              this.props.history.push("/limitedaccess/login-report")
            }
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="user-alt" />
              </div>
              <div className="sidebar_dropdown_name">Login report </div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown dropright className="sidebar_dropdown">
          <MDBDropdownToggle
            onClick={() =>
              this.props.history.push("/limitedaccess/privacy-policy")
            }
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="archive" />
              </div>
              <div className="sidebar_dropdown_name">Privacy Policy</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>
        <MDBDropdown dropright className="sidebar_dropdown">
          <MDBDropdownToggle
            onClick={() =>
              this.props.history.push("/limitedaccess/cookie-policy")
            }
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="archive" />
              </div>
              <div className="sidebar_dropdown_name">Cookie Policy</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        {/* <MDBDropdown dropright   className='sidebar_dropdown' style={{width:'100%'}}  >
      <MDBDropdownToggle onClick={()=>this.props.history.push('/limitedaccess/menu')}  color="gray" className='sidebar_dropdown_btn' style={{ color: '#fff' }}  >
      
      <div className='sidebar_dropdown_name_icon_main'> <div  className='sidebar_dropdown_icon'><MDBIcon far icon="caret-square-down" /></div>
        <div className='sidebar_dropdown_name' >Menu</div></div>
 
      
      </MDBDropdownToggle>
      
    </MDBDropdown> */}
      </div>
    );
  }
}

export default SidebarPage;
