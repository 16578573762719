import React, { Component } from "react";
import SidebarPage from "../Sidebar/sidebarPage";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBTable,
  MDBTableBody,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBContainer,
  MDBInput,
} from "mdbreact";
import "../Lectures/Addlecture.css";
import { css } from "@emotion/core";
//import {MDBInput,MDBTable,MDBTableBody,MDBBtn, MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter  } from "mdbreact";
import GridLoader from "react-spinners/GridLoader";
import AdminNavbar from "../Navbar/adminNavbar";
import "../UserDetail/userDetail.css";
import swal from "sweetalert";
import { FormGroup, Label, Input } from "reactstrap";
import { HalfCircleSpinner } from "react-epic-spinners";
import ActivePNG from "../../../Assets/images/activestatus.png";
import InactivePNG from "../../../Assets/images/inactivestatus.png";
import Select from "react-select";

const axios = require("axios").default;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 20% 45%;
`;
class Lecture extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: false,
      searchTerm: "",
      lecturesSearch: [],
      loading: false,
      showAction: false,
      englishAboveBanner: "",
      hindiAboveBanner: "",
      englishBelowBanner: "",
      hindiBelowBanner: "",
      draft_count: 0,
      published_count: 0,
      trash_count: 0,
      all_selected: true,
      lectures: [],
      draft_selected: false,
      published_selected: false,
      trash_selected: false,
      selected_data: [],
      options: [
        { value: "-1", label: "All" },
        { value: "1", label: "English" },
        { value: "0", label: "Hindi" },
      ],
      selectedLanguage: [{ value: "-1", label: "All" }],
    };
  }
  componentWillMount() {
    this.setState({ loading: true });
    this.getBanners();
  }
  getBanners = async () => {
    this.setState({
      // response: fetch("http://localhost:8000/get_lectures", {
      response: fetch("https://10x10learning.com/get_banner", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var draft_count = 0,
            published_count = 0,
            trash_count = 0;
          for (var v = 0; v < responseJson.length; v++) {
            responseJson[v].selected = 0;
            if (responseJson[v].status == 0) {
              draft_count = draft_count + 1;
            } else if (responseJson[v].status == 1) {
              published_count = published_count + 1;
            } else if (responseJson[v].status == 2) {
              trash_count = trash_count + 1;
            }
          }

          this.setState({
            lectures: responseJson,
            draft_count: draft_count,
            published_count: published_count,
            trash_count: trash_count,
            loading: false,
          },()=>this.getContent());
        })
        .catch((error) => {
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
          console.log(error);
        }),
    });
  };

  componentDidMount() {
    window.localStorage.removeItem("subject_item");
  }

  getContent = async () => {
    this.setState({
      // response: fetch("http://localhost:8000/get_lectures", {
      response: fetch("https://10x10learning.com/getBannerContent", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          let results=JSON.parse(responseJson[0].content)
          console.log(results)
          this.setState({
            loading: false,
            englishAboveBanner:results.englishAbove,
            englishBelowBanner:results.englishBelow,
            hindiAboveBanner:results.hindiAbove,
            hindiBelowBanner:results.hindiBelow
          });
        })
        .catch((error) => {
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  handleChangeAddName = (e) => {
    this.setState({ name: e.target.value });
  };
  handleChangeAddLink = (e) => {
    this.setState({ website_link: e.target.value });
  };

  handleChangeAddLogo = (e) => {
    this.setState({ logo: e.target.files[0] });
  };

  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Novr",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd + "/" + mm + "/" + dt.getFullYear().toString();
  }

  commenttime(timestamp) {
    var convertdLocalTime = new Date(timestamp);
    var hours = convertdLocalTime.getHours();
    var minutes = convertdLocalTime.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  handleEditlecture(item) {
    window.localStorage.setItem("banner_item", JSON.stringify(item));

    this.props.history.push("/limitedaccess/edit-banner");
  }

  handleAddNewSubject() {
    this.props.history.push("/limitedaccess/add-banner");
  }

  renderActions(item, index) {
    if (item.selected == 1) {
      if (this.state.trash_selected) {
        return (
          <div style={{ width: "15%" }} className="actions_div">
            {" "}
            <span
              onClick={() => this.restoreAlert(item)}
              className="actionstext"
            >
              Restore
            </span>
            |{" "}
            <span
              onClick={() => this.deleteAlert(item)}
              className="actionstext"
              style={{ color: "#a00" }}
            >
              Delete
            </span>
          </div>
        );
      } else {
        return (
          <div className="actions_div">
            <span
              onClick={() => this.handleEditlecture(item, index)}
              className="actionstext"
            >
              Edit
            </span>{" "}
            {/* <span
              onClick={() => this.trashAlert(item)}
              className="actionstext"
              style={{ color: "#a00" }}
            >
              Trash
            </span>{" "} */}
          </div>
        );
      }
    } else {
      return (
        <div className="actions_div" style={{ visibility: "hidden" }}>
          <span className="actionstext">Edit</span>{" "}
          {/* <span className="actionstext" style={{ color: "#a00" }}>
            Trash
          </span>{" "} */}
        </div>
      );
    }
  }
  showactionbutton(item, index) {
    let { lectures } = this.state;
    let selected_page = lectures[index];
    for (var v = 0; v < lectures.length; v++) {
      lectures[v].selected = 0;
    }
    selected_page.selected = 1;
    this.setState({
      lectures,
    });
  }
  hideactionbutton() {
    let { lectures } = this.state;

    for (var v = 0; v < lectures.length; v++) {
      lectures[v].selected = 0;
    }

    this.setState({
      lectures,
    });
  }
  renderStatus(item) {
    if (item.status == 0) {
      return "Last Modified";
    } else {
      return "Published";
    }
  }
  trashAlert = (item) => {
    swal({
      title: "Are you sure?",
      text: "You want to move this page to trash",
      buttons: true,
      dangerMode: true,
    }).then((willAdd) => {
      if (willAdd) {
        this.trashPage(item);
      } else return;
    });
  };
  deleteAlert = (item) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this page",
      buttons: true,
      dangerMode: true,
    }).then((willAdd) => {
      if (willAdd) {
        this.deletePage(item);
      } else return;
    });
  };
  restoreAlert = (item) => {
    swal({
      title: "Are you sure?",
      text: "You want to restore this page",
      buttons: true,
      dangerMode: true,
    }).then((willAdd) => {
      if (willAdd) {
        this.restorePage(item);
      } else return;
    });
  };
  trashPage(item) {
    this.setState({
      loading: true,
    });
    let details = {
      id: item.subject_id,
    };
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/trash_content", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          window.location.reload();
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }

  changeStatus(item, status) {
    let details = {
      id: item.id,
      status: status,
    };
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/edit_lecture", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          window.location.reload();
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }
  restorePage(item) {
    let details = {
      id: item.id,
    };
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/restore_content", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          window.location.reload();
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }

  renderActiveStatus(item) {
    if (item.status == 1) {
      return (
        <img
          src={ActivePNG}
          alt="Course is active"
          className="course-status-icon"
          onClick={() => this.changeStatus(item, 0)}
        />
      );
    } else {
      return (
        <img
          src={InactivePNG}
          alt="Course is INactive"
          className="course-status-icon"
          onClick={() => this.changeStatus(item, 1)}
        />
      );
    }
  }

  renderTableData(item, index) {
    if (item.language_id != this.state.selectedLanguage.value) {
      if (this.state.all_selected == true) {
        if (item.status != 2) {
          return (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td style={{ width: "25%" }} className="tdName textFontSize">
                <div
                  onMouseEnter={() => this.showactionbutton(item, index)}
                  onMouseLeave={() => this.hideactionbutton()}
                  className="name_div_table"
                >
                  <span
                    style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}
                    onClick={() => this.handleEditlecture(item, index)}
                  >
                    {item.link}
                  </span>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
          );
        }
      } else if (this.state.published_selected == true) {
        if (item.status == 1) {
          return (
            <tr key={item.subject_id}>
              <td>{index + 1}</td>
              <td style={{ width: "25%" }} className="tdName textFontSize">
                <div
                  onMouseEnter={() => this.showactionbutton(item, index)}
                  onMouseLeave={() => this.hideactionbutton()}
                  className="name_div_table"
                >
                  <span
                    style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}
                    onClick={() => this.handleEditlecture(item, index)}
                  >
                    {item.link}
                  </span>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
          );
        }
      } else if (this.state.draft_selected == true) {
        if (item.status == 0) {
          return (
            <tr key={item.subject_id}>
              <td>{index + 1}</td>
              <td style={{ width: "25%" }} className="tdName textFontSize">
                <div
                  onMouseEnter={() => this.showactionbutton(item, index)}
                  onMouseLeave={() => this.hideactionbutton()}
                  className="name_div_table"
                >
                  <span
                    style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}
                    onClick={() => this.handleEditlecture(item, index)}
                  >
                    {item.link}
                  </span>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
          );
        }
      } else if (this.state.trash_selected == true) {
        if (item.status == 2) {
          return (
            <tr key={item.subject_id}>
              <td>{index + 1}</td>
              <td style={{ width: "25%" }} className="tdName textFontSize">
                <div
                  onMouseEnter={() => this.showactionbutton(item, index)}
                  onMouseLeave={() => this.hideactionbutton()}
                  className="name_div_table"
                >
                  <span
                    style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}
                    onClick={() => this.handleEditlecture(item, index)}
                  >
                    {item.link}
                  </span>
                </div>
              </td>
              <td></td>
              <td></td>
            </tr>
          );
        }
      }
    }
  }
  renderContentAboveBanner = () => {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="course-duration-label subject-labels"
        >
          In English:-
        </label>
        <input
          value={this.state.englishAboveBanner}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control duration-form custom-form"
          onChange={(duration) =>
            this.setState({ englishAboveBanner: duration.target.value })
          }
        />
      </div>
    );
  };
  saveContent() {
    if (this.state.englishAboveBanner == "")
      return swal(
        "Check English Content",
        "Please enter English Content",
        "error"
      );
    else if (this.state.englishBelowBanner == "")
      return swal(
        "Check English Content!",
        "Please enter English Content",
        "error"
      );
    else if (this.state.hindiAboveBanner == "")
      return swal(
        "Check Hindi Content!",
        "Please enter Hindi ContentType",
        "error"
      );
    else if (this.state.hindiBelowBanner == "")
      return swal(
        "Check Hindi Content!",
        "Please enter Hindi Content",
        "error"
      );
    else {
      let content = {
        englishAbove: this.state.englishAboveBanner,
        englishBelow: this.state.englishBelowBanner,
        hindiAbove: this.state.hindiAboveBanner,
        hindiBelow: this.state.hindiBelowBanner,
      };
      let details = {
        // product_id:this.state.selected_products.product_id,
        id: 1,
        content: JSON.stringify(content),
      };

      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      this.setState({
        response: fetch("https://10x10learning.com/editBannerContent", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "max-age=31536000",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            if (responseJson.status == 200) {
              swal("Success", "Content edited", "success");
              this.props.history.push("/limitedaccess/banner-images");
            } else {
              swal("Warning!", responseJson.message, "warning");
            }
          })
          .catch((error) => {
            swal("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    }
  }

  renderContentAboveBanner1 = () => {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="course-duration-label subject-labels"
        >
          In Hindi:- &nbsp;&nbsp;
        </label>
        <input
          value={this.state.hindiAboveBanner}
          type="text"
          className="mr-3"
          id="defaultFormRegisterNameEx"
          className="form-control duration-form custom-form"
          onChange={(duration) =>
            this.setState({ hindiAboveBanner: duration.target.value })
          }
        />
      </div>
    );
  };

  renderContentBelowBanner = () => {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="course-duration-label subject-labels"
        >
          In English:-
        </label>
        <input
          value={this.state.englishBelowBanner}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control duration-form custom-form"
          onChange={(duration) =>
            this.setState({ englishBelowBanner: duration.target.value })
          }
        />
      </div>
    );
  };
  renderContentBelowBanner1 = () => {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="course-duration-label subject-labels"
        >
          In Hindi:- &nbsp;&nbsp;
        </label>
        <input
          value={this.state.hindiBelowBanner}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control duration-form custom-form"
          onChange={(duration) =>
            this.setState({ hindiBelowBanner: duration.target.value })
          }
        />
      </div>
    );
  };

  onToggle(index, e) {
    let { lectures, selected_data } = this.state;
    let selected_item = lectures[index];
    if (selected_item.checked == 1) {
      selected_item.checked = 0;
      selected_data.splice(index, 1);
    } else {
      selected_item.checked = 1;
      selected_data.push(selected_item);
    }

    this.setState({ selected_data });
    this.setState({ lectures });
  }

  handleAllChecked = (event) => {
    let { subjects } = this.state;
    if (this.state.item_selected) {
      for (var v = 0; v < subjects.length; v++) {
        subjects[v].checked = 1;
      }
      this.setState({ selected_data: subjects });
    } else {
      for (var v = 0; v < subjects.length; v++) {
        subjects[v].checked = 0;
      }
      this.setState({ selected_data: [] });
    }
    this.setState({ subjects });
  };

  search = (e) => {
    this.setState({ search: true, searchTerm: e.target.value }, () => {
      let filterArray = [];
      this.state.lectures.map((item) => {
        if (
          item.lecture_name
            .toLowerCase()
            .includes(this.state.searchTerm.toLowerCase())
        ) {
          filterArray.push(item);
        }
      });
      this.setState({ lecturesSearch: filterArray });
    });
  };

  handleChange = (selectedLanguage) => {
    this.setState({ selectedLanguage });
  };

  renderSelect() {
    return (
      <div className="col-5  d-flex align-items-baseline justify-content-end">
        <span
          style={{ fontWeight: "bold", fontSize: "18px", paddingRight: "5px" }}
        >
          Language :
        </span>
        <Select
          closeMenuOnSelect={true}
          onChange={this.handleChange}
          value={this.state.selectedLanguage}
          className="language-select "
          options={this.state.options}
        />
      </div>
    );
  }

  render() {
    return (
      <div id="subject-page-list">
        <AdminNavbar {...this.props} />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <SidebarPage {...this.props} active={11} />

          <div
            className="colDefault"
            style={{ width: "100%", backgroundColor: "#F9F9F9" }}
          >
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#c01825"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <div className="page_heading">
                  <h3 style={{ marginBottom: 0 }}>Banner Images </h3>
                  <span
                    onClick={() => this.handleAddNewSubject()}
                    className="add_new_button"
                  >
                    Add New
                  </span>
                  {this.renderSelect()}
                </div>

                <div>
                  <ul className="subsubsub">
                    <li className="all">
                      <span
                        onClick={() =>
                          this.setState({
                            all_selected: true,
                            published_selected: false,
                            trash_selected: false,
                            draft_selected: false,
                          })
                        }
                        className={
                          this.state.all_selected
                            ? "text_Pub current"
                            : "text_Pub"
                        }
                        aria-current="page"
                      >
                        All{" "}
                        <span className="count">
                          {" "}
                          ({this.state.draft_count + this.state.published_count}
                          )
                        </span>
                      </span>
                      |
                    </li>

                    <li className="publish">
                      <span
                        onClick={() =>
                          this.setState({
                            all_selected: false,
                            published_selected: true,
                            trash_selected: false,
                            draft_selected: false,
                          })
                        }
                        className={
                          this.state.published_selected
                            ? "text_Pub current"
                            : "text_Pub"
                        }
                      >
                        <span className="count">
                          Published ({this.state.published_count})
                        </span>
                      </span>
                      |
                    </li>
                    <li className="publish">
                      <span
                        onClick={() =>
                          this.setState({
                            all_selected: false,
                            published_selected: false,
                            trash_selected: false,
                            draft_selected: true,
                          })
                        }
                        className={
                          this.state.published_selected
                            ? "text_Pub current"
                            : "text_Pub"
                        }
                      >
                        <span className="count">
                          UnPublished ({this.state.draft_count})
                        </span>
                      </span>
                    </li>

                    {/* <li className="draft">
                    <span
                      onClick={() =>
                        this.setState({
                          all_selected: false,
                          published_selected: false,
                          trash_selected: false,
                          draft_selected: true
                        })
                      }
                      className={
                        this.state.draft_selected
                          ? "text_Pub current"
                          : "text_Pub"
                      }
                    >
                      Drafts <span className="count">({this.state.draft_count})</span>
                    </span>{" "}
                    |
                  </li> */}

                    <li className="draft">
                      <span
                        onClick={() =>
                          this.setState({
                            all_selected: false,
                            published_selected: false,
                            trash_selected: true,
                            draft_selected: false,
                          })
                        }
                        style={{
                          color: this.state.trash_selected ? "#000" : "#a00",
                        }}
                        className={
                          this.state.trash_selected
                            ? "text_Pub current"
                            : "text_Pub"
                        }
                      >
                        {/* Trash <span className="count">()</span> */}
                      </span>
                    </li>
                  </ul>
                  {/* <div className="tablenav">
                  <div className="alignleft actions bulkactions">
                    <select name="action" id="bulk-action-selector-top">
                      <option value="-1">Bulk Actions</option>

                      <option value="trash">Move to Trash</option>
                    </select>
                    <span
                      onClick={() => console.log(this.state.selected_data)}
                      className="apply"
                    >
                      Apply
                    </span>
                  </div>
                </div> */}
                  <MDBTable
                    striped
                    className="pages_table"
                    style={{ backgroundColor: "#F9F9F9" }}
                  >
                    <tr style={{ backgroundColor: "white" }}>
                      <th>Serial No.</th>
                      <th className="">Banner Image Link</th>
                    </tr>

                    <MDBTableBody>
                      {this.state.search == false
                        ? this.state.lectures.map((item, index) => {
                            return this.renderTableData(item, index);
                          })
                        : this.state.lecturesSearch.map((item, index) => {
                            return this.renderTableData(item, index);
                          })}
                    </MDBTableBody>
                  </MDBTable>
                  <br />
                  <br />
                  <span className="mt-5 ml-3">
                    Edit Content above Banner Image{" "}
                  </span>

                  <div className="d-flex mt-3">
                    <br />
                    <div className="subject-content-container ml-4 fit-content">
                      <div className="row px-0 mx-0">
                        {this.renderContentAboveBanner()}
                        {this.renderContentAboveBanner1()}
                      </div>
                    </div>
                  </div>

                  <br />
                  <br />
                  <span className="mt-5 ml-3">
                    Edit Content below Banner Image{" "}
                  </span>

                  <div className="d-flex mt-3">
                    <br />
                    <div className="subject-content-container ml-4 fit-content">
                      <div className="row px-0 mx-0">
                        {this.renderContentBelowBanner()}
                        {this.renderContentBelowBanner1()}
                      </div>
                    </div>
                  </div>

                  <button
                    className="admin-button-style ml-3 mt-5"
                    onClick={() => this.saveContent()}
                  >
                    {" "}
                    Save &amp; Publish
                  </button>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Lecture;
