import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../Sidebar/sidebarPage";
import GridLoader from "react-spinners/GridLoader";
import Select from "react-select";
import "../../commonStyles.css";
import Fade from "react-reveal";
import swal from "sweetalert";
import "../Verticals/AddVerticals.css";
import { MDBIcon } from "mdbreact";
import MediaComponent from "../MediaComponent/chooseMediaFile";
import { css } from "@emotion/core";
import { SketchPicker } from "react-color";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

export default class EditOtherService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "image",
      status: 1,
      languageID: "",
      id: "",
      url: "",
      name: "",
      categoryName: "",
      categoryImage: "",
      categorytype: "",
      background: "",
      info: "",
      categoryorder: "",
      selectedType: "",

      inputLink: "",
      subtitle: "",
      bannerImage: "",
      verticalUrl: " ",
      options: [
        { value: "0", label: "English" },
        { value: "1", label: "Hindi" },
      ],
      selectedLanguage: "",
    };
  }
  componentDidMount() {
    let item = JSON.parse(localStorage.getItem("category_item"));
    console.log(item)

    let ans=this.state.options.find(ele=>ele.value==item.language_id)
    console.log(ans)
    this.setState({
      categoryName: item.categoryname,
      categoryImage: item.categoryimage,
      categorytype: item.categorytype,
      categoryorder: item.orderid,
      id: item.id,
      selectedLanguage: this.state.options.find(ele=>ele.value==item.language_id)
    });
  }
  addCategory() {
    // var type_local = JSON.parse(
    //   window.localStorage.getItem("selected_media_type")
    // ).type;
    // console.log(this.state);
    if (this.state.categoryName.length === 0)
      return swal("Check Name!", "Please enter name", "error");
    else if (this.state.categorytype.length === 0)
      return swal("Check Info!", "Please enter name", "error");
    else if (!this.state.selectedLanguage.value)
      return swal("Check Info!", "Please select language", "error");

    // let language = JSON.parse(localStorage.getItem("language"));
    let formData = new FormData();
    formData.append("id", this.state.id);
    formData.append("categoryname", this.state.categoryName);
    formData.append("language_id", this.state.selectedLanguage.value);
    formData.append("categorytype", this.state.categorytype);
    formData.append("categoryimage", this.state.categoryImage);
    formData.append("orderid", this.state.categoryorder);
    this.setState({
      response: fetch("https://10x10learning.com/edit_otherservicecategories", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "QWERTy");
          swal("Success", "Dashboard succesfullly added", "success");
          this.props.history.push("/limitedaccess/other-service/categories");
        })
        .catch((error) => {
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }

  renderChooseFile = () => {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="course-duration-label subject-labels"
        >
          Vertical Home Image:
        </label>

        <input
          autoFocus
          type="text"
          value={this.state.inputLink}
          onChange={(text) => this.handlechangeurl(text)}
          id="defaultFormRegisterNameEx"
          className="form-control duration-form custom-form "
        />
      </div>
    );
  };

  renderCategory = () => {
    return (
      <>
        <div className="col-12  d-flex">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="course-duration-label subject-labels"
          >
            Category Name
          </label>

          <input
            autoFocus
            type="text"
            value={this.state.categoryName}
            onChange={(text) =>
              this.setState({ categoryName: text.target.value })
            }
            id="defaultFormRegisterNameEx"
            className="form-control duration-form custom-form "
          />
        </div>
        <div className="col-12  d-flex">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="course-duration-label subject-labels"
          >
            Category type
          </label>

          <input
            autoFocus
            type="text"
            value={this.state.categorytype}
            onChange={(text) =>
              this.setState({ categorytype: text.target.value })
            }
            id="defaultFormRegisterNameEx"
            className="form-control duration-form custom-form "
          />
        </div>
        <div className="col-12  d-flex">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="course-duration-label subject-labels"
          >
            Category Image
          </label>

          <input
            autoFocus
            type="text"
            value={this.state.categoryImage}
            onChange={(text) =>
              this.setState({ categoryImage: text.target.value })
            }
            id="defaultFormRegisterNameEx"
            className="form-control duration-form custom-form "
          />
        </div>

        <div className="col-12  d-flex">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="course-duration-label subject-labels"
          >
            Image Thumbnail
          </label>

          <img src={this.state.categoryImage} className="thumbnailAdmin" />
        </div>

        <div className="col-12  d-flex">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="course-duration-label subject-labels"
          >
            Category Order
          </label>

          <input
            type="number"
            value={this.state.categoryorder}
            onChange={(text) =>
              this.setState({ categoryorder: text.target.value })
            }
            id="defaultFormRegisterNameEx"
            className="form-control duration-form custom-form "
          />
        </div>
      </>
    );
  };

  renderChooseFileType() {
    return (
      <div className="col-12 exam-section h-25 min-height-unset">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="exam-label subject-labels pl-0"
        >
          Choose file type :
        </label>

        <Select
          closeMenuOnSelect={true}
          onChange={(e) => this.setState({ selectedType: e })}
          value={this.state.selectedType}
          className="select-exam select"
          options={[
            { value: 1, label: "PDF" },
            { value: 2, label: "PPT" },
            { vaue: 3, label: "Video" },
            { value: 4, label: "Image" },
            { value: 5, label: "Text" },
          ]}
        />
      </div>
    );
  }

  renderSelect() {
    return (
      <div className="col-5  d-flex align-items-baseline justify-content-end">
        <span
          style={{ fontWeight: "bold", fontSize: "18px", paddingRight: "5px" }}
        >
          Language :
        </span>
        <Select
          closeMenuOnSelect={true}
          onChange={(e) => this.setState({ selectedLanguage: e })}
          value={this.state.selectedLanguage}
          className="language-select "
          options={this.state.options}
        />
      </div>
    );
  }

  render() {
    console.log(this.state)
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={11} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#c01825"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <div className="row my-3 px-4">
                  <div className="col-12 top_button_bar d-flex align-items-center">
                    <button
                      className="admin-button-style admin-button-style-margin"
                      onClick={() =>
                        this.props.history.push("/limitedaccess/vertical")
                      }
                    >
                      All Category
                    </button>
                    <MediaComponent
                      onSelectMediaItem={this.handleMediaChooseImage}
                      buttonName={<MDBIcon icon="camera" />}
                      restriction="image/*"
                    />
                    <button
                      className="admin-button-style ml-auto"
                      onClick={() => this.addCategory()}
                    >
                      {" "}
                      Save &amp; Publish
                    </button>
                  </div>

                  <div className="col-12 mt-4 d-flex justify-content-start align-items-center">
                    <h3> Edit Category</h3>
                    {this.renderSelect()}
                  </div>
                </div>

                <div className="d-flex">
                  <div className="subject-content-container ml-4 fit-content">
                    <div className="row px-0 mx-0">
                      {this.renderCategory()}
                      <br />
                    </div>
                  </div>
                  <div className="subject-select-fields-container ml-2 row align-self-start flex-column mr-4 h-100">
                    {/* {this.renderChooseFileType()} */}
                  </div>
                </div>

                {/* <button className='add-subject my-5 float-right mr-4' onClick={()=>this.addlectures()}>Save &amp; Publish</button>  */}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}
