import React, { Component } from "react";
import SidebarPage from "../Sidebar/sidebarPage";
import RangePicker from "react-range-picker";
import {
  MDBTable,
  MDBTableBody,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import AdminNavbar from "../Navbar/adminNavbar";
import "../UserDetail/userDetail.css";
import swal from "sweetalert";
import { FormGroup, Input, Label } from "reactstrap";

import ActivePNG from "../../../Assets/images/activestatus.png";
import InactivePNG from "../../../Assets/images/inactivestatus.png";
import GridLoader from "react-spinners/GridLoader";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: calc(100vh / 2) auto;
`;

class Loginreport extends Component {
  handleDateSelect(start, end) {
    this.setState({ start_date: start, end_date: end });
  }
  commentdate(timestamp) {
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd;
  }

  commentMonth(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return monthNames[Number(dt.getMonth())];
  }

  commentYear(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dt.getFullYear().toString();
  }
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      ipAddress: "",
      loginreport: [],
      modal_edit: false,
      logo: null,
      name: "",
      link: "",
      delete_item: "",
      clientData: "",
      selected_client: "",
      selected_data: [],
      selected_orders:[],
      updated_status: "",
      start_date: null,
      end_date: null,
      sort: false,
      id: "",
    };
  }

  componentWillMount() {
    this.getLoginReport();
  }

  getLoginReport = async () => {
    this.setState({
      loading: true,
    });
    this.setState({
      response: fetch("https://10x10learning.com/get_loginreport", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({ loginreport: responseJson, loading: false });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  // -------------function for rendering  data---------

  confirmDelete = (item) => {
    swal({
      title: "Are you sure?",
      text: "You are deleting this client!!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.deleteClient(item);
      } else return;
    });
  };

  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Novr",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd + "/" + mm + "/" + dt.getFullYear().toString();
  }
  commenttime(timestamp) {
    var convertdLocalTime = new Date(timestamp);
    var hours = convertdLocalTime.getHours();
    var minutes = convertdLocalTime.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  edit_user(data) {
    window.localStorage.removeItem("10x10_userdata");
    window.localStorage.setItem("10x10_userdata", JSON.stringify(data));
    this.props.history.push("/edit-user");
  }

  renderActions(item, index) {
    if (item.selected == 1) {
      return (
        <div className="actions_div">
          <text
            onClick={() => this.edit_user(item, index)}
            className="actionstext"
          >
            Details
          </text>
        </div>
      );
    } else {
      return (
        <div className="actions_div" style={{ visibility: "hidden" }}>
          <text className="actionstext">Details</text>
        </div>
      );
    }
  }
  showactionbutton(item, index) {
    let { user_data } = this.state;
    let selected_page = user_data[index];
    for (var v = 0; v < user_data.length; v++) {
      user_data[v].selected = 0;
    }
    selected_page.selected = 1;
    this.setState({
      user_data,
    });
  }
  hideactionbutton() {
    let { user_data } = this.state;

    for (var v = 0; v < user_data.length; v++) {
      user_data[v].selected = 0;
    }

    this.setState({
      user_data,
    });
  }
  renderStatus(item) {
    if (item.status == 0) {
      return "Last Modified";
    } else {
      return "Published";
    }
  }

  onToggle(index, e) {
    let { user_data, selected_data } = this.state;
    let selected_item = user_data[index];
    if (selected_item.checked == 1) {
      selected_item.checked = 0;
      selected_data.splice(index, 1);
    } else {
      selected_item.checked = 1;
      selected_data.push(selected_item);
    }

    this.setState({ selected_data });
    this.setState({ user_data });
  }

  handleAllChecked = (event) => {
    let { user_data } = this.state;
    if (this.state.all_selected) {
      for (var v = 0; v < user_data.length; v++) {
        user_data[v].checked = 1;
      }
      this.setState({ selected_data: user_data });
    } else {
      for (var v = 0; v < user_data.length; v++) {
        user_data[v].checked = 0;
      }
      this.setState({ selected_data: user_data });
    }
    this.setState({ user_data });
  };

  handleOnClose() {
    let startDate = new Date(this.state.start_date)
      .setHours(0, 0, 0)
      .toString();
    //  console.log(startDate.getTime())
    let endDate = new Date(this.state.end_date).setHours(0, 0, 0).toString();
    let selected_orders = this.state.loginreport.filter((item) => {
      let order_date = new Date(item.ts).setHours(0, 0, 0).toString();
      if (order_date >= startDate && order_date <= endDate) {
        return item;
      }
    });
    this.setState({ selected_orders, showToggle: false, sort: true });
  }
  handleViewAll=()=>{
    this.setState({sort:false})
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="loadingSpin">
          {" "}
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      let data;
      if (this.state.sort == true) {
        data = this.state.selected_orders;
      } else if (this.state.sort == false) {
        data = this.state.loginreport;
      }
      return (
        <div>
          <AdminNavbar {...this.props} />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <SidebarPage {...this.props} active={0} />

            <div
              className="colDefault"
              style={{ width: "100%", backgroundColor: "#F9F9F9" }}
            >
              <div className="page_heading">
                <h3 style={{ marginBottom: 0, marginRight: "30px" }}>
                  Login Report
                </h3>
                <RangePicker
                  onDateSelected={(start, end) =>
                    this.handleDateSelect(start, end)
                  }
                  onClose={() => this.handleOnClose()}
                />

                <span
                className="add_new_button viewallOrder"
                style={{ fontSize: "18px" }}
                onClick={() => this.handleViewAll()}
              >
                {" "}
                View all
              </span>
                {/* <text onClick={()=>this.props.history.push('/add-client')} className='add_new_button'>Add New</text> */}
              </div>
              <div>
                {/* <div className='tablenav'>
          <div class="alignleft actions bulkactions">
        <select name="action" id="bulk-action-selector-top">
  <option value="-1">Bulk Actions</option>
  
    <option value="trash">Move to Trash</option>
  </select>
  <text onClick={()=>console.log(this.state.selected_data)} className='apply'>Apply</text>
      </div>
          </div> */}

                <MDBTable
                  striped
                  className="pages_table"
                  style={{ backgroundColor: "#F9F9F9" }}
                >
                  <tr style={{ backgroundColor: "white" }}>
                    <th className="">Email</th>
                    <th className="">Ip Address</th>
                    <th>Logged In Date</th>
                    <th>Logged In Time</th>
                  </tr>

                  <MDBTableBody>
                    {data.map((item, index) => (
                          <tr key={item.id}>
                            <td
                              style={{ width: "25%" }}
                              className="tdName textFontSize"
                            >
                              <div className="name_div_table">
                                <text
                                  style={{
                                    fontFamily: "Ubuntu-B",
                                    color: "#0073aa",
                                  }}
                                >
                                  {item.email}
                                </text>
                                {this.renderActions(item, index)}
                              </div>
                            </td>
                            <td
                              style={{
                                fontFamily: "Ubuntu-B",
                                color: "#0073aa",
                              }}
                              className="tdName textFontSize"
                            >
                              {item.ipAddress}
                            </td>
                            <td>{this.commentdate(item.ts)} </td>
                            <td>{this.commenttime(item.ts)} </td>
                          </tr>
                        ))
                            }
                  </MDBTableBody>
                </MDBTable>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Loginreport;
