import React, { Component, Fragment } from "react";
import styles from "./DashboardComponent.module.css";
import time from "../../../Assets/images/time.png";
import lessons from "../../../Assets/images/lessons.png";
import grad from "../../../Assets/images/grade.png";
import swal from "sweetalert";
import stars from "../../../Assets/images/star.png";
import courseDuration from "../../../Assets/images/course-duration.png";

import HomeHeader from "../../../Reusable/Header/homeHeader";
import MainFooterPage from "../../../Reusable/Footer/footer";
import GridLoader from "react-spinners/GridLoader";
import { css } from "@emotion/core";
import Login from "../../../Reusable/Header/login";
import { LinearGradient } from "@progress/kendo-drawing";
import ProfTen from "../../Prof10X10/prof10x10";

var subjectsToDisplay = [];

export default class DashboardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      choosenSubject: null,
      dashboard_item: [],
      selectedClass: "",
      finalSubject: [],
      banner: "",
      language_id: "",
      subjects: [],
      subjectsToDisplay: [],
      subjectsFiltered: [],
      schoolSubjects: [],
      loginToggle: false,
      allItems: [],
      userdata: null,
      showLogin:
        this.props.showLogin === undefined ? false : this.props.showLogin,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.type !== this.props.match.params.type) {
      // Load Funcition....
      window.location.reload();
    }
  }

  componentWillMount() {
    let language_id = localStorage.getItem("language_id");
    let banner = JSON.parse(localStorage.getItem("verticalBannerImage"));
    this.setState({ banner: banner[0].link });
    if (!language_id) {
      language_id = 0;
    }
    console.log(language_id);
    this.setState({ language_id }, () => console.log(this.state.language_id));
    this.getDashboard();
    // this.setState({ dashboard_item });
  }
  getDashboard = () => {
    // let dashboard_id = localStorage.getItem("Dashboard id");
    let language_id = localStorage.getItem("language_id")
      ? localStorage.getItem("language_id")
      : 0;
    // let dashboard_type = localStorage.getItem("dashboard type");
    // console.log(dashboard_id);
    let details = {
      language_id: language_id,
      dashboard_type: this.props.match.params.type,
    };
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    // console.log(language_id);
    // console.log(dashboard_type);
    console.log(this.props.match.params);
    this.setState({
      response: fetch("https://10x10learning.com/get_dashboard_type", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "xxx");
          // let dashboard = responseJson.filter(item => {
          //   return (
          //     item.language_id == language_id && item.type == dashboard_type
          //   );
          // });
          // console.log(dashboard);

          let dashboard = responseJson;
          if (!responseJson || responseJson.length == 0) {
            this.props.history.push("/");
          }
          this.setState({ dashboard_item: dashboard[0] });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  componentDidMount() {
    console.log("rtyu");
    this.setState({ loading: true }, () => this.getDialogues());
  }

  getDialogues = async () => {
    let formBody = new FormData();
    formBody.append("language_id", this.state.language_id);
    try {
      const dialogueResponse = await fetch(
        "https://10x10learning.com/prof10_dashboard",
        {
          method: "POST",
          body: formBody,
        }
      );
      const result = await dialogueResponse.json();
      console.log(result, "dfvcv");
      let details = JSON.parse(result[0].text);
      console.log(details);
      console.log(this.props.match.params);
      let dialogues;
      if (this.props.match.params.type == "civil-services") {
        dialogues = details.civilDescriptions.map((item) => item.description);
        this.setState({ dialogues });
      } else if (this.props.match.params.type == "humanities") {
        dialogues = details.humanityDescriptions.map(
          (item) => item.description
        );
      } else if (this.props.match.params.type == "school") {
        dialogues = details.classDescriptions.map((item) => item.description);
      }
      else if(this.props.match.params.type=="CCA"){
        dialogues = details.commerceDescriptions.map((item)=>item.description)
      }
      //civil-services humanities school
      //   const dialogues = JSON.parse(result[0].text).map(
      //     (item) => item.description
      //   );
      this.setState({ dialogues }, () => this.getSubjects());
      // this.getSubjects()
    } catch (err) {
      console.log(err);
    }
  };

  getUserDetails() {
    var userdata = window.localStorage.getItem("userdata");
    // console.log(userdata ,'Naruto')
    if (userdata != null) {
      this.setState(
        {
          userdata: JSON.parse(userdata),
        },
        () => this.getCart()
      );
    } else {
      this.setState({
        course_purchase: 2,
        loading: false,
      });
    }
  }

  getSubjects = async () => {
    console.log(this.state.language_id);
    let details = {
      language_id: this.state.language_id,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/get_subjects", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          let sort = responseJson.sort((a, b) =>
          a.subject_name.localeCompare(b.subject_name)
        );
          this.setState(
            {
              subjects: sort,
            },
            () => this.getUserDetails()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  startTest(item) {
    //console.logthis.state.test_included);

    window.localStorage.removeItem("selected_test");
    window.localStorage.setItem("selected_test", item.subject_id);
    this.props.history.push("/start-test");
  }

  fetchSubject = (subjectid) => {
    window.localStorage.removeItem("selected_course_user");
    window.localStorage.setItem(
      "selected_course_user",
      JSON.stringify({ id: subjectid })
    );
    window.localStorage.removeItem("sample_mode");
    window.localStorage.setItem("sample_mode", JSON.stringify(false));
    this.props.history.push({
      pathname: "/lessons",
      state: {
        id: subjectid,
      },
    });
  };

  productDetail = (item) => {
    console.log(item);
    console.log('rtyui')
    localStorage.setItem("selected_subject", JSON.stringify(item));
    this.props.history.push(`/product/${item.subject_name}`);
  };
  renderAddToCart(subject) {
    //   console.log(this.state.loading, this.state.course_purchase);
    if (subject.course_purchase == 1) {
      return (
        <React.Fragment>
          <div className={`${styles.CardButtons} text-center`}>
            <button
              className={`${styles.AddCart}`}
              onClick={() => this.fetchSubject(subject.subject_id)}
            >
              {this.state.language_id == 0 ? (
                <span>Go to Course</span>
              ) : (
                <span>विषय पर जाएं</span>
              )}
            </button>
          </div>
        </React.Fragment>
      );
    } else if (subject.course_purchase == 2) {
      if (this.state.item_in_cart) {
        return (
          <React.Fragment>
            <div className={`${styles.CardButtons} text-center`}>
              <button
                className={`${styles.AddCart}`}
                onClick={() => this.addToCart(subject)}
              >
                {this.state.language_id == 0 ? (
                  <span>Go to Cart</span>
                ) : (
                  <span>कार्ट में जाएँ</span>
                )}
              </button>
              <button
                className={`${styles.BuyNow}`}
                onClick={() => this.addToCart(subject)}
              >
                {this.state.language_id == 0 ? (
                  <span>Details</span>
                ) : (
                  <span>विवरण</span>
                )}
              </button>
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <div className={`${styles.CardButtons} text-center`}>
              <button
                className={`${styles.AddCart}`}
                // onClick={() =>
                //   this.props.history.push(`/product/${subject.subject_name}`)
                // }
                onClick={() => this.productDetail(subject)}
              >
                {this.state.language_id == 0 ? (
                  <span>View course</span>
                ) : (
                  <span>पाठ्यक्रम देखें</span>
                )}
              </button>
              <button
                className={`${styles.BuyNow}`}
                onClick={() => this.addToCart(subject)}
              >
                {this.state.language_id == 0 ? (
                  <span>Buy Now</span>
                ) : (
                  <span>अभी खरीदें</span>
                )}
              </button>
            </div>
          </React.Fragment>
        );
      }
    } else if (subject.course_purchase == 3) {
      return (
        <React.Fragment>
          <div className={`${styles.CardButtons} text-center`}>
            <button
              className={`${styles.AddCart}`}
              onClick={() => this.addToCart(subject)}
            >
              {this.state.language_id == 0 ? (
                <span>Go to Course</span>
              ) : (
                <span>विषय पर जाएं</span>
              )}
            </button>
            <button
              className={`${styles.BuyNow}`}
              onClick={() => this.addToCart(subject)}
            >
              {this.state.language_id == 0 ? (
                <span>Buy Test</span>
              ) : (
                <span>अभी खरीदें</span>
              )}
            </button>
          </div>
        </React.Fragment>
      );
    } else if (subject.course_purchase == 4) {
      return (
        <React.Fragment>
          <div className={`${styles.CardButtons} text-center`}>
            <p className={`text-muted ${styles.alreadyPurchasedText}`}>
              {/* You have purchased the study material for this course. */}
            </p>
            <button
              className={`${styles.AddCart} dashboardTest`}
              onClick={() => this.startTest(subject)}
            >
              {this.state.language_id == 0 ? (
                <span>Go to Test</span>
              ) : (
                <span>परीक्षण करने के लिए जाएँ</span>
              )}
            </button>
            <button
              className={`${styles.BuyNow}`}
              onClick={() => this.addToCart(subject)}
            >
              {this.state.language_id == 0 ? (
                <span>Buy Course</span>
              ) : (
                <span>अभी खरीदें</span>
              )}
            </button>
          </div>
        </React.Fragment>
      );
    }
  }
  buyNow(item) {
    // console.log(this.state.userdata ,'Naruto')

    if (this.state.userdata == null) {
      this.setState({ showLogin: true });
    } else {
      this.props.history.push("/my-cart");
    }
  }

  addToCart = async (item) => {
    console.log("adding to cart", item);
    if (this.state.userdata == null) {
      this.setState({ showLogin: true });
    } else {
      let formData = new FormData();
      formData.append('id', item.subject_id)
      let test_exists = await fetch("https://10x10learning.com/checktest", {
        method:"POST",
        body: formData
      })
      let result = await test_exists.json()
      let details = {
        user_id: this.state.userdata.user_id,
        items: JSON.stringify([
          {
            subject_id: item.subject_id,
            test_included: result.length == 0 ? 0 : 1,
            subject_included: 1,
          },
        ]),
      };
      console.log(details, "fetching-details");

      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://10x10learning.com/update_cart", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson, "update_cart");
            this.setState(
              {
                showAdded: true,
                cart_status: responseJson.status,
                item_in_cart: true,
                loading: false,
              },
              () => this.buyNow()
            );
            this.props.history.push("/my-cart");
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            alert("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    }
  };

  removeDuplicates(originalArray, objKey) {
    console.log(originalArray, "remove duplcates param 1");
    var trimmedArray = [];
    var values = [];
    var value;

    for (var i = 0; i < originalArray.length; i++) {
      value = originalArray[i][objKey];

      if (values.indexOf(value) === -1) {
        trimmedArray.push(originalArray[i]);
        values.push(value);
      }
    }
    console.log(this.state.subjects);
    this.setState(
      {
        all_items: trimmedArray,
      },
      () => {
        this.state.subjects.forEach((subject) => {
          if (
            this.state.all_items.find(
              (item) => item.subject_id == subject.subject_id
            )
          ) {
            var selected_subject = this.state.all_items.find(
              (item) => item.subject_id == subject.subject_id
            );
            if (selected_subject.course_status == "active") {
              if (
                selected_subject.subject_included == 0 &&
                selected_subject.test_included == 1
              ) {
                // count = 3;
                subject.course_purchase = 4;
              } else if (
                selected_subject.subject_included == 1 &&
                selected_subject.test_included == 0
              ) {
                // count = 2;
                subject.course_purchase = 3;
              } else if (
                selected_subject.subject_included == 1 &&
                selected_subject.subject_included == 1
              ) {
                // count = 1;
                subject.course_purchase = 1;
              } else {
                subject.course_purchase = 1;
              }
            }
            console.log(this.state, "state");
          } else {
            subject.course_purchase = 2;
          }
        });
        this.setState({ loading: false });
      }
    );
  }

  checkDuplicates(originalArray) {
    console.log(originalArray, "check duplicates param");
    var trimmedArray = [];

    var all_courses = originalArray;
    if (originalArray.length != 0) {
      for (var i = 0; i < originalArray.length; i++) {
        for (var i1 = 0; i1 < originalArray.length; i1++) {
          if (i != i1) {
            console.log(originalArray[i], i, originalArray[i1], i1);
            if (originalArray[i].subject_id == originalArray[i1].subject_id) {
              //   console.log(originalArray[i],originalArray[i1],'iii')
              var matched_object = originalArray[i];

              matched_object.subject_included = 1;
              matched_object.test_included = 1;
              trimmedArray.push(matched_object);
            }
          }
        }
      }
    }
    console.log(all_courses, "iii");

    for (var i3 = 0; i3 < all_courses.length; i3++) {
      for (var i4 = 0; i4 < trimmedArray.length; i4++) {
        if (all_courses[i3].subject_id == trimmedArray[i4].subject_id) {
          all_courses.splice(i3, 1);
          all_courses.splice(i3, 0, trimmedArray[i4]);
        }
      }
    }
    this.removeDuplicates(all_courses, "subject_id");
  }

  getOrders = () => {
    let details = {
      user_id: this.state.userdata.user_id,
    };

    console.log(details);

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      loading: true,
      response: fetch("https://10x10learning.com/get_my_orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          var items = [];
          for (var v = 0; v < responseJson.length; v++) {
            for (
              var v1 = 0;
              v1 < JSON.parse(responseJson[v].items).length;
              v1++
            ) {
              var data = JSON.parse(responseJson[v].items)[v1];
              data.creation_time = responseJson[v].ts;
              items.push(data);
            }
          }
          var data_to_send = items;

          this.checkDuplicates(data_to_send);
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  getCart = () => {
    console.log(this.state.userdata);
    let details = {
      user_id: this.state.userdata.user_id,
    };
    console.log(details, "fetching-details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState(
            {
              cart: responseJson,
            },
            () => this.getOrders()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  checkCart() {
    let item_in_cart = false;
    if (this.state.cart.length != 0) {
      for (var v = 0; v < JSON.parse(this.state.cart[0].items).length; v++) {
        // console.log(this.state.cart[v].subject.subject_id);
        if (
          JSON.parse(this.state.cart[0].items)[v].subject_id ==
          this.state.selected_subject.subject_id
        ) {
          item_in_cart = true;
          break;
        } else {
        }
      }
    } else {
    }

    if (item_in_cart) {
      this.setState({ item_in_cart: true }, () => this.getOrders());
    } else {
      this.setState({ item_in_cart: false }, () => this.getOrders());
    }
  }

  displayCourseCards = () => {
    console.log(this.state.subjects);
    subjectsToDisplay = this.state.subjects.filter((item) => {
      let parsedStudenttype = JSON.parse(item.student_type);
      console.log(parsedStudenttype, this.state.dashboard_item.type);
      return (
        parsedStudenttype.label == this.state.dashboard_item.type.toString()
      );
    });
    console.log(subjectsToDisplay);

    return (
      <Fragment>
        {this.state.choosenSubject == null
          ? subjectsToDisplay.map((item) => {
              console.log(item);
              if (item.status != 0) {
                return (
                  <div className="col-12 col-lg-3 col-md-6">
                    <div className={`${styles.OuterContentCourse}`}>
                      <div className={`${styles.ContentCourse}`}>
                        <img
                          src={item.image}
                          className={`${styles.CardImage}`}
                          onClick={() => this.productDetail(item)}
                        />
                        <div className={`${styles.CardBody}`}>
                          <div onClick={() => this.productDetail(item)}>
                            <p className={`${styles.CardTitle}`}>
                              {item.subject_name}{" "}
                            </p>
                            <p className={`${styles.Author}`}>
                              {this.state.language_id == 0 ? (
                                <span>Author(s): Team 10x10 Learning</span>
                              ) : (
                                <span>लेखक : टीम 10x10learning द्वारा बनाया गया</span>
                              )}
                            </p>
                            <p className={`${styles.Ratings}`}>
                              <p className={`${styles.Lessons}`}></p>
                              <p className={`${styles.Duration}`}>
                                <span className={`${styles.TotalDuration}`}>
                                  <img
                                    src={courseDuration}
                                    className={`${styles.CourseDurationImage}`}
                                  />{" "}
                                  {item.duration}{" "}
                                  {this.state.language_id == 0 ? (
                                    <React.Fragment>minutes</React.Fragment>
                                  ) : (
                                    <React.Fragment>मिनट</React.Fragment>
                                  )}
                                </span>
                              </p>
                              <div className={`${styles.Costs}`}>
                                <span></span>
                                <span></span>
                                <span className={`${styles.OldCost}`}>
                                  <strike>&#8377; {item.old_cost}</strike>
                                </span>
                                <span className={`${styles.NewCost}`}>
                                  &#8377; {item.cost}
                                </span>
                              </div>
                            </p>
                          </div>
                          {this.renderAddToCart(item)}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })
          : this.state.finalSubject.map((item) => {
              console.log(item);
              if (item.status != 0) {
                return (
                  <div className="col-12 col-lg-3 col-md-6">
                    <div className={`${styles.OuterContentCourse}`}>
                      <div className={`${styles.ContentCourse}`}>
                        <img
                          src={item.image}
                          className={`${styles.CardImage}`}
                          onClick={() => this.productDetail(item)}
                        />
                        <div className={`${styles.CardBody}`}>
                          <div onClick={() => this.productDetail(item)}>
                            <p className={`${styles.CardTitle}`}>
                              {item.subject_name}{" "}
                            </p>
                            {/* <p className={`${styles.Author}`}>
                            Author(s): Team 10x10 Learning
                          </p> */}
                            <p className={`${styles.Ratings}`}>
                              <img src={stars} /> <img src={stars} />{" "}
                              <img src={stars} /> <img src={stars} />{" "}
                              <img src={stars} /> {item.averageRating} (
                              {item.totalRatings})
                              <p className={`${styles.Lessons}`}>
                                <img
                                  src={lessons}
                                  className={`${styles.LessonsImage}`}
                                />{" "}
                                36 Lessons + Test series
                              </p>
                              <p className={`${styles.Duration}`}></p>
                              <div className={`${styles.Costs}`}>
                                <span></span>
                                <span></span>
                                <span className={`${styles.OldCost}`}>
                                  <strike>&#8377; {item.old_cost}</strike>
                                </span>
                                <span className={`${styles.NewCost}`}>
                                  &#8377; {item.cost}
                                </span>
                              </div>
                            </p>
                          </div>
                          {this.renderAddToCart(item)}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
      </Fragment>
    );
  };

  bannerDashboard = () => {
    return (
      <div className={`${styles.bannerContentWrapper}`}>
        <span
          className={`${styles.BannerText}`}
          dangerouslySetInnerHTML={{
            __html: `${this.state.dashboard_item.name}`,
          }}
        ></span>
        <span className={`${styles.BannerTextBelow}`}>
          ( {this.state.dashboard_item.subtitle} )
        </span>
      </div>
    );
  };

  showSubjects = () => {
    this.setState({ choosenSubject: 1 });
    var finalSubject = [];
    for (let i = 0; i < subjectsToDisplay.length; i++) {
      console.log(subjectsToDisplay[i]);
      console.log(JSON.parse(subjectsToDisplay[i].class));
      let subjectClass = JSON.parse(subjectsToDisplay[i].class);
      for (let i2 = 0; i2 < subjectClass.length; i2++) {
        if (
          subjectClass[i2].label.toString() == this.state.inputState.toString()
        ) {
          console.log(subjectClass[i2]);
          console.log(subjectsToDisplay[i]);

          finalSubject.push(subjectsToDisplay[i]);
        }
      }
      this.setState({ finalSubject }, () =>
        console.log(this.state.finalSubject)
      );
    }
  };
  selectClass = (e) => {
    let classRelated = "Class " + e.target.value;
    this.setState({ inputState: classRelated });
  };
  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div className={`${styles.CivilServices}`} id="civil-services">
          <HomeHeader
            {...this.props}
            backgroundColor="#FFFFFF"
            textcolor="#000"
            width="100%"
            height="auto"
          />
          <div
            className={`${styles.BannerImage}`}
            style={{
              backgroundImage: `url(${this.state.banner})`,
              opacity: 0.8,
              background: "linear-gradient(#e66465, #9198e5);",
            }}
          >
            {this.bannerDashboard()}
          </div>
          {this.state.dashboard_item.type == "school" ? (
            <div className={`${styles.SchoolForm}`}>
              {/* <p className={`${styles.Heading}`}>
                {this.state.language_id == 0 ? (
                  <span>Let us find the Best for you!</span>
                ) : (
                  <span>हमें आप के लिए सबसे अच्छा लगता है!</span>
                )}
              </p> */}
              {/* <p className={`${styles.Description}`}>
                {this.state.language_id == 0 ? (
                  <span>Just help us out with some details...</span>
                ) : (
                  <span>बस हमें कुछ विवरण के साथ मदद ...</span>
                )}
              </p> */}
              <div className={`${styles.Contents}`}>
                <div className={`${styles.Form}`}>
                  <span>
                    <img src={grad} className={`${styles.grade}`} />
                    <span className={`${styles.TextForm}`}>
                      {this.state.language_id == 0 ? (
                        <span>Select A Class:</span>
                      ) : (
                        <span>एक वर्ग का चयन करें</span>
                      )}
                    </span>
                  </span>
                  <select
                    className={`${styles.SelectBox} browser-default custom-select`}
                    onChange={(e) => this.selectClass(e)}
                  >
                    <option value="select">Select Class</option>
                    <option value="5th">5th Standard</option>
                    <option value="6th">6th Standard</option>
                    <option value="7th">7th Standard</option>
                    <option value="8th">8th Standard</option>
                    <option value="9th">9th Standard</option>
                    <option value="10th">10th Standard</option>
                    <option value="11th">11th Standard</option>
                    <option value="12th">12th Standard</option>
                  </select>
                </div>
                <div className={`${styles.ButtonDiv}`}>
                  <button
                    className={`${styles.AddCart}`}
                    onClick={this.showSubjects}
                  >
                    <span>
                      {this.state.language_id == 0 ? (
                        <span>Show Subjects</span>
                      ) : (
                        <span>विषय दिखाएं</span>
                      )}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className={`${styles.Courses}`}>
            {this.state.loading ? (
              <div className="sweet-loading-main">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#c01825"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <div class="row">{this.displayCourseCards()}</div>
            )}
          </div>
          <ProfTen dialogues={this.state.dialogues} />
          <div style={{ height: "auto" }}>
            <MainFooterPage {...this.props} />
          </div>

          <Login showLogin={this.state.showLogin} {...this.props} />
        </div>
      );
    }
  }
}
