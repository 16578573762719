import React from "react";
import "./latest-updates.css";
// import styles from "./Aboutus.module.css";
import "../commonStyles.css";
import swal from "sweetalert";
import { MDBCardBody, MDBCardText, MDBCardTitle } from "mdbreact";

import HomeHeader from "../../Reusable/Header/homeHeader";
import MainFooterPage from "../../Reusable/Footer/footer";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";

class mainLatestUpdates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      latest_updates_data: [],
      id:'',
      loading:true,
      bannerImages:'',
      startDate: new Date()
    };

    this.itemRefs = {};
  }


  getBannerImages = async () => {
    this.setState({
      response: fetch("https://10x10learning.com/latest_update_banner_image", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "xxx");
          this.setState({ bannerImages: responseJson[0].link }, () =>
            console.log(this.state.bannerImages)
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  scrollTo(id) {
    // const item_id = `${id}_latest_item`;
    // const yOffset = -100; 
    // const element = document.getElementById(item_id);
    const item_id = this.itemRefs[id]
    const yOffset = -100; 
    const y = item_id.getBoundingClientRect().top + window.pageYOffset + yOffset;

window.scrollTo({top: y, behavior: 'smooth'});
    // console.log(`${id}_latest_item`)

  }

  componentWillMount() {
    this.getBannerImages()
    let language_id = localStorage.getItem("language_id");
    // let id=localStorage.getItem('latestUpdateId')
    this.setState({ language_id },()=>console.log(this.state.id));
  }
  componentDidMount() {
    console.log(this.props, "PROPS")
    this.getLatestUpdates();
  }

  getLatestUpdates = () => {
    let details = {
      language_id: this.state.language_id
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/get_latest_updates", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          var response = responseJson.reverse();
          console.log(responseJson, "xxx");
          let latest = response.sort(
            (a, b) =>
              new Date(b.latest_updates_dates) -
              new Date(a.latest_updates_dates)
          );
          console.log(latest,'latestsort')
          latest= latest.filter(item=>item.status==1)
          if(this.props.location.state == undefined || !this.props.location.state){
            this.setState({ latest_updates_data: latest, loading: false })   
          }
          else{
          this.setState({ latest_updates_data: latest, loading: false },()=>this.scrollTo(this.props.location.state.listItemId));
          }
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };

  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth();
    console.log(dt.getMonth() + 1);
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd;
  }

  commentMonth(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth();
    console.log(dt.getMonth() + 1);
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return monthNames[Number(dt.getMonth())];
  }

  commentYear(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth();
    console.log(dt.getMonth() + 1);
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dt.getFullYear().toString();
  }

  // commenttime(timestamp) {
  //   var convertdLocalTime = new Date(timestamp);
  //   var hours = convertdLocalTime.getHours();
  //   var minutes = convertdLocalTime.getMinutes();
  //   var ampm = hours >= 12 ? "pm" : "am";
  //   hours = hours % 12;
  //   hours = hours ? hours : 12; // the hour '0' should be '12'
  //   minutes = minutes < 10 ? "0" + minutes : minutes;
  //   var strTime = hours + ":" + minutes + " " + ampm;
  //   return strTime;
  // }

  renderDate(item, v) {
    return (
      <div className="HeadingFontDiv">
        <div className="DateDiv">
          <span className="DateFont">
            {this.commentdate(item.latest_updates_dates)}
          </span>
          <span className="MonthFont opensansR">
            {this.commentMonth(item.latest_updates_dates).toUpperCase()}
          </span>
        </div>
        <div className="YearDiv">
          <span className="YearFont">
            {this.commentYear(item.latest_updates_dates)}
          </span>
        </div>
        {/* <text className='rotateText'>Upcoming Exam</text> */}
      </div>
    );
  }

  latestUpdates() {
    return this.state.latest_updates_data
   
      .map((item, index) => {
        console.log(item.latest_updates_dates, "140");
        let now = new Date();
        let examDate = new Date(item.latest_updates_dates);

        return (
          <div ref={el => (this.itemRefs[item.id] = el)}>
            <MDBCardBody className="DivPosition" id={`${item.id}_latest_item`}>
              <div className="DivJustifyContent">
                <div className="DivWidth">
                  <MDBCardTitle tag="h5">
                    {this.renderDate(item, index)}
                  </MDBCardTitle>
                </div>

                <div className="DivWidthTitleDescription">
                  <MDBCardTitle
                    tag="h4"
                    style={{ color: "#303030" }}
                    className="TiltleHeadingFont opensansH"
                  >
                    {item.latest_updates_title.toUpperCase()}
                  </MDBCardTitle>

                  <MDBCardText
                    style={{ color: "#303030" }}
                    className="DescriptionText"
                  >
                    <div
                      className="opensansRLine description-font-size"
                      dangerouslySetInnerHTML={{
                        __html: item.updates_description
                      }}
                    ></div>
                  </MDBCardText>
                </div>
              </div>{" "}
            </MDBCardBody>
          </div>
        );
      });
  }

  renderExamNavigation() {
    return (
      <div style={{ width: "100%" }} className="mt-2">
        <p style={{ fontSize: "14px", margin: "0% 0% 0% 8%" }}>
          {" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => this.props.history.push("/")}
            on
          >
            Home
          </span>{" "}
          / News
        </p>
      </div>
    );
  }
  selectExams(item, index) {}

  render() {
    const override = css`
    display: block;
    margin: 0 auto;
    border-color: black;
    margin: 30% 45%;
  `;

  if (this.state.loading) {
    return (
      <div className="sweet-loading-main">
        <GridLoader
          css={override}
          sizeUnit={"px"}
          size={5}
          color={"#c01825"}
          loading={this.state.loading}
        />
      </div>
    );
  } else {
      return (
        <div id="latest-updates">
          <HomeHeader {...this.props} />
          {/* 
className={`${styles.AboutUsDiscription}`} */}

          <div
            className={this.state.language_id == 0 ? "opensansR" : "opensansR"}
          >
            <div className="Main mb-5">
              <div className="w-10 d-flex justify-content-center Heading" style={{ backgroundImage: `url(${this.state.bannerImages})`,opacity:0.8 }}> 
                <h1 className="BannerFont">
                  {" "}
                  {this.state.language_id == 0 ? (
                    <h1>Latest Updates</h1>
                  ) : (
                    <h1>नवीनतम अपडेट</h1>
                  )}{" "}
                </h1>
              </div>

              <div>{/* {this.renderExamNavigation()} */}</div>

              <div>{this.latestUpdates()}</div>
            </div>

            <div style={{ height: "auto" }}>
              <MainFooterPage {...this.props} />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default mainLatestUpdates;
