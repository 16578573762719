import React from "react";
import "../../Components/commonStyles.css";
import styles from "./homeHeader.module.css";
import Logo from "../../Assets/images/Logo.png";

import SearchIcon from "../../Assets/images/search.png";
import Login from "./login";
import { matchPath } from "react-router-dom";

import swal from "sweetalert";

import {
  MDBIcon,
  MDBCollapse,
  MDBContainer,
} from "mdbreact";
import {NavLink } from "react-router-dom";
import MobileHeader from "./mobileHeader";
import LoginHeader from "./productLoginHeader";

class homepageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin:
        this.props.showLogin === undefined ? false : this.props.showLogin,
      examsMenu: [],
      showSubmenu: false,
      language_id: "",
      collapseID: "",
      subjects: [],
      dashboards: []
    };
  }


  
  getDashboard = () => {
    let details = {
      language_id: this.state.language_id
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/get_dashboard", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          this.setState({ dashboards: responseJson }, () =>
            this.openDashboard(this.state.dashboards)
          );
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };
  getDetails() {
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState(
        {
          userdata: JSON.parse(userdata)
        }
        // () => console.log(this.state.userdata, "uuuu")
      );
    }
  }
  selectVertical(item, index) {
    window.localStorage.removeItem("selected_vertical");
    window.localStorage.removeItem("collapse");
    window.localStorage.setItem("selected_vertical", JSON.stringify(item));
    //   window.localStorage.setItem('collapse',0)
    //   if (this.props.location.pathname.indexOf("dashboard") === 0 ) {
    //     document.location.reload();
    // }
    //  else{
    //    this.props.history.push('/dashboard');
    // }

    if (matchPath(this.props.location.pathname, "/dashboard")) {
      document.location.reload();
    } else {
      this.props.history.push("/dashboard");
    }
  }

  getVerticals = () => {
    this.setState({
      loading: true
    });
    this.setState({
      response: fetch("https://10x10learning.com/get_verticals", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          // console.log(responseJson, "verticals");
          this.setState({
            // verticals:responseJson.reverse(),
            verticalsMenu: responseJson.reverse(),
            loading: false
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });

          console.log(error);
        })
    });
  };

  getExams = async () => {
    console.log("fetching exams");
    this.setState({
      response: fetch("https://10x10learning.com/get_exams", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          // console.log("Ëxams", responseJson);
          // console.log(JSON.parse(responseJson[0].verticals));
          this.setState({
            examsMenu: responseJson.reverse()
          });
        })
        .catch(error => {
          console.log(error);
        })
    });
  };

  getSubjects = async () => {
    this.setState({
      loading: true
    });
    this.setState({
      response: fetch("https://10x10learning.com/get_subjects", {
        method: "GET"
      })
        .then(response => response.json())
        .then(responseJson => {
          this.setState({
            subjects: responseJson,

            loading: false
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };

  componentWillMount() {
    let languageid = localStorage.getItem("language_id");
    if (languageid == null) {
      localStorage.setItem("language_id", 0);
    }
this.setState({language_id:languageid})

    // let finallanguageid=localStorage.getItem('language_id')
    // console.log(finallanguageid)

    this.getDetails();
    this.renderLogin();
    this.getExams();
    this.getVerticals();
    this.getSubjects();
  }

  renderSubjectsInExams = (item, exam_id) => {
    let subjectExams = JSON.parse(item.exams);
    let exam_ids = [];
    subjectExams.forEach(element => {
      exam_ids.push(element.exam_id);
    });
    if (exam_ids.includes(exam_id)) {
      return (
        <li
          className="border-bottom pb-2 w-75 pt-2 ml-3"
          onClick={() => this.setSubject(item)}
        >
          {item.subject_name}
        </li>
      );
    }
  };
  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  };

  renderVerticalsSubMenu(vertical_id) {
    return this.state.examsMenu.map(examItem => {
      var exam_verticals = JSON.parse(examItem.verticals);
      return exam_verticals.map((item, index) => {
        if (item.vertical_id === vertical_id && examItem.status == 1) {
          return (
            <React.Fragment>
              <li
                key={index}
                className="d-flex align-items-center justify-content-betweeen"
                style={{ marginBottom: "1rem" }}
              >
                <span onClick={() => this.selectVertical(item, index)}>
                  {examItem.exam_name}{" "}
                </span>
                <MDBIcon
                  icon="plus"
                  className="iconPlus"
                  onClick={this.toggleCollapse(examItem.exam_name)}
                  color="primary"
                  style={{ marginLeft: "0.5rem" }}
                />
              </li>
              <MDBCollapse
                id={examItem.exam_name}
                isOpen={this.state.collapseID}
              >
                <MDBContainer>
                  <ul className="pl-0">
                    {this.state.subjects.map((item, index) => {
                      return this.renderSubjectsInExams(item, examItem.exam_id);
                    })}
                  </ul>
                </MDBContainer>
              </MDBCollapse>
            </React.Fragment>
          );
        }
      });
    });
  }
  setSubject = item => {
    // console.log(item);
    window.localStorage.removeItem("selected_subject");
    window.localStorage.setItem("selected_subject", JSON.stringify(item));
    if (matchPath(this.props.location.pathname, "/product")) {
      document.location.reload();
    } else {
      this.props.history.push("/product");
    }
  };

  renderClassesMenu() {
    if (this.state.showSubmenu) {
      return (
        <div className={`${styles.ClassesSubMenuContainer}`}>
          <div className={`${styles.RowReverse}`}>
            <div
              className={`${styles.CrossDiv}`}
              id="up"
              onClick={() => this.setState({ showSubmenu: false })}
            >
              <img
                src={require("../../Assets/images/cross.png")}
                className={`${styles.CrossImg}`}
                alt="cross"
              />
            </div>

            <div className={`${styles.ClassesMenuLogo}`}>
              <img
                src="https://10x10learning.com/Logo.png"
                alt="10x10 Logo"
                className={`${styles.LogoImage}`}
              />
            </div>
          </div>
          <div className={`${styles.ClassesSubMenu}`}>
            {this.state.verticalsMenu.map((item, index) => {
              if (item.status == 1)
                return (
                  <ul className="px-0 w-25" key={index}>
                    {item.vertical_name}
                    <hr
                      className="mt-1 mx-0 w-50"
                      style={{ border: "1px solid #d2d2d2" }}
                    />
                    {this.renderVerticalsSubMenu(item.vertical_id)}
                  </ul>
                );
            })}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  onScroll() {
    window.scrollTo(0, 2000);
  }
  openDashboard = item => {
    item.filter(item => {
      if (item.url === "school") {
        localStorage.setItem("Dashboard id", JSON.stringify(item.id));
        localStorage.setItem("dashboard type", item.type);
        this.props.history.push(`/dashboard/${item.type}`);
      }
    });
  };


 
  renderMenu() {
    return (
      <div className="col-9 d-flex row align-item-center">
         <NavLink
          exact
          className={`${styles.ProfileMenuList} `}
          activeClassName={`${styles.activeLink}`}
          to="/"
        >
          {this.state.language_id != 1 ? `Home` : `मुख पृष्ठ`}
          </NavLink>
          <NavLink
          exact
          className={`${styles.ProfileMenuList} `}
          activeClassName={`${styles.activeLink}`}
          to="/about-us"
        >
          {this.state.language_id != 1 ? `About Us` : `हमारे बारे में`}
          </NavLink>
         
  
          <NavLink
          exact
          className={`${styles.ProfileMenuList} `}
          activeClassName={`${styles.activeLink}`}
          to="/dashboard/civil-services"
       
        >
           {this.state.language_id == 0 ? `Civil Services` : `
           सिविल सेवा`}
        </NavLink>
  
  
        <NavLink
        exact
        className={`${styles.ProfileMenuList} `}
        activeClassName={`${styles.activeLink}`}
        to="/dashboard/humanities"
     
      >
         {this.state.language_id == 0 ? `Humanities` : `मानविकी`}
      </NavLink>
      <NavLink
          exact
          className={`${styles.ProfileMenuList} `}
          activeClassName={`${styles.activeLink}`}
          to="/dashboard/CCA"
        >
        {this.state.language_id != 1 ? `Commerce/CA` : `
वाणिज्य / सीए`}
        </NavLink>
      
          <NavLink
            exact
            className={`${styles.ProfileMenuList} `}
            activeClassName={`${styles.activeLink}`}
            to="/dashboard/school"
          >
          {this.state.language_id != 1 ? `Classes` : `कक्षाएं`}
          </NavLink>
  
          {/* <NavLink
            exact
            className={`${styles.ProfileMenuList} `}
            activeClassName={`${styles.activeLink}`}
            to="/other-services"
         
          >
             {this.state.language_id == 0 ? `Other Services` : `अन्य सेवाएं`}
  
          </NavLink> */}
          <NavLink
          exact
          className={`${styles.ProfileMenuList} `}
          activeClassName={`${styles.activeLink}`}
          to="/my-exams"
        >
          {this.state.language_id != 1 ? `Practice` : `
          अभ्यास`}
          </NavLink>
      
        <p
    
          className={`${styles.ProfileMenuListContact} ${styles.contactlogout}`}
        >
          {this.state.language_id != 1 ? `Contact Us` : `संपर्क करें`}
        </p>
      </div>
    );
  }
  renderSocialMediaTab() {
    return (
      <div className="col-3 d-flex row justify-content-end px-0">
        {/* <button className={`${styles.HeaderSocialMedia}`}>
          {" "}
          <MDBIcon fab icon="twitter" />{" "}
        </button> */}
          <button className={`${styles.HeaderSocialMedia}`}>
          <a target="_blank" href="https://facebook.com/10x10learning">
            <MDBIcon fab icon="facebook-f" />
          </a>
        </button>
        <button className={`${styles.HeaderSocialMedia}`}>
          <a target="_blank" href="https://instagram.com/10x10learning">
            <MDBIcon fab icon="instagram" />
          </a>
        </button>
        {/* <button className={`${styles.HeaderSocialMedia}`}>
          <MDBIcon fab icon="pinterest-p" />
        </button> */}
      </div>
    );
  }
  handleSaveEnglish = () => {
    this.setState({ languageid: 0 });
    localStorage.setItem("language_id", 0);
    window.location.reload()
  };
  handleSaveHindi = () => {
    localStorage.setItem("language_id", 1);
    window.location.reload()
  };

  renderheaderBefore() {
    return (
      <div className={this.state.language_id == 0 ? ("opensansR"):("opensansR")}>
        <div className="d-lg-block d-md-none d-none">
          <div className={`${styles.HomepageHeaderTopPart} row  `}>
            <div className="col-4 d-flex justify-content-start pb-3 pt-2  pr-0 py-2">
              <img src="https://10x10learning.com/Logo.png" onClick={() => this.props.history.push("/")} />
            </div>

            <div
              className={`${styles.HomepageHeaderBtnSearchBagraounColor} col-8 pr-0`}
            >
              <div
                className={`${styles.HomepageHeaderBtnSearch} col-7 d-flex justify-content-end pt-4 pl-0`}
              >
                <div className="d-flex justify-content-end">
              

                  <button
                    onClick={() => this.setState({ showLogin: true })}
                    className={`${styles.HeaderBtn} mr-2 ml-4`}
                  >
                 

                    {this.state.language_id == 0 ? `Log in` :`लॉग इन`}
                  </button>
                  <button
                    onClick={() => this.props.history.push("/sign-up")}
                    className={`${styles.HeaderBtn} `}
                  >   {this.state.language_id == 0 ? `Sign up` :`साइन अप`}
                    
                  </button>
                </div>
                <div className={`${styles.InputHeaderSearchDiv} py-3`}>
                  <input
                  

                  placeholder=  {this.state.language_id == 0 ? "Search" :"खोजें" }
                    className={`${styles.InputHeaderSearch}`}
                  />
                  <div className={`${styles.SearchIcon}`}>
                    <img src={SearchIcon} alt="search" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.HomepageHeaderBottomPart}`}>
            <hr className={`${styles.HrTag}`}></hr>
            <hr className={`${styles.HrTag}`}></hr>

            <div className={`${styles.HomepageHeaderMenus}  pt-2`}>
              {this.renderMenu()}
              {this.renderSocialMediaTab()}
            </div>

            <hr className={`${styles.HrTag}`}></hr>
            <hr className={`${styles.HrTag}`}></hr>
          </div>
          <Login showLogin={this.state.showLogin} {...this.props} />
        </div>
      </div>
    );
  }

  renderLogin() {
    if (this.state.userdata == null) {
      return <div>{this.renderheaderBefore()}</div>;
    } else {
      return (
        <div>
          <LoginHeader {...this.props} />
        </div>
      );
    }
  }

  render() {
    return (
      <div
        id="homepageHeader-main "
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden"
        }}
      >
            <div className={this.state.language_id == 0 ? ("opensansR"):("opensansR")}>
        <div className={`${styles.DisplayHeader} `}>{this.renderLogin()}</div>
</div>
        <div className={`${styles.SignOutMobileHeader} `}>
          <MobileHeader {...this.props} />
        </div>

        {this.renderClassesMenu()}
      </div>
    );
  }
}

export default homepageHeader;
