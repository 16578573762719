import React from "react";
import TestBg from "../../Assets/images/Evolution-BG.jpg";
import MainFooterPage from "../../Reusable/Footer/footer";
import HomeHeader from "../../Reusable/Header/homeHeader";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import "./startTest.css";
import swal from "sweetalert";
import "../commonStyles.css";

class startTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instructions: "",
      loading: false,
      InstructionAndDisclaimer: [
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ",
        "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isnt anything embarrassing hidden in the middle of text.",
      ],
      test_type: "",
    };
  }

  componentWillMount() {
    this.setState(
      {
        test_id: JSON.parse(window.localStorage.getItem("selected_test")),
        loading: true,
      },
      () => this.getTestConfig()
    );
  }

  getTestConfig = async () => {
    //let subject = JSON.parse(localStorage.getItem("selected_course_user"));

    let details = {
      test_id: this.state.test_id,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/get_test_by_id", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(
            responseJson,
            "topics response.........................................."
          );
          window.localStorage.setItem(
            "test_id",
            JSON.stringify(responseJson[0].test_id)
          );
          window.localStorage.setItem(
            "test_type",
            JSON.stringify(responseJson[0].test_type)
          );
          var testConfig = JSON.parse(responseJson[0].test_config);
          var duration = responseJson[0].duration;
          var test_type = responseJson[0].test_type;
          let instructions = responseJson[0].instructions;
          localStorage.setItem(
            "test_name_evaluataion",
            responseJson[0].test_name
          );
          console.log(testConfig, "test topics");
          console.log(duration, "duration");
          this.setState({ testConfig, duration, test_type, instructions });
        })
        .then(() => this.getAllQuestions())

        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  getAllQuestions = () => {
    this.setState({
      loading: true,
    });

    let topicIds = [];
    this.state.testConfig.forEach((element) => {
      topicIds.push(Number(element.topic_id));
    });
    console.log(topicIds);
    console.log(this.state.test_type);
    //IF TEST TYPE OBJECTIVE GET OBJECTIVE QUESTIONS

    let formBody = new FormData();
    formBody.append("topic_ids", JSON.stringify(topicIds));
    formBody.append("test_type", this.state.test_type);

    fetch("https://10x10learning.com/get_questions", {
      method: "POST",
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        // let results=responseJson.filter(item=>item.status==1)
        // console.log(results)
        //COMBINING ARRAYS OF QUESTIONS FROM DIFFERENT TOPICS INTO ONE ARRAY
        let questionsResponse = [];
        responseJson.forEach((array, index) => {
          //questionsResponse = [...questionsResponse,...array]
          array.forEach((question) => {
            let topics = [];
            topics.push(question.topic);
            let answers = [];
            answers[0] = new Object();
            answers[0].id = 0;
            answers[0].answer = question.correct_answer;
            answers[0].value = 1; //
            answers[0].link = "";
            //other answers
            answers[1] = new Object();
            answers[1].id = 1;
            answers[1].answer = question.other_option_1;
            answers[1].value = 0;
            answers[1].link = "";

            answers[2] = new Object();
            answers[2].id = 2;
            answers[2].answer = question.other_option_2;
            answers[2].value = 0;
            answers[2].link = "";

            answers[3] = new Object();
            answers[3].id = 3;
            answers[3].answer = question.other_option_3;
            answers[3].value = 0;
            answers[3].link = "";
            question.answers = JSON.stringify(answers);
            question.topics = topics;
          });
          questionsResponse.push({
            topic_id: topicIds[index],
            questions: array,
          });
        });
        console.log(questionsResponse);
        //STRUCTURING EACH QUESTION OBJECT
        this.setState({ questions: questionsResponse });
      })
      .then(() => this.groupTestQuestions());
  };

  randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  shuffleArray(array) {
    console.log(array);
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    console.log(array);
    return [...array];
  }

  pickRandomQuestions(questions, limit) {
    console.log(limit);
    console.log(questions);
    var questionObject = [];
    const orderedQuestions = questions;
    console.log(orderedQuestions);
    if (orderedQuestions.length <= limit) {
      return orderedQuestions;
    } else {
      const randomizedQuestions = this.shuffleArray(orderedQuestions);
      for (let i = 0; i < limit; i++) {
        questionObject.push(randomizedQuestions[i]);
      }

      return questionObject;
    }
    //   console.log(questions)

    // return questionObject;
  }

  groupTestQuestions() {
    let testType = this.state.test_type;

    //  let questions = this.state.questions;

    let testConfig = this.state.testConfig;

    var testsArray = this.state.questions;

    var evaluationQuestions = [];
    testsArray.forEach((element) => {
      var topicConfig = testConfig.find(
        (item) => item.topic_id == element.topic_id
      );
      console.log(topicConfig);
      var questionObject = this.sortGroupedQuestions(element, topicConfig);
      console.log(questionObject);
      evaluationQuestions = [...evaluationQuestions, questionObject];
      console.log(evaluationQuestions, "evaluating questins");
      // console.count("loop")
    });

    var finalTestQuestions = [];
    evaluationQuestions.forEach((element) => {
      for (var i = 0; i < element.length; i++) {
        console.log(element);
        finalTestQuestions.push(element[i]);
      }
    });

    console.log(finalTestQuestions, "final final fingers crossed");
    if (testType == "Objective") {
      finalTestQuestions.forEach((element) => {
        if (element.question_id) {
          let answers = JSON.parse(element.answers);
          answers = this.shuffleArray(answers);
          element.answers = JSON.stringify(answers);
        }
      });
    }

    console.log(finalTestQuestions);
    this.setState({ finalTestQuestions, loading: false });
  }

  sortGroupedQuestions(topicQuestions, topicConfig) {
    console.log(topicQuestions);
    var easyQuestions = this.pickRandomQuestions(
      topicQuestions.questions.filter((item) => item.difficulty_level == 1),
      topicConfig.easy
    );
    var moderateQuestions = this.pickRandomQuestions(
      topicQuestions.questions.filter((item) => item.difficulty_level == 2),
      topicConfig.moderate
    );
    var toughQuestions = this.pickRandomQuestions(
      topicQuestions.questions.filter((item) => item.difficulty_level == 3),
      topicConfig.tough
    );
    console.log(easyQuestions, "easy");
    console.log(moderateQuestions, "modeate");
    console.log(toughQuestions, "tough");

    return [...easyQuestions, ...moderateQuestions, ...toughQuestions];
  }

  renderInstructionAndDisclaimer() {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: this.state.instructions,
        }}
      ></div>
    );
    // return this.state.InstructionAndDisclaimer.map(member => {
    //   return (
    //     <div key={member}>
    //       <div>
    //         <div>
    //           <ul>
    //             <li>{member}</li>
    //           </ul>
    //         </div>
    //         <div></div>
    //       </div>
    //     </div>
    //   );
    // });
    return;
  }

  handleStartTest() {
    this.props.history.push({
      pathname: "/newEvaluation",
      state: {
        questions: this.state.finalTestQuestions,
        duration: this.state.duration,
      },
    });
  }

  render() {
    console.log(this.state);
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
          className="start-test-page"
        >
          <div>
            <HomeHeader {...this.props} width="100%" height="auto" />
          </div>

          <div className="start-test">
            <div className="position-relative">
              {" "}
              <div className="bg_img_div">
                <img className="bg_img" src={TestBg} />
              </div>
              <div className="button_and_text">
                <div className="text_on_test_BGimg">
                  <text>Ensure your marks by completing this test. </text>
                </div>
                <div className="buttons_flex">
                  <div>
                    <button
                      className="Button_NotNow"
                      onClick={() => this.props.history.push("/lessons")}
                    >
                      {" "}
                      Not Now
                    </button>
                  </div>

                  <div style={{ marginLeft: "10px" }}>
                    <button
                      className="Button_startTest"
                      onClick={() => this.handleStartTest()}
                    >
                      {" "}
                      Start Test{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="startTestDecrpition">
              <div>
                <h1 className="startTestDecrpitionH1">
                  Instructions And Disclaimer
                </h1>
                <div className="RegistrationCheckBoxhr"></div>
              </div>
              <div className="startTestList">
                {this.renderInstructionAndDisclaimer()}
              </div>
            </div>
          </div>

          <div style={{ height: "auto" }} className="windowView">
            <MainFooterPage {...this.props} />
          </div>
        </div>
      );
    }
  }
}

export default startTest;
