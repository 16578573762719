// import React from 'react';
import React, { Component } from "react";
// import StarsRating from 'stars-rating'
// import { NavItem, Nav,} from "react-bootstrap";
import "./myCart.css";
// import { FulfillingBouncingCircleSpinner } from 'react-epic-spinners';
import swal from "sweetalert";
import ReactDOM from "react-dom";
import AImg from "../../Assets/images/v2.PNG";
import BImg from "../../Assets/images/v3.PNG";
import AccountNav from "../../Reusable/AccountNavigation/AccountNav";
import MainFooterPage from "../../Reusable/Footer/footer.jsx";
import HomeHeader from "../../Reusable/Header/homeHeader";
import Fade from "react-reveal/Fade";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import ReactToPrint from "react-to-print";
import ReactTooltip from "react-tooltip";
import ProfTen from "../Prof10X10/prof10x10";

import { HollowDotsSpinner } from "react-epic-spinners";
import {
  MDBIcon,
  MDBModal,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBModalTitle,
  MDBCol,
  MDBCarousel,
  MDBRow,
  MDBCarouselItem,
  MDBCarouselInner,
  MDBTooltip,
} from "mdbreact";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import Swal from "sweetalert2";
import converter from "number-to-words";
import { RegionDropdown } from "react-country-region-selector";

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myCart: [],
      cart: [],
      all_subjects: [],
      old_total: 0,
      address: null,
      edit_address: false,
      add_address: false,
      address_edit_text: "",
      address_add_text: "",
      address_loading: false,
      calculated_tax: 0,
      calculated_cess: 0,
      date: new Date(),
      invoice_modal: false,
      invoice_number: "",
      promos: [],
      PromoCode: "",
      new_total: 0,
      all_orders: [],
      discount: 0,
      promo_data: null,
      promoApplied: false,
      total_cost: 0,
      promo_modal: false,
      country: "",
      region: "",
      phone: "",
      language_id: "",
      pincode: "",
      state: "",
      tax: [],
      new_order_id: 0,
      download_invoice: 0,
      loading: true,
    };
  }
  getPromos = async () => {
    this.setState({
      loading: true,
    });

    let details = {};

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/get_promo_codes", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            promos: responseJson,
          });

          console.log(this.state.promos, "promos");
        })
        .catch((error) => {
          this.setState({});

          console.log(error);
        }),
    });
  };
  getUsers = async () => {
    this.setState({
      response: fetch("https://10x10learning.com/get_users", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            users: responseJson,
          });

          console.log(this.state.users, "users____");
        })
        .catch((error) => {
          this.setState({});

          console.log(error);
        }),
    });
  };
  componentDidMount() {
    // this.getHeader()
  }
  componentWillMount() {
    let language_id = localStorage.getItem("language_id");
    console.log(language_id);
    this.setState({ language_id });

    this.getDetails();
    this.getPromos();
    this.getUsers();
    this.getInvoiceNo();
    this.getTax();
  }

  getDialogues = async () => {
    let formBody = new FormData();
    formBody.append("language_id", this.state.language_id);
    try {
      const dialogueResponse = await fetch(
        "https://10x10learning.com/prof10_mycart",
        {
          method: "POST",
          body: formBody,
        }
      );
      const result = await dialogueResponse.json();
      const dialogues = JSON.parse(result[0].text).map(
        (item) => item.description
      );
      this.setState({ dialogues });
    } catch (err) {
      console.log(err);
    }
  };
  handleApply = () => {
    console.log("called");
    if (this.state.PromoCode.length == 0) {
    } else {
      var promos = [],
        offerCount = 0;
      this.setState({ dialogVisible: false, old_total: this.state.total_cost });
      for (let v = 0; v < this.state.promos.length; v++) {
        console.log(
          this.state.PromoCode,
          this.state.promos[v].promo_code.toUpperCase()
        );
        if (
          this.state.PromoCode.toUpperCase() ===
            this.state.promos[v].promo_code.toUpperCase() &&
          this.state.promos[v].type === 0
        ) {
          offerCount = offerCount + 1;

          for (let v1 = 0; v1 < this.state.all_orders.length; v1++) {
            if (
              this.state.promos[v].promo_id ===
              this.state.all_orders[v1].promo_id
            ) {
              promos.push(this.state.all_orders[v1]);
            }
          }
          if (promos.length >= this.state.promos[v].use_limit) {
            swal("Warning!", "You have used this offer!", "warning");
            this.setState({
              promoApplied: false,
              PromoCode: "",
            });
          } else if (this.state.promos[v].status === 2) {
            swal("Warning!", "This offer is no longer available", "warning");
            this.setState({
              promoApplied: false,
              PromoCode: "",
            });
          } else if (
            this.state.old_total >=
            parseFloat(this.state.promos[v].minimum_order)
          ) {
            let discount = (
              this.state.old_total *
              (this.state.promos[v].discount / 100)
            ).toFixed(2);
            if (discount > parseFloat(this.state.promos[v].max_off)) {
              this.setState({
                old_total:
                  this.state.old_total -
                  parseFloat(this.state.promos[v].max_off),
                promoApplied: true,
                promo_id: this.state.promos[v].promo_id,
                discount: parseFloat(this.state.promos[v].max_off),
                promo_data: this.state.promos[v],
              });
            } else {
              this.setState({
                old_total: this.state.old_total - discount,
                promoApplied: true,
                promo_id: this.state.promos[v].promo_id,
                discount: discount,
                promo_data: this.state.promos[v],
              });
            }
          } else {
            swal(
              "Warning!",
              `Applicable on cart value of more than Rs.${this.state.promos[v].minimum_order}`,
              "warning"
            );
            this.setState({
              promoApplied: false,
              PromoCode: "",
            });
          }
        } else if (
          this.state.PromoCode.toUpperCase() ===
            this.state.promos[v].promo_code.toUpperCase() &&
          this.state.promos[v].type === 1
        ) {
          offerCount = offerCount + 1;
          console.log("====1");
          for (let v1 = 0; v1 < this.state.all_orders.length; v1++) {
            if (
              this.state.promos[v].id === this.state.all_orders[v1].promo_id
            ) {
              promos.push(this.state.all_orders[v1]);
            }
          }
          if (promos.length >= this.state.promos[v].use_limit) {
            swal("Warning!", "You have used this offer!", "warning");

            this.setState({
              promoApplied: false,
              PromoCode: "",
            });
          } else if (this.state.promos[v].status === 0) {
            swal("", "This offer is no longer available!", "info");
            this.setState({
              promoApplied: false,
              PromoCode: "",
            });
          } else {
            let offerItems = [];
            let orderItems = [];
            let count = 0;
            console.log(this.state.myCart);
            for (let v3 = 0; v3 < this.state.myCart.length; v3++) {
              orderItems.push(this.state.myCart[v3].subject_id);
            }
            for (
              let v2 = 0;
              v2 < JSON.parse(this.state.promos[v].products).length;
              v2++
            ) {
              offerItems.push(
                JSON.parse(this.state.promos[v].products)[v2].value
              );
            }
            console.log(orderItems, offerItems);
            for (let a = 0; a < orderItems.length; a++) {
              if (offerItems.includes(orderItems[a]) === false) {
                count = count + 1;
              }
            }
            if (count > 0) {
              swal(
                "Warning!",
                "This offer is not applicable on all items!",
                "warning"
              );
            } else {
              if (
                this.state.old_total >=
                parseFloat(this.state.promos[v].minimum_order)
              ) {
                let discount = (
                  this.state.old_total *
                  (this.state.promos[v].discount / 100)
                ).toFixed(2);
                if (discount > parseFloat(this.state.promos[v].max_off)) {
                  this.setState({
                    old_total:
                      this.state.old_total -
                      parseFloat(this.state.promos[v].max_off),
                    promoApplied: true,
                    promo_id: this.state.promos[v].promo_id,
                    discount: parseFloat(this.state.promos[v].max_off),
                    promo_data: this.state.promos[v],
                  });
                } else {
                  this.setState({
                    old_total: this.state.old_total - discount,
                    promoApplied: true,
                    promo_id: this.state.promos[v].promo_id,
                    discount: discount,
                    promo_data: this.state.promos[v],
                  });
                }
              } else {
                swal(
                  "Warning!",
                  `Applicable on cart value of more than Rs.${this.state.promos[v].minimum_order}`,
                  "warning"
                );
                this.setState({
                  promoApplied: false,
                  PromoCode: "",
                });
              }
            }
          }
        } else if (
          this.state.PromoCode.toUpperCase() ===
            this.state.promos[v].promo_code.toUpperCase() &&
          this.state.promos[v].type === 2
        ) {
          offerCount = offerCount + 1;
          if (this.state.promos[v].lower_limit > this.state.myCart.length) {
            swal(
              "",
              `To avail this offer you have to purchase minimum ${this.state.promos[v].lower_limit} products`,
              "warning"
            );
            this.setState({
              promoApplied: false,
              PromoCode: "",
            });
          } else {
            for (let v1 = 0; v1 < this.state.all_orders.length; v1++) {
              if (
                this.state.promos[v].promo_id ===
                this.state.all_orders[v1].promo_id
              ) {
                promos.push(this.state.all_orders[v1]);
              }
            }
            if (promos.length >= this.state.promos[v].use_limit) {
              swal("Warning!", "You have used this offer!", "warning");
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            } else if (this.state.promos[v].status === 2) {
              swal("Warning!", "This offer is no longer available", "warning");
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            } else if (
              this.state.old_total >=
              parseFloat(this.state.promos[v].minimum_order)
            ) {
              let discount = (
                this.state.old_total *
                (this.state.promos[v].discount / 100)
              ).toFixed(2);
              if (discount > parseFloat(this.state.promos[v].max_off)) {
                this.setState({
                  old_total:
                    this.state.old_total -
                    parseFloat(this.state.promos[v].max_off),
                  promoApplied: true,
                  promo_id: this.state.promos[v].promo_id,
                  discount: parseFloat(this.state.promos[v].max_off),
                  promo_data: this.state.promos[v],
                });
              } else {
                this.setState({
                  old_total: this.state.old_total - discount,
                  promoApplied: true,
                  promo_id: this.state.promos[v].promo_id,
                  discount: discount,
                  promo_data: this.state.promos[v],
                });
              }
            } else {
              swal(
                "Warning!",
                `Applicable on cart value of more than Rs.${this.state.promos[v].minimum_order}`,
                "warning"
              );
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            }
          }
        } else if (
          this.state.PromoCode.toUpperCase() ===
            this.state.promos[v].promo_code.toUpperCase() &&
          this.state.promos[v].type === 3
        ) {
          offerCount = offerCount + 1;
          if (this.state.promos[v].lower_limit > this.state.myCart.length) {
            swal(
              "",
              `To avail this offer you have to purchase minimum ${this.state.promos[v].lower_limit} products`,
              "warning"
            );
            this.setState({
              promoApplied: false,
              PromoCode: "",
            });
          } else {
            for (let v1 = 0; v1 < this.state.all_orders.length; v1++) {
              if (
                this.state.promos[v].promo_id ===
                this.state.all_orders[v1].promo_id
              ) {
                promos.push(this.state.all_orders[v1]);
              }
            }
            if (promos.length >= this.state.promos[v].use_limit) {
              swal("Warning!", "You have used this offer!", "warning");
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            } else if (this.state.promos[v].status === 2) {
              swal("Warning!", "This offer is no longer available", "warning");
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            } else if (
              this.state.old_total >=
              parseFloat(this.state.promos[v].minimum_order)
            ) {
              let discount = (
                this.state.old_total *
                (this.state.promos[v].discount / 100)
              ).toFixed(2);
              if (discount > parseFloat(this.state.promos[v].max_off)) {
                this.setState({
                  old_total:
                    this.state.old_total -
                    parseFloat(this.state.promos[v].max_off),
                  promoApplied: true,
                  promo_id: this.state.promos[v].promo_id,
                  discount: parseFloat(this.state.promos[v].max_off),
                  promo_data: this.state.promos[v],
                });
              } else {
                this.setState({
                  old_total: this.state.old_total - discount,
                  promoApplied: true,
                  promo_id: this.state.promos[v].promo_id,
                  discount: discount,
                  promo_data: this.state.promos[v],
                });
              }
            } else {
              swal(
                "Warning!",
                `Applicable on cart value of more than Rs.${this.state.promos[v].minimum_order}`,
                "warning"
              );
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            }
          }
        } else if (
          this.state.PromoCode.toUpperCase() ===
            this.state.promos[v].promo_code.toUpperCase() &&
          this.state.promos[v].type === 4
        ) {
          offerCount = offerCount + 1;
          var valid_user = 0;

          if (this.state.promos[v].lower_limit > this.state.myCart.length) {
            swal(
              "",
              `To avail this offer you have to purchase minimum ${this.state.promos[v].lower_limit} products`,
              "warning"
            );
            this.setState({
              promoApplied: false,
              PromoCode: "",
            });
          } else {
            for (let v1 = 0; v1 < this.state.all_orders.length; v1++) {
              if (
                this.state.promos[v].promo_id ===
                this.state.all_orders[v1].promo_id
              ) {
                promos.push(this.state.all_orders[v1]);
              }
            }
            for (
              let i = 0;
              i < JSON.parse(this.state.promos[v].users).length;
              i++
            ) {
              if (
                JSON.parse(this.state.promos[v].users)[i].value ===
                this.state.userdata.user_id
              ) {
                valid_user = valid_user + 1;
              }
            }
            if (promos.length >= this.state.promos[v].use_limit) {
              swal("", "You have used this offer!", "warning");
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            } else if (this.state.promos[v].status === 2) {
              swal("", "This offer is no longer available", "warning");
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            } else if (valid_user == 0) {
              swal("", "Offer is not applicable", "warning");
            } else if (
              this.state.old_total >=
              parseFloat(this.state.promos[v].minimum_order)
            ) {
              let discount = (
                this.state.old_total *
                (this.state.promos[v].discount / 100)
              ).toFixed(2);
              if (discount > parseFloat(this.state.promos[v].max_off)) {
                this.setState({
                  old_total:
                    this.state.old_total -
                    parseFloat(this.state.promos[v].max_off),
                  promoApplied: true,
                  promo_id: this.state.promos[v].promo_id,
                  discount: parseFloat(this.state.promos[v].max_off),
                  promo_data: this.state.promos[v],
                });
              } else {
                this.setState({
                  old_total: this.state.old_total - discount,
                  promoApplied: true,
                  promo_id: this.state.promos[v].promo_id,
                  discount: discount,
                  promo_data: this.state.promos[v],
                });
              }
            } else {
              swal(
                "Warning!",
                `Applicable on cart value of more than Rs.${this.state.promos[v].minimum_order}`,
                "warning"
              );
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            }
          }
        }
      }

      if (offerCount === 0) {
        swal("Warning!", "Invalid Promo Code!", "warning");
        this.setState({
          promoApplied: false,
          PromoCode: "",
          old_total: this.state.total_cost,
        });
      }
    }
  };

  getDetails() {
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState(
        {
          userdata: JSON.parse(userdata),
          phone: JSON.parse(userdata).phone,
          loading: true,
        },
        () => {
          this.getCart();
          this.getOrders();
        }
      );
    } else {
      this.props.history.push("/dashboard");
    }
  }
  confirmDelete = (item) => {
    swal({
      title: "Payment Successfull !!",
      text: "Thank you for purchasing this course!!",
      icon: "success",
    }).then(() => {
      this.props.history.push("/my-courses");
    });
  };
  activeC(data) {
    let { myCart } = this.state;

    for (var v = 0; v < myCart.length; v++) {
      let sl = myCart[v];
      sl.course_status = "active";
    }
    this.setState(
      {
        myCart,
      },
      () => this.addOrder(data)
    );
  }
  addOrder = async (data) => {
    this.setState({
      loading: true,
    });

    const igst = this.state.tax.filter(
      (item) => item.tax_name.toLowerCase() == "igst"
    );
    console.log(igst);
    const cgst = this.state.tax.filter(
      (item) => item.tax_name.toLowerCase() == "cgst"
    );
    const sgst = this.state.tax.filter(
      (item) => item.tax_name.toLowerCase() == "sgst"
    );
    let details = {
      user_id: this.state.userdata.user_id,
      name: data.name,
      address: JSON.stringify(data.address),
      phone: data.phone,
      amount: (
        this.state.old_total +
        (this.state.old_total * this.state.calculated_tax) / 100
      ).toFixed(2),
      cost_exc_tax: this.state.old_total.toFixed(2),
      IGST: this.state.state.toLowerCase() == "haryana" ? 0 : igst[0].tax_value,
      SGST: this.state.state.toLowerCase() == "haryana" ? sgst[0].tax_value : 0,
      CGST: this.state.state.toLowerCase() == "haryana" ? cgst[0].tax_value : 0,
      promo_id: data.promo_id,
      payment_id: data.payment_id,
      items: JSON.stringify(this.state.myCart),
    };
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/insert_order", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          //this.confirmDelete()
          this.setState(
            {
              new_order_id: responseJson.order_id,
              loading: false,
            },
            () => {
              window.localStorage.setItem(
                "invoice_state",
                JSON.stringify({
                  userdata: this.state.userdata,
                  new_order_id: responseJson.order_id,
                  myCart: this.state.myCart,
                  address: this.state.address,
                  invoice_number: this.state.invoice_number,
                  promoApplied: this.state.promoApplied,
                  discount: this.state.discount,
                  state: this.state.state,
                  tax: this.state.tax,
                  old_total: this.state.old_total,
                  calculated_tax: this.state.calculated_tax,
                  date:this.state.date
                })
              );
              this.props.history.push({
                pathname: "/invoice",
                state: {
                  userdata: this.state.userdata,
                  new_order_id: responseJson.order_id,
                  myCart: this.state.myCart,
                  address: this.state.address,
                  invoice_number: this.state.invoice_number,
                  promoApplied: this.state.promoApplied,
                  discount: this.state.discount,
                  state: this.state.state,
                  tax: this.state.tax,
                  old_total: this.state.old_total,
                  calculated_tax: this.state.calculated_tax,
                  date:this.state.date
                },
              });
              // window.open("http://localhost:3000/invoice", '_blank')
            }
          );
          // this.setState({
          //   loading:false
          // })
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };
  toggle_invoice = () => {
    this.setState({
      invoice_modal: !this.state.invoice_modal,
    });
  };
  titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  }
  getInvoiceNo = async () => {
    this.setState({
      response: fetch("https://10x10learning.com/get_invoice_no", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({ invoice_number: responseJson.invoice_number });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  renderTaxColumn() {
    if (this.state.state.toLowerCase() != "haryana") {
      return [...this.state.tax].reverse().map((tax_data, tax_data_key) => {
        if (tax_data.tax_id == 8) {
          return (
            <React.Fragment>
              <tr style={{ fontSize: "14px" }}>
                <td></td>
                <td>
                  {" "}
                  {tax_data.tax_name}&nbsp;@&nbsp;{tax_data.tax_value}%
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td style={{ textAlign: "center" }}>
                  Rs.&nbsp;
                  {(
                    (Number(this.state.old_total) * tax_data.tax_value) /
                    100
                  ).toFixed(2)}
                </td>
              </tr>
              {this.renderCess2(tax_data, tax_data_key)}
            </React.Fragment>
          );
        }
        if (tax_data.tax_id == 1 || tax_data.tax_id == 2) {
          return (
            <React.Fragment>
              <tr style={{ fontSize: "14px" }}>
                <td></td>
                <td>
                  {" "}
                  {tax_data.tax_name}&nbsp;@&nbsp;{tax_data.tax_value}%{" "}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ textAlign: "center" }}>-</td>
              </tr>
            </React.Fragment>
          );
        }
      });
    } else {
      return [...this.state.tax].reverse().map((tax_data, tax_data_key) => {
        if (tax_data.tax_id == 1 || tax_data.tax_id == 2) {
          return (
            <React.Fragment>
              <tr style={{ fontSize: "14px" }}>
                <td></td>
                <td>
                  {tax_data.tax_name}&nbsp;@&nbsp;{tax_data.tax_value}%
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ textAlign: "center" }}>
                  Rs.&nbsp;
                  {(Number(this.state.old_total) * tax_data.tax_value) / 100}
                </td>
              </tr>
              {this.renderCess2(tax_data, tax_data_key)}
            </React.Fragment>
          );
        }
        if (tax_data.tax_id == 8) {
          return (
            <React.Fragment>
              <tr style={{ fontSize: "14px" }}>
                <td></td>
                <td>
                  {" "}
                  {tax_data.tax_name}&nbsp;@ &nbsp;{tax_data.tax_value}%
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ textAlign: "center" }}>-</td>
              </tr>
            </React.Fragment>
          );
        }
      });
    }
  }

  renderCess2(data, index) {
    if (data.cess != null) {
      return JSON.parse(data.cess).map((item, index) => {
        return (
          <tr style={{ fontSize: "14px" }}>
            <td></td>
            <td>
              {item.name} @ {item.value}%
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ textAlign: "center" }}>
              Rs.{" "}
              {(((Number(this.state.old_total) * data.tax_value) / 100) *
                Number(item.value)) /
                (100).toFixed(2)}
            </td>
          </tr>
        );
      });
    }
  }
  renderCess(data, index) {
    if (data.cess != null) {
      return JSON.parse(data.cess).map((item, index) => {
        return (
          <div className="rowDefault myCartTotal">
            <text>
              {item.name} @ {item.value}%
            </text>

            <text style={{ textAlign: "end" }}>
              Rs.
              {(
                (((Number(this.state.old_total) * data.tax_value) / 100) *
                  Number(item.value)) /
                100
              ).toFixed(2)}
            </text>
          </div>
        );
      });
    }
  }
  renderTaxColumn2() {
    if (this.state.myCart.length != 0) {
      if (this.state.tax.length != 0) {
        if (this.state.state.toLowerCase() != "haryana") {
          return this.state.tax.map((tax_data, tax_data_key) => {
            if (tax_data.tax_id == 8) {
              return (
                <React.Fragment>
                  <div className="rowDefault myCartTotal">
                    <text>
                      {tax_data.tax_name} @ {tax_data.tax_value}%
                    </text>

                    <text style={{ textAlign: "end" }}>
                      Rs.
                      {(
                        (Number(this.state.old_total) * tax_data.tax_value) /
                        100
                      ).toFixed(2)}
                    </text>
                  </div>
                  {this.renderCess(tax_data, tax_data_key)}
                </React.Fragment>
              );
            } else return;
          });
        } else {
          return this.state.tax.map((tax_data, tax_data_key) => {
            if (tax_data.tax_id == 1 || tax_data.tax_id == 2) {
              console.log(
                `Rs.${
                  (Number(this.state.old_total) * tax_data.tax_value) / 100
                }`
              );
              return (
                <React.Fragment>
                  <div className="rowDefault myCartTotal">
                    <text>
                      {tax_data.tax_name} @ {tax_data.tax_value}%
                    </text>

                    <text style={{ textAlign: "end" }}>
                      Rs.
                      {(
                        (Number(this.state.old_total) * tax_data.tax_value) /
                        100
                      ).toFixed(2)}
                    </text>
                  </div>
                  {this.renderCess(tax_data, tax_data_key)}
                </React.Fragment>
              );
            } else return;
          });
        }
      }
    }
  }
  renderTestCostRow(data, key) {
    if (data.test_included == 1) {
      return (
        <tr style={{ fontSize: "14px" }}>
          <td style={{ textAlign: "center", fontSize: "14px" }}>
            {data.subject_included ? key + 2 : key + 1}
          </td>
          <td style={{ textAlign: "left", fontSize: "14px" }}>
            {data.subject_name}&nbsp;Test
          </td>
          <td style={{ textAlign: "center", fontSize: "14px" }}>998431</td>
          <td style={{ textAlign: "center", fontSize: "14px" }}>1</td>
          <td style={{ textAlign: "center", fontSize: "14px" }}>Nos.</td>
          <td style={{ textAlign: "center", fontSize: "14px" }}>
            Rs.&nbsp;{Number(JSON.parse(data.test_cost)).toFixed(2)}{" "}
          </td>
          <td style={{ textAlign: "right", fontSize: "14px" }}>
            Rs.&nbsp;{Number(JSON.parse(data.test_cost)).toFixed(2)}
          </td>
        </tr>
      );
    }
  }
  //
  //
  // <td>9%</td>
  // <td></td>
  // <td></td>
  // <td></td>
  // <td></td>
  // <td></td>

  renderTaxColumn3() {
    if (this.state.state.toLowerCase() != "haryana") {
      return [...this.state.tax].reverse().map((tax_data, tax_data_key) => {
        if (tax_data.tax_id == 8) {
          return (
            <React.Fragment>
              <td style={{ textAlign: "center" }}>
                {tax_data.tax_value}&nbsp;%
              </td>
              <td style={{ textAlign: "center" }}>
                Rs.&nbsp;
                {(
                  (Number(this.state.old_total) * tax_data.tax_value) /
                  100
                ).toFixed(2)}
              </td>
            </React.Fragment>
          );
        }
        if (tax_data.tax_id == 1 || tax_data.tax_id == 2) {
          return (
            <React.Fragment>
              <td style={{ textAlign: "center" }}>
                {" "}
                {tax_data.tax_value}&nbsp;%{" "}
              </td>
              <td style={{ textAlign: "center" }}> - </td>
            </React.Fragment>
          );
        }
      });
    } else {
      return [...this.state.tax].reverse().map((tax_data, tax_data_key) => {
        if (tax_data.tax_id == 1 || tax_data.tax_id == 2) {
          return (
            <React.Fragment>
              <td style={{ textAlign: "center" }}>
                {tax_data.tax_value}&nbsp;%
              </td>
              <td style={{ textAlign: "center" }}>
                Rs.&nbsp;
                {(Number(this.state.old_total) * tax_data.tax_value) / 100}
              </td>
            </React.Fragment>
          );
        }
        if (tax_data.tax_id == 8) {
          return (
            <React.Fragment>
              <td style={{ textAlign: "center" }}>
                {tax_data.tax_value}&nbsp;%
              </td>
              <td style={{ textAlign: "center" }}>-</td>
            </React.Fragment>
          );
        }
      });
    }
  }

  renderDiscountRow() {
    if (this.state.promoApplied) {
      return (
        <tr style={{ fontSize: "14px" }}>
          <td></td>
          <td>Discount </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td style={{ textAlign: "center" }}>
            -Rs.&nbsp;{this.state.discount}
          </td>
        </tr>
      );
    }
  }

  // <th style={{ width: "10%" }}>Sl. No</th>
  // <th style={{ width: "40%" }}>Particulars</th>
  // <th style={{ width: "10%" }}>HSN / SAC Code</th>
  // <th style={{ width: "10%" }}>Qty</th>
  // <th style={{ width: "10%" }}>UOM</th>
  // <th style={{ width: "10%" }}>Rate</th>
  // <th style={{ width: "10%" }}>Amount</th>

  renderSujectCostRow(data, key) {
    if (data.subject_included == 1) {
      return (
        <tr style={{ fontSize: "14px" }}>
          <td style={{ textAlign: "center" }}>{key + 1}</td>
          <td style={{ textAlign: "left" }}>{data.subject_name} </td>
          <td style={{ textAlign: "center" }}>998431</td>
          <td style={{ textAlign: "center" }}>1</td>
          <td style={{ textAlign: "center" }}>Nos.</td>
          <td style={{ textAlign: "center" }}>
            Rs.&nbsp;{Number(data.cost).toFixed(2)}{" "}
          </td>
          <td style={{ textAlign: "right" }}>
            Rs.&nbsp;{Number(data.cost).toFixed(2)}
          </td>
        </tr>
      );
    }
  }

  renderExamCostRow(data, key) {
    return (
      <tr style={{ fontSize: "14px" }}>
        <td style={{ textAlign: "center" }}>{key + 1}</td>
        <td style={{ textAlign: "left" }}>{data.service_name}</td>
        <td style={{ textAlign: "center" }}>998431</td>
        <td style={{ textAlign: "center" }}>1</td>
        <td style={{ textAlign: "center" }}>Nos</td>
        <td style={{ textAlign: "center" }}>
          Rs.&nbsp;{Number(data.cost).toFixed(2)}{" "}
        </td>
        <td style={{ textAlign: "center" }}>
          Rs.&nbsp;{Number(data.cost).toFixed(2)}
        </td>
      </tr>
    );
  }
  printDiv() {
    var printContents = document.getElementById("invoice").innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
  }



  payment() {
    if (this.state.state == null) {
      swal("Select state", "", "info");
    } else if (this.state.phone == null || this.state.phone.length == 0) {
      swal("Enter phone", "", "info");
    } else if (
      this.state.address_edit_text == null ||
      this.state.address_edit_text.length == 0
    ) {
      swal("Enter address", "", "info");
    } else {
      if (this.state.myCart.length != 0) {
        var orderData = {
          name: this.state.userdata.name,
          phone: this.state.userdata.phone,
          items: this.state.myCart,
          address: this.state.address,
          price: this.state.old_total,
          promo_id:
            this.state.promo_data == null ? 0 : this.state.promo_data.promo_id,
        };

        let options = {
          currency: "INR",
          key: "rzp_live_45Y84k5Zpd6N9h",
          amount: (
            (orderData.price +
              (Number(
                this.state.promoApplied
                  ? this.state.total_cost - this.state.discount
                  : this.state.old_total
              ) *
                18) /
                100) *
            100
          ).toFixed(2), // 2000 paise = INR 20, amount in paisa
          name: "10x10 Learning",
          description: "Pay to 10x10 Learning",

          handler: (response) => {
            orderData.payment_id = response.razorpay_payment_id;
            this.setState({
              orderData: orderData,
            });
            this.activeC(orderData);
          },
          prefill: {
            email: this.state.userdata.email,
            contact: this.state.phone,
            name: this.state.userdata.name,
          },

          theme: {
            color: "#e7a331",
          },
        };

        let rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        swal("Cart is empty", "", "error");
      }
    }
  }

  trigger() {
    this.getCart();
    console.log(this.state.promoApplied);
  }
  removeCart = async (data) => {
    this.setState({
      loading: true,
    });
    let details = {
      user_id: this.state.userdata.user_id,
      items: JSON.stringify([{ subject_id: data.subject_id }]),
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/remove_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({}, () => this.trigger());
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  toggle_promo = () => {
    this.setState({
      promo_modal: !this.state.promo_modal,
    });
  };
  renderModal() {
    if (this.state.promo_modal) {
      return (
        <MDBModal
          isOpen={this.state.promo_modal}
          toggle={this.toggle_promo}
          fullHeight
          position="right"
          className="promo-modal-container"
        >
          <MDBModalHeader toggle={this.toggle_promo}>
            <span
              style={{
                fontFamily: "Ubuntu-r",
                fontSize: "18px",
                color: "#4A4A4A",
              }}
            >
              {this.state.language_id == 0 ? (
                <React.Fragment>Promo Codes</React.Fragment>
              ) : (
                <React.Fragment>प्रोमो कोड</React.Fragment>
              )}
            </span>{" "}
          </MDBModalHeader>
          <MDBModalBody>
            <div className="promo-container">
              {this.state.promos.map((item) => {
                return (
                  <div className="promo-item" key={item.promo_id}>
                    <p className="promo-name">
                      <span>
                        <span>
                          <img src={item.image} className="promo-image" />
                        </span>
                        <span>{item.promo_code}</span>
                      </span>
                    </p>
                    <p className="promo-heading"> {item.heading} </p>
                    <p className="promo-description">{item.description}</p>
                    <button
                      className="apply-promo-button btn"
                      onClick={() =>
                        this.setState({ PromoCode: item.promo_code }, () => {
                          (() => this.handleApply())();
                          (() => this.setState({ promo_modal: false }))();
                        })
                      }
                    >
                      {this.state.language_id == 0 ? (
                        <React.Fragment>Apply Promo</React.Fragment>
                      ) : (
                        <React.Fragment>प्रोमो लागू करें</React.Fragment>
                      )}
                    </button>
                  </div>
                );
              })}
            </div>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }
  renderCost(modal) {
    console.log(modal, "aaa");
    if (modal.service_included == false) {
      if (modal.old_cost == 0) {
        return (
          <div className="myCoursesCostAfterSingle">
            <text
              style={{ display: modal.subject_included == 0 ? "none" : "flex" }}
              className="myCoursesFinalCost"
            >
              {this.state.language_id == 0 ? (
                <React.Fragment>Rs.</React.Fragment>
              ) : (
                <React.Fragment>₹</React.Fragment>
              )}{" "}
              {Number(modal.cost).toFixed(2)}{" "}
            </text>
            {this.renderTestCost(modal)}
          </div>
        );
      } else {
        return (
          <div
            className="colDefault align-items-center mt-2"
            style={{ flex: 2 }}
          >
            <div
              style={{ display: modal.subject_included == 0 ? "none" : "flex" }}
              className="rowDefault align-items-center w-100"
            >
              <div className="myCoursesCostBefore" style={{ flex: 0.5 }}>
                <strike>
                  <text className="myCoursesCost">
                    {this.state.language_id == 0 ? (
                      <React.Fragment>Rs.</React.Fragment>
                    ) : (
                      <React.Fragment>₹</React.Fragment>
                    )}{" "}
                    {Number(modal.old_cost).toFixed(2)}{" "}
                  </text>
                </strike>
              </div>
              <div className="myCoursesYouSaved" style={{ flex: 1 }}>
                <text className="myCoursesCost">
                  {this.state.language_id == 0 ? (
                    <React.Fragment>You Saved </React.Fragment>
                  ) : (
                    <React.Fragment>आपने बचाया</React.Fragment>
                  )}{" "}
                  {this.state.language_id == 0 ? (
                    <React.Fragment>Rs.</React.Fragment>
                  ) : (
                    <React.Fragment>₹</React.Fragment>
                  )}{" "}
                  {(Number(modal.old_cost) - Number(modal.cost)).toFixed(2)}
                </text>
              </div>
              <div className="myCoursesCostAfter" style={{ flex: 0.5 }}>
                <text className="myCoursesFinalCost">
                  {this.state.language_id == 0 ? (
                    <React.Fragment>Rs.</React.Fragment>
                  ) : (
                    <React.Fragment>₹</React.Fragment>
                  )}{" "}
                  {Number(modal.cost).toFixed(2)}{" "}
                </text>
              </div>
            </div>

            {this.renderTestCost(modal)}
          </div>
        );
      }
    } else {
      return (
        <div className="myCoursesCostAfterSingle ml-auto">
          <text style={{ display: "flex" }} className="myCoursesFinalCost">
            {this.state.language_id == 0 ? (
              <React.Fragment>Rs.</React.Fragment>
            ) : (
              <React.Fragment>₹</React.Fragment>
            )}{" "}
            {Number(modal.cost).toFixed(2)}{" "}
          </text>
          {/* {this.renderTestCost(modal)} */}
        </div>
      );
    }
  }
  renderTestCost(modal) {
    if (modal.test_included == 1) {
      return (
        <div className="test_cost_cart myCoursesFinalCost">
          {this.state.language_id == 0 ? (
            <React.Fragment>Rs.</React.Fragment>
          ) : (
            <React.Fragment>₹</React.Fragment>
          )}{" "}
          {modal.test_cost.toFixed(2)}
        </div>
      );
    }
  }
  renderMyCart() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      const { expanded } = this.state;

      if (this.state.myCart.length == 0) {
        return (
          <div className="styles--sc-shopping-list--empty--1isYv">
            <MDBIcon
              style={{ color: "#e8e9eb", marginBottom: 20 }}
              size="5x"
              icon="shopping-cart"
            />
            <p>
              {this.state.language_id == 0 ? (
                <React.Fragment>
                  Your cart is empty. Keep exploring to find a course!
                </React.Fragment>
              ) : (
                <React.Fragment>
                  आपकी शॉपिंग कार्ट खाली है। एक कोर्स खोजने के लिए खोज जारी
                  रखें!
                </React.Fragment>
              )}
            </p>
            <a
              className="btn "
              onClick={() => this.props.history.push("/")}
              data-purpose="keep-shopping-action"
              style={{ backgroundColor: "#0093d9", color: "white" }}
            >
              {this.state.language_id == 0 ? (
                <React.Fragment>Keep Learning</React.Fragment>
              ) : (
                <React.Fragment>सीखते रहें</React.Fragment>
              )}
            </a>
          </div>
        );
      } else {
        return this.state.myCart.map((modal) => {
          console.log(modal, "modal");

          if (modal.service_included == false) {
            console.log("subject in cart");
            return (
              <React.Fragment>
                <div className="myCourses">
                  <div className="myCoursesImg">
                    {" "}
                    <img
                      src={modal.image}
                      alt="10x10-logo"
                      width="100%"
                      height={modal.test_included == 1 ? "auto" : "100%"}
                    />{" "}
                  </div>
                  <div className="myCoursesNameAndDel">
                    <div>
                      <text className="myCoursesTitle">
                        {modal.subject_name}{" "}
                      </text>

                      {modal.subject_included == 0 && modal.test_included == 1
                        ? `Test`
                        : ""}
                      {modal.subject_included == 1 &&
                      modal.test_included == 1 ? (
                        <React.Fragment>
                          <br /> {modal.subject_name}{" "}
                          {this.state.language_id == 0 ? (
                            <React.Fragment>Test</React.Fragment>
                          ) : (
                            <React.Fragment>परीक्षा</React.Fragment>
                          )}
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="myCoursesRemove">
                      <a
                        className="myCoursesRemove"
                        onClick={() => this.removeCart(modal)}
                      >
                        {" "}
                        <MDBIcon icon="trash-alt" />{" "}
                        {this.state.language_id == 0 ? (
                          <React.Fragment>Remove</React.Fragment>
                        ) : (
                          <React.Fragment>हटाए</React.Fragment>
                        )}
                      </a>
                    </div>
                  </div>
                  {this.renderCost(modal)}

                  <div></div>
                </div>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment>
                <div className="myCourses" style={{ height: 100 }}>
                  <div className="myCoursesImg">
                    {" "}
                    <img
                      src={modal.image}
                      alt="10x10-logo"
                      style={{ display: "block" }}
                      width="100%"
                      height="100%"
                    />{" "}
                  </div>
                  <div className="myCoursesNameAndDel">
                    <div>
                      <text className="myCoursesTitle">
                        {modal.service_name}{" "}
                      </text>

                      {/*                     
                    {modal.subject_included==0 && modal.test_included==1?`Test`:''}
                    {modal.subject_included==1 && modal.test_included==1?<React.Fragment><br /> {modal.subject_name} Test</React.Fragment>:''} */}
                    </div>
                    <div className="myCoursesRemove">
                      <a
                        className="myCoursesRemove"
                        onClick={() => this.removeCart(modal)}
                      >
                        {" "}
                        <MDBIcon icon="trash-alt" />{" "}
                        {this.state.language_id == 0 ? (
                          <React.Fragment>Remove</React.Fragment>
                        ) : (
                          <React.Fragment>हटाए</React.Fragment>
                        )}
                      </a>
                    </div>
                  </div>
                  {this.renderCost(modal)}

                  <div></div>
                </div>
              </React.Fragment>
            );
          }
        });
      }
    }
  }

  getCart = () => {
    let details = {
      user_id: this.state.userdata.user_id,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState(
            {
              cart: responseJson,
            },
            () => this.getSubjects()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  getTax = () => {
    this.setState({
      response: fetch("https://10x10learning.com/get_tax_user", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "get_tax_user");
          this.setState({
            tax: responseJson,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  getOrders = async () => {
    //   this.setState({
    //     loading:true
    // })
    let details = {
      user_id: this.state.userdata.user_id,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/get_my_orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            all_orders: responseJson,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  getSubjects = () => {
    this.setState({
      response: fetch("https://10x10learning.com/get_subjects", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState(
            {
              all_subjects: responseJson,
            },
            () => this.getServices()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  getServices() {
    fetch("https://10x10learning.com/get_subcategories", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "max-age=31536000",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        this.setState({ services: responseJson }, () => this.getCartSubjects());
      });
  }

  getAddress = () => {
    let details = {
      user_id: this.state.userdata.user_id,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/get_address", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (this.state.userdata.phone == null || responseJson.state == null) {
            this.setState({ edit_address: true });
          }
          this.setState(
            {
              address: responseJson,
              address_edit_text: responseJson.address,
              state: responseJson.state,
              pincode: responseJson.pincode,
              address_loading: false,
            },
            () => this.selectRegion(responseJson.state)
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  editAddress = () => {
    if (
      this.state.address_edit_text == null ||
      this.state.address_edit_text == ""
    ) {
      swal("Enter address", "", "info");
    } else if (this.state.state == null || this.state.state == "") {
      swal("Choose state", "", "info");
    } else if (this.state.pincode == null || this.state.pincode == "") {
      swal("Enter pincode", "", "info");
    } else if (this.state.phone == null || this.state.phone == "") {
      swal("Choose state", "", "info");
    } else {
      this.setState({
        address_loading: true,
      });
      let details = {
        address_id: this.state.address.address_id,
        address: this.state.address_edit_text,
        state: this.state.state,
        pincode: this.state.pincode,
      };

      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://10x10learning.com/edit_address", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "max-age=31536000",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            this.getAddress();
            this.editPhone();
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });

            console.log(error);
          }),
      });
    }
  };

  editPhone = () => {
    console.log(this.state.phone.length);
    if (this.state.phone.length != 10) {
      swal("Enter valid phone phone", "", "info");
      this.setState({
        address_loading: false,
        edit_address: true,
      });
    } else {
      this.setState({
        address_loading: true,
      });
      let details = {
        phone: this.state.phone,
        user_id: this.state.userdata.user_id,
      };

      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://10x10learning.com/edit_user_details", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "max-age=31536000",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            window.localStorage.removeItem("userdata");
            window.localStorage.setItem(
              "userdata",
              JSON.stringify(responseJson)
            );
            this.setState({
              loading: false,
              address_loading: false,
              edit_address: false,
            });
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });

            console.log(error);
          }),
      });
    }
  };

  getCartSubjects() {
    let { myCart, all_subjects, cart, services } = this.state;

    let cart_array = [],
      total = 0;
    var total_test_cost = 0;
    var total_subject_cost = 0;

    var total_services_cost = 0;
    if (cart.length > 0) {
      for (var v = 0; v < all_subjects.length; v++) {
        for (var v1 = 0; v1 < JSON.parse(cart[0].items).length; v1++) {
          if (
            JSON.parse(cart[0].items)[v1].subject_id ==
            all_subjects[v].subject_id
          ) {
            let data3 = all_subjects[v];

            data3.test_included = JSON.parse(cart[0].items)[v1].test_included;
            data3.subject_included = JSON.parse(cart[0].items)[
              v1
            ].subject_included;
            data3.service_included = false;
            cart_array.push(data3);
            if (data3.subject_included != 0) {
              total_subject_cost = total_subject_cost + Number(data3.cost);
            }

            if (data3.test_included != 0) {
              console.log(Number(data3.test_cost), "test_iiii");
              total_test_cost = total_test_cost + Number(data3.test_cost);
            }
          }
        }
      }

      for (var i = 0; i < services.length; i++) {
        for (var j = 0; j < JSON.parse(cart[0].items).length; j++) {
          if (JSON.parse(cart[0].items)[j].service_id !== undefined) {
            if (JSON.parse(cart[0].items)[j].service_id === services[i].id) {
              let service = services[i];
              service.service_included = true;
              cart_array.push(service);
              total_services_cost = total_services_cost + Number(service.cost);
            }
          }
        }
      }

      total = total_subject_cost + total_test_cost + total_services_cost;
    }

    this.setState(
      {
        myCart: cart_array,
        old_total: total,
        total_cost: total,
        loading: false,
      },
      () => {
        console.log(this.state);
        this.getAddress();
        if (this.state.promoApplied == true) {
          this.handleApply();
        }
      }
    );
  }
  selectCountry(val) {
    console.log(val);
    this.setState({ country: val });
  }

  selectRegion(val) {
    if (val != null) {
      console.log(val);
      var calculated_cess = 0,
        calculated_tax = 0,
        total_tax = 0;
      this.setState({ state: val });
      for (var v = 0; v < this.state.tax.length; v++) {
        if (val.toLowerCase() != "haryana") {
          if (this.state.tax[v].tax_id == 8) {
            if (this.state.tax[v].cess != null) {
              for (
                var v1 = 0;
                v1 < JSON.parse(this.state.tax[v].cess).length;
                v1++
              ) {
                calculated_cess =
                  calculated_cess +
                  Number(JSON.parse(this.state.tax[v].cess)[v1].value);
              }
            }
            calculated_tax =
              calculated_tax + Number(this.state.tax[v].tax_value);
          }
        } else {
          if (this.state.tax[v].tax_id == 1 || this.state.tax[v].tax_id == 2) {
            if (this.state.tax[v].cess != null) {
              for (
                var v1 = 0;
                v1 < JSON.parse(this.state.tax[v].cess).length;
                v1++
              ) {
                calculated_cess =
                  calculated_cess +
                  Number(JSON.parse(this.state.tax[v].cess)[v1].value);
              }
            }
            calculated_tax =
              calculated_tax + Number(this.state.tax[v].tax_value);
          }
        }
      }

      if (val.toLowerCase() == "haryana") {
        total_tax =
          calculated_tax + (calculated_tax * (calculated_cess / 2)) / 100;
      } else {
        total_tax = calculated_tax + (calculated_tax * calculated_cess) / 100;
      }
      this.setState(
        {
          calculated_cess: calculated_cess,
          calculated_tax: total_tax,
        },
        () => this.getDialogues()
      );
    }
  }
  renderAddress() {
    const { country, region } = this.state;

    if (this.state.myCart.length != 0) {
      if (this.state.address == null) {
        return <div></div>;
      } else {
        if (this.state.address_loading) {
          return (
            <div
              className="myCartAddress"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <div className="myCartAddressH">
                <text className="myCartAddressH1">
                  {this.state.language_id == 0 ? (
                    <React.Fragment>Billing Address:</React.Fragment>
                  ) : (
                    <React.Fragment>बिल भेजने का पता</React.Fragment>
                  )}
                </text>
              </div>
              <HollowDotsSpinner color="#c01825" />
              <div className="myCartAddressButton">
                {/* <button onClick={()=>this.setState({edit_address:false},()=>this.editAddress())} className='myCartButtonEdit' type="button" >Save</button> */}
                {/* <button className='myCartButtonNew' type="button"  >New</button> */}
              </div>
            </div>
          );
        } else {
          if (this.state.edit_address) {
            return (
              <div className="myCartAddress">
                <div className="myCartAddressH">
                  <text className="myCartAddressH1">
                    {this.state.language_id == 0 ? (
                      <React.Fragment>Billing Address:</React.Fragment>
                    ) : (
                      <React.Fragment>बिल भेजने का पता</React.Fragment>
                    )}
                  </text>
                </div>
                <div className="colDefault edit_address_div">
                  <input
                    autofocus
                    value={
                      this.state.address_edit_text == null ||
                      this.state.address_edit_text == "null"
                        ? ""
                        : this.state.address_edit_text
                    }
                    type="text"
                    id="defaultFormRegisterNameEx"
                    className="form-control"
                    style={{ marginRight: 15 }}
                    onChange={(text) =>
                      this.setState({ address_edit_text: text.target.value })
                    }
                  />
                  {/* <CountryDropdown
          value={country}
          onChange={(val) => this.selectCountry(val)} /> */}

                  <div
                    className="rowDefault "
                    style={{ justifyContent: "space-between" }}
                  >
                    <RegionDropdown
                      country="India"
                      className="region_select"
                      value={this.state.state}
                      onChange={(val) => this.selectRegion(val)}
                    />
                    <input
                      autofocus
                      value={
                        this.state.pincode == null ||
                        this.state.pincode == "null"
                          ? ""
                          : this.state.pincode
                      }
                      type="text"
                      id="defaultFormRegisterNameEx"
                      className="form-control region_select"
                      placeholder="Enter pincode"
                      onChange={(text) =>
                        this.setState({ pincode: text.target.value })
                      }
                    />
                  </div>
                  <input
                    autofocus
                    value={
                      this.state.phone == null || this.state.phone == "null"
                        ? ""
                        : this.state.phone
                    }
                    type="text"
                    id="defaultFormRegisterNameEx"
                    className="form-control region_select"
                    placeholder="Enter 10 digit phone number"
                    onChange={(text) =>
                      this.setState({ phone: text.target.value })
                    }
                  />
                </div>
                <div className="myCartAddressButton">
                  <button
                    onClick={() =>
                      this.setState({ edit_address: false }, () =>
                        this.editAddress()
                      )
                    }
                    className="myCartButtonEdit"
                    type="button"
                  >
                    {this.state.language_id == 0 ? (
                      <React.Fragment>Save</React.Fragment>
                    ) : (
                      <React.Fragment>सेव करें</React.Fragment>
                    )}
                  </button>
                  {/* <button className='myCartButtonNew' type="button"  >New</button> */}
                </div>
              </div>
            );
          } else {
            return (
              <div className="myCartAddress">
                <div className="myCartAddressH">
                  <text className="myCartAddressH1">
                    {this.state.language_id == 0 ? (
                      <React.Fragment>Billing Address:</React.Fragment>
                    ) : (
                      <React.Fragment>बिल भेजने का पता</React.Fragment>
                    )}
                  </text>
                </div>
                <div className="myCartAddressN">
                  <text
                    style={{
                      display:
                        this.state.address.address == "Edit Address"
                          ? "none"
                          : "block",
                    }}
                    className="myCartAddressN1"
                  >
                    {this.state.userdata.name}{" "}
                  </text>
                  <text className="myCartAddressN2">
                    {this.state.address_edit_text == null ||
                    this.state.address_edit_text == "null"
                      ? ""
                      : this.state.address_edit_text}
                  </text>
                  <br />

                  <text className="myCartAddressN2">
                    {this.state.state == null || this.state.state == "null"
                      ? ""
                      : this.state.state}{" "}
                    {this.state.pincode == null || this.state.pincode == "null"
                      ? ""
                      : this.state.pincode}
                  </text>
                  <br />
                  <text className="myCartAddressN2">
                    {this.state.phone == null ? "" : this.state.phone}
                  </text>
                </div>
                <div className="myCartAddressButton">
                  <button
                    onClick={() =>
                      this.setState({
                        address_edit_text: this.state.address.address,
                        edit_address: true,
                      })
                    }
                    className="myCartButtonEdit"
                    type="button"
                  >
                    {this.state.language_id == 0 ? (
                      <React.Fragment>Edit</React.Fragment>
                    ) : (
                      <React.Fragment>संपादित करें</React.Fragment>
                    )}
                  </button>
                  {/* <button className='myCartButtonNew' type="button"  >New</button> */}
                </div>
              </div>
            );
          }
        }
      }
    }
  }
  toggleEdit = () => {
    this.setState({
      edit_address: !this.state.edit_address,
    });
  };
  renderSave() {
    console.log(this.state.discount);
    if (this.state.promoApplied) {
      return (
        <p style={{ marginBottom: 0, fontSize: "14px", color: "#6A6A6A" }}>
          {this.state.language_id == 0 ? (
            <React.Fragment>You Saved </React.Fragment>
          ) : (
            <React.Fragment>आपने बचाया</React.Fragment>
          )}{" "}
          {this.state.language_id == 0 ? (
            <React.Fragment>Rs.</React.Fragment>
          ) : (
            <React.Fragment>₹</React.Fragment>
          )}
          {(
            Number(this.state.discount) +
            (Number(this.state.discount) * this.state.calculated_tax) / 100
          ).toFixed(2)}
        </p>
      );
    }
  }
  renderTotal() {
    if (this.state.myCart.length != 0) {
      return (
        <div className="myCartTotal">
          <text className="myCartTotalName">
            {this.state.language_id == 0 ? (
              <React.Fragment>Grand Total :</React.Fragment>
            ) : (
              <React.Fragment>कुल योग</React.Fragment>
            )}
          </text>

          <div className="myCartTotalName">
            {this.state.language_id == 0 ? (
              <React.Fragment>Rs.</React.Fragment>
            ) : (
              <React.Fragment>₹</React.Fragment>
            )}
            {(
              this.state.old_total +
              (this.state.old_total * this.state.calculated_tax) / 100
            ).toFixed(2)}
            {this.renderSave()}
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
  renderDiscountedCost() {
    if (this.state.promoApplied) {
      return (
        <text style={{ color: "black", marginLeft: 20 }}>
          Rs. {this.state.old_total.toFixed(2)}
        </text>
      );
    }
  }
  renderOldCostWitoutTax() {
    if (this.state.promoApplied) {
      return (
        <del>
          {" "}
          {this.state.language_id == 0 ? (
            <React.Fragment>Rs.</React.Fragment>
          ) : (
            <React.Fragment>₹</React.Fragment>
          )}{" "}
          {this.state.total_cost.toFixed(2)}
        </del>
      );
    } else {
      return (
        <React.Fragment>
          {" "}
          {this.state.language_id == 0 ? (
            <React.Fragment>Rs.</React.Fragment>
          ) : (
            <React.Fragment>₹</React.Fragment>
          )}{" "}
          {this.state.total_cost.toFixed(2)}
        </React.Fragment>
      );
    }
  }
  renderTotalWithoutTax() {
    if (this.state.myCart.length != 0) {
      return (
        <div className="myCartTotal">
          <text className="myCartTotalName2">
            {this.state.language_id == 0 ? (
              <React.Fragment>Total :</React.Fragment>
            ) : (
              <React.Fragment>कुल</React.Fragment>
            )}
          </text>

          <div className="myCartTotalName2">
            {this.renderOldCostWitoutTax()}
            {this.renderDiscountedCost()}
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
  renderPromoContent() {
    if (this.state.promoApplied) {
      return (
        <div className="myCartPromoCodeInputMain">
          <div className="myCartPromoCodeInputtypeDiv" style={{ width: "6%" }}>
            {this.state.language_id == 0 ? (
              <React.Fragment>Applied:</React.Fragment>
            ) : (
              <React.Fragment>लागू</React.Fragment>
            )}
          </div>

          <div className="myCartPromoCodeApply">
            <text style={{ color: "#dbaa34", textDecoration: "underline" }}>
              {this.state.PromoCode}
            </text>
            &nbsp;&nbsp;
            <text
              className="removePromoText"
              onClick={() =>
                this.setState({
                  PromoCode: "",
                  promoApplied: false,
                  old_total: this.state.total_cost,
                })
              }
            >
              {this.state.language_id == 0 ? (
                <React.Fragment>Remove</React.Fragment>
              ) : (
                <React.Fragment>हटाए</React.Fragment>
              )}
            </text>
          </div>
        </div>
      );
    } else {
      return (
        <div className="myCartPromoCodeInputMain">
          <div className="myCartPromoCodeInputtypeDiv">
            <input
              placeholder="Enter Promo Code"
              style={{
                width: "100%",
                height: "45px",
                borderRadius: "5px",
                textAlign: "left",
                border: ".5px solid #E5E5E5",
                backgroundColor: "#fff",
                paddingLeft: 10,
              }}
              type="text"
              value={this.state.PromoCode}
              onChange={(text) =>
                this.setState({ PromoCode: text.target.value })
              }
            />{" "}
          </div>

          <div className="myCartPromoCodeApply">
            <a>
              <text onClick={() => this.handleApply()}>
                {this.state.language_id == 0 ? (
                  <React.Fragment>Apply</React.Fragment>
                ) : (
                  <React.Fragment>लागू करें</React.Fragment>
                )}
              </text>
            </a>
          </div>
        </div>
      );
    }
  }
  renderPromoCode() {
    if (this.state.myCart.length != 0) {
      return (
        <div className="myCartPromoCode">
          <div
            className="rowDefault align-items-center"
            style={{ justifyContent: "space-between" }}
          >
            <text className="myCartItem">
              {this.state.language_id == 0 ? (
                <React.Fragment>Promo Code:</React.Fragment>
              ) : (
                <React.Fragment>प्रोमो कोड</React.Fragment>
              )}{" "}
            </text>
            <text
              onClick={() => this.setState({ promo_modal: true })}
              className="viewPromoText"
              style={{ display: this.state.promoApplied ? "none" : "block" }}
            >
              {this.state.language_id == 0 ? (
                <React.Fragment>View Promo Codes</React.Fragment>
              ) : (
                <React.Fragment>प्रोमोकोड देखें</React.Fragment>
              )}
            </text>
          </div>
          {this.renderPromoContent()}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
  renderButton() {
    if (this.state.myCart.length == 0) {
      return <div></div>;
    } else {
      return (
        <div className="">
          <button
            onClick={() => this.payment()}
            className="view-details-button btn-custom"
            type="button"
          >
            {this.state.language_id == 0 ? (
              <React.Fragment>Continue</React.Fragment>
            ) : (
              <React.Fragment>जारी रखें</React.Fragment>
            )}
          </button>
        </div>
      );
    }
  }

  showAlert = (value) => {
    alert(value);
  };
  renderFade() {
    if (this.state.invoice_modal == false) {
      return (
        <Fade>
          <h1 className="myCartMainHeading">
            {this.state.language_id == 0 ? (
              <React.Fragment>My Carts</React.Fragment>
            ) : (
              <React.Fragment>मेरी खरीदारी</React.Fragment>
            )}
          </h1>
          <p className="myCartandLearn">
            {this.state.language_id == 0 ? (
              <React.Fragment>Start learning With 10x10</React.Fragment>
            ) : (
              <React.Fragment>10 x 10 के साथ सीखना शुरू करें</React.Fragment>
            )}
          </p>
          {this.renderMyCart()}
          {/* <hr style={{display:this.state.myCart.length==0?'none':'block'}} className='myCartHR'></hr> */}
          <div>
            {this.renderPromoCode()}
            {this.renderTotalWithoutTax()}
            <hr
              style={{
                display: this.state.myCart.length == 0 ? "none" : "block",
              }}
              className="myCartHR"
            ></hr>
            {this.renderTaxColumn2()}

            {this.renderTotal()}

            {this.renderAddress()}
          </div>
        </Fade>
      );
    }
  }
  render() {
    console.log(this.state);
    if (this.state.loading) {
      return "loading..";
    }
    return (
      <div id="my-cart" className="bg-whitesmoke">
        <HomeHeader
          cart={
            this.state.cart.length == 0
              ? 0
              : JSON.parse(this.state.cart[0].items).length
          }
          {...this.props}
          active="My Cart"
        />

        <div className="container-c">
          <div className="row account-components-border">
            <div className="col-12 col-md-3 account-nav-ipad px-0">
              <AccountNav {...this.props} active="My Cart" />
            </div>

            <div className="myCartDetail col-12 col-md-9 margin-top-mobile">
              {this.renderFade()}
            </div>
          </div>
        </div>

        <div className="myCartButton">{this.renderButton()}</div>
        <ProfTen dialogues={this.state.dialogues} />
        <div style={{ height: "auto" }}>
          <MainFooterPage {...this.props} />
        </div>
        {this.renderModal()}

      </div>
    );
  }
}
export default Cart;
