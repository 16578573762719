import React from "react";
import Fade from "react-reveal/Fade";
import "./orderHistory.css";
import OrderThumb from "../../Assets/images/order-thumb.png";
import Footer from "../../Reusable/Footer/footer";
import NavArrow from "../../Assets/images/right-arrow-nav.svg";
import NewHeader from "../../Reusable/Header/homeHeader";
import AccountNav from "../../Reusable/AccountNavigation/AccountNav";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import { MDBIcon } from "mdbreact";
import MainFooterPage from "../../Reusable/Footer/footer.jsx";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
class OrderHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: [],
     language_id:'',
      all_items: [],
      all_orders: []
    };
  }
renderCost(item){
  console.log(item)
  return(
    <p className="course-title" style={{ marginRight: 10,color:'black',marginTop:0 }}>
    Rs. {item.amount}
    <br />
    <span style={{color:'red'}}>{item.refund == 1? <i>Refunded</i> : ''}</span>
  </p>
  )
}
renderStatus(item){
  console.log(item)
 if(item.test_included==1 && item.subject_included==1){
  return(
    <p className="invoice-items" >
    Course + Test
  </p>
  )
 }
 if(item.test_included==0 && item.subject_included==1){
  return(
    <p className="invoice-items" >
    Course
  </p>
  )
 }
 if(item.test_included==1 && item.subject_included==0){
  return(
    <p className="invoice-items" >
    Test
  </p>
  )
 }
}
  renderOrders() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      if (this.state.all_items.length == 0) {
        return (
          <div className="styles--sc-shopping-list--empty--1isYv">
            <MDBIcon
              style={{ color: "#e8e9eb", marginBottom: 20 }}
              size="5x"
              icon="file-invoice"
              className='OpenBook'
            />
            <p>
              You have not made any orders yet! Keep exploring to find a course!
            </p>
            <a
              className="btn "
              onClick={() => this.props.history.push("/")}
              data-purpose="keep-shopping-action"
              style={{ backgroundColor: "#c01825",border:'1px solid #c01825', color: "white" }}
            >
              Keep Learning
            </a>
          </div>
        );
      } else {
        return this.state.all_orders.map((item_all, index_all) => {
          console.log(item_all,'item_all')
        return(
          <div key={index_all} className="col-12 course-row">
         <div className="row" style={{ justifyContent: "space-between" }}>
         <span className="course-purchase" style={{margin:7}}>
        {this.state.language_id==0?<>Purchased on</>:<>खरीदा गया</>} {this.commentdate(item_all.ts)}
                      </span>
                  {
            JSON.parse(item_all.items).map((item,index)=>{
            return (
              
              <div className='rowDefault align-items-center' style={{width:'100%',justifyContent:'flex-start',borderBottom:'1px dotted #d2d2d2'}}>
                  <div className="col-4 m-1 py-2">
                    <img className="course-thumb-order" src={item.image} />
                  </div>
                  <div className="col-8 pl-4">
                    <p className="course-title">{item.subject_name}</p>
                    <p className="course-dates">
                     
                      {this.renderStatus(item)}
                    </p>
                  </div>
                  
                </div> 
             
            );
          })
          }
         
         <div className='justify-content-flex-end view_invoice'>
         {this.renderCost(item_all)}

        {/* <a className="order-invoice-button edit-button btn-custom text-center" target="_blank" onClick={()=>window.open(item_all.invoice_pdf,'_blank')} >{this.state.language_id==0?<>View Invoice</>:<>चालान देखें</>}</a> */}
         </div>
          </div>
          </div>
        )
        });
      }
    }
  }

  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth();
    console.log(dt.getMonth() + 1);
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (
      dd +
      "-" +
      monthNames[Number(dt.getMonth())] +
      "-" +
      dt.getFullYear().toString()
    );
  }

  
  commenttime(timestamp) {
    var convertdLocalTime = new Date(timestamp);
    var hours = convertdLocalTime.getHours();
    var minutes = convertdLocalTime.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  getOrders = async () => {
    let details = {
      user_id: this.state.userdata.user_id
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/get_all_my_orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          var items = [];
          for (var v = 0; v < responseJson.length; v++) {
            for (
              var v1 = 0;
              v1 < JSON.parse(responseJson[v].items).length;
              v1++
            ) {
              items.push(JSON.parse(responseJson[v].items)[v1]);
            }
          }
          this.setState({
            all_orders: responseJson,
            all_items: items,
            loading: false
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };
  componentWillMount() {
    let language_id = localStorage.getItem("language_id");
    console.log(language_id);
    this.setState({ language_id });
    this.getDetails();
  }
  getDetails() {
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState(
        {
          userdata: JSON.parse(userdata),
          profile_pic_url: JSON.parse(userdata).profile_pic,
          loading: true
        },
        () => {
          this.getOrders();
          this.getCart();
        }
      );
    } else {
      this.props.history.push("/");
    }
  }
  getCart = () => {
    let details = {
      user_id: this.state.userdata.user_id
    };

    console.log(details, "fetching-details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "cart");

          this.setState({
            cart: responseJson
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });

          console.log(error);
        })
    });
  };
  render() {
    console.log(this.state)
    return (
      <div id="my-orders" className="bg-whitesmoke" style={{overflow:'hidden'}}>
        <NewHeader
          profile_pic_url={this.state.profile_pic_url}
          cart={
            this.state.cart.length == 0
              ? 0
              : JSON.parse(this.state.cart[0].items).length
          }
          {...this.props}
        />

        <div className="container-c">
          <div className="row bg-white account-components-border">
            <div className="col-12 col-md-3  px-0 account-nav-ipad">
              <AccountNav
                profile_pic_url={this.state.profile_pic_url}
                {...this.props}
                active="Purchase History"
              />
            </div>
            <div className="col-12 col-md-9 " style={{paddingBottom:10}}>
              <Fade>
                <div className="my-orders-container">
                  <h1 className="my-orders-title mt-4 mb-0 text-center text-md-left">
                   {this.state.language_id==0?<>My Orders History</>:<>खरीद इतिहास</>}
                  </h1>
                  <p className="my-orders-subtitle mb-4 text-center text-md-left">
                    {" "}
        {this.state.language_id==0?<>See complete order details over here.</>:<>यहाँ पर पूर्ण आदेश विवरण देखें।</>}
                  </p>

                  <hr />

                  {this.renderOrders()}
                </div>
              </Fade>
            </div>
          </div>
        </div>
        <div className="py-3">
          <div className="row flex-row-reverse mx-0">
            {/* <button className="view-details-button btn-custom">View Details</button>
                     <button className="back-button btn-custom">Back</button> */}
          </div>
        </div>
        <div style={{ height: "auto" }} >
            <MainFooterPage {...this.props} />
          </div>

      </div>
    );
  }
}

export default OrderHistory;
