import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../../../Components/Admin/Sidebar/sidebarPage";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import Fade from "react-reveal";
import "./questions.css";
import { MDBIcon } from "mdbreact";
import CKEditor from "ckeditor4-react";
import Select from "react-select";
import swal from "sweetalert";
import MediaComponent from "../MediaComponent/chooseMediaFile";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

export default class EditQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // otherImage1: "",
      // otherImage2: "",
      objective: "",
      // otherImage3: "",
      // answerImage: "",
      // inputLink: "",
      loading: false,
      // answers: [],
      questionType: [
        { value: 1, label: "Objective" },
        { value: 2, label: "Subjective" },
      ],
      topics: [],
      selectedTopics: "",
      difficultyLevels: [
        { value: 1, label: "Easy" },
        { value: 2, label: "Moderate" },
        { value: 3, label: "Tough" },
      ],
      selectedDifficultyLevel: null,
      selectedQuestionType: "",
      typeOfQuestion: "",
      objectiveExplaination: "",
      correctAnswer: "",
      difficultyLevelOfQuestion: "",
      keywords: "",
      language_id: "",
      otherOption1: "",
      otherOption2: "",
      otherOption3: "",
      questionId: "",
      questionTitle: "",
      topicID: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
  }

  componentWillMount = async () => {
    let res = await this.getTopics();
  };

  componentDidMount() {}

  renderQuestionType() {
    return (
      <div className="col-4 diffiulty-level-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="difficulty-label subject-labels question-label "
        >
          Question Type:
        </label>
        <Select
          closeMenuOnSelect={true}
          onChange={(e) =>
            this.setState({ typeOfQuestion: e }, () => {
              if (this.state.typeOfQuestion.label == "Objective") {
                this.setState({ objective: 1 });
              } else this.setState({ objective: 0 });
            })
          }
          value={this.state.typeOfQuestion}
          className="select-difficulty select"
          options={this.state.questionType}
        />
      </div>
    );
  }

  setInitialQuestionState() {
    var question_item = JSON.parse(
      window.localStorage.getItem("question_item")
    );

    if (question_item.question_type == "Objective") {
      let selectedTopics = [];
      this.setState({ objective: 1 });
      var answers = JSON.parse(question_item.answers);
      var selected_topics = JSON.parse(question_item.topics);

      this.state.topics.forEach((element) => {
        for (var i = 0; i < selected_topics.length; i++) {
          if (selected_topics[i] == element.value) {
            var object = new Object();
            object.label = element.label;
            object.value = element.value;
            selectedTopics.push(object);
          }
        }
      });
      this.setState({
        questionId: question_item.question_id,
        choseImage: question_item.question_image,

        questionTitle: question_item.question_title,

        correctAnswer: answers.find(function (element) {
          return element.value == 1;
        }).answer,
        otherOption1: answers[1].answer,
        otherOption2: answers[2].answer,
        otherOption3: answers[3].answer,
        otherImage1: answers[1].link,
        otherImage2: answers[2].link,
        otherImage3: answers[3].link,
        answerImage: answers.find((item) => item.value == 1).link,
        selectedTopics: selectedTopics,

        selectedDifficultyLevel: this.state.difficultyLevels.find(
          (item) => item.value == question_item.difficulty_level
        ),
        loading: false,
      });
    } else {
      var selected_topics = JSON.parse(question_item.topics);
      let selectedTopics = [];
      this.state.topics.forEach((element) => {
        for (var i = 0; i < selected_topics.length; i++) {
          if (selected_topics[i] == element.value) {
            var object = new Object();
            object.label = element.label;
            object.value = element.value;
            selectedTopics.push(object);
          }
        }
      });
      this.setState({
        questionId: question_item.question_id,
        choseImage: question_item.question_image,

        questionTitle: question_item.question_title,

        keywords: question_item.keywords,
        // otherOption1:answers[1].answer,
        // otherOption2:answers[2].answer,
        // otherOption3:answers[3].answer,
        // otherImage1:answers[1].link,
        // otherImage2:answers[2].link,
        // otherImage3:answers[3].link,
        // answerImage:answers.find(item=>item.value==1).link,
        selectedTopics: selectedTopics,

        selectedDifficultyLevel: this.state.difficultyLevels.find(
          (item) => item.value == question_item.difficulty_level
        ),
        loading: false,
      });
    }

    // console.log("topics",question_item)w
  }

  getTopics = async () => {
    this.setState({
      loading: true,
    });
    this.setState({
      response: fetch("https://10x10learning.com/get_topics", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          responseJson.forEach((element) => {
            element.value = element.topic_id;
            element.label = element.topic_name;
          });
          console.log(responseJson);

          let question_item = JSON.parse(
            window.localStorage.getItem("question_item")
          );
          console.log(question_item, this.state.topics);
          this.setState(
            {
              topics: responseJson,
              typeOfQuestion: this.state.questionType.find(
                (ele) => ele.label == question_item.question_type
              ),
            },
            () => {
              this.setState(
                {
                  objective:this.state.typeOfQuestion.label=='Subjective'?'0':'1',
                  objectiveExplaination:
                    question_item.explaination_for_objective,
                  correct_answer: question_item.correct_answer,
                  difficultyLevelOfQuestion: this.state.difficultyLevels.find(
                    (ele) => ele.value == question_item.difficulty_level
                  ),
                  explaination_for_objective:
                    question_item.explaination_for_objective,
                  keywords: question_item.keywords,
                  selectedTopics: this.state.topics.find(
                    (ele) => ele.topic_id == question_item.topic_id
                  ),
                  language_id: question_item.language_id,
                  otherOption1: question_item.other_option_1,
                  otherOption2: question_item.other_option_2,
                  otherOption3: question_item.other_option_3,
                  correctAnswer: question_item.correct_answer,
                  questionId: question_item.question_id,
                  questionTitle: question_item.question_title,
                  topicID: question_item.topic_id,
                  loading: false,
                },
                () => console.log(this.state)
              );
            }
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  handleSaveQuestion() {
    var questionObject = new Object();
    if (this.state.typeOfQuestion.label == "Objective") {
      //question title
      questionObject.id = this.state.questionId;
      questionObject.question = this.state.questionTitle;
      //answers
      questionObject.answers = new Array();
      //correct answer
      questionObject.answers[0] = new Object();
      questionObject.answers[0].id = 0;
      questionObject.answers[0].answer = this.state.correctAnswer;
      questionObject.answers[0].value = 1;
      //oter answers
      questionObject.answers[1] = new Object();
      questionObject.answers[1].id = 1;
      questionObject.answers[1].answer = this.state.otherOption1;
      questionObject.answers[1].value = 0;

      questionObject.answers[2] = new Object();
      questionObject.answers[2].id = 2;
      questionObject.answers[2].answer = this.state.otherOption2;
      questionObject.answers[2].value = 0;

      questionObject.answers[3] = new Object();
      questionObject.answers[3].id = 3;
      questionObject.answers[3].answer = this.state.otherOption3;
      questionObject.answers[3].value = 0;
      questionObject.questionType = this.state.typeOfQuestion.label;
      questionObject.keywords=''
      questionObject.difficultyLevel = this.state.difficultyLevelOfQuestion.value;
      questionObject.objectiveExplaination = this.state.objectiveExplaination;
      //topics
      questionObject.topics = this.state.selectedTopics;
      console.log(
        questionObject,
        this.state.objectiveExplaination,
        "rwhwguiwgwieohgewugg"
      );
    } else if (this.state.typeOfQuestion.label == "Subjective") {
      questionObject.id = this.state.questionId;
      questionObject.question = this.state.questionTitle;
      questionObject.keywords = this.state.keywords;
      questionObject.objectiveExplaination = ''
      questionObject.answers = new Array();
      questionObject.answers[0] = new Object();
      questionObject.answers[0].id = 1;
      questionObject.answers[0].answer = '';
      questionObject.answers[1] = new Object();
      questionObject.answers[1].id = 1;
      questionObject.answers[1].answer = '';
      questionObject.answers[2] = new Object();
      questionObject.answers[2].id = 1;
      questionObject.answers[2].answer = '';
      questionObject.answers[3] = new Object();
      questionObject.answers[3].id = 1;
      questionObject.answers[3].answer = '';
      questionObject.questionType = this.state.typeOfQuestion.label;
      questionObject.difficultyLevel = this.state.difficultyLevelOfQuestion.value;
      questionObject.topics = this.state.selectedTopics;
      console.log(questionObject);
    }
    this.editQuestion(questionObject);
  }

  editQuestion(question) {
    this.setState({
      loading: true,
    });

    console.log(question.questionType);
    let formData = new FormData();
    if (question.questionType == "Objective") {
      formData.append("question_id", question.id);
      formData.append("difficulty_level", question.difficultyLevel);
      formData.append('keywords',question.keywords)
      formData.append("question_title", question.question);
      formData.append("topic_id", question.topics.topic_id);
      // formData.append("answers", JSON.stringify(question.answers));
      // formData.append("question_image", question.questionImage);
      formData.append("question_type", question.questionType);
      formData.append(
        "explaination_for_objective",
        question.objectiveExplaination
      );
      formData.append("correct_answer", question.answers[0].answer);
      formData.append("other_option_1", question.answers[1].answer);
      formData.append("other_option_2", question.answers[2].answer);
      formData.append("other_option_3", question.answers[3].answer);
    } else {
      formData.append("question_id", question.id);
      formData.append("difficulty_level", question.difficultyLevel);
      formData.append(
        "explaination_for_objective",
        question.objectiveExplaination
      );
      formData.append("correct_answer", question.answers[0].answer);
      formData.append("other_option_1", question.answers[1].answer);
      formData.append("other_option_2", question.answers[2].answer);
      formData.append("other_option_3", question.answers[3].answer);
      formData.append("question_title", question.question);
      formData.append("topic_id", question.topics.topic_id);
      formData.append("keywords", this.state.keywords);
      // formData.append('answers',JSON.stringify(question.answers))
      // formData.append("question_image", question.questionImage);
      formData.append("question_type", question.questionType);
    }

    for (let key of formData.entries()) {
      console.log(key[0] + ",with photo " + key[1]);
    }

    this.setState({
      response: fetch("https://10x10learning.com/edit_question", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "QWERTy");
          swal("Success", "Question succesfullly edited", "success").then(
            (value) => {
              this.setState({ loading: false }, () =>
                this.props.history.push("/limitedaccess/all-questions")
              );
            }
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }

  //HANDLE FUNCTIONS

  renderQuestionTitleSection() {
    return (
      <React.Fragment>
        <div className="col-12 my-2 course-duration-section question-title-section d-flex justify-content-start align-items-center">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className=" subject-labels w-25"
          >
            Question:
          </label>

          <input
            value={this.state.questionTitle}
            type="text"
            id="defaultFormRegisterNameEx"
            className="form-control question-form custom-form"
            onChange={(data) =>
              this.setState({
                questionTitle: data.target.value,
              })
            }
          />
        </div>
      </React.Fragment>
    );
  }

  renderAnswerSection() {
    return (
      <div className="col-8 my-2 right-answer-section">
        <p className="question-label">Answers</p>

        <div className="d-flex align-items-center">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="course-duration-label subject-labels"
          >
            Correct Answer:
          </label>

          <input
            value={this.state.correctAnswer}
            type="text"
            id="defaultFormRegisterNameEx"
            className="form-control correct-answer-form custom-form w-100"
            onChange={(data) =>
              this.setState({ correctAnswer: data.target.value })
            }
          />

         
        </div>
        <div className="d-flex  mt-3 other-answers-section">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="course-duration-label subject-labels"
          >
            Other options:
          </label>

          <div className="d-flex flex-column " style={{ width: "110%" }}>
            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <input
                value={this.state.otherOption1}
                type="text"
                id="defaultFormRegisterNameEx"
                className="form-control other-answer-form custom-form w-100"
                onChange={(data) =>
                  this.setState({ otherOption1: data.target.value })
                }
              />

              <div>
              
              </div>
            </div>

            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <input
                value={this.state.otherOption2}
                type="text"
                id="defaultFormRegisterNameEx"
                className="form-control other-answer-form custom-form w-100"
                onChange={(data) =>
                  this.setState({ otherOption2: data.target.value })
                }
              />

              <div>
               
              </div>
            </div>

            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <input
                value={this.state.otherOption3}
                type="text"
                id="defaultFormRegisterNameEx"
                className="form-control other-answer-form custom-form w-100"
                onChange={(data) =>
                  this.setState({ otherOption3: data.target.value })
                }
              />

              <div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  //PART OF MEDIA COMPONENT
  handleMediaChangeAnswer = (item) => {
    console.log(item, "handleMediaChange");
    this.setState({ answerImage: `https://10x10learning.com/${item.file}` });
  };

  handleMediaChangeOption1 = (item) => {
    console.log(item, "handleMediaChange");
    this.setState({ otherImage1: `https://10x10learning.com/${item.file}` });
  };
  handleMediaChangeOption2 = (item) => {
    console.log(item, "handleMediaChange");
    this.setState({ otherImage2: `https://10x10learning.com/${item.file}` });
  };
  handleMediaChangeOption3 = (item) => {
    console.log(item, "handleMediaChange");
    this.setState({ otherImage3: `https://10x10learning.com/${item.file}` });
  };

  handleMediaChooseImage = (item) => {
    console.log(item, "handleMediaChange");
    this.setState({ choseImage: `https://10x10learning.com/${item.file}` });
  };

  renderRelatedTopicsSection() {
    return (
      <div className="col-6 rquestion-topics-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="question-topics-label subject-labels question-label "
        >
          Topics:
        </label>
        <Select
          closeMenuOnSelect={false}
          onChange={(e) =>
            this.setState({ selectedTopics: e }, () =>
              console.log(this.state.selectedTopics)
            )
          }
          value={this.state.selectedTopics}
          className="select-question-topics select"
          options={this.state.topics}
        />
      </div>
    );
  }

  renderDifficultyLevelSection() {
    return (
      <div className="col-4 diffiulty-level-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="difficulty-label subject-labels question-label "
        >
          Difficulty Level:
        </label>
        <Select
          closeMenuOnSelect={true}
          onChange={(e) => this.setState({ difficultyLevelOfQuestion: e })}
          value={this.state.difficultyLevelOfQuestion}
          className="select-difficulty select"
          options={this.state.difficultyLevels}
        />
      </div>
    );
  }

  renderAnswerSectionSubjective() {
    return (
      <div className="col-8 my-2 right-answer-section">
        <div className="d-flex align-items-center">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="course-duration-label subject-labels"
          >
            Keywords
          </label>

          <textarea
            value={this.state.keywords}
            type="text"
            id="defaultFormRegisterNameEx"
            className="form-control correct-answer-form custom-form w-100"
            onChange={(data) => this.setState({ keywords: data.target.value })}
          />
        </div>
      </div>
    );
  }
  onEditorChange(evt) {
    this.setState({
      objectiveExplaination: evt.editor.getData(),
    });
  }

  handleChange(changeEvent) {
    this.setState({
      objectiveExplaination: changeEvent.target.value,
    });
  }

  renderAnswerSectionObjective() {
    return (
      <div className="col-8 my-2 right-answer-section">
        <p className="question-label">Answers</p>

        <div className="d-flex align-items-center">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="course-duration-label subject-labels"
          >
            Correct Answer:
          </label>

          <input
            value={this.state.correctAnswer}
            type="text"
            id="defaultFormRegisterNameEx"
            className="form-control correct-answer-form custom-form w-100"
            onChange={(data) =>
              this.setState({ correctAnswer: data.target.value })
            }
          />

          
        </div>
        <div className="d-flex  mt-3 other-answers-section">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="course-duration-label subject-labels"
          >
            Other options:
          </label>

          <div className="d-flex flex-column " style={{ width: "110%" }}>
            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <input
                value={this.state.otherOption1}
                type="text"
                id="defaultFormRegisterNameEx"
                className="form-control other-answer-form custom-form w-100"
                onChange={(data) =>
                  this.setState({ otherOption1: data.target.value })
                }
              />

              <div>
               
              </div>
            </div>

            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <input
                value={this.state.otherOption2}
                type="text"
                id="defaultFormRegisterNameEx"
                className="form-control other-answer-form custom-form w-100"
                onChange={(data) =>
                  this.setState({ otherOption2: data.target.value })
                }
              />

              <div>
              
              </div>
            </div>

            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <input
                value={this.state.otherOption3}
                type="text"
                id="defaultFormRegisterNameEx"
                className="form-control other-answer-form custom-form w-100"
                onChange={(data) =>
                  this.setState({ otherOption3: data.target.value })
                }
              />

              <div>
             
              </div>
            </div>
          </div>
        </div>
        <div className="col-8 my-2 right-answer-section">
          <div className="d-flex align-items-center">
            <label
              htmlFor="defaultFormRegisterNameEx"
              className="course-duration-label subject-labels"
            >
              Explanation
            </label>

            <CKEditor
              data={this.state.objectiveExplaination}
              onChange={this.onEditorChange}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    console.log(this.state.topics);
    return (
      <div id="add-subject-page" className="add-question-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#c01825"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <Fade>
                  <div className="row my-3 px-4">
                    <div className="col-12 d-flex justify-content-between">
                      <button
                        className="admin-button-style"
                        onClick={() =>
                          this.props.history.push(
                            "/limitedaccess/all-questions"
                          )
                        }
                      >
                        All Questions
                      </button>
                      <button
                      className="admin-button-style"
                      onClick={() => this.handleSaveQuestion()}
                    >
                      {" "}
                      Save &amp; Publish
                    </button>
                    </div>
                    <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                      <h3> Edit Question</h3>
                    
                    </div>
                  </div>

                  <div className="row px-2 my-2 mx-0">
                    {this.renderRelatedTopicsSection()}
                    {this.renderDifficultyLevelSection()}
                    {this.renderQuestionType()}
                    <hr />
                    {this.renderQuestionTitleSection()}

                    <hr />

                    {this.state.objective == 1
                      ? this.renderAnswerSectionObjective()
                      : this.renderAnswerSectionSubjective()}

                    <hr />

                    <button
                      className="add-subject"
                      onClick={() => this.handleSaveQuestion()}
                    >
                      Save &amp; Publish
                    </button>
                  </div>
                </Fade>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

// <div className="col-12 d-flex align-items-center">
// <label
//   htmlFor="defaultFormRegisterNameEx"
//   className="course-duration-label subject-labels  w-25"
// >
//   Choose Image:
//   <MediaComponent
//     onSelectMediaItem={this.handleMediaChooseImage}
//     buttonName={<MDBIcon icon="camera" />}
//     restriction="image/*"
//   />
// </label>

// <input
//   value={this.state.choseImage}
//   type="text"
//   id="defaultFormRegisterNameEx"
//   className="form-control other-answer-form custom-form"
//   onChange={(data) =>
//     this.setState({ choseImage: data.target.value })
//   }
// />
// </div>
// <div className="col-8 text-center">
// <img src={this.state.choseImage} style={{ width: "200px" }} />
// </div>
