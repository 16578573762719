import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../Sidebar/sidebarPage";
import "../AddSubject/AddSubject";
import Select from "react-select";
import {
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBInput,
} from "mdbreact";
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import swal from "sweetalert";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import CKEditor from "ckeditor4-react";
import Fade from "react-reveal";
const axios = require("axios").default;
// const topicsList=[];
// for(var i=1;i<=100;i++){
//     topicsList.push({id:i,name:`Topic ${i}`})
// }

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

class HomepageProf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadng: true,
      subjectId: ``,
      dashboard: [
        { value: 0, label: "Civil" },
        { value: 1, label: "Class" },
        { value: 2, label: "Humanity" },
        {value:3, label:'Commerce/CA'}
      ],
      dashboardSelected: { value: 1, label: "Class" },
      prof10: [],
      civilDescriptions: [
        {
          id: `1`,
          description: "",
        },
      ],
      classDescriptions: [
        {
          id: `1`,
          description: "",
        },
      ],
      commerceDescriptions: [
        {
          id: `1`,
          description: "wf",
        },
      ],
      humanityDescriptions: [
        {
          id: `1`,
          description: "",
        },
      ],
    };
  }
  componentDidMount() {
    let item = JSON.parse(localStorage.getItem("prof1010"));
    let details=JSON.parse(item.text)
    let civilDescriptions=details.civilDescriptions
    let classDescriptions=details.classDescriptions
    let humanityDescriptions=details.humanityDescriptions
    let commerceDescriptions=details.commerceDescriptions
this.setState({prof10: item,civilDescriptions,humanityDescriptions,classDescriptions,commerceDescriptions})
    // this.setState({ prof10: item }, () => {
    //   console.log(JSON.parse(this.state.prof10.text));
    //   let setItem = JSON.parse(this.state.prof10.text);
      
    //   this.setState({ civilDescriptions: setItem });
    // });
  }

  addSubject() {
    if (this.state.civilDescriptions.length === 0) {
      return swal("Check dialogue box", "Please do not leave blank", "error");
    } else if (this.state.civilDescriptions[0].description === "") {
      return swal("Check dialogue box", "Please do not leave blank", "error");
    } else if (this.state.classDescriptions.length === 0) {
      return swal("Check dialogue box", "Please do not leave blank", "error");
    } else if (this.state.classDescriptions[0].description === "") {
      return swal("Check dialogue box", "Please do not leave blank", "error");
    } else if (this.state.humanityDescriptions.length === 0) {
      return swal("Check dialogue box", "Please do not leave blank", "error");
    } else if (this.state.humanityDescriptions[0].description === "") {
      return swal("Check dialogue box", "Please do not leave blank", "error");
    } 
    else if (this.state.commerceDescriptions[0].description === "") {
      return swal("Check dialogue box", "Please do not leave blank", "error");
    } else {
      let details = {
        classDescriptions:this.state.classDescriptions,
        civilDescriptions: this.state.civilDescriptions,
        humanityDescriptions:this.state.humanityDescriptions,
        commerceDescriptions:this.state.commerceDescriptions
      }
      console.log(details)
      axios
        .post("https://10x10learning.com/edit_prof10_dashboard", {
          id: this.state.prof10.id,
          text: JSON.stringify(details),
          language_id: this.state.prof10.language_id,
        })
        .then( (response) =>{
          console.log(response);
          swal("Success", "Prof 10x10 dialogue updated", "success");
          this.props.history.push('/limitedaccess/professor/dashboard')
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    //   this.setState({
    //     response: fetch("https://10x10learning.com/edit_prof10x10_homepage", {
    //       method: "POST",
    //       body: formData,
    //     })
    //       .then((response) => response.json())
    //       .then((responseJson) => {
    //         if (responseJson.status == 200) {
    //           swal(
    //             "Success",
    //             "Prof 10x10 dialogue succesfullly updated",
    //             "success"
    //           );
    //           window.location.reload();
    //         } else {
    //           swal("Error", `Please try again`, "info").then(
    //             this.setState({ loading: false })
    //           );
    //         }
    //       })
    //       .catch((error) => {
    //         this.setState({
    //           loading: false,
    //         });
    //         swal("Warning!", "Check your network!", "warning");
    //         console.log(error);
    //       }),
    //   });
  }

  //HANDLE CLICK EVENTS
  addCivilDescription() {
    let { civilDescriptions } = this.state;
    civilDescriptions.push({
      id: `${civilDescriptions.length + 1}`,
      description: "",
    });
    this.setState({ civilDescriptions });
  }
  deleteCivilDescription() {
    let { civilDescriptions } = this.state;
    civilDescriptions.pop();
    this.setState({ civilDescriptions });
  }
  addClassDescription() {
    let { classDescriptions } = this.state;
    classDescriptions.push({
      id: `${classDescriptions.length + 1}`,
      description: "",
    });
    this.setState({ classDescriptions });
  }
  deleteClassDescription() {
    let { classDescriptions } = this.state;
    classDescriptions.pop();
    this.setState({ classDescriptions });
  }

  addHumanityDescription() {
    let { humanityDescriptions } = this.state;
    humanityDescriptions.push({
      id: `${humanityDescriptions.length + 1}`,
      description: "",
    });
    this.setState({ humanityDescriptions });
  }
  addCommerceDescription() {
    let { commerceDescriptions } = this.state;
    commerceDescriptions.push({
      id: `${commerceDescriptions.length + 1}`,
      description: "",
    });
    this.setState({ commerceDescriptions });
  }
  deleteCommerceDescription(){
    let { commerceDescriptions } = this.state;
    commerceDescriptions.push({
      id: `${commerceDescriptions.length + 1}`,
      description: "",
    });
    this.setState({ commerceDescriptions });
  }
  deleteHumanityDescription() {
    let { humanityDescriptions } = this.state;
    humanityDescriptions.push({
      id: `${humanityDescriptions.length + 1}`,
      description: "",
    });
    this.setState({ humanityDescriptions });
  }

  removeSubjectDescription(index) {
    console.log(index);
    let { civilDescriptions } = this.state;
    if (civilDescriptions.length > 0) {
      civilDescriptions.splice(index, 1);
      console.log(civilDescriptions);
    }
    for (var i = 0; i < civilDescriptions.length; i++) {
      civilDescriptions[i].id = i + 1;
    }
    console.log(civilDescriptions);
    this.setState({ civilDescriptions });
  }

  handleCivilDescriptions(item, index) {
    console.log(item.target.value);
    let civilDescriptions = this.state.civilDescriptions;
    let descriptionObject = civilDescriptions[index];
    descriptionObject.description = item.target.value;
    this.setState({ civilDescriptions });
    console.log(this.state.civilDescriptions);
  }

  handleClassDescriptions(item, index) {
    console.log(item.target.value);
    let classDescriptions = this.state.classDescriptions;
    let descriptionObject = classDescriptions[index];
    descriptionObject.description = item.target.value;
    this.setState({ classDescriptions });
    console.log(this.state.classDescriptions);
  }
  handleHumanityDescriptions(item, index) {
    console.log(item.target.value);
    let humanityDescriptions = this.state.humanityDescriptions;
    let descriptionObject = humanityDescriptions[index];
    descriptionObject.description = item.target.value;
    this.setState({ humanityDescriptions });
    console.log(this.state.humanityDescriptions);
  }
  handleCommerceDescription(item,index){
    console.log(item.target.value);
    let commerceDescriptions = this.state.commerceDescriptions;
    let descriptionObject = commerceDescriptions[index];
    descriptionObject.description = item.target.value;
    this.setState({ commerceDescriptions });
    console.log(this.state.commerceDescriptions);
  }

  renderCivilDescription() {
    return this.state.civilDescriptions.map((item, index) => {
      return (
        <React.Fragment key={item.id}>
          <div className="d-flex align-items-start mt-3">
            <label className="p-1"> {index + 1}.&nbsp; </label>
            <div className="position-relative w-100 mr-4">
              <textarea
                label="subject-description"
                className="subject-description-textarea w-100"
                type="text"
                value={item.description}
                onChange={(text) => this.handleCivilDescriptions(text, index)}
              />
            </div>
          </div>
          <div className="w-100" />
        </React.Fragment>
      );
    });
  }
  renderClassDescription() {
    return this.state.classDescriptions.map((item, index) => {
      return (
        <React.Fragment key={item.id}>
          <div className="d-flex align-items-start mt-3">
            <label className="p-1"> {index + 1}.&nbsp; </label>
            <div className="position-relative w-100 mr-4">
              <textarea
                label="subject-description"
                className="subject-description-textarea w-100"
                type="text"
                value={item.description}
                onChange={(text) => this.handleClassDescriptions(text, index)}
              />
            </div>
          </div>
          <div className="w-100" />
        </React.Fragment>
      );
    });
  }

  renderHumanityDescription() {
    return this.state.humanityDescriptions.map((item, index) => {
      return (
        <React.Fragment key={item.id}>
          <div className="d-flex align-items-start mt-3">
            <label className="p-1"> {index + 1}.&nbsp; </label>
            <div className="position-relative w-100 mr-4">
              <textarea
                label="subject-description"
                className="subject-description-textarea w-100"
                type="text"
                value={item.description}
                onChange={(text) =>
                  this.handleHumanityDescriptions(text, index)
                }
              />
            </div>
          </div>
          <div className="w-100" />
        </React.Fragment>
      );
    });
  }
  renderCommerceDescription() {
    return this.state.commerceDescriptions.map((item, index) => {
      return (
        <React.Fragment key={item.id}>
          <div className="d-flex align-items-start mt-3">
            <label className="p-1"> {index + 1}.&nbsp; </label>
            <div className="position-relative w-100 mr-4">
              <textarea
                label="subject-description"
                className="subject-description-textarea w-100"
                type="text"
                value={item.description}
                onChange={(text) =>
                  this.handleCommerceDescription(text, index)
                }
              />
            </div>
          </div>
          <div className="w-100" />
        </React.Fragment>
      );
    });
  }
  selectDashboard = (e) => {
    this.setState({ dashboardSelected: e }, () => {
      console.log(this.state.dashboardSelected);
      // let lectures = [...this.state.lecturesArray];
      // console.log(lectures, this.state.selectedLanguage);
      // let sortedlec = lectures.filter(
      // (item) => item.language_id == this.state.selectedLanguage.value
      // );
      // console.log(sortedlec);
      // this.setState({ lecturesArraySort: sortedlec });
    });
  };
  renderSelect() {
    return (
      <div className="col-5  d-flex align-items-baseline justify-content-end">
        <span
          style={{ fontWeight: "bold", fontSize: "18px", paddingRight: "5px" }}
        >
          Dashboard Type :
        </span>
        <Select
          closeMenuOnSelect={true}
          onChange={(e) => this.selectDashboard(e)}
          value={this.state.dashboardSelected}
          className="language-select "
          options={this.state.dashboard}
        />
        {/* {console.log(y)} */}
      </div>
    );
  }
  renderCivilDescriptionContainer() {
    return (
      <React.Fragment>
        <div className="col-12 subject-description-container  d-flex align-items-center">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="subject-labels h-100"
          >
            Civil Services Description(s):
          </label>
          <div className="descriptions-container">
            {this.renderCivilDescription()}
            <button
              className="admin-button-style ml-30px float-right mr-4 mb-3"
              onClick={() => this.addCivilDescription()}
            >
              Add para
            </button>
            <button
            className="admin-button-style ml-30px float-right mr-4 mb-3"
            onClick={() => this.deleteCivilDescription()}
          >
            Delete para
          </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
  renderClassDescriptionContainer() {
    return (
      <React.Fragment>
        <div className="col-12 subject-description-container  d-flex align-items-center">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="subject-labels h-100"
          >
            Classes Description(s):
          </label>
          <div className="descriptions-container">
            {this.renderClassDescription()}
            <button
              className="admin-button-style ml-30px float-right mr-4 mb-3"
              onClick={() => this.addClassDescription()}
            >
              Add para
            </button>
            <button
            className="admin-button-style ml-30px float-right mr-4 mb-3"
            onClick={() => this.deleteClassDescription()}
          >
            Delete para
          </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderHumanityDescriptionContainer() {
    return (
      <React.Fragment>
        <div className="col-12 subject-description-container  d-flex align-items-center">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="subject-labels h-100"
          >
            Humanities Description(s):
          </label>
          <div className="descriptions-container">
            {this.renderHumanityDescription()}
            <button
              className="admin-button-style ml-30px float-right mr-4 mb-3"
              onClick={() => this.addHumanityDescription()}
            >
              Add para
            </button>
            <button
            className="admin-button-style ml-30px float-right mr-4 mb-3"
            onClick={() => this.deleteHumanityDescription()}
          >
            Delete para
          </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderCommerceDescriptionContainer() {
    return (
      <React.Fragment>
        <div className="col-12 subject-description-container  d-flex align-items-center">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="subject-labels h-100"
          >
            Commerce Description(s):
          </label>
          <div className="descriptions-container">
            {this.renderCommerceDescription()}
            <button
              className="admin-button-style ml-30px float-right mr-4 mb-3"
              onClick={() => this.addCommerceDescription()}
            >
              Add para
            </button>
            <button
            className="admin-button-style ml-30px float-right mr-4 mb-3"
            onClick={() => this.deleteCommerceDescription()}
          >
            Delete para
          </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={3} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <div className="row my-3 px-4">
                  <div className="col-12 d-flex align-items-center justify-content-between">
                    <button
                      className="admin-button-style"
                      onClick={() =>
                        this.props.history.push(
                          "/limitedaccess/professor/dashboard"
                        )
                      }
                    >
                      Professor Ten Ten Dashboard
                    </button>
                    {this.renderSelect()}
                    <button
                      className="admin-button-style"
                      onClick={() => this.addSubject()}
                    >
                      {" "}
                      Save &amp; Publish
                    </button>
                  </div>
                  <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                    <h3> Edit Professor Ten Ten Dashboard</h3>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="subject-content-container ml-4">
                    <div className="row px-0 mx-0">
                      {this.state.dashboardSelected.label == "Civil"
                        ? this.renderCivilDescriptionContainer()
                        : ""}
                      {this.state.dashboardSelected.label == "Class"
                        ? this.renderClassDescriptionContainer()
                        : ""}
                      {this.state.dashboardSelected.label == "Humanity"
                        ? this.renderHumanityDescriptionContainer()
                        : ""}
                        {this.state.dashboardSelected.label == "Commerce/CA"
                        ? this.renderCommerceDescriptionContainer()
                        : ""}
                    </div>
                  </div>
                </div>

                <hr />

                <button
                  className="add-subject my-5 float-right mr-4"
                  onClick={() => this.addSubject()}
                >
                  Save &amp; Publish
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default HomepageProf;
