// import React from 'react';
import React, { Component } from "react";
import swal from "sweetalert";
import "../../Components/commonStyles.css";
import { Document, Page, pdfjs } from "react-pdf";
import styles1 from "../Products/products.module.css";
import ReactPlayer from "react-player";
import styles from "./lesson.module.css";
import Select from "react-select";
import "../../../node_modules/video-react/dist/video-react.css";
import LessonHeader from "../../Reusable/Header/lessonHeader";
import FacultyImage from "../../Assets/images/avatar.jpg";
import ContentImg from "../../Assets/images/helloquence.png";
import ReactTooltip from "react-tooltip";
import Img from "react-image";
import { MDBCollapse, MDBCloseIcon, MDBBtn, MDBIcon } from "mdbreact";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";

// import TopicQuiz from "../Quiz/TopicQuiz";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
const sources = {
  sintelTrailer: "http://media.w3.org/2010/05/sintel/trailer.mp4",
  bunnyTrailer: "http://media.w3.org/2010/05/bunny/trailer.mp4",
  bunnyMovie: "http://media.w3.org/2010/05/bunny/movie.mp4",
  test: "http://media.w3.org/2010/05/video/movie_300.webm",
};
var sampleMode;
class lessons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showContact: false,
      loading: true,
      source: sources.bunnyMovie,
      subjectid: "",
      showCourseTopicDiv: true,
      selected_subject: null,
      selectedSubjectTopic: [],
      test_included: 0,
      x: "",
      queryName: "",
      queryDescription: "",
      quizzes: [],
      topics: [],

      //lesson STATE
      lessonArray: [
        {
          memberImage: `${FacultyImage}`,
        },
        {
          memberImage: `${ContentImg}`,
        },
      ],

      aboutCourseDescriptionEnglish: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
      aboutCourseDescriptionHindi: `लोरम इप्सम केवल मुद्रण और टंकण उद्योग का डमी पाठ है। लोरम इप्सम 1500 के दशक के बाद से उद्योग का मानक डमी पाठ रहा है, जब एक अज्ञात प्रिंटर ने एक प्रकार की गली ली और एक प्रकार की नमूना पुस्तक बनाने के लिए इसे छान डाला। यह न केवल पांच शताब्दियों तक जीवित रहा है, बल्कि इलेक्ट्रॉनिक टंकण में भी छलांग लगाता है, शेष अनिवार्य रूप से अपरिवर्तित रहता है। यह 1960 में लोरम इप्सम मार्ग से युक्त लेट्रसेट शीट के विमोचन के साथ लोकप्रिय हुआ था, और हाल ही में एल्दस पेजमेकर जैसे डेस्कटॉप प्रकाशन सॉफ्टवेयर के साथ जिसमें लोरम इप्सम के संस्करण भी शामिल हैं।`,
      instructorName: `Amit`,
      instructorSubEnglish: `Lorem Ipsum`,
      instructorSubHindi: `
      लोरेम इप्सम`,
      instructorDescriptionEnglish: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
      instructorDescriptionHindi: `लोरम इप्सम केवल मुद्रण और टंकण उद्योग का डमी पाठ है। लोरम इप्सम 1500 के दशक के बाद से उद्योग का मानक डमी पाठ रहा है, जब एक अज्ञात प्रिंटर ने एक प्रकार की गली ली और एक प्रकार की नमूना पुस्तक बनाने के लिए इसे छान डाला। यह न केवल पांच शताब्दियों तक जीवित रहा है, बल्कि इलेक्ट्रॉनिक टंकण में भी छलांग लगाता है, शेष अनिवार्य रूप से अपरिवर्तित रहता है। इसे 1960 के दशक में लोरम इप्सम मार्ग से युक्त लेट्रसेट शीट के विमोचन के साथ लोकप्रिय किया गया था, और हाल ही में एल्दस पेजमेकर जैसे डेस्कटॉप प्रकाशन सॉफ्टवेयर के साथ जिसमें लोरम इप्सम के संस्करण शामिल हैं।`,
      instructorImage: `${FacultyImage}`,

      //description

      descriptionList: [
        "Lorem Ipsum is simply d",
        "Ipsum has been the industrys standard ",
        "Type specimen book. It has survived not only five centuries",
      ],

      numPages: null,
      pageNumber: 1,

      Who_course: [
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      ],

      lectures: [],
      selected_Lec: null,
      sampleMode: null,
      userid: "",
      subjectid: "",
      selected_lecture_index: 0,
      selected_topic_index: 0,
      progress: "",
      getcompleted_lectures: [],
      completed_quiz: [],
      completed: 0,
      quiz_modal: false,
      descriptions: [],
      topicsInclude: [],
      whatYou: [],
      languageId: "",

      R_there_any_course: [],
      selected_test: null,
    };
  }
  toggle_quiz = () => {
    this.setState({
      quiz_modal: !this.state.quiz_modal,
    });
  };
  handleClose = () => {
    this.setState({ quiz_modal: false });
    let topic_ids = this.state.selectedSubjectTopic.map((item) => {
      return { topic_id: item.topic_id };
    });
    this.getcompleted_Quiz();
    this.getQuizzes(topic_ids);
  };
  openContact() {
    document.getElementById("myContact").style.width = "450px";
  }

  closeContact() {
    document.getElementById("myContact").style.width = "125px";
  }
  renderModal() {
    // if (this.state.quiz_modal) {
    //   return (
    //     <MDBModal
    //       isOpen={this.state.quiz_modal}
    //       toggle={this.toggle_quiz}
    //       fullHeight
    //       backdrop={false}
    //       position="right"
    //       className="promo-modal-container"
    //     >
    //       <MDBModalBody style={{height:'90vh'}}>
    //        <TopicQuiz checkscore={0} closeModal={false}  onSelectClose={this.handleClose} />
    //       </MDBModalBody>
    //       <MDBModalFooter>
    //       <button
    //         onClick={()=>this.setState({quiz_modal:false})}
    //         className="start-quiz-button"
    //         type="button"
    //       >
    //         Close
    //       </button>
    //     </MDBModalFooter>
    //     </MDBModal>
    //   );
    // }
  }
  sendQuery = () => {
    this.setState({
      loading: true,
    });
    let user = JSON.parse(localStorage.getItem("userdata"));
    let userName = user.name;
    let subjectName = this.state.subject_name;
    let formData = new FormData();

    formData.append("queryName", this.state.queryName);
    formData.append("queryDescription", this.state.queryDescription);
    formData.append("userName", userName);
    formData.append("userEmail", user.email);
    formData.append("subjectName", subjectName);

    // NEED TO MAKE API ON BACKEND
    this.setState({
      response: fetch("https://10x10learning.com/send_query", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          swal("Query has been sent. We will contact you shortly");
          // this.getcompleted_lectures()
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  renderContactUs() {
    console.log("con23");
    return (
      <div
        className={`${styles1.contactUsContainer}`}
        id="myContact"
        style={{ width: "125px" }}
      >
        <div className={`${styles1.contactUsButtonContainer}`}>
          <button
            className={`${styles1.contactUsButton}`}
            onClick={
              this.state.showContact === true
                ? () => {
                    this.closeContact();
                    this.setState({ showContact: false });
                  }
                : () => {
                    this.openContact();
                    this.setState({ showContact: true });
                  }
            }
          >
            <span>Send query</span>
          </button>
        </div>
        <div
          className={`${styles1.contactUsForm} ${styles.contactUsFormQuery}`}
        >
          <MDBCloseIcon
            className={`${styles1.contactUsCloseBtn}`}
            onClick={this.closeContact}
          />
          <h2 className="Wcolor contact_header client_heading_font">
            <span className={`${styles.writeQuery}`}>Write your Query</span>
          </h2>
          <input
            placeholder="Title"
            group
            type="text"
            validate
            className="contact_input_text px-2 py-1 border-rounded form mb-2 queryDescription"
            value={this.state.queryName}
            onChange={(text) => this.setState({ queryName: text.target.value })}
          />

          <textarea
            rows="5"
            placeholder="Description"
            className={`${styles1.contactInputText} px-2 py-1 border-rounded form mb-2`}
            value={this.state.queryDescription}
            onChange={(text) =>
              this.setState({ queryDescription: text.target.value })
            }
          />
          <button
            style={{ backgroundColor: "red" }}
            className="contact_button contact_send_btn "
            onClick={() => {}}
          >
            <span style={{ color: "white" }}>Send</span>
          </button>
        </div>
      </div>
    );
  }
  componentWillMount() {
    // this.setState({sample_mode:this.props.history.location.state.sampleMode})
    console.log(this.props);
    // var id = window.localStorage.getItem("selected_course_user");
    let userid = 0;
    // let subjectid = localStorage.getItem("selected_subject");
    this.setState({ userid });
    sampleMode = JSON.parse(window.localStorage.getItem("sample_mode"));

    let subject_id;

    subject_id = atob(this.props.match.params.id);

    console.log(this.props);
    this.setState(
      {
        subjectid: subject_id,
        // test_included: JSON.parse(id).test_included,
        sampleMode,
      },
      () => this.getSubject()
    );
  }

  setLecture = (item) => {
    this.getlecture();
    // let userdata = JSON.parse(this.state.userid);
    // let userid = userdata.user_id;
    // console.log(userid);
    // console.log(this.state.subjectid);
    // console.log(item, "152");
    // let formData = new FormData();

    // formData.append("user_id", userid);
    // formData.append("subject_id", this.state.subjectid);
    // formData.append("lecture_id", item.id);

    // this.setState({
    //   response: fetch("https://10x10learning.com/add_progress", {
    //     method: "POST",
    //     body: formData,
    //   })
    //     .then((response) => response.json())
    //     .then((responseJson) => {
    //       this.getlecture();
    //       // this.getcompleted_lectures()

    //       this.setState({});
    //     })
    //     .catch((error) => {
    //       swal("Warning!", "Check your network!", "warning");
    //       console.log(error);
    //     }),
    // });
  };
  handlePreviousPDFPage() {
    if (this.state.pageNumber > 1) {
      this.setState({
        pageNumber: this.state.pageNumber - 1,
      });
    } else {
      this.selectLecture(
        this.state.topics[
          this.state.selected_lecture_index ==
          this.state.topics[this.state.selected_topic_index].lectures.length - 1
            ? this.state.selected_topic_index + 1
            : this.state.selected_topic_index
        ].lectures[
          this.state.selected_lecture_index ==
          this.state.topics[this.state.selected_topic_index].lectures.length - 1
            ? 0
            : this.state.selected_lecture_index + 1
        ],
        this.state.selected_lecture_index ==
          this.state.topics[this.state.selected_topic_index].lectures.length - 1
          ? 0
          : this.state.selected_lecture_index + 1,
        this.state.selected_lecture_index ==
          this.state.topics[this.state.selected_topic_index].lectures.length - 1
          ? this.state.selected_topic_index + 1
          : this.state.selected_topic_index
      );
    }
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  handleNextPDFPage() {
    if (this.state.pageNumber < this.state.numPages) {
      this.setState({
        pageNumber: this.state.pageNumber + 1,
      });
    } else {
      this.selectLecture(
        this.state.topics[
          this.state.selected_lecture_index ==
          this.state.topics[this.state.selected_topic_index].lectures.length - 1
            ? this.state.selected_topic_index + 1
            : this.state.selected_topic_index
        ].lectures[
          this.state.selected_lecture_index ==
          this.state.topics[this.state.selected_topic_index].lectures.length - 1
            ? 0
            : this.state.selected_lecture_index + 1
        ],
        this.state.selected_lecture_index ==
          this.state.topics[this.state.selected_topic_index].lectures.length - 1
          ? 0
          : this.state.selected_lecture_index + 1,
        this.state.selected_lecture_index ==
          this.state.topics[this.state.selected_topic_index].lectures.length - 1
          ? this.state.selected_topic_index + 1
          : this.state.selected_topic_index
      );
    }
  }
  addQuiz = (item) => {
    let userdata = JSON.parse(this.state.userid);
    let userid = userdata.user_id;
    console.log(userid);
    console.log(this.state.subjectid);
    console.log(item, "152");
    let formData = new FormData();

    formData.append("user_id", userid);
    formData.append("quiz_id", item.quiz_id);
    formData.append("subject_id", this.state.subjectid);
    formData.append(
      "topic_id",
      this.state.topics[this.state.selected_topic_index].topic_id
    );

    this.setState({
      response: fetch("https://10x10learning.com/add_progress_quiz", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.getQuestionsByQuestionId(item);
          this.getcompleted_Quiz();

          let topic_ids = this.state.selectedSubjectTopic.map((item) => {
            return { topic_id: item.topic_id };
          });
          this.getTopic(topic_ids, 0);
        })
        .catch((error) => {
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  componentDidMount() {
    // this.getcompleted_lectures();

    console.log(this.state);
    // const subjectid = this.props.history.location.state.id;
    // this.setState({ subjectid: subjectid }, () => this.getSubject());
  }

  getTests() {
    console.log(this.state.subjectid);
    let formBody = new FormData();
    formBody.append("subject_id", this.state.subjectid);
    fetch("https://10x10learning.com/get_test", {
      method: "POST",
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        responseJson.forEach((element) => {
          element.label = element.test_name;
          element.value = element.test_id;
        });
        this.setState({
          tests: responseJson,
          loading: false,
        });
      });
  }

  getlecture = async () => {
    this.setState({
      progress: String(0),
      loading: false,
    });

    // let userdata = JSON.parse(this.state.userid);
    // let userid = userdata.user_id;
    // let formData = new FormData();
    // formData.append("user_id", userid);
    // formData.append("subject_id", this.state.subjectid);

    // for (let key of formData.entries()) {
    //   console.log(key[0] + ",with  " + key[1]);
    // }

    // this.setState({
    //   response: fetch("https://10x10learning.com/get_progress", {
    //     method: "POST",
    //     body: formData,
    //   })
    //     .then((response) => response.json())
    //     .then((responseJson) => {
    //       console.log(responseJson);
    //       var val1 = Math.round(
    //         (Number(responseJson.completed_lectures) /
    //           Number(this.state.total_lectures)) *
    //           100
    //       );

    //       this.setState({
    //         progress: String(val1),
    //         loading: false,
    //       });

    //       // this.props.history.push("/limitedaccess/InlighterVein");
    //     })
    //     .catch((error) => {
    //       swal("Warning!", "Check your network!", "warning");
    //       console.log(error);
    //     }),
    // });
  };
  getcompleted_lectures = async () => {
    let userdata = JSON.parse(this.state.userid);
    let userid = userdata.user_id;
    let formData = new FormData();
    formData.append("user_id", userid);
    formData.append("subject_id", this.state.subjectid);

    for (let key of formData.entries()) {
      console.log(key[0] + ",with  " + key[1]);
    }

    this.setState({
      response: fetch("https://10x10learning.com/get_completed_lectures", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "sas1");

          this.setState(
            {
              getcompleted_lectures: responseJson.results,
            },
            () => this.getcompleted_Quiz()
          );

          // this.props.history.push("/limitedaccess/InlighterVein");
        })
        .catch((error) => {
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  getcompleted_Quiz = async () => {
    let userdata = JSON.parse(this.state.userid);
    let userid = userdata.user_id;
    let formData = new FormData();
    formData.append("user_id", userid);
    formData.append("subject_id", this.state.subjectid);

    for (let key of formData.entries()) {
      console.log(key[0] + ",with  " + key[1]);
    }

    this.setState({
      response: fetch("https://10x10learning.com/get_completed_quiz", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(this.state.completed_quiz);
          let { completed_quiz } = this.state;

          console.log(responseJson, "sas2");

          this.setState(
            {
              completed_quiz: responseJson.results,
            },
            () => this.getTests()
          );

          // this.props.history.push("/limitedaccess/InlighterVein");
        })
        .catch((error) => {
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  getlessonssub = async () => {
    let formData = new FormData();
    formData.append("subject_id", this.state.subjectid);
    this.setState({
      response: fetch("https://10x10learning.com/get_subject", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var languageId = responseJson[0].language_id;

          console.log(languageId, "987654");
          this.setState(
            {
              languageId: languageId,
            },
            () => this.getDialogues()
          );
        })
        // .then(() => {})
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  getSubject = async () => {
    let formData = new FormData();
    formData.append("subject_id", this.state.subjectid);
    this.setState({
      response: fetch("https://10x10learning.com/get_subject", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          let descriptionArray = JSON.parse(responseJson[0].description);
          let topicsArray = JSON.parse(responseJson[0].topics);
          let requirementsArray = JSON.parse(responseJson[0].requirements);
          let learning_topicArray = JSON.parse(responseJson[0].learning_topic);
          let responseJsonTopics = JSON.parse(responseJson[0].topics);
          let subject_name = responseJson[0].subject_name;
          this.setState(
            {
              selectedSubjectTopic: responseJsonTopics,
              descriptions: descriptionArray,
              topicsInclude: topicsArray,
              whatYou: learning_topicArray,
              R_there_any_course: requirementsArray,

              selected_subject: responseJson[0],
              subject_name,
            },
            () => this.getlessonssub()
          );
        })
        .then(() => {
          let topic_ids = this.state.selectedSubjectTopic.map((item) => {
            return { topic_id: item.topic_id };
          });
          console.log(topic_ids);
          this.getQuizzes(topic_ids);
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  getTopic = async (topicids, allow) => {
    console.log(topicids); //aaray

    let formData = new FormData();
    for (let key of formData.entries()) {
      console.log(key[0] + "" + key[1]);
    }
    formData.append("topic_ids", JSON.stringify(topicids));
    this.setState({
      response: fetch("https://10x10learning.com/get_multiple_topics", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "sas");

          var completed_lectures = this.state.getcompleted_lectures;
          var completed_quiz = this.state.completed_quiz;

          var total = 0;
          for (var v = 0; v < responseJson.length; v++) {
            var data = JSON.parse(responseJson[v].lectures);

            total = total + JSON.parse(responseJson[v].lectures).length;

            for (var v1 = 0; v1 < data.length; v1++) {
              data[v1].selected = 0;
              responseJson[v].lectures = data;

              for (let j = 0; j < completed_lectures.length; j++) {
                if (data[v1].id === completed_lectures[j].lecture_id) {
                  data[v1].completed = 1;
                }

                responseJson[v].lectures = data;
              }
            }
          }

          for (var i = 0; i < responseJson.length; i++) {
            var datai = responseJson[i].lectures;
            for (var q = 0; q < this.state.quizzes.length; q++) {
              console.log(this.state.quizzes[q], responseJson[i]);
              if (responseJson[i].topic_id == this.state.quizzes[q].topic_id) {
                datai.push(this.state.quizzes[q]);
              }
            }
            for (var i1 = 0; i1 < datai.length; i1++) {
              datai[i1].selected = 0;
              responseJson[i].lectures = datai;
            }
          }

          if (allow == 1) {
            console.log("allowed");
            this.setState({ topics: responseJson, total_lectures: total }, () =>
              this.selectLecture(responseJson[0].lectures[0], 0, 0)
            );
          } else {
            console.log("not  allowed");
            console.log(responseJson);
            this.setState({ topics: responseJson, total_lectures: total });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  getallLecture() {
    let { topics, lectures } = this.state;
  }
  selectLecture(item, index, index1) {
    console.log(item, "sssss");
    if (item.type != 10) {
      let { lectures, topics } = this.state;
      let current_topic = topics[index1];
      let current_lectures = topics[index1].lectures;

      for (var v = 0; v < current_lectures.length; v++) {
        current_lectures[v].selected = 0;
      }
      current_lectures[index].selected = 1;

      //topics[index1].lectures = current_lectures
      // selected_lecture.selected = 1;
      this.setState(
        {
          selected_Lec: item,
          selected_lecture_index: index,
          collapseID: this.state.topics[index1].topic_id,
          selected_topic_index: index1,
          pageNumber: 1,
        },
        () => {
          this.setLecture(item);
          // this.getcompleted_lectures();
          let topic_ids = this.state.selectedSubjectTopic.map((item) => {
            return { topic_id: item.topic_id };
          });
          this.getTopic(topic_ids, 0);
        }
      );

      this.setState({ topics });
    } else {
      let { lectures, topics } = this.state;
      let current_topic = topics[index1];

      //topics[index1].lectures = current_lectures
      // selected_lecture.selected = 1;
      this.setState(
        {
          selected_Lec: null,
          selected_lecture_index: -1,
          collapseID: this.state.topics[index1].topic_id,
          selected_topic_index: index1,
        },
        () => {
          this.addQuiz(item);
          this.setState({ quiz_modal: true });
          //this.selectQuiz(quiz_item)
          //window.open("http://localhost:3000/#/start-quiz", "_blank", "width=1280,height=720");
        }
      );

      this.setState({ topics });
    }
  }

  view() {}

  next() {
    let { lectures } = this.state;
    var selected_index = this.state.selected_Lec.id;
    let selected_lecture = lectures[selected_index];
    for (var v = 0; v < lectures.length; v++) {
      lectures[v].selected = 0;
    }
    selected_lecture.selected = 1;

    this.setState({ lectures });
    this.setState({
      selected_Lec: lectures[selected_index],
    });
  }

  prev() {
    let { lectures } = this.state;
    var selected_index = this.state.selected_Lec.id;
    let selected_lecture = lectures[selected_index - 2];
    for (var v = 0; v < lectures.length; v++) {
      lectures[v].selected = 0;
    }
    selected_lecture.selected = 1;

    this.setState({ lectures });
    this.setState({
      selected_Lec: lectures[selected_index - 2],
    });
  }

  renderWho_course() {
    const { expanded } = this.state;

    return this.state.Who_course.map((modal) => {
      return (
        <React.Fragment>
          <li>{modal}</li>
        </React.Fragment>
      );
    });
  }

  renderR_there_any_coursec() {
    return this.state.R_there_any_course.map((modal) => {
      return (
        <React.Fragment>
          <li>{modal.requirement}</li>
        </React.Fragment>
      );
    });
  }

  renderWhatYou() {
    return this.state.whatYou.map((modal) => {
      return (
        <React.Fragment>
          <li>{modal.topic}</li>
        </React.Fragment>
      );
    });
  }

  renderTopicInc() {
    return this.state.topicsInclude.map((modal) => {
      return (
        <React.Fragment>
          <li className={`${styles.DescriptionP}`}>{modal.name}</li>
        </React.Fragment>
      );
    });
  }

  renderdescriptionList() {
    const { expanded } = this.state;

    return this.state.descriptionList.map((modal) => {
      return (
        <React.Fragment>
          <li>{modal}</li>
        </React.Fragment>
      );
    });
  }

  renderdescriptionCourse() {
    return this.state.descriptions.map((modal) => {
      return (
        <React.Fragment>
          <p>{modal.description}</p>
        </React.Fragment>
      );
    });
  }

  renderLessonItems() {
    return this.state.lessonArray.map((member) => {
      return (
        <div key={member.memberName}>
          <div>
            <div>
              <img src={member.memberImage} />
            </div>
            <div></div>
          </div>
        </div>
      );
    });
  }

  toggleCollapse(id) {
    if (this.state.collapseID === id) {
      this.setState({
        collapseID: 0,
      });
    } else {
      this.setState({
        collapseID: id,
      });
    }
  }
  renderTxt(data) {
    //   var dbx = new Dropbox({ accessToken: 'nuv25l5gk8ktbug' });
    // dbx.filesListFolder({path: data.link})
    //     .then(function (response) {
    //         var blob = response.fileBlob;
    //         var reader = new FileReader();
    //         reader.addEventListener("loadend", function() {
    //           return( <p>{reader.result}</p>) // will print out file content
    //         });
    //         reader.readAsText(blob);
    //     })
    //     .catch(function (error) {
    //     })
  }
  readTextFile = (file) => {
    var rawFile = new XMLHttpRequest();
    rawFile.open("GET", file, false);
    rawFile.onreadystatechange = () => {
      if (rawFile.readyState === 4) {
        if (rawFile.status === 200 || rawFile.status == 0) {
          var allText = rawFile.responseText;

          this.setState({
            fundData: allText,
          });
        }
      }
    };
    rawFile.send(null);
  };
  renderIcon(data) {
    console.log(data);
    let type = data.type.toLowerCase();
    // var str = data.type;
    // var res = str.split('.');
    // console.log(res)

    if (type == "text") {
      return (
        <img
          src={require("../../Assets/images/doc_icon.png")}
          style={{ cursor: "pointer" }}
          alt="10x10-logo"
          className={`${styles.lessontypeicon}`}
          height="12px"
        />
      );
    } else if (type.toLowerCase() === "image") {
      return (
        <img
          src={require("../../Assets/images/image_icon.png")}
          style={{ cursor: "pointer" }}
          alt="10x10-logo"
          className={`${styles.lessontypeicon}`}
        />
      );
    } else if (type.toLowerCase() == "pptx") {
      return (
        <img
          src={require("../../Assets/images/ppt_icon.png")}
          style={{ cursor: "pointer" }}
          alt="10x10-logo"
          className={`${styles.lessontypeicon}`}
        />
      );
    } else if (type.toLowerCase() == "ppt") {
      return (
        <img
          src={require("../../Assets/images/ppt_icon.png")}
          style={{ cursor: "pointer" }}
          alt="10x10-logo"
          className={`${styles.lessontypeicon}`}
        />
      );
    } else if (type.toLowerCase() == "video") {
      return (
        <img
          src={require("../../Assets/images/video_icon.png")}
          style={{ cursor: "pointer", color: "gray" }}
          alt="10x10-logo"
          className={`${styles.lessontypeicon}`}
        />
      );
    } else if (type.toLowerCase() == "pdf") {
      return (
        <img
          src={require("../../Assets/images/pdf_icon.png")}
          style={{ cursor: "pointer", color: "gray" }}
          alt="10x10-logo"
          className={`${styles.lessontypeicon}`}
        />
      );
    }
  }

  renderSampleCourseTopics() {
    return this.state.topics.map((modal, index1) => {
      if (modal.sample === 0) {
        return (
          <React.Fragment>
            <del className="d-none">
              <MDBBtn
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "initial",
                  alignItems: "center",
                }}
                className={`${styles.CollapseBtn} ${styles.CollapseBtnCourse}`}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "initial",
                  }}
                >
                  <div
                    className={`${styles.CourseIntroduction} d-flex justify-space-between align-items-center`}
                  >
                    <span>{modal.topic_name}</span>
                    <MDBIcon
                      icon={
                        modal.topic_id == this.state.collapseID
                          ? "minus"
                          : "plus"
                      }
                      className="ml-auto mr-3"
                    />
                  </div>

                  <div className={`${styles.CourseLectures}`}>
                    <text>{modal.lectures.length} lecture(s) </text>
                  </div>
                </div>
              </MDBBtn>
            </del>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <MDBBtn
              className={`${styles.CollapseBtn} ${styles.CollapseBtnCourse} pl-2`}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                textAlign: "initial",
                alignItems: "center",
              }}
              onClick={() => this.toggleCollapse(modal.topic_id)}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "initial",
                }}
              >
                <div
                  className={`${styles.CourseIntroduction} d-flex justify-space-between align-items-center}`}
                >
                  <span>{modal.topic_name}</span>{" "}
                </div>

                <div className={`${styles.CourseLectures}`}>
                  <text>{modal.lectures.length} lecture(s) </text>{" "}
                </div>
              </div>
              <MDBIcon
                icon={
                  modal.topic_id == this.state.collapseID ? "minus" : "plus"
                }
                className="ml-auto mr-3"
              />
            </MDBBtn>

            {modal.lectures.map((item, index) => {
              console.log(item);
              if (item.type != 10) {
                return (
                  <MDBCollapse
                    className={`${styles.CollapseInside_lesson}`}
                    id={modal.topic_id}
                    isOpen={this.state.collapseID}
                  >
                    <div
                      style={{
                        backgroundColor:
                          this.state.selected_Lec != null &&
                          item.id == this.state.selected_Lec.id
                            ? "#e6f2f5"
                            : "#fff",
                      }}
                      onClick={() => this.selectLecture(item, index, index1)}
                      className={`d-flex justify-content-between align-items-center ${styles.inside_collapse}`}
                    >
                      <p
                        style={{
                          cursor: "pointer",
                          color: "#4A4A4A",

                          fontSize: "15px",

                          marginBottom: ".5%",
                        }}
                        className=""
                      >
                        {index + 1}. {item.lecture_name}
                        {console.log(item)}
                      </p>
                      <p
                        className={`${styles.duration_text} d-flex flex-column `}
                        style={{ marginBottom: 0 }}
                      >
                        <span>
                          {this.renderIcon(item)} {item.duration}{" "}
                          {item.link.includes("pdf") ? (
                            <React.Fragment> pages</React.Fragment>
                          ) : (
                            <React.Fragment>min</React.Fragment>
                          )}
                          {this.renderCompleted(item, "Lecture Completed")}{" "}
                        </span>
                        {/* {this.renderDownloadButton(item)} */}
                      </p>
                    </div>
                    <ReactTooltip place="bottom" type="dark" effect="float" />
                  </MDBCollapse>
                );
              } else {
                console.log(item);
                return (
                  <MDBCollapse
                    className={`${styles.CollapseInside_lesson}`}
                    id={modal.topic_id}
                    isOpen={this.state.collapseID}
                    style={{
                      backgroundColor: item.selected == 1 ? "#e6f2f5" : "#fff",
                    }}
                    onClick={() => this.selectLecture(item, index, index1)}
                  >
                    <div
                      className={`d-flex justify-content-between align-items-center ${styles.inside_collapse}`}
                    >
                      <p
                        style={{
                          cursor: "pointer",
                          color: "#4A4A4A",

                          fontSize: "15px",

                          marginBottom: ".5%",
                        }}
                        className=""
                      >
                        <MDBIcon icon="pencil-alt" /> {item.quiz_name} Quiz{" "}
                        {this.renderCompleted(item, `Score: ${item.score}`)}
                      </p>
                    </div>
                    <ReactTooltip place="bottom" type="dark" effect="float" />
                  </MDBCollapse>
                );
              }
            })}
          </React.Fragment>
        );
      }
    });
  }

  getQuestionsByQuestionId(quiz) {
    let formData = new FormData();
    formData.append("question_id", quiz.questions);
    // let result = null
    fetch("https://10x10learning.com/get_multiple_questions", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((responsejson) => {
        console.log(responsejson);
        window.localStorage.setItem(
          "quiz_details",
          JSON.stringify({
            questions: responsejson,
            subject: this.state.selected_subject.subject_name,
            subject_id: this.state.selected_subject.subject_id,
            topic_id: quiz.topic_id,
            topic: this.state.selectedSubjectTopic.find(
              (element) => element.topic_id === quiz.topic_id
            ).topic_name,
          })
        );
      })
      .catch((error) => {
        console.log(error);
        swal("Warning", "Network error", "warning");
      });
  }

  renderQuizModal(quiz_item) {
    this.getQuestionsByQuestionId(quiz_item);
    //this.selectQuiz(quiz_item)
    window.open(
      "http://localhost:3000/#/start-quiz",
      "_blank",
      "width=1280,height=720"
    );
  }

  renderCourseTopic() {
    if (this.state.topics.length != 0) {
      return this.state.topics.map((modal, index1) => {
        let quizForTopic = false;
        let quiz = null;

        return (
          <React.Fragment>
            <MDBBtn
              className={`${styles.CollapseBtn} ${styles.CollapseBtnCourse} pl-2`}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                textAlign: "initial",
                alignItems: "center",
              }}
              onClick={() => this.toggleCollapse(modal.topic_id)}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "initial",
                }}
              >
                <div
                  className={`${styles.CourseIntroduction} d-flex justify-space-between align-items-center}`}
                >
                  <span>{modal.topic_name}</span>{" "}
                </div>

                <div className={`${styles.CourseLectures}`}>
                  <text>{modal.lectures.length} lecture(s) </text>{" "}
                </div>
              </div>
              <MDBIcon
                icon={
                  modal.topic_id == this.state.collapseID ? "minus" : "plus"
                }
                className="ml-auto mr-3"
              />
            </MDBBtn>

            {modal.lectures.map((item, index) => {
              console.log(item);
              if (item.type != 10) {
                return (
                  <MDBCollapse
                    className={`${styles.CollapseInside_lesson}`}
                    id={modal.topic_id}
                    isOpen={this.state.collapseID}
                  >
                    <div
                      style={{
                        backgroundColor:
                          this.state.selected_Lec != null &&
                          item.id == this.state.selected_Lec.id
                            ? "#e6f2f5"
                            : "#fff",
                      }}
                      onClick={() => this.selectLecture(item, index, index1)}
                      className={`d-flex justify-content-between align-items-center ${styles.inside_collapse}`}
                    >
                      <p
                        style={{
                          cursor: "pointer",
                          color: "#4A4A4A",

                          fontSize: "15px",

                          marginBottom: ".5%",
                        }}
                        className=""
                      >
                        {index + 1}. {item.lecture_name}
                        {console.log(item)}
                      </p>
                      <p
                        className={`${styles.duration_text} d-flex flex-column `}
                        style={{ marginBottom: 0 }}
                      >
                        <span>
                          {this.renderIcon(item)} {item.duration}
                          {item.link.includes("pdf") ? (
                            <React.Fragment> pages</React.Fragment>
                          ) : (
                            <React.Fragment>min</React.Fragment>
                          )}
                          {this.renderCompleted(item, "Lecture Completed")}{" "}
                        </span>
                        {/* {this.renderDownloadButton(item)} */}
                      </p>
                    </div>
                    <ReactTooltip place="bottom" type="dark" effect="float" />
                  </MDBCollapse>
                );
              } else {
                console.log(item);
                return (
                  <MDBCollapse
                    className={`${styles.CollapseInside_lesson}`}
                    id={modal.topic_id}
                    isOpen={this.state.collapseID}
                    style={{
                      backgroundColor: item.selected == 1 ? "#e6f2f5" : "#fff",
                    }}
                    onClick={() => this.selectLecture(item, index, index1)}
                  >
                    <div
                      className={`d-flex justify-content-between align-items-center ${styles.inside_collapse}`}
                    >
                      <p
                        style={{
                          cursor: "pointer",
                          color: "#4A4A4A",

                          fontSize: "15px",

                          marginBottom: ".5%",
                        }}
                        className=""
                      >
                        <MDBIcon icon="pencil-alt" /> {item.quiz_name} Quiz{" "}
                        {this.renderCompleted(item, `Score: ${item.score}`)}
                      </p>
                    </div>
                    <ReactTooltip place="bottom" type="dark" effect="float" />
                  </MDBCollapse>
                );
              }
            })}
          </React.Fragment>
        );
      });
    }
  }
  renderCompleted(item, messsage) {
    if (item.completed) {
      return (
        <MDBIcon
          style={{ color: "#FD8400", minWidth: 30, textAlign: "center" }}
          data-tip={messsage}
          icon="check-circle"
        />
      );
    }
  }

  renderDownloadButton = (item) => {
    console.log(item.link);
    if (
      (item.type == "PDF" || item.type == "pptx" || item.type == "PPT") &&
      this.state.progress >= 100
    ) {
      return (
        <p className={`${styles.downloadButton} mt-2`}>
          <a href={item.link} download>
            Download File
          </a>
        </p>
      );
    }
  };

  renderLectures() {
    if (this.state.selected_Lec != null) {
      if (this.state.selected_Lec.type == "Image") {
        return (
          <Img
            src={this.state.selected_Lec.link}
            className={`${styles.renderImage}`}
          />
        );
      } else if (this.state.selected_Lec.type == "PPT") {
        return (
          <iframe
            src="https://docs.google.com/presentation/d/e/2PACX-1vQUzy2Jvbob4F5_HBFwsXV9ZyZBFU9hvH0FuPjtvVHYygd-Mc38HNfqI1biER-MteEwEiivZAwR69K8/embed?start=false&loop=true&delayms=3000"
            frameborder="0"
            // width="100%"
            // height="500"
            className={`${styles.pptiframe}`}
            allowfullscreen="true"
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
          ></iframe>
        );
      } else if (this.state.selected_Lec.type == "PDF") {
        const { pageNumber, numPages } = this.state;
        return (
          <React.Fragment>
            <div style={{ width: "100%", height: "100%" }}>
              <Document
                file={this.state.selected_Lec.link}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
              <p className="ml-3 pdfArrows">
                <MDBIcon
                  icon="angle-left"
                  style={{
                    color: "black",
                    cursor: "pointer",
                    left: "10%",
                    position: "absolute",
                    fontSize: "35px",
                  }}
                  // onClick={() =>
                  //   this.setState({
                  //     pageNumber:
                  //       pageNumber > 1 ? this.state.pageNumber - 1 : ""
                  //   })
                  // }
                  onClick={() => this.handlePreviousPDFPage()}
                />{" "}
                Page {pageNumber} of {numPages}{" "}
                <MDBIcon
                  icon="angle-right"
                  style={{
                    color: "black",
                    cursor: "pointer",
                    right: "10%",
                    position: "absolute",
                    fontSize: "35px",
                  }}
                  // onClick={() =>
                  //   this.setState(
                  //     {
                  //       pageNumber:
                  //         pageNumber < numPages ? this.state.pageNumber + 1 : ""
                  //     },
                  //     () => console.log("dsghe")
                  //   )
                  // }
                  onClick={() => this.handleNextPDFPage()}
                />
              </p>
            </div>
          </React.Fragment>
        );
      } else if (this.state.selected_Lec.type == "Text") {
        return (
          <iframe
            src={this.state.selected_Lec.link}
            frameborder="0"
            width="100%"
            height="500"
            allowfullscreen="true"
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
          >
            {" "}
          </iframe>
        );
      } else if (this.state.selected_Lec.type == "Zip") {
        return (
          <React.Fragment>
            <iframe
              src={this.state.selected_Lec.link}
              width="100%"
              height="100%"
              frameborder="0"
            ></iframe>
          </React.Fragment>
        );
      } else if (
        this.state.selected_Lec.type == "Video" ||
        this.state.selected_Lec.type == "video"
      ) {
        return (
          <React.Fragment>
            <ReactPlayer
              url={this.state.selected_Lec.link}
              width="100%"
              height="500px"
              playing
              controls={true}
              className=" video-lecture"
            />
          </React.Fragment>
        );
      }
    }
  }

  closeCourseContent() {
    document.getElementById("lessonContent").style.width = "90%";
    // document.getElementById("myCourse").style.opacity = "1";
    document.getElementById("myCourse").style.display = "none";
    document.getElementById("lessonCloseBtnDiv").style.display = "block";
    // document.getElementsByClassName("video-react").style.paddingTop='41%';
  }
  openCourseContent() {
    document.getElementById("lessonCloseBtnDiv").style.display = "none";

    document.getElementById("lessonContent").style.width = "70%";

    document.getElementById("myCourse").style.display = "block";
  }

  overViewColor() {
    document.getElementById("overViewChange").style.borderBottom =
      "4px solid #0093D9";
  }
  toggleDiv = () => {};

  getQuizzes = async (topic_ids) => {
    this.getTopic(topic_ids, 1);
    // let formData = new FormData();
    // formData.append("subject_id", this.state.selected_subject.subject_id);
    // this.setState({
    //   response: fetch("https://10x10learning.com/get_quiz_subject", {
    //     method: "POST",
    //     body: formData,
    //   })
    //     .then((response) => response.json())
    //     .then((responseJson) => {
    //       console.log(responseJson);
    //       let { completed_quiz } = this.state;
    //       for (var r = 0; r < responseJson.length; r++) {
    //         for (var q = 0; q < completed_quiz.length; q++) {
    //           if (responseJson[r].quiz_id == completed_quiz[q].quiz_id) {
    //             console.log(completed_quiz[q]);
    //             if (completed_quiz[q].score != null) {
    //               responseJson[r].completed = 1;
    //               responseJson[r].score = completed_quiz[q].score;
    //             }
    //           }
    //         }
    //       }
    //       this.setState({ quizzes: responseJson }, () =>
    //         this.getTopic(topic_ids, 1)
    //       );
    //     })
    //     .catch((error) => {
    //       this.setState({
    //         loading: false,
    //       });
    //       swal("Warning!", "Check your network!", "warning");
    //       console.log(error);
    //     }),
    // });
  };

  startTest() {
    localStorage.setItem("test_for_subject", this.state.subject_name);
    console.log(this.state.test_included);
    if (this.state.selected_test == null || !this.state.selected_test) {
      return swal("Select a test", "", "info");
    }
    // if (this.state.test_included == 1) {
    window.localStorage.removeItem("selected_test");
    window.localStorage.setItem(
      "selected_test",
      this.state.selected_test.test_id
    );
    window.localStorage.setItem(
      "test_name",
      this.state.selected_test.test_name
    );
    console.log(this.state.selected_subject);
    this.props.history.push("/start-test");
    // } else {
    //   swal(
    //     "",
    //     "To use the test series for this course, please purchase it from the Course Page",
    //     "info"
    //   );
    // }
  }
  onclickstart = () => {};

  getDialogues = async () => {
    let formBody = new FormData();
    formBody.append("language_id", this.state.languageId);
    try {
      const dialogueResponse = await fetch(
        "https://10x10learning.com/prof10_lesson",
        {
          method: "POST",
          body: formBody,
        }
      );
      const result = await dialogueResponse.json();
      const dialogues = JSON.parse(result[0].text).map(
        (item) => item.description
      );
      this.setState({ dialogues });
    } catch (err) {
      console.log(err);
    }
  };
  render() {
    let user = JSON.parse(localStorage.getItem("userdata"));
    console.log(this.state.progress);
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: calc(100vh / 2) auto;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      if (this.state.topics.length != 0) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
            id="lesson-page"
          >
            {/* {!this.state.sampleMode ? (
              <LessonHeader {...this.props} progress={this.state.progress} />
            ) : null} */}

            <div className="">
              <div className="row mx-0" id="lessonContent">
                <div className={`${styles.VideoDiv}`}>
                  <div
                    className={
                      this.state.showCourseTopicDiv
                        ? `${styles.lessonShowContent}`
                        : `col-12 ${styles.lessonShowContent}`
                    }
                  >
                    {this.renderLectures()}

                    <div
                      className={
                        this.state.showCourseTopicDiv
                          ? "d-none"
                          : `d-block ${styles.renderopenArrow}`
                      }
                    >
                      <button
                        className={`${styles.lessonCloseBtn}`}
                        onClick={() =>
                          this.setState({ showCourseTopicDiv: true })
                        }
                      >
                        <i
                          style={{ marginRight: "5px" }}
                          class="fas fa-arrow-left"
                        ></i>
                      </button>
                    </div>
                    <img
                      style={{
                        display:
                          this.state.selected_topic_index ==
                            this.state.topics.length - 1 &&
                          this.state.selected_lecture_index ==
                            this.state.topics[this.state.selected_topic_index]
                              .lectures.length -
                              1
                            ? "none"
                            : "block",
                      }}
                      src={require("../../Assets/images/arrowRight.png")}
                      className={`${styles.arrowRight}`}
                      onClick={() =>
                        this.selectLecture(
                          this.state.topics[
                            this.state.selected_lecture_index ==
                            this.state.topics[this.state.selected_topic_index]
                              .lectures.length -
                              1
                              ? this.state.selected_topic_index + 1
                              : this.state.selected_topic_index
                          ].lectures[
                            this.state.selected_lecture_index ==
                            this.state.topics[this.state.selected_topic_index]
                              .lectures.length -
                              1
                              ? 0
                              : this.state.selected_lecture_index + 1
                          ],
                          this.state.selected_lecture_index ==
                            this.state.topics[this.state.selected_topic_index]
                              .lectures.length -
                              1
                            ? 0
                            : this.state.selected_lecture_index + 1,
                          this.state.selected_lecture_index ==
                            this.state.topics[this.state.selected_topic_index]
                              .lectures.length -
                              1
                            ? this.state.selected_topic_index + 1
                            : this.state.selected_topic_index
                        )
                      }
                    />

                    <img
                      style={{
                        display:
                          this.state.selected_lecture_index == 0 &&
                          this.state.selected_topic_index == 0
                            ? "none"
                            : "block",
                      }}
                      onClick={() =>
                        this.selectLecture(
                          this.state.topics[
                            this.state.selected_lecture_index == 0
                              ? this.state.selected_topic_index - 1
                              : this.state.selected_topic_index
                          ].lectures[
                            this.state.selected_lecture_index == 0
                              ? this.state.topics[
                                  this.state.selected_lecture_index == 0
                                    ? this.state.selected_topic_index - 1
                                    : this.state.selected_topic_index
                                ].lectures.length - 1
                              : this.state.selected_lecture_index - 1
                          ],
                          this.state.selected_lecture_index == 0
                            ? this.state.topics[
                                this.state.selected_lecture_index == 0
                                  ? this.state.selected_topic_index - 1
                                  : this.state.selected_topic_index
                              ].lectures.length - 1
                            : this.state.selected_lecture_index - 1,
                          this.state.selected_lecture_index == 0
                            ? this.state.selected_topic_index - 1
                            : this.state.selected_topic_index
                        )
                      }
                      src={require("../../Assets/images/arrowLeft.png")}
                      className={`${styles.arrowLeft}`}
                    />
                  </div>

                  <div
                    className={
                      sampleMode == true
                        ? "d-none col-12 m-5"
                        : `col-12 m-5 ${styles.lessondetailscontainer}`
                    }
                  >
                    <div className="w-100">
                      <div className={`${styles.aboutCourse}`}>
                        <p
                          className={
                            this.state.languageId == 0
                              ? `${styles.aboutCourseHeading} opensansB`
                              : `${styles.aboutCourseHeading} opensansB`
                          }
                        >
                          {this.state.languageId === 0
                            ? `About this course`
                            : `इस कोर्स के बारे में`}

                          {console.log(this.state.languageId, "123456")}
                        </p>
                      </div>

                      <div className={`${styles.description}`}>
                        <div className={`${styles.descriptiontext}`}>
                          <text
                            className={
                              this.state.languageId == 0
                                ? "opensansB"
                                : "opensansB"
                            }
                          >
                            {this.state.languageId == 0
                              ? `Description`
                              : `विवरण`}
                          </text>
                        </div>
                        <div
                          className={
                            this.state.languageId == 0
                              ? `${styles.descriptionPara} opensansRLine`
                              : `${styles.descriptionPara} opensansRLine`
                          }
                        >
                          <div>
                            {this.renderdescriptionCourse()}

                            {/* {this.renderdescriptionList()} */}
                          </div>
                          <div
                            className={`${styles.descriptionTextHeadingDivMargin}`}
                          >
                            <text
                              className={
                                this.state.languageId == 0
                                  ? `${styles.descriptionTextHeading} opensansB`
                                  : `${styles.descriptionTextHeading} opensansB`
                              }
                            >
                              {this.state.languageId == 0
                                ? `Topic include `
                                : `विषय शामिल हैं  `}
                              <span className="opensansR">:</span>
                            </text>

                            {this.renderTopicInc()}
                          </div>
                          <div
                            className={`${styles.descriptionTextHeadingDivMargin}`}
                          >
                            <text
                              className={
                                this.state.languageId == 0
                                  ? `${styles.descriptionTextHeading} opensansB`
                                  : `${styles.descriptionTextHeading} opensansB`
                              }
                            >
                              {this.state.languageId == 0
                                ? `What you'll learn `
                                : `आप क्या सीखेंगे  `}
                              <span className="opensansR">:</span>
                            </text>

                            {this.renderWhatYou()}
                          </div>
                          <div
                            className={`${styles.descriptionTextHeadingDivMargin}`}
                          >
                            <text
                              className={
                                this.state.languageId == 0
                                  ? `${styles.descriptionTextHeading} opensansB`
                                  : `${styles.descriptionTextHeading} opensansB`
                              }
                            >
                              {this.state.languageId == 0
                                ? `Are there any course requirements prerequisites `
                                : `क्या किसी भी तरह की आवश्यकताएं आवश्यक शर्तें हैं  `}
                              <span className="opensansR">:</span>
                            </text>

                            {this.renderR_there_any_coursec()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    this.state.showCourseTopicDiv
                      ? `${styles.lessonTopicsID}`
                      : "d-none"
                  }
                >
                  <p
                    className={`mt-3 mb-3 ml-3 d-flex justify-content-between ${styles.courseContentIcon}`}
                  >
                    <span
                      className={
                        this.state.languageId == 0
                          ? `${styles.subjectName} opensansB`
                          : `${styles.subjectName} opensansB`
                      }
                    >
                      {this.state.subject_name}
                    </span>
                  </p>

                  {this.state.sampleMode
                    ? this.renderSampleCourseTopics()
                    : this.renderCourseTopic()}
                  <div
                    className={
                      !this.state.sampleMode
                        ? this.state.tests?.length > 0
                          ? `${styles.Test}`
                          : "d-none"
                        : `d-none`
                    }
                  >
                    <p
                      className={
                        this.state.languageId == 0
                          ? `${styles.TestHeading} opensansB`
                          : `${styles.TestHeading} opensansB`
                      }
                    >
                      {this.state.languageId == 0
                        ? `Test Series`
                        : `टेस्ट सीरीज`}
                    </p>

                    <p
                      className={
                        this.state.languageId == 0
                          ? `${styles.TestPara} opensansB`
                          : `${styles.TestPara} opensansB`
                      }
                    >
                      {this.state.languageId == 0 ? `` : ``}
                    </p>
                    <Select
                      options={this.state.tests}
                      value={this.state.selected_test}
                      onChange={(e) => this.setState({ selected_test: e })}
                      className={styles.chooseTest}
                    />

                    <button
                      className={
                        this.state.languageId == 0
                          ? `${styles.TestButton} btn opensansR`
                          : `${styles.TestButton} btn opensansRR`
                      }
                      onClick={() => this.startTest()}
                    >
                      {this.state.languageId == 0
                        ? `Start Now`
                        : `अभी शुरू करो`}
                    </button>
                  </div>
                </div>

                <div className="w-100"></div>
              </div>
            </div>
            {/* <ProfTen dialogues={this.state.dialogues} /> */}

            {/* <div style={{ height: "auto" }}>
              <MainFooterPage {...this.props} />/
            </div> */}
            {/* {user != undefined ? this.renderContactUs() : ""}
            {this.renderModal()} */}
          </div>
        );
      } else {
        return (
          <div className="sweet-loading">
            <GridLoader
              css={override}
              sizeUnit={"px"}
              size={5}
              color={"#c01825"}
              loading={this.state.loading}
            />
          </div>
        );
      }
    }
  }
}
export default lessons;
