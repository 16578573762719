import React, { Component, Fragment } from "react";
import styles from "../DashboardComponent/DashboardComponent.module.css";
import time from "../../../Assets/images/time.png";
import lessons from "../../../Assets/images/lessons.png";
import grad from "../../../Assets/images/grade.png";
import swal from "sweetalert";
import stars from "../../../Assets/images/star.png";
import courseDuration from "../../../Assets/images/course-duration.png";
import "./otherservice.css";
import HomeHeader from "../../../Reusable/Header/homeHeader";
import MainFooterPage from "../../../Reusable/Footer/footer";
import GridLoader from "react-spinners/GridLoader";
import { css } from "@emotion/core";
import Login from "../../../Reusable/Header/login";
import ProfTen from "../../Prof10X10/prof10x10";

var subjectsToDisplay = [];

export default class Otherservice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dashboard_item: [],
      selectedClass: "",
      title: "",
      subtitle: "",
      language_id: "",
      loginToggle: false,
      banner: "",
      dialogues: [],
      showLogin:
        this.props.showLogin === undefined ? false : this.props.showLogin,
    };
  }
  componentWillMount() {
    let banner = JSON.parse(localStorage.getItem("verticalBannerImage"));
    this.setState({ banner: banner[0].link });
    let language_id = localStorage.getItem("language_id");
    console.log(language_id);

    this.setState({ language_id }, () => this.getDialogues());
  }

  getDialogues = async () => {
    let formBody = new FormData();
    formBody.append("language_id", this.state.language_id);
    try {
      const dialogueResponse = await fetch(
        "https://10x10learning.com/prof10_other_service",
        {
          method: "POST",
          body: formBody,
        }
      );
      const result = await dialogueResponse.json();
      const dialogues = JSON.parse(result[0].text).map(
        (item) => item.description
      );
      this.setState({ dialogues }, () => this.getOtherServiceCategory());
    } catch (err) {
      console.log(err);
    }
  };

  productDetail = (item) => {
    localStorage.setItem("other_service_type", item.categorytype);
    this.props.history.push(`/other-service/${item.categorytype}`);
    console.log(item);
  };
  getOtherServiceCategory = async () => {
    console.log(this.state.language_id);
    let details = {
      language_id: this.state.language_id,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/get_otherservicecategories", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "qwesd");
          let categories = responseJson.sort((a, b) => a.orderid - b.orderid);
          console.log(categories);
          categories = categories.filter((item) => item.status == 1);
          console.log(categories);
          this.setState({ categories }, () => this.getServicesText());
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  getServicesText() {
    let formData = new FormData();
    formData.append("language_id", this.state.language_id);
    fetch("https://10x10learning.com/get_other_services_text", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("ServicesText", responseJson);
        const result = responseJson[0];
        this.setState({
          title: result.name,
          subtitle: result.subtitle,
          loading: false,
        });
      });
  }

  getUserDetails() {
    var userdata = window.localStorage.getItem("userdata");
    // console.log(userdata ,'Naruto')
    if (userdata != null) {
      this.setState(
        {
          userdata: JSON.parse(userdata),
        },
        () => this.getCart()
      );
    } else {
      this.setState({
        course_purchase: 2,
        loading: false,
      });
    }
  }

  bannerDashboard = () => {
    return (
      <Fragment>
        <span className={`${styles.BannerText}`}>{this.state.title}</span>
        <span className={`${styles.BannerTextBelow}`}>
          {this.state.subtitle}
        </span>
      </Fragment>
    );
  };
  displayCategories = () => {
    return (
      <Fragment>
        {this.state.categories.map((item) => {
          console.log(item);
          return (
            <div className="col-12 col-lg-3 col-md-6">
              <div className={`${styles.OuterContentCourse}`}>
                <div
                  className="ContentCourseOtherService"
                  onClick={() => this.productDetail(item)}
                >
                  <img
                    src={item.categoryimage}
                    className={`${styles.CardImage}`}
                  />
                  <div className={`${styles.CardBody}`}>
                    <p className="CardTitleOtherService">
                      {item.categoryname}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Fragment>
    );
  };

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div className={`${styles.CivilServices}`} id="civil-services">
          <HomeHeader
            {...this.props}
            backgroundColor="#FFFFFF"
            textcolor="#000"
            width="100%"
            height="auto"
          />
          <div
            className={`${styles.BannerImage}`}
            style={{
              backgroundImage: `url(${this.state.banner})`,
              opacity: 0.8,
              background: "linear-gradient(#e66465, #9198e5);",
            }}
          >
            {this.bannerDashboard()}
          </div>
          <div className={`${styles.Courses}`}>
            {this.state.loading ? (
              <div className="sweet-loading-main">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#c01825"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <div class="row otherservicerow">{this.displayCategories()}</div>
            )}
          </div>
          <ProfTen dialogues={this.state.dialogues} />
          <div style={{ height: "auto" }}>
            <MainFooterPage {...this.props} />
          </div>

          <Login showLogin={this.state.showLogin} {...this.props} />
        </div>
      );
    }
  }
}
