import React, { Component } from "react";
import { Carousel, CarouselItem, CarouselIndicators } from "reactstrap";
import "./slider.css";
import Image1 from "../../Assets/images/Layer1.jpg";
import Image2 from "../../Assets/images/Layer2.jpg";
import swal from "sweetalert";
import Image3 from "../../Assets/images/Layer3.jpg";

class SliderImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      language_id: "",
      bannerImages: [],
      items: [
        {
          img: `${Image1}`,
        },
        {
          img: `${Image2}`,
        },
        {
          img: `${Image2}`,
        },
      ],
    };

    this.goToIndex = this.goToIndex.bind(this);
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  componentWillMount() {
    let language_id = localStorage.getItem("language_id");
    console.log(language_id);
    if (language_id == null) {
      language_id = 0;
    }
    this.setState({ language_id }, () => {
      this.getBannerImages();
    });
    //    this.getSliderImages()
  }

  getBannerImages = async () => {
    let details = {
      language_id: this.state.language_id,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://10x10learning.com/get_banner_by_languageID", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "xxx");
          this.setState({ bannerImages: responseJson }, () =>
            console.log(this.state.bannerImages)
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  // getSliderImages=async()=>{
  //   this.setState({
  //       loading:true
  //   })
  // this.setState({
  //   response: fetch('https://10x10learning.com/get_slider_images', {
  //    method: 'GET',
  //    headers: {

  //      'Content-Type': 'application/x-www-form-urlencoded',

  //   },

  //   }).then((response) => response.json())
  //   .then((responseJson)=>{
  //     console.log(responseJson,'slides')
  //     var images = []
  //     for(var v=0;v<responseJson.length;v++){
  //       if((responseJson[v].image).length!==0){

  //         images.push(responseJson[v])
  //       }
  //     }
  //     this.setState({items:images, loading:false})

  //   }

  //   )
  //    .catch((error) => {
  //   this.setState({
  //     loading:false
  //   })
  //   alert("Warning!", "Check your network!", "warning");
  //   console.log(error)
  //     })
  //    })
  // }
  next = () => {
    if(this.animating) return
    const nextIndex =
      this.state.activeIndex === this.state.items.length - 1
        ? 0
        : this.stateactiveIndex + 1;
    this.setState({ nextIndex });
  };

  previous = () => {
    if(this.animating) return
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ nextIndex });
  };

  renderSlides() {
    return this.state.bannerImages.map((item, index) => {
      return (
        <CarouselItem
          key={index}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <img
            src={item.link}
            className="sliderImage_width"
            alt="slider_image"
          />
        </CarouselItem>
      );
    });
  }
  render() {
    const { activeIndex } = this.state;

    return (
      <Carousel
        activeIndex={activeIndex}
        autoPlay={true}
        next={this.next}
        previous={this.previous}
        ride={"carousel"}
      >
        <CarouselIndicators
          items={this.state.items}
          activeIndex={activeIndex}
          onClickHandler={this.goToIndex}
        />
        {this.renderSlides()}
      </Carousel>
    );
  }
}

export default SliderImage;


