import React, { Component } from "react";
import "./test.css";
import SidebarPage from "../Sidebar/sidebarPage";
import AdminNavbar from "../Navbar/adminNavbar";
import swal from "sweetalert";
import { css } from "@emotion/core";
import { MDBTable, MDBTableBody, MDBTableHead, MDBIcon } from "mdbreact";
// import { HalfCircleSpinner } from 'react-epic-spinners';
import GridLoader from "react-spinners/GridLoader";
import ActivePNG from "../../../Assets/images/activestatus.png";
import InactivePNG from "../../../Assets/images/inactivestatus.png";
import Swal from "sweetalert2";
const axios = require("axios").default;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 20% 45%;
`;

class getTest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      test_data: [],
      showAction: false,
      draft_count: 0,
      published_count: 0,
      test_dataSearch: [],
      search: false,
      searchTerm: "",

      viewTest: false,
      all_selected: true,
      test: "",
      draft_selected: false,
      published_selected: false,
      trash_selected: false,
      selected_data: [],
    };
  }
  componentWillMount = async () => {
    this.setState({ loading: true });
    let res = this.getTests();
  };

  componentDidMount() {
    window.localStorage.removeItem("subject_item");
  }

  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Novr",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd + "/" + mm + "/" + dt.getFullYear().toString();
  }

  commenttime(timestamp) {
    var convertdLocalTime = new Date(timestamp);
    var hours = convertdLocalTime.getHours();
    var minutes = convertdLocalTime.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  getTests = () => {
    this.setState({
      response: fetch("https://10x10learning.com/get_tests", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "xxx");
          let sort = responseJson.sort((a, b) =>
            a.test_name.localeCompare(b.test_name)
          );
          var draft_count = 0,
            published_count = 0,
            trash_count = 0;
          for (var v = 0; v < responseJson.length; v++) {
            responseJson[v].selected = 0;
            if (responseJson[v].status == 0) {
              draft_count = draft_count + 1;
            } else if (responseJson[v].status == 1) {
              published_count = published_count + 1;
            }
          }

          this.setState(
            {
              test_data: sort,
              draft_count: draft_count,
              published_count: published_count,
            },
            () => this.getSubjects()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  getSubjects = () => {
    fetch("https://10x10learning.com/get_subjects")
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.forEach((element) => {
          element.value = element.subject_id;
          element.label = element.subject_name;
        });

        console.log(responseJson, "JSON Respo");
        window.localStorage.setItem(
          "subject_list",
          JSON.stringify(responseJson)
        );
      });
    this.setState({ loading: false });
  };

  handleEditTest(item) {
    window.localStorage.setItem("test_item", JSON.stringify(item));
    let test = JSON.parse(localStorage.getItem("test_item"));
    this.setState({ test }, () => this.setState({ viewTest: true }));
    //    window.localStorage.setItem('test_list',JSON.stringify(this.state.test_data))

    // this.props.history.push('/limitedaccess/edit-test');
  }

  handleAddNewExam() {
    //   window.localStorage.setItem('test_list',JSON.stringify(this.state.test_data))
    this.props.history.push("/limitedaccess/add-test");
  }

  renderActions(item, index) {
    if (item.selected == 1) {
      if (this.state.trash_selected) {
        return (
          <div style={{ width: "15%" }} className="actions_div">
            {" "}
            <span
              onClick={() => this.restoreAlert(item)}
              className="actionstext"
            >
              Restore
            </span>
            <span
              onClick={() => this.deleteAlert(item)}
              className="actionstext"
              style={{ color: "#a00" }}
            >
              Delete
            </span>
          </div>
        );
      } else {
        return (
          <div className="actions_div">
            <span
              onClick={() => this.handleEditTest(item, index)}
              className="actionstext"
            >
              View
            </span>
          </div>
        );
      }
    } else {
      return (
        <div className="actions_div" style={{ visibility: "hidden" }}>
          <span className="actionstext">Edit</span>{" "}
        </div>
      );
    }
  }
  showactionbutton(item, index) {
    let { test_data } = this.state;
    let selected_page = test_data[index];
    for (var v = 0; v < test_data.length; v++) {
      test_data[v].selected = 0;
    }
    selected_page.selected = 1;
    this.setState({
      test_data,
    });
  }
  deleteTest = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        axios
          .post("https://10x10learning.com/delete-test", {
            id: id,
          })
          .then((response) => {
            console.log(response);
            window.location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
    console.log(id);
  };
  hideactionbutton() {
    let { test_data } = this.state;

    for (var v = 0; v < test_data.length; v++) {
      test_data[v].selected = 0;
    }

    this.setState({
      test_data,
    });
  }
  renderStatus(item) {
    if (item.status == 0) {
      return "Last Modified";
    } else {
      return "Published";
    }
  }

  changeStatus(item, status) {
    this.setState({
      loading: true,
    });
    let details = {
      test_id: item.test_id,
      status: status,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/edit_test", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          window.location.reload();
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }

  renderActiveStatus(item) {
    if (item.status == 1) {
      return (
        <img
          src={ActivePNG}
          alt="Course is active"
          className="course-status-icon"
          onClick={() => this.changeStatus(item, 0)}
        />
      );
    } else {
      return (
        <img
          src={InactivePNG}
          alt="Course is INactive"
          className="course-status-icon"
          onClick={() => this.changeStatus(item, 1)}
        />
      );
    }
  }

  renderTableData(item, index) {
    if (this.state.all_selected == true) {
      if (item.status != 2) {
        // console.log(this.state.all_selected,item.status,'bigE')
        return (
          <tr key={item.test_id}>
            {/* <td><input  onChange={()=>this.onToggle(index)} checked={item.checked==1 ? true : false} type='checkbox' /></td> */}
            <td style={{ width: "65%" }} className="tdName textFontSize">
              <div
                onMouseEnter={() => this.showactionbutton(item, index)}
                onMouseLeave={() => this.hideactionbutton()}
                className="name_div_table"
              >
                <span style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}>
                  {item.test_name}
                </span>
                {this.renderActions(item, index)}
              </div>
            </td>
            <td className="datefontsize">
              {this.renderStatus(item)} <br />{" "}
              <span className="tabledate">
                {this.commentdate(item.creation_time)}
              </span>
            </td>
            <td style={{ marginLeft: "30px" }}>
              <MDBIcon
                far
                icon="trash-alt"
                className="deleteTest"
                onClick={() => this.deleteTest(item.test_id)}
                style={{ color: "red" }}
              />
            </td>
            {/* <td>{this.renderActiveStatus(item)}</td> */}
          </tr>
        );
      }
    } else if (this.state.published_selected == true) {
      if (item.status == 1) {
        return (
          <tr key={item.test_id}>
            {/* <td><input className='checkbox_content' type='checkbox' /></td> */}
            <td style={{ width: "25%" }} className="tdName textFontSize">
              <div
                onMouseEnter={() => this.showactionbutton(item, index)}
                onMouseLeave={() => this.hideactionbutton()}
                className="name_div_table"
              >
                <span style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}>
                  {item.test_name}
                </span>
                {this.renderActions(item, index)}
              </div>
            </td>

            <td>{this.renderActiveStatus(item)}</td>
          </tr>
        );
      }
    } else if (this.state.draft_selected == true) {
      if (item.status == 0) {
        return (
          <tr key={item.test_id}>
            {/* <td><input className='checkbox_content' type='checkbox' /></td> */}
            <td style={{ width: "25%" }} className="tdName textFontSize">
              <div
                onMouseEnter={() => this.showactionbutton(item, index)}
                onMouseLeave={() => this.hideactionbutton()}
                className="name_div_table"
              >
                <span style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}>
                  {item.test_name}
                </span>
                {this.renderActions(item, index)}
              </div>
            </td>

            <td>{this.renderActiveStatus(item)}</td>
          </tr>
        );
      }
    }
  }

  search = (e) => {
    this.setState({ search: true, searchTerm: e.target.value }, () => {
      let filterArray = [];
      this.state.test_data.map((item) => {
        if (
          item.test_name
            .toLowerCase()
            .includes(this.state.searchTerm.toLowerCase())
        ) {
          filterArray.push(item);
        }
      });
      this.setState({ test_dataSearch: filterArray });
    });
  };

  onToggle(index, e) {
    console.log(index, "kjkk");
    let { test_data, selected_data } = this.state;
    let selected_item = test_data[index];
    if (selected_item.checked == 1) {
      selected_item.checked = 0;
      selected_data.splice(index, 1);
    } else {
      selected_item.checked = 1;
      selected_data.push(selected_item);
    }

    this.setState({ selected_data });
    this.setState({ test_data });
  }

  handleAllChecked = (event) => {
    let { test_data } = this.state;
    if (this.state.item_selected) {
      for (var v = 0; v < test_data.length; v++) {
        test_data[v].checked = 1;
      }
      this.setState({ selected_data: test_data });
    } else {
      for (var v = 0; v < test_data.length; v++) {
        test_data[v].checked = 0;
      }
      this.setState({ selected_data: [] });
    }
    this.setState({ test_data });
  };

  findSubjectRelatedToTest = (testID) => {
    let subjects = JSON.parse(localStorage.getItem("subject_list"));
    console.log(subjects);
    console.log(testID);
    if (subjects != undefined) {
      let sorted = subjects.filter((ele) => {
        console.log(ele.subject_id, testID);
        if (ele.subject_id == testID) {
          return ele;
        }
      });
      console.log(sorted[0].subject_name);

      return sorted[0].subject_name;
    }
  };
  findTopicName = async () => {
    this.setState({
      loading: true,
    });
    this.setState({
      response: fetch("https://10x10learning.com/get_topics", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          var draft_count = 0,
            published_count = 0,
            trash_count = 0;
          for (var v = 0; v < responseJson.length; v++) {
            responseJson[v].selected = 0;
            if (responseJson[v].status == 0) {
              draft_count = draft_count + 1;
            } else if (responseJson[v].status == 1) {
              published_count = published_count + 1;
            } else if (responseJson[v].status == 2) {
              trash_count = trash_count + 1;
            }
          }
          this.setState(
            {
              topics: responseJson,
              draft_count: draft_count,
              published_count: published_count,
              trash_count: trash_count,
              loading: false,
            },
            () => this.getLectures()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  showTopics = (config) => {
    console.log(config);
    let config1 = JSON.parse(config);
    return config1.map((item) => {
      return (
        <React.Fragment className="showtopic">
          <br />
          Topic ID:{item.topic_id} &nbsp;&nbsp;
          <span>
            Easy {item.easy}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Moderate{" "}
            {item.moderate}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Tough {item.tough}
          </span>
        </React.Fragment>
      );
    });
  };
  render() {
    if (this.state.loading) {
      return (
        <div className="sweet-loading">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div id="subject-page-list">
          <AdminNavbar {...this.props} />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <SidebarPage {...this.props} active={9} />

            <div
              className="test-list-page colDefault"
              style={{ width: "100%", backgroundColor: "#F9F9F9" }}
            >
              <div className="page_heading">
                <h3 style={{ marginBottom: 0, marginRight: "5px" }}>
                  All Test
                </h3>
                <span
                  onClick={() => this.handleAddNewExam()}
                  className="add_new_button"
                >
                  Add New
                </span>
              </div>
              <div>
                <input
                  type="text"
                  style={{
                    marginLeft: "20px",
                    marginTop: "20px",
                    width: "200px",
                  }}
                  placeholder="Search here..."
                  onChange={(e) => this.search(e)}
                />

                {/* <div className='tablenav'>
        <div className="alignleft actions bulkactions">
			<select name="action" id="bulk-action-selector-top">
<option value="-1">Bulk Actions</option>

	<option value="trash">Move to Trash</option>
</select>
<span onClick={()=>console.log(this.state.selected_data)} className='apply'>Apply</span>
		</div>
        </div> */}

                <MDBTable
                  striped
                  className="pages_table"
                  style={{ backgroundColor: "#F9F9F9" }}
                >
                  <tr style={{ backgroundColor: "white" }}>
                    {/* <th><input type="checkbox" onClick={(event)=> this.setState({item_selected:!this.state.item_selected},()=>this.handleAllChecked(event))}  checked={this.state.item_selected}  value="checkedall" /></th> */}
                    <th className="">Test Name</th>
                    <th className="textFontSize">Date</th>
                    <th>Delete Test </th>
                    {/* <th>Status</th> */}
                  </tr>

                  <MDBTableBody>
                    {this.state.search == false
                      ? this.state.test_data.map((item, index) => {
                          return this.renderTableData(item, index);
                        })
                      : this.state.test_dataSearch.map((item, index) => {
                          return this.renderTableData(item, index);
                        })}
                  </MDBTableBody>
                </MDBTable>
              </div>
              {this.state.viewTest == true ? (
                <div className="modalReport1" ref={this.setWrapperRef}>
                  <p className="headingModal">
                    <span
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        marginLeft: "10px",
                      }}
                    >
                      Configuration for "{this.state.test.test_name}"
                      <br /> Test type :&nbsp; {this.state.test.test_type}
                      <br />
                      Allocated to{" "}
                      {this.findSubjectRelatedToTest(
                        this.state.test.subject_id
                      )}
                      <br />
                      Duration :&nbsp; {this.state.test.duration} minutes
                      <br />
                      {this.showTopics(this.state.test.test_config)}
                    </span>
                    <span className="iconModal">
                      <MDBIcon
                        icon="times"
                        style={{ fontSize: "25px", marginTop: "2px" }}
                        onClick={() => this.setState({ viewTest: false })}
                        className="mdbIconCross"
                      />
                    </span>
                  </p>
                  <div></div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default getTest;

// <ul className="subsubsub">
// <li className="all">
//   <span
//     onClick={() =>
//       this.setState({
//         all_selected: true,
//         published_selected: false,
//         trash_selected: false,
//         draft_selected: false,
//       })
//     }
//     className={
//       this.state.all_selected
//         ? "text_Pub current"
//         : "text_Pub"
//     }
//     aria-current="page"
//   >
//     All{" "}
//     <span className="count">
//       ({this.state.draft_count + this.state.published_count})
//     </span>
//   </span>{" "}
//   |
// </li>
// <li className="publish">
//   <span
//     onClick={() =>
//       this.setState({
//         all_selected: false,
//         published_selected: true,
//         trash_selected: false,
//         draft_selected: false,
//       })
//     }
//     className={
//       this.state.published_selected
//         ? "text_Pub current"
//         : "text_Pub"
//     }
//   >
//     Active{" "}
//     <span className="count">
//       ({this.state.published_count})
//     </span>
//   </span>{" "}
//   |
// </li>
// <li className="draft">
//   <span
//     onClick={() =>
//       this.setState({
//         all_selected: false,
//         published_selected: false,
//         trash_selected: false,
//         draft_selected: true,
//       })
//     }
//     className={
//       this.state.draft_selected
//         ? "text_Pub current"
//         : "text_Pub"
//     }
//   >
//     In-Active{" "}
//     <span className="count">({this.state.draft_count})</span>
//   </span>{" "}
//   |
// </li>
// </ul>
