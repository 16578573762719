import React, { Component } from "react";

import styles from "./ProductService.module.css";
import "../commonStyles.css";

import MainFooterPage from "../../Reusable/Footer/footer";
import swal from "sweetalert";
import { MDBCollapse, MDBIcon, MDBBtn, MDBInput } from "mdbreact";
import CoreImg from "../../Assets/images/v1.PNG";

import ScrollAnimation from "react-animate-on-scroll";
import { MDBCloseIcon } from "mdbreact";
import HomeHeader from "../../Reusable/Header/productHeader";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import Login from "../../Reusable/Header/login";
import Slider from "react-slick";
import ProfTen from "../Prof10X10/prof10x10";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class ProductService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showContact: false,
      clicked: [],
      activeIndex: 0,
      collapseID: "",
      subjectstobedisplayed: [],
      expanded: false,
      shown: true,
      studentsEnrolled: "",
      subjectOrders: "",
      subjectSelected: "",
      ratings: "",
      avgRating: "",
      banner:'',
      // expanded1: false,
      shown1: true,
      expandedYes: false,
      expandedNo: false,
      userdata: null,
      cart_status: 0,
      showAdded: false,
      test_selected: true,
      course_purchase: 0,
      item_in_cart: false,
      subject_data: {},
      subjects: [],
      cart: [],
      all_items: [],
      test_cost_zero: false,
      course_selected: true,
      test_cost: 0,
      subjectSelected: "",

      //CourseContent
      CourseContentArray: {
        courseImg: `${CoreImg}`,
        heading: ``,
        subheading: ``,
        rating: "",
        creatorName: ``,
        lastUpdateEnglish: `Last Updated 8/2019`,
        lastUpdateHindi: ` आखरी अपडेट 8/2019`,
        language: `English,Hindi,Panjabi,Tamil`,
        learnHeading: `what you'll learn`,
        para: {},
        costAfterDiscount: ``,
        actualCost: `₹ 11000 `,

        instructorName: ``,
        lectureInTopic: "",
        instructorSub: ``,
        instructorDescription: ``,

        RequrmentList: [],
      },
      CourseInformation: [],
      descriptionCourse: [],
      studentsAlsobought: [],
      language_id: "",

      selected_subject: {},
      loginToggle: false,
      loading: true,

      email: "",
      phone: "",
      name: "",
      message: "",
    };
  }

  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Novr",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd + "/" + mm + "/" + dt.getFullYear().toString();
  }

  checkCart() {
    console.log("checking cart");
    if (this.state.cart.length != 0) {
      for (var v = 0; v < JSON.parse(this.state.cart[0].items).length; v++) {
        console.log(JSON.parse(this.state.cart[0].items)[v]);
        if (JSON.parse(this.state.cart[0].items)[v].type == "service") {
          if (
            JSON.parse(this.state.cart[0].items)[v].service_id ==
            this.state.subjectSelected.id
          ) {
            this.setState(
              {
                serviceInCart: true,
                // loading:false
              },
              () => console.log(this.state)
            );
          }
        }
      }
    }
    this.setState({ loading: false });
  }

  getCart = () => {
    this.getOrders();
    let details = {
      user_id: this.state.userdata.user_id,
    };

    console.log(details, "fetching-details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState(
            {
              cart: responseJson,
            },
            () => this.checkCart()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  componentDidMount = async () => {
    // let subject_id = JSON.parse(localStorage.getItem("selected_subject"));
    // console.log(language_id);
    let banner = JSON.parse(localStorage.getItem("productBannerImage"));
    this.setState({ banner: banner[0].link });
    let subjectSelected = JSON.parse(localStorage.getItem("selected_subject"));
    let service_name = this.props.match.params.service;
    let service = null;
    try {
      let formBody = new FormData();
      formBody.append("service_name", service_name);
      const serviceJson = await fetch(
        "https://10x10learning.com/get_subcategory",
        {
          method: "POST",
          body: formBody,
        }
      );
      const result = await serviceJson.json();
      console.log(result);
      if (result.service.length == 0) {
        this.props.history.push("/error");
      } else {
        service = result.service[0];
        let language_id = service.language_id;
        this.setState({ language_id, subjectSelected: service }, () =>
          this.getDialogues()
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  getDialogues = async () => {
    let formBody = new FormData()
    formBody.append('language_id', this.state.language_id)
    try {
      const dialogueResponse = await fetch(
        "https://10x10learning.com/prof10_products",{
          method:'POST',
          body:formBody
        }
      );
      const result = await dialogueResponse.json();
      const dialogues = JSON.parse(result[0].text).map(
        (item) => item.description
      );
      this.setState({ dialogues },() => this.getDetails());
    } catch (err) {
      console.log(err);
    }
  };

  getDetails = () => {
    var userdata = window.localStorage.getItem("userdata");
    console.log(this.state.service);

    if (userdata != null) {
      this.setState(
        {
          userdata: JSON.parse(userdata),
        },
        () => this.getCart()
      );
    } else {
      this.setState({
        serviceInCart: false,
        servicePurchased: false,
        loading: false,
      });
    }

    // if (this.state.subjectSelected == null) {
    //   this.props.history.push("/");
    // }
  };

  toggleCollapse(id) {
    if (this.state.collapseID === id) {
      this.setState({
        collapseID: 0,
      });
    } else {
      this.setState({
        collapseID: id,
      });
    }
  }

  renderDescription() {
    const { expanded } = this.state;

    return this.state.subjectSelected.description;
  }

  renderAddToCart() {
    console.log(this.state.loading, this.state.course_purchase);
    if (this.state.servicePurchased) {
      return (
        <React.Fragment>
          <div
            className={`${styles.productCourseContentPic6}`}
            style={{ marginTop: "2%", marginBottom: "2%" }}
          >
            <p className={`text-muted ${styles.alreadyPurchasedText}`}>
              {this.state.language_id == 0 ? (
                <React.Fragment>
                  Thank you for purchasing our service!
                </React.Fragment>
              ) : (
                <>आपने यह पाठ्यक्रम और इसकी परीक्षण श्रृंखला खरीदी है</>
              )}
            </p>
          </div>
        </React.Fragment>
      );
    } else if (this.state.serviceInCart) {
      return (
        <div
          className={`${styles.productCourseContentPic6}`}
          style={{ marginTop: "2%", marginBottom: "2%", height: "40px" }}
        >
          <button
            onClick={() => this.props.history.push("/my-cart")}
            className={`${styles.addCart}`}
            type="button"
          >
            {this.state.language_id == 0 ? (
              <React.Fragment>Go to Cart</React.Fragment>
            ) : (
              <React.Fragment>कार्ट में जाएँ</React.Fragment>
            )}
          </button>
        </div>
      );
    } else {
      return (
        <div
          className={`${styles.productCourseContentPic6}`}
          style={{ marginTop: "2%", marginBottom: "2%", height: "40px" }}
        >
          <button
            onClick={() => this.addToCart()}
            className={`${styles.addCart}`}
            type="button"
            style={{ fontSize: "18px" }}
          >
            {this.state.language_id == 0 ? (
              <React.Fragment>Add to Cart</React.Fragment>
            ) : (
              <React.Fragment>कार्ट में डालें</React.Fragment>
            )}
          </button>
        </div>
      );
    }
  }

  buyNow() {
    this.addToCart();
    if (this.state.userdata) this.props.history.push("/my-cart");
  }

  addToCart = async () => {
    if (this.state.userdata == null) {
      this.setState({ showLogin: true });
    } else {
      let details = {
        user_id: this.state.userdata.user_id,
        items: JSON.stringify([
          {
            service_id: this.state.subjectSelected.id,
            type: "service",
          },
        ]),
      };
      console.log(details, "fetching-details");

      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        loading: true,
        response: fetch("https://10x10learning.com/update_cart", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson, "update_cart");
            this.setState(
              {
                serviceInCart: true,
                cart_status: responseJson.status,
              },
              () => this.trigger()
            );
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            alert("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    }
  };

  selectiveTrigger() {
    this.trigger();
    if (this.state.userdata) this.props.history.push("/my-cart");
  }

  renderBuy() {
    if (!this.state.servicePurchased) {
      return (
        <div
          className={`${styles.productCourseContentPic7}`}
          style={{ marginTop: "2%", marginBottom: "2%" }}
        >
          <button
            onClick={() => this.buyNow()}
            className={`${styles.buyNow}`}
            type="button"
            onclick="alert('Hello world!')"
          >
            {this.state.language_id == 0 ? <>Buy Now</> : <>अभी खरीदें</>}
          </button>
        </div>
      );
    } else {
      return (
        <button
          className={`${styles.buyNow} d-none`}
          type="button"
          onclick="alert('Hello world!')"
        >
          Already Purchased
        </button>
      );
    }
  }

  renderPrice() {
    console.log(this.state.subjectSelected);
    const service = this.state.subjectSelected;
    if (service.old_cost == null) {
      return <p>Rs. {service.cost}</p>;
    } else {
      return (
        <div
          className={`${styles.productCourseContentPic2}`}
          style={{
            marginTop: "6%",
            display: "",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <text className={`${styles.productCourseContentPic2Text}`} style={{}}>
            Rs.
            {Number(this.state.subjectSelected.cost)}
            <span
              className={`${styles.productCourseContentPic2TextSpan}`}
              style={{}}
            >
              <strike>
                {" "}
                Rs.
                {Number(this.state.subjectSelected.old_cost)}
              </strike>{" "}
            </span>
            <span
              className={`${styles.productCourseContentPic2TextSpan}}`}
              style={{}}
            >
              {" "}
              {100 -
                Math.round(
                  (Number(this.state.subjectSelected.cost) /
                    Number(this.state.subjectSelected.old_cost)) *
                    100
                )}
              % off
            </span>{" "}
          </text>
        </div>
      );
    }
  }

  rendeCourseCard() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        key={this.state.subjectSelected.id}
      >
        <img
          src={this.state.subjectSelected.image}
          alt="10x10-logo"
          className={`${styles.logoHeight} ${styles.productCourseContentPic1}`}
        />

        {this.renderPrice()}

        {this.renderAddToCart()}

        {this.renderBuy()}
      </div>
    );
  }

  renderlearn() {
    let columns = [];

    JSON.parse(this.state.selected_subject.learning_topic).forEach(
      (item, key) => {
        columns.push(
          <div className={`${styles.productCourseContentText1}`}>
            <div className={`${styles.productCourseContentTextInner1}`}>
              <div>
                <i class="fas fa-check"></i>{" "}
              </div>

              <div style={{ marginLeft: "2%" }}>
                <text
                  className={
                    this.state.language_id == 0
                      ? "opensansRLine"
                      : "opensansRLine"
                  }
                >
                  {" "}
                  {item.topic}{" "}
                </text>
              </div>
            </div>
          </div>
        );
        if ((key + 1) % 2 === 0) {
          columns.push(<div className="w-100"></div>);
        }
      }
    );

    return (
      <div>
        <div className={`${styles.productCourseContentTextHeading}`}>
          <h1
            className={
              this.state.language_id == 0
                ? `${styles.productCourseContentTextHeadingH1} opensansB`
                : `${styles.productCourseContentTextHeadingH1} opensansR`
            }
          >
            {this.state.language_id == 0 ? (
              <>What will you learn</>
            ) : (
              <>आप क्या सीखेंगे</>
            )}
          </h1>
        </div>
        <div className="row row_mobile">
          <p
            className={
              this.state.language_id == 0 ? ` opensansB` : `opensansRLine`
            }
          >
            {columns}
          </p>
        </div>
      </div>
    );
  }

  rendeCourseContent() {
    console.log(this.state);
    return (
      <div key={this.state.subjectSelected.id}>
        <h2
          className={
            this.state.language_id == 0
              ? `${styles.productMainSecondTextH2} opensansB`
              : `${styles.productMainSecondTextH2} opensansB`
          }
        >
          {" "}
          {this.state.subjectSelected.service_name}{" "}
        </h2>
        <h5
          className={
            this.state.language_id == 0
              ? `${styles.productMainSecondTextH5} opensansB`
              : `${styles.productMainSecondTextH5} opensansB`
          }
        >
          {this.state.subjectSelected.subtitle}{" "}
        </h5>
      </div>
    );
  }

  trigger() {
    this.getCart();
    setTimeout(() => {
      this.setState({
        showAdded: false,
      });
    }, 2000);
  }

  showUpdateBar() {
    if (this.state.showAdded) {
      if (this.state.cart_status == 240) {
        return (
          <ScrollAnimation
            animateOnce={false}
            animateIn="slideInLeft"
            className={`slideInLeft ${styles.Toast2}`}
            animationOut="slideOutLeft"
            isVisible={true}
          >
            <div id="snackbar">Added to cart</div>
          </ScrollAnimation>
        );
      } else {
        return (
          <ScrollAnimation
            animateOnce={false}
            animateIn="slideInLeft"
            className={`slideInLeft ${styles.Toast2}`}
            animationOut="slideOutLeft"
            isVisible={true}
          >
            <div id="snackbar">Added to cart </div>
          </ScrollAnimation>
        );
      }
    }
  }

  getOrders = () => {
    //   this.setState({
    //     loading:true
    // })

    let details = {
      user_id: this.state.userdata.user_id,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      // loading: true,
      response: fetch("https://10x10learning.com/get_my_orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var items = [];
          for (var v = 0; v < responseJson.length; v++) {
            for (
              var v1 = 0;
              v1 < JSON.parse(responseJson[v].items).length;
              v1++
            ) {
              var data = JSON.parse(responseJson[v].items)[v1];
              data.creation_time = responseJson[v].ts;
              items.push(data);
            }
          }
          var data_to_send = items;

          this.checkDuplicates(data_to_send);
          //count = 0 NOT PURCHASED
          //count = 1 COURSE AND TEST PURCHASED
          //count = 2 ONLY COURSE PURCHASED
          //count = 3 ONLY TEST PURCHASED
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  checkDuplicates(originalArray) {
    console.log(originalArray);
    const servicePurchased =
      originalArray.find((item) => item.id == this.state.subjectSelected.id) !==
      undefined;
    if (servicePurchased) {
      this.setState({ servicePurchased: true });
    } else {
    }
  }

  removeDuplicates(originalArray, objKey) {
    var trimmedArray = [];
    var values = [];
    var value;

    for (var i = 0; i < originalArray.length; i++) {
      value = originalArray[i][objKey];

      if (values.indexOf(value) === -1) {
        trimmedArray.push(originalArray[i]);
        values.push(value);
      }
    }

    this.setState(
      {
        all_items: trimmedArray,
      },
      () => {
        var items = [];
        var count = 0;
        for (var v = 0; v < this.state.all_items.length; v++) {
          for (var v1 = 0; v1 < this.state.all_items.length; v1++) {
            if (
              this.state.all_items[v].subject_id ==
              this.state.selected_subject.subject_id
            ) {
              if (this.state.all_items[v].course_status == "active") {
                if (
                  this.state.all_items[v].subject_included == 0 &&
                  this.state.all_items[v].test_included == 1
                ) {
                  count = 3;
                } else if (
                  this.state.all_items[v].subject_included == 1 &&
                  this.state.all_items[v].test_included == 0
                ) {
                  count = 2;
                } else if (
                  this.state.all_items[v].subject_included == 1 &&
                  this.state.all_items[v].subject_included == 1
                ) {
                  count = 1;
                }
              }
            }
          }
        }
        if (count == 0) {
          this.setState({
            course_purchase: 2,
          });
        } else if (count == 1) {
          this.setState({
            course_purchase: 1,
            test_selected: true,
            course_selected: true,
          });
        } else if (count == 2) {
          this.setState({
            course_purchase: 3,
            test_selected: true,
            course_selected: false,
          });
        } else if (count === 3) {
          this.setState({
            course_purchase: 4,
            test_selected: false,
            course_selected: true,
          });
        }
      }
    );
  }

  validateEmail = (text) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      this.setState({ email: text, valid_email: false });
      return false;
    } else {
      this.setState({ email: text, valid_email: true });
    }
  };

  addContact = async () => {
    if (this.state.valid_email == false) {
      swal("Warning!", "Please enter valid Email Id", "warning");
    } else {
      if (
        this.state.email.length === 0 ||
        this.state.phone.length === 0 ||
        this.state.name.length === 0 ||
        this.state.message.length === 0
      ) {
        swal("Warning!", "all fields are mandatory", "warning");
      } else {
        this.setState({
          loading: true,
        });
        let details = {
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          message: this.state.message,
        };

        console.log(details, "add-details");
        let formBody = [];
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        this.setState({
          response: fetch("https://10x10learning.com/add_contact_query", {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Cache-Control": "max-age=31536000",
            },
            body: formBody,
          })
            .then((response) => response.json())
            .then((responseJson) => {
              console.log(responseJson);
              this.setState(
                {
                  name: "",
                  email: "",
                  message: "",
                  phone: "",
                },
                () =>
                  swal("Success!", "We will contact you shortly!", "success")
              );
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });

              console.log(error);
            }),
        });
      }
    }
  };

  openContact() {
    document.getElementById("myContact").style.width = "450px";
  }

  closeContact() {
    document.getElementById("myContact").style.width = "125px";
  }

  renderContactUs() {
    return (
      <div
        className={`${styles.contactUsContainer}`}
        id="myContact"
        style={{ width: "125px" }}
      >
        <div className={`${styles.contactUsButtonContainer}`}>
          <button
            className={`${styles.contactUsButton}`}
            onClick={
              this.state.showContact === true
                ? () => {
                    this.closeContact();
                    this.setState({ showContact: false });
                  }
                : () => {
                    this.openContact();
                    this.setState({ showContact: true });
                  }
            }
          >
            {" "}
            {this.state.language_id == 0 ? <>Contact Us</> : <>संपर्क करें</>}
          </button>
        </div>
        <div className={`${styles.contactUsForm}`}>
          <MDBCloseIcon
            className={`${styles.contactUsCloseBtn}`}
            onClick={this.closeContact}
          />
          <h2 className="Wcolor contact_header client_heading_font">
            {this.state.language_id == 0 ? (
              <React.Fragment>Get in Touch</React.Fragment>
            ) : (
              <>संपर्क में रहो</>
            )}
          </h2>
          <input
            placeholder={`${this.state.language_id == 0 ? "Name" : "नाम"}`}
            group
            type="text"
            validate
            className="contact_input_text px-2 py-1 border-rounded form mb-2"
            value={this.state.name}
            onChange={(text) => this.setState({ name: text.target.value })}
          />
          <input
            placeholder={`${this.state.language_id == 0 ? "Email" : "ईमेल"}`}
            group
            type="text"
            validate
            error="wrong"
            success="right"
            value={this.state.email}
            onChange={(e) => this.validateEmail(e.target.value)}
            className={`${styles.contactInputEmail}  shadow-none px-2 py-1 border-rounded form mb-2 bg-white`}
          />
          <input
            placeholder={`${this.state.language_id == 0 ? "Phone" : "फ़ोन"}`}
            group
            type="phone"
            validate
            className="contact_input_text px-2 py-1 border-rounded form mb-2"
            value={this.state.phone}
            // maxlength="10" pattern="\d{10}"
            onChange={(text) => this.setState({ phone: text.target.value })}
          />
          <textarea
            rows="3"
            placeholder={`${this.state.language_id == 0 ? "Message" : "संदेश"}`}
            className={`${styles.contactInputText} px-2 py-1 border-rounded form mb-2`}
            value={this.state.message}
            onChange={(text) => this.setState({ message: text.target.value })}
          />
          <button
            className="contact_button contact_send_btn "
            onClick={() => this.addContact()}
          >
            {this.state.language_id == 0 ? (
              <React.Fragment>Send</React.Fragment>
            ) : (
              <>प्रश्न भेजें</>
            )}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#c01825"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div
          id="product-page"
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <HomeHeader
            cart={
              this.state.cart.length == 0
                ? 0
                : JSON.parse(this.state.cart[0].items).length
            }
            {...this.props}
          />
          <div className={`${styles.productMain}`}>
            <div className={`${styles.productMainFirst}`}>
              <text className={`${styles.productMainFirstText}`}></text>
            </div>

            <div className={`${styles.productMainSecond}`} style={{ backgroundImage: `url(${this.state.banner})`,opacity:0.8 }}>
              <div className={`${styles.productMainSecondText}`}>
                {this.rendeCourseContent()}
              </div>
            </div>

            <div className={`${styles.productCourseContent}`}>
              <div className={`${styles.Description}`}>
                <h1
                  className={
                    this.state.language_id == 0
                      ? `${styles.DescriptionH1} opensansB`
                      : `${styles.DescriptionH1} opensansRR`
                  }
                >
                  {this.state.language_id == 0 ? (
                    <>About this Service</>
                  ) : (
                    <>इस सेवा के बारे में</>
                  )}
                </h1>
                <span
                  className={
                    this.state.language_id == 0
                      ? "opensansRLine aboutOtherService"
                      : "KrutiRLine aboutOtherService"
                  }
                >
                  {" "}
                  {this.renderDescription()}{" "}
                </span>

                <hr className={`${styles.productHR}`}></hr>
              </div>

              <div className={`${styles.productCourseContentPic}`}>
                {this.rendeCourseCard()}
              </div>
            </div>
          </div>
          <ProfTen
            dialogues={this.state.dialogues}
          />
          <div>
            <MainFooterPage {...this.props} />
          </div>

          {this.showUpdateBar()}

          {this.renderContactUs()}

          <Login showLogin={this.state.showLogin} {...this.props} />
        </div>
      );
    }
  }
}
export default ProductService;
