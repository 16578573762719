// import React from 'react';
import React, { Component } from "react";
import "../../Components/commonStyles.css";
import Prof10X10 from "../../Assets/images/10x10.gif";
import styles from "./login.module.css";

import ReCAPTCHA from "react-google-recaptcha";
// import { FulfillingBouncingCircleSpinner } from 'react-epic-spinners';
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import swal from "sweetalert";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBIcon,
  MDBInput,
  MDBTable,
  MDBTableBody,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import {Link} from 'react-router-dom'

import GoogleImg from "../../Assets/images/google.png";
import FbImg from "../../Assets/images/fb.png";
import publicIp from "public-ip";

const responseFacebook = (response) => {
  console.log(response);
};


class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      header_data: [],
      menu_data: [],
      clicked: [],
      activeIndex: 0,
      captcha_value:'',
      showlogin: false,
      forgetPassword: false,
      otp: false,
      email: "",
      password: "",
      language_id: "",
    };
  }
  componentDidMount() {
    console.log(this.state, "state");
  }

  componentWillReceiveProps(props) {
    console.log(props.showLogin);

    this.setState({ showlogin: props.showLogin });
  }

  componentWillMount() {
    let languageid = localStorage.getItem("language_id");
    if (!languageid) {
      languageid = 0
    }
    this.setState({ language_id: languageid });
  }

  handleOtp = () => {
    const { newPassword, ConfirmNewPassword } = this.state;
    // perform all neccassary validations
    if (newPassword !== ConfirmNewPassword) {
      swal("Warning!", "Password and confirm Password not Match!", "warning", {
        buttons: false,
        timer: 2000,
      });
    } else {
      this.otpSet();
    }
  };

  otpSet = () => {
    this.setState({
      loading: true,
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      email: this.state.email111,
      otp: this.state.otp,
      password: this.state.newPassword,
    };
    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/reset_password", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "Forgot");
          if (responseJson.status == 200) {
            this.setState({
              loading: false,
              otp: false,
              forgetPassword: false,
              login: true,

              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning", {
              buttons: false,
              timer: 2000,
            });
          } else {
            this.setState({
              loading: false,
              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning", {
              buttons: false,
              timer: 2000,
            });
            // this.props.history.push('/product')
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          swal("Warning!", "Check your network!", "warning", {
            buttons: false,
            timer: 2000,
          });

          console.log(error);
        }),
    });
  };

  forgotPassword = () => {
    this.setState({
      loading: true,
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      email: this.state.email11,
    };
    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/forgot_password", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "Forgot");
          if (responseJson.status == 200) {
            this.setState({
              loading: false,
              otp: true,
              forgetPassword: false,

              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning", {
              buttons: false,
              timer: 2000,
            });
          } else {
            this.setState({
              loading: false,
              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning", {
              buttons: false,
              timer: 2000,
            });
            // this.props.history.push('/product')
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          swal("Warning!", "Check your network!", "warning", {
            buttons: false,
            timer: 2000,
          });

          console.log(error);
        }),
    });
  };

  LoginForm = async (e) => {
    if(this.state.captcha_value==''){
      return swal("Warning!","Please fill the captcha before logging in.", "warning");
     }
    e.preventDefault()
    this.setState({
      loading: true,
    });
    let ipAddress = await publicIp.v4()
    let details = {
      // product_id:this.state.selected_products.product_id,
      ipAddress:ipAddress,
      email: this.state.email1,
      password: this.state.password1,
    };
    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/user_login", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "123");
          if (responseJson.status == 200) {
            const user = responseJson.user
            const user_token = responseJson.token
            window.localStorage.removeItem("userdata");
            window.localStorage.setItem(
              "userdata",
              JSON.stringify(user)
            );

            window.localStorage.setItem(
              "user_token",
              user_token
            );
            this.props.history.push("/");
            window.location.reload();
          } 
          else if (responseJson.status == 400) {
            swal("Warning!", "Please verify your email first !", "warning", {
              buttons: false,
              timer: 2000,
            });
          } else if (responseJson.status == 401) {
            swal("Warning!", "Incorrect Password !", "warning", {
              buttons: false,
              timer: 2000,
            });
          }
          else if (responseJson.status == 402) {
            swal("Warning!", "User does not exists !", "warning", {
              buttons: false,
              timer: 2000,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          swal("Warning!", "Check your network!", "warning", {
            buttons: false,
            timer: 2000,
          });

          console.log(error);
        }),
    });
  };

  toggle_edit11 = () => {
    this.setState({
      otp: !this.state.otp,
    });
  };

  renderModalOtp() {
    if (this.state.otp) {
      return (
        <MDBModal isOpen={this.state.otp} toggle={this.toggle_edit11}>
          <MDBModalHeader toggle={this.toggle_edit11}></MDBModalHeader>
          <MDBModalBody>
            <div className={`${styles.OneTimePasswordDiv} `}>
              <span className={`${styles.OneTimePasswordHeading} `}>
                {this.state.language_id == 0
                  ? `One Time Password`
                  : `एक बार इस्तेमाल किये जाने वाला पासवर्ड`}{" "}
              </span>{" "}
              <text className={`${styles.OtpSetPassword} `}>
                {this.state.language_id == 0
                  ? `Set New Password`
                  : `नया पासवर्ड सेट करें`}
              </text>
              <input
                placeholder="OTP"
                className={`${styles.OtpInput} opensansR `}
                type="text"
                onChange={(text) => this.setState({ otp: text.target.value })}
              />
              <input
                placeholder="Email or Phone"
                className={`${styles.OtpInput} opensansR`}
                type="Email"
                onChange={(text) =>
                  this.setState({ email111: text.target.value })
                }
              />
              <input
                placeholder="Enter New Password"
                className={`${styles.OtpInput} opensansR`}
                type="text"
                onChange={(text) =>
                  this.setState({ newPassword: text.target.value })
                }
              />
              <input
                placeholder="Enter Password Again"
                className={`${styles.OtpInput} opensansR`}
                type="text"
                onChange={(text) =>
                  this.setState({ ConfirmNewPassword: text.target.value })
                }
              />
              <button
                className={`${styles.OtpBtn}  `}
                type="button"
                onClick={() => this.handleOtp()}
              >
                {this.state.language_id == 0
                  ? `Forget Password`
                  : `पासवर्ड भूल गए`}
              </button>
            </div>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }

  toggle_edit1 = () => {
    this.setState({
      forgetPassword: !this.state.forgetPassword,
    });
  };

  renderModalForget() {
    if (this.state.forgetPassword) {
      return (
        <MDBModal isOpen={this.state.forgetPassword} toggle={this.toggle_edit1}>
          <MDBModalHeader toggle={this.toggle_edit1}></MDBModalHeader>
          <MDBModalBody>
            <div className={`${styles.ForgetPasswordDiv} `}>
              <span className={`${styles.ForgetPasswordText} `}>
                {this.state.language_id == 0
                  ? `Forget Password`
                  : `पासवर्ड भूल गए`}
              </span>

              <text className={`${styles.ForgetPasswordEnterMail} `}>
                {this.state.language_id == 0
                  ? `Please Enter Your Email`
                  : `अपना ईमेल दर्ज करें`}{" "}
              </text>

              <input
                className={`${styles.ForgetPasswordInput} opensansR`}
                placeholder={this.state.language_id == 0 ? "Email" : "ईमेल"}
                type="Email"
                onChange={(text) =>
                  this.setState({ email11: text.target.value })
                }
              />

              <button
                className={`${styles.ForgetPasswordLoginBtn}  `}
                type="button"
                onClick={() => this.forgotPassword()}
              >
                {this.state.language_id == 0
                  ? `Forget Password`
                  : `पासवर्ड भूल गए`}
              </button>
            </div>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }

  validateEmail = (text) => {
    var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      this.setState({ email: text, valid_email: false });
      return false;
    } else {
      this.setState({ email: text, valid_email: true });
    }
  };

  validateCompany = (text) => {
    var reg = /^[\w]+([-_\s]{1}[a-z0-9]+)*$/i;

    if (reg.test(text) === false) {
      this.setState({ company: text, valid_company: false });

      return false;
    } else {
      this.setState({ company: text, valid_company: true });
    }
  };
  onChange = (value) => {
    console.log("Captcha value:", value);
    this.setState({
      captcha_value: value,
    });
  };

  toggle_edit = () => {
    this.setState({
      showlogin: !this.state.showlogin,
    });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.LoginForm(e);
    }
  };

  
  responseGoogle = response => {
    console.log(response,'oooo')
   this.setState({
     social_user: {

      email:response.profileObj.email,
      name:response.profileObj.name,
     profile_pic:response.profileObj.imageUrl,
      type:this.state.type
    },
     type:2
   },()=>this.socialLogin())
 };
  responseFailureGoogle = response => {
    console.log(response, "oooo");
  };

  socialLogin = () => {
    this.setState({
      loading: true
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      email:this.state.social_user.email,
      name:this.state.social_user.name,
      profile_pic:this.state.social_user.profile_pic,
      type:this.state.type,
      status:1
    };
    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.10x10learning.com/social_login", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "123");
          if (responseJson.status == 200) {
            this.setState({
              loading: false
              // add_modal:false
            });
            window.localStorage.removeItem("userdata");
            window.localStorage.setItem(
              "userdata",
              JSON.stringify(responseJson)
            );
           window.location.reload()
          } else {
            this.setState({
              loading: false
              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning");
          }
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };

  renderModal() {
    if (this.state.showlogin) {
      return (
        <MDBModal isOpen={this.state.showlogin} toggle={this.toggle_edit}>
          <MDBModalHeader toggle={this.toggle_edit}></MDBModalHeader>
          <MDBModalBody>
            <div className={`${styles.MainLogin} d-flex col-12 px-0`}>
              <div className={`${styles.WelcomeLogin} col-lg-5 col-sm-12`}>
                <img className={`${styles.ProfImage}`} src={Prof10X10} />

                <div>
                  {" "}
                  <p>
                    {this.state.language_id == 0 ? `Welcome to` : `स्वागत है`}
                  </p>
                  <h2>
                    {" "}
                    <span className="opensansR">10x10</span>{" "}
                    {this.state.language_id == 0 ? `Learning` : `लर्निंग में`}{" "}
                    <span>&reg;</span>
                  </h2>
                  <hr className={`${styles.LogInContainerHr} `} />
                  {/* <h3>
                    {this.state.language_id == 0
                      ? `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been`
                      : `लोरम इप्सम केवल मुद्रण और टंकण उद्योग का डमी पाठ है। लोरम इप्सम रही है`}
                  </h3> */}
                </div>
              </div>

              <div className={`${styles.LoginMain} col-lg-7 col-sm-12 pt-3`}>
                <span className={`${styles.LoginMainHeading} px-3  `}>
                  {this.state.language_id == 0
                    ? `Login to Your `
                    : `लॉग इन करें अपना`}{" "}
                  <span className="opensansR">10x10</span>
                  {this.state.language_id == 0
                    ? ` Learning Account`
                    : `लर्निंग खाता`}
                  <hr className={`${styles.LoginMainHeadingHr} `} />
                </span>

                <FacebookLogin
                  appId="1409504552532102"
                  autoLoad={false}
                  fields="name,email,picture"
                  // onClick={componentClicked}
                  callback={responseFacebook}
                  render={(renderProps) => (
                    <button
                      className={`${styles.BtnFacebook} my-3 d-none`}
                      onClick={renderProps.onClick}
                    >
                      <img
                        src={FbImg}
                        alt="facebook-logo"
                        className={`${styles.LogoHeight} `}
                      />

                      <span className={`${styles.LoginText} `}>
                        {this.state.language_id == 0
                          ? `Continue with Facebook`
                          : `फेसबुक के साथ जारी रखें`}
                      </span>
                    </button>
                  )}
                />

                <GoogleLogin
                  clientId="190376538396-q3s6i13of5ecqc7s8cd22odutf6ct5q4.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <button
                      className={`${styles.BtnGoogle} my-3`}
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <img
                        src={GoogleImg}
                        alt="google-logo"
                        className={`${styles.LogoHeight} `}
                      />

                      <span className={`${styles.LoginText} `}>
                        {this.state.language_id == 0
                          ? `Continue with Google`
                          : `गूगल के साथ जारी रखें`}
                      </span>
                    </button>
                  )}
                  buttonText="Login"
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseFailureGoogle}
                  cookiePolicy={"single_host_origin"}
                  // onLogoutSuccess={logout}
                />

                <form
                  onSubmit={() => this.LoginForm()}
                  style={{ width: "100%" }}
                >
                  <input
                    placeholder={
                      this.state.language_id == 0
                        ? "Email"
                        : "ईमेल"
                    }
                    className={`${styles.LoginInputEmail} opensansR`}
                    type="Email"
                    onChange={(text) =>
                      this.setState({ email1: text.target.value })
                    }
                  />

                  <input
                    className={`${styles.LoginInputPassword} opensansR`}
                    placeholder={
                      this.state.language_id == 0 ? "Password" : "कुंजिका"
                    }
                    type="password"
                    onChange={(text) =>
                      this.setState({ password1: text.target.value })
                    }
                    onKeyDown={(e) => this.handleKeyDown(e)}
                  />
                  <div className="recaptcha">
                  <ReCAPTCHA
                    sitekey="6Lc0bLQZAAAAAKnOxQKvy3Y8GFi__tdtj6pcgnlp"
                    onChange={this.onChange}
                  />
                </div>
                  <div>
                    <button
                      className={`${styles.LoginBtn} `}
                      type="button"
                      onClick={(e) => this.LoginForm(e)}
                    >
                      {this.state.language_id == 0 ? `Login` : `लॉग इन करें`}
                    </button>

                    <p className={`${styles.ForgetPassword} `}>
                      <span>
                        <a
                          onClick={() =>
                            this.setState({
                              login: false,
                              forgetPassword: true,
                            })
                          }
                        >
                          <text>
                            {this.state.language_id == 0
                              ? `Forgot Password `
                              : `पासवर्ड भूल गए `}{" "}
                            <span className="opensansR">?</span>
                          </text>
                        </a>
                      </span>
                    </p>
                    <p className={`${styles.DontHaveAccount} `}>
                      {this.state.language_id == 0
                        ? `Don't have a account `
                        : `खाता नहीं है `}{" "}
                      <span className="opensansR">?</span>
                      <Link to="/sign-up">
                        <span className={`${styles.DontHaveAccountSignUp} `}>
                          {this.state.language_id == 0
                            ? ` Sign Up`
                            : ` साइन अप करें`}
                        </span>
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }

  render() {
    return (
      <div id="LoginComponent">
        <div
          className={this.state.language_id == 0 ? "opensansR" : "opensansR"}
        >
          {/* {() => this.setState({ login: true })} */}

          {this.renderModal()}
          {this.renderModalForget()}
          {this.renderModalOtp()}
        </div>
      </div>
    );
  }
}

export default Login;
