import React, {Component} from 'react'
import AdminNavbar from '../Navbar/adminNavbar'
import SidebarPage from '../../../Components/Admin/Sidebar/sidebarPage'
import '../AddSubject/addSubject.css'
import "../Lectures/Addlecture.css"
import Select from 'react-select';
import MediaComponent from "../MediaComponent/chooseMediaFile";
import {MDBNavbar,MDBBtn,MDBNavItem,MDBNavbarNav,MDBIcon,MDBModal,MDBModalBody,MDBModalFooter,MDBModalHeader,MDBInput} from 'mdbreact';
import Picky from 'react-picky';
import 'react-picky/dist/picky.css';
import swal from 'sweetalert'
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { css } from '@emotion/core';
import GridLoader from 'react-spinners/GridLoader';
import Fade from 'react-reveal'


// const topicsList=[];
// for(var i=1;i<=100;i++){
//     topicsList.push({id:i,name:`Topic ${i}`})
// }

const override = css`
display: block;
margin: 0 auto;
border-color: black;
margin:30% 45%;
`;


class Addfaculty extends Component {
    constructor(props){
        super(props);
        this.state={
            loadng:true,
            facultyName:"",
            profession:"",

            description:``,
            facultyThumbnail:``,
            facultyThumbnailUrl:``,
           

        }



    }



    componentWillMount(){
  
    //    this.setState({loading:true})
       
                }

    



    componentDidMount(){
    }

    //GETS , POSTS , FORM VALIDATION



 
   



    //ADD FORM

    addFaculty(){



            if(this.state.facultyName.length==0){
                swal("Check subject name!", "Please enter valid name", "error");
            }
           
           
            else if(!this.state.profession){
                swal("Check thumbnail field","Please enter profession!","error");
            }
            else if(!this.state.description){
                swal("Check Test ID field","Please enter description!","error");
            }
            else if(!this.state.facultyThumbnailUrl){
                swal("Check Thumbnail url","Please upload a thumbnail!","error");
            }
          

            else{



                this.setState({
                loading:true
                // add_modal:false
                })




console.log(this.state.facultyThumbnailUrl)
                  let formData = new FormData()
                  formData.append('faculty_name',this.state.facultyName)
                  formData.append('faculty_profession',this.state.profession)
                  formData.append('faculty_description',this.state.description)
                  formData.append('faculty_picurl',this.state.facultyThumbnailUrl)
    // console.log(this.state.facultyName,this.state.facultyThumbnail,this.state.profession,this.state.description)
                    this.setState({
                    response: fetch('https://10x10learning.com/add_our_faculty', {
                    method: 'POST',
                    body: formData
                    }).then((response) => response.json())
                    .then((responseJson)=>{
                    console.log(responseJson,'QWERTy')
                    swal("Success", "Subject succesfullly added", "success").then((value)=>{
                    this.props.history.push('/limitedaccess/add-faculty')
                    });

                    })
                    .catch((error) => {
                    this.setState({
                    loading:false
                    })
                    swal("Warning!", "Check your network!", "warning")
                     console.log(error)
                        })
                    })
                 }
        
                }








    //GET FUNCTIONS




    //ITEM RENDERER DRAG DROP
  
   //HANDLE CLICK EVENTS
  



   

   

   
  


   



  




    //HANDLE CHANGE FUNCTIONS

    handleFacultyNameChange=(text)=> {
        this.setState({facultyName:text.target.value})
        }

    handleProfessionChange=(text)=> {
        this.setState({profession:text.target.value})
        }
        handleFacultyDescription=(text)=>{
        this.setState({description:text.target.value})
        }

   
    

  


    //RENDER FUNCTIONS
    renderFacultyNameSection(){
        return(
            <div className="col-12  subject-name-section d-flex">
                 <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
                      Faculty Name:
                 </label>
                 <input
                    
                    value={this.state.facultyName}
                        type="text"
                        id="defaultFormRegisterNameEx"
                        className="form-control subject-name-form custom-form"
                        onChange={(text)=>this.handleFacultyNameChange(text)}
                    />
            </div>
        )
    }

    renderFacultyProfessionSection(){
        return(
            <div className="col-12  subtitle-section d-flex">
                 <label htmlFor="defaultFormRegisterNameEx" className="subject-subtitle-label subject-labels">
                      Profession:
                 </label>
                 <input
                    
                    value={this.state.profession}
                        type="text"
                        id="defaultFormRegisterNameEx"
                        className="form-control subject-subtitle-form custom-form"
                        onChange={(text)=>this.handleProfessionChange(text)}
                    />
            </div>
        )
    }

  
    



    renderFacultyThumbSection(){

           if(!this.state.subjectThumbnail){
                return(
                <div className="col-12 d-flex align-items-center subject-thumbnail-container">
                <label htmlFor="defaultFormRegisterNameEx" className="image-label subject-labels">
                 Faculty Thumbnail Url: <br /> <span className="text-right text-muted ml-auto"> <i>(280 x 200 pixels)</i></span>
                </label>
                <input
                    type="text"
                    className="thumbnail-file-input d-block"
                    accept="image/*"
                    aria-describedby="inputGroupFileAddon01"
                    onChange={(text)=>this.setState({facultyThumbnailUrl:text.target.value})}

                />

                </div>)
            }
            


          else{
              return(
                <div className='col-12 d-flex align-items-center subject-thumbnail-container'>
                    <label htmlFor="defaultFormRegisterNameEx" className="image-label subject-labels h-100">
                        Faculty Thumbnail:
                    </label>
                    <div className="position-relative">
                       <img src={this.state.facultyThumbnailUrl} style={{margin:'10px 0px',width:"300px"}}/>
                     <MDBIcon style={{position: "absolute",
    top: "20px",right: "-5px", cursor:"pointer"}} className='cross_icon' onClick={()=>this.setState({facultyThumbnail:null, facultyThumbnailUrl:null})} icon="times-circle" / >
                     </div>
                  </div>
                  )
          }
    }
    

  
       renderFacultyDescriptionContainer(){
        return(
            <React.Fragment>
       <div className="col-12 subject-description-container  d-flex align-items-center">
       <label htmlFor="defaultFormRegisterNameEx" className="subject-labels h-100">
                        Faculty Description:
          </label>
          <div className="descriptions-container">
          {this.renderFacultyDescriptions()}
          </div>
        </div>
        </React.Fragment>)
    }
 

 renderFacultyDescriptions(){
          return ( <React.Fragment>
                      <div className="d-flex align-items-start mt-3">
                   <div className="position-relative w-100 ml-3 mr-4">
                   <textarea style={{marginLeft:'5px',marginRight:'5px'}}
                   label="subject-description"
                   className="subject-description-textarea w-100"
                   type="text"
                   onChange={(text)=>this.handleFacultyDescription(text)}
                     />
                    </div>
                    </div>
                    <div className="w-100" />
                    </React.Fragment>
                )
        
    }

    render(){
        return(
            <div id="add-subject-page">
            <AdminNavbar {...this.props} />

            <div className="d-flex">
              <SidebarPage {...this.props} active={1}/>

              <div className="add-subject-column">
               {this.state.loading?
                     (
                    <div className='sweet-loading'>
                        <GridLoader
                        css={override}
                        sizeUnit={"px"}
                        size={5}
                        color={'#c01825'}
                        loading={this.state.loading}
                        />
                        </div>
                    )
                    :
                    (
                    <React.Fragment>

                    <div className="row my-3 px-4">
                      <div className="col-12 top_button_bar d-flex">
                    <button className="admin-button-style admin-button-style-margin" onClick={()=>this.props.history.push('/limitedaccess/lecture')}>
                        All Lectures
                    </button>
                    <MediaComponent onSelectMediaItem={this.handleMediaChooseImage} buttonName={<MDBIcon icon="camera" /> } restriction= 'image/*' /> 
                    <button className="admin-button-style ml-auto" onClick={()=>this.addFaculty()}> Save &amp; Publish</button>

                    </div>
                    <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                    <h3> Add Faculty</h3> 
                    </div>
                    </div>
                <div className="d-flex">
                <div className="subject-content-container ml-4">
                <div className="row px-0 mx-0 ">
                    {this.renderFacultyNameSection()}
                    {this.renderFacultyProfessionSection()}
                    {this.renderFacultyDescriptionContainer()}
                    {this.renderFacultyThumbSection()}
                </div>
                </div>
              
                </div>

                

                <hr />

                    
                    
                    </React.Fragment>
                   )
                }
              </div>


            </div>
        </div>
        )
    }
}


export default Addfaculty;