import React from "react";
import "../../Components/commonStyles.css";
import styles from "./mobileHeader.module.css";
import MobileLogo from "../../Assets/images/Logo.png";

import WishlistPNG from "../../Assets/images/wishlist.png";
import NotifPNG from "../../Assets/images/notification.png";
import CartPNG from "../../Assets/images/cart.png";
import UserPNG from "../../Assets/images/dp1.png";
import TogglePNG from "../../Assets/images/Toggle2.png";
import LeftArrow from "../../Assets/images/left-arrow.png";
import RightArrow from "../../Assets/images/buttons-arrow.png";
import "../../Components/commonStyles.css";
import ExamPNG from "../../Assets/images/exams-toggle.png";
import SearchPNG from "../../Assets/images/search.png";
import PinterestLogo from "../../Assets/images/pinterest-logo.png";
import swal from "sweetalert";
import Login from "./login";
import "../../index.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import StarRatingComponent from "react-star-rating-component";
import GridLoader from "react-spinners/GridLoader";
import { Button, ButtonToolbar, Dropdown } from "react-bootstrap";
import { css } from "@emotion/core";
import Fade from "react-reveal";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBIcon,
  MDBInput,
  MDBTable,
  MDBTableBody,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter
} from "mdbreact";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import GoogleImg from "../../Assets/images/google.png";
import FbImg from "../../Assets/images/facebook.png";
import { matchPath } from "react-router-dom";
const responseFacebook = response => {
  // console.log(response);
};
const responseGoogle = response => {
  // console.log(response);
};

class LessonMobileHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: false,
      collapseID: "",
      buttons: [
        {
          name: "My Account",
          url: "/my-account"
        },
        {
          name: "My Cart",
          url: "/my-cart"
        },
        {
          name: "My Courses",
          url: "/my-courses"
        },
        {
          name: "Order History",
          url: "/purchase-history"
        },
        {
          name: "Payment Options",
          url: "/my-account"
        }
      ],

      // About us
      // Exams
      // Classes
      // Other Services
      // Commerce
      // Contact Us

      menuItems: [
        {
          itemName: "Home"
        },
        {
          itemName: "Practice"
        },

        {
          itemName: "Classes",
          subItems: true
        },
        // {
        //   itemName: "Other Services",
        //   url:"/other-services"
        // },
        {
          itemName: "About-Us",
          url: "/about-us"
        },
        {
          itemName: "Contact Us"
        }
      ],

      activeButton: props.active,
      userImage: `${UserPNG}`,
      userName: `Priyanka Singh`,
      userEmail: `priyankasingh@gmail.com`,
      viewForm: "",
      userdata: null,
      login: props.showLogin,
      forgetPassword: false,
      otp: false,
      email: "",
      password: "",
      loading: false,
      examsMenu: [],
      verticalsMenu: [],
      showSubmenu: false,
      ratingChecked: null,
      ratingReview: false,
      cart:'',
      userdata:''
    };
  }
  getCart =()=>{
    if(this.state.userdata!=null){
  
      this.getCartApi()
    }
  
  
  
  }
  
  
    getCartApi=()=>{
    if(this.state.userdata!=null){
  
      this.getCartApi()
    }
  
  
  
  }
  
  
    getCartApi=()=>{    
    let details = {
      user_id:this.state.userdata.user_id,
      };
 
    //  console.log(details,'fetching-details')
     let formBody = [];
     for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
     }
     formBody = formBody.join("&");
  
  this.setState({
  response: fetch('https://10x10learning.com/get_cart', {
  method: 'POST',
  headers: {
   
    'Content-Type': 'application/x-www-form-urlencoded',
    'Cache-Control': 'max-age=31536000'
  },
  body:formBody
  
  
  
  }).then((response) => response.json())
  .then((responseJson)=>{
  // console.log(responseJson.item,'neo')
    
  this.setState({
  cart:JSON.parse(responseJson[0].items).length,
  
  
  },()=>console.log(this.state.cart,'neo'))
 }
)
  .catch((error) => {
  this.setState({
   loading:false
  })
  
  console.log(error)
   })
  })
  
  }


  componentDidMount() {
    this.setState({ viewForm: "My Account" });
    this.getExams();
    this.getVerticals();
    this.checkRating();
  }

  checkRating(){
    let selectedSubject=JSON.parse(localStorage.getItem('selected_course_user'))
    console.log(selectedSubject)
    let user=JSON.parse(localStorage.getItem('userdata'))
    let details = {};

  
    details = {
      // product_id:this.state.selected_products.product_id,

      subject_id:selectedSubject.id,
      user_id:user.user_id,

    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    console.log(details)

    this.setState({
      response: fetch("https://10x10learning.com/check_ratings", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "123");
            this.setState({
              loading: false,
              ratingChecked:responseJson[0]
              // add_modal:false
            },()=>console.log(this.state.ratingChecked,'efghernenrenrenernernernernenernre'))
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  }

  componentWillReceiveProps({ showLogin }) {
    this.setState({ ...this.state, login: showLogin });
  }

  getVerticals = () => {
    this.setState({
      loading: true
    });
    this.setState({
      response: fetch("https://10x10learning.com/get_verticals", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          // console.log(responseJson, "verticals");
          this.setState({
            verticalsMenu: responseJson.reverse(),
            loading: false
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });

          // console.log(error);
        })
    });
  };

  getExams = async () => {
    // console.log("fetching exams");
    this.setState({
      response: fetch("https://10x10learning.com/get_exams", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          // console.log("Ëxams", responseJson);
          // console.log(JSON.parse(responseJson[0].verticals));
          this.setState({
            examsMenu: responseJson.reverse()
          });
        })
        .catch(error => {
          console.log(error);
        })
    });
  };

  openHeaderMobileNav() {
    document.getElementById("header-mobile-nav").style.width = "100%";
    // console.log('dsd')
  }

  closeHeaderMobileNav() {
    document.getElementById("header-mobile-nav").style.width = "0";
  }

  setActiveButton(data) {
    this.setState({ activeButton: data.name }, () =>
      this.props.history.push(data.url)
    );
  }

  getSubjects = async () => {
    this.setState({
      loading: true
    });
    this.setState({
      response: fetch("https://10x10learning.com/get_subjects", {
        method: "GET"
      })
        .then(response => response.json())
        .then(responseJson => {
          this.setState({
            subjects: responseJson,

            loading: false
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };
  getDetails() {
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState(
        {
          userdata: JSON.parse(userdata)
        }
        // () => console.log(this.state.userdata, "uuuu")
      );
    }
  }
  componentWillMount() {

    let languageid = localStorage.getItem("language_id");
    var userdata = window.localStorage.getItem("userdata");
    if (languageid == null) {
      localStorage.setItem("language_id", 0);
    }

    this.setState({language_id:languageid,userdata: JSON.parse(userdata)},()=>this.getCart())



    this.getDetails();
    this.getSubjects();
  }

  renderSubjectsInExams = (item, exam_id) => {
    let subjectExams = JSON.parse(item.exams);
    let exam_ids = [];
    subjectExams.forEach(element => {
      exam_ids.push(element.exam_id);
    });
    if (exam_ids.includes(exam_id)) {
      return (
        <li
          className="border-bottom pb-2 w-75 pt-2 ml-3"
          onClick={() => this.setSubject(item)}
        >
          {item.subject_name}
        </li>
      );
    }
  };
  setSubject = item => {
    // console.log(item)
    window.localStorage.removeItem("selected_subject");
    window.localStorage.setItem("selected_subject", JSON.stringify(item));
    if (matchPath(this.props.location.pathname, "/product-details")) {
      document.location.reload();
    } else {
      this.props.history.push("/product-details");
    }
  };
  selectVertical(item, index) {
    window.localStorage.removeItem("selected_vertical");
    window.localStorage.removeItem("collapse");
    window.localStorage.setItem("selected_vertical", JSON.stringify(item));

    if (matchPath(this.props.location.pathname, "/dashboard")) {
      document.location.reload();
    } else {
      this.props.history.push("/dashboard");
    }
  }

  handleClick(index, e) {
    let clicked = this.state.clicked;
    clicked[index] = !clicked[index];
    this.setState({ clicked: clicked });
  }
  handleOutsideClick(event) {
    if (!this.refs.megaMenu.contains(event.target)) {
      this.setState({
        clicked: []
      });
    }
  }

  getDetails() {
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState({
        userdata: JSON.parse(userdata)
      });
    }
  }
 
  logout() {
    window.localStorage.removeItem("userdata");
    window.localStorage.removeItem("user_token");
    this.props.history.push("/");
    window.location.reload();
  }

  renderProfilePic2() {
    if (this.state.userdata.profile_pic == null) {
      return (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img
          className={`${styles.Avatar} mr-2 ${styles.NavBarDP}`}
          src={
            this.state.userdata.gender == "Male"
              ? require("../../Assets/images/male.png")
              : require("../../Assets/images/female.png")
          }
        />
      );
    } else {
      return (
        <img
          className={`${styles.Avatar} mr-2 ${styles.NavBarDP}`}
          src={this.state.userdata.profile_pic}
        />
      );
    }
  }

  renderModals() {
    return this.state.verticalsMenu.map((modal, index) => {
      if (modal.status == 1) {
        return (
          <div onClick={() => this.selectVertical(modal, index)} key={index}>
            <p className={`${styles.MobileSidebarExams}`}>
              {modal.vertical_name}
            </p>
          </div>
        );
      }
    });
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  };

  renderMobileMenuitems() {
    return this.state.menuItems.map((item, index) => {
      return !item.subItems ? (
        <Button
          variant=""
          key={index}
          className={`${styles.MobileMdbBtn}`}
          onClick={() => this.props.history.push(item.url)}
        >
          {item.itemName}
        </Button>
      ) : (
        <React.Fragment>
          <MDBBtn
            color="#"
            className={`${styles.MobileMdbBtn}`}
            onClick={this.toggleCollapse("basicCollapse")}
          >
            <span> {item.itemName}</span>{" "}
            <span className={`${styles.MobileMdbIcon}`}>{item.icon}</span>
          </MDBBtn>
          <MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
            <p>{this.renderModals()}</p>
          </MDBCollapse>
        </React.Fragment>
      );
    });
  }

  renderLogOut() {
    if (this.state.userdata == null) {
      return (
        <div>
          <p className={`${styles.ProfileMenuList} mt-2`}>Help</p>
          <div className={`${styles.HeaderSocialMediaDiv}`}>
            {/* <button className={`${styles.HeaderSocialMedia}`}>
              {" "}
              <MDBIcon fab icon="twitter" />{" "}
            </button> */}
            <button className={`${styles.HeaderSocialMedia}`}>
              <MDBIcon fab icon="facebook-f" />
            </button>
            <button className={`${styles.HeaderSocialMedia}`}>
              <MDBIcon fab icon="youtube" />
            </button>
            <button className={`${styles.HeaderSocialMedia}`}>
              <MDBIcon fab icon="linkedin-in" />
            </button>
            {/* <button className={`${styles.HeaderSocialMedia}`}>
              <MDBIcon fab icon="pinterest-p" />
            </button> */}
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-100">
          <div className={`${styles.LogOutHelpDiv}`}>
            <p
              className={`${styles.ProfileMenuList}`}
              onClick={() => this.logout()}
            >
              Log Out
            </p>
            <p className={`${styles.ProfileMenuList} mt-2`}>Help</p>
          </div>

          <div className={`${styles.HeaderSocialMediaDiv}`}>
            <button className={`${styles.HeaderSocialMedia}`}>
              {" "}
              <MDBIcon fab icon="twitter" />{" "}
            </button>
            <button className={`${styles.HeaderSocialMedia}`}>
              <MDBIcon fab icon="facebook-f" />
            </button>
            <button className={`${styles.HeaderSocialMedia}`}>
              <MDBIcon fab icon="youtube" />
            </button>
            <button className={`${styles.HeaderSocialMedia}`}>
              <MDBIcon fab icon="linkedin-in" />
            </button>
            <button className={`${styles.HeaderSocialMedia}`}>
              <MDBIcon fab icon="pinterest-p" />
            </button>
          </div>
        </div>
      );
    }
  }

  renderMenu1() {
    if (this.state.userdata == null) {
      return (
        <div className={`${styles.ButtonMobileSideBar}`}>
          <button
            onClick={() => this.setState({ showLogin: true })}
            className={`${styles.ButtonMobileLogIn}`}
          >
            Log In
          </button>
          <button
            onClick={() => this.props.history.push("/sign-up")}
            className={`${styles.ButtonMobileRegister}`}
          >
            Register
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <div className={`${styles.EmailTextProfilePicDiv}`}>
            <div>{this.renderProfilePic2()} </div>
            <div className="d-flex flex-column">
              <div>
                <text className={`${styles.UserName}`}>
                  {this.state.userdata.name}
                </text>
              </div>
              <div>
                <text className={`${styles.UserMailId}`}>
                  {this.state.userdata.email}
                </text>
              </div>
            </div>
          </div>
          <p
            onClick={() => this.props.history.push("/my-account")}
            className={`${styles.ProfileMenuList}`}
          >
            My Account
          </p>
          <p
            onClick={() => this.props.history.push("/my-courses")}
            className={`${styles.ProfileMenuList}`}
          >
            My Courses
          </p>
          <p
            onClick={() => this.props.history.push("/purchase-history")}
            className={`${styles.ProfileMenuList}`}
          >
            Purchase History
          </p>
        

          <hr className={`${styles.HrTag}`}></hr>
        </div>
      );
    }
  }
  toggle_edit_rating = () => {
    this.setState({
      ratingReview: !this.state.ratingReview
    });
  };

  onStarClick(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  }

  renderModalRating() {
    const { rating } = this.state;
    const ratingChanged = newRating => {
      console.log(newRating, "newRating");
    };

    if (this.state.ratingReview) {
      return (
        <MDBModal
          isOpen={this.state.ratingReview}
          toggle={this.toggle_edit_rating}
        >
          <MDBModalHeader toggle={this.toggle_edit_rating}>
            <span>How would you rate this course?</span>{" "}
          </MDBModalHeader>
          <MDBModalBody>
            <div className="d-flex flex-column  align-items-center ">
              <div>
                <text className={`${styles.SelectRating}`}>Select Rating</text>
              </div>
              <div>
                <StarRatingComponent
                  className={`${styles.Star}`}
                  name="rate1"
                  starCount={5}
                  value={rating}
                  onStarClick={this.onStarClick.bind(this)}
                />
              </div>
              <div>
                <MDBInput
                  type="textarea"
                  label="Enter your Review"
                  outline
                  size="lg"
                  onChange={text =>
                    this.setState({ review: text.target.value })
                  }
                />
              </div>

              <div className="ratingChangedButtonDiv">
                <button
                  onClick={() =>
                    this.setState({ ratingReview: false }, () =>
                      this.submitRatingReview()
                    )
                  }
                >
                  Submit
                </button>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }

  renderMobileCartAndFav() {
    if (this.state.userdata == null) {
    } else {
      return (
        <div className={`${styles.MobileHeaderIconsContainer}`}>
          {this.state.ratingChecked == null ? (
            <div className={`${styles.HeaderIconsDiv}  mr-2`}>
{/*         
              <a
                onClick={() => this.setState({ ratingReview: true })}
                style={{ fontSize: "16px" }}
              >
                <MDBIcon far icon="star" />
              </a> */}
            </div>
          ) : ""
          }

          <div>
            {" "}
            <CircularProgressbar
              value={this.props.progress}
              // text={`${this.props.progress}%`}
            />
          </div>

          <div>
            <MDBIcon icon="bell" className={`${styles.HeaderIcons} d-none`} />

            {/* <img
              
              src={NotifPNG}
              className={`${styles.HeaderIcons}`}
            /> */}
          </div>

          <div onClick={() => this.props.history.push("/my-cart")}>
            <MDBIcon icon="shopping-cart" className={`${styles.HeaderIcons}`} />

            {/* <img
          
          className={`${styles.HeaderIcons}`}
              src={CartPNG}
            /> */}
            <span aria-label="2 items" class="cart-badge">
            {this.state.cart!=0? this.state.cart:''}

            </span>
          </div>
        </div>
      );
    }
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    //   if(this.state.loading){
    //     return(
    //     <div >

    //     </div>)
    // }
    // else{
    return (
      <React.Fragment>
        <div className={`${styles.MobileHeader}  `}>
          <div className="row align-items-center mx-0 py-2 border-bottom h-100">
            <div className="col-1 pl-0 ml-2">
              <button
                className="menu-toggle bg-transparent"
                style={{ border: "none" }}
                onClick={() => this.openHeaderMobileNav()}
              >
                <img src={TogglePNG} className={`${styles.TogglePNG} `} />
              </button>
            </div>
            <div className="col-3">
              <img
                src={MobileLogo}
                className={`${styles.NewHeaderLogo} `}
                onClick={() => this.props.history.push("/")}
              />
            </div>
            <div
              className="col-7 px-0 "
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {this.renderMobileCartAndFav()}
            </div>
          </div>
          <hr className={`${styles.HrTagX}`}></hr>
          <hr className={`${styles.HrTagY}`}></hr>
        </div>

        <div id="header-mobile-nav">
          <div>{this.renderMenu1()}</div>
          <button
            className={`${styles.CloseMobMenuButton} `}
            onClick={() => this.closeHeaderMobileNav()}
          >
            <MDBIcon icon="times" />

            {/* <MDBIcon icon="arrow-left" /> */}
            {/* <img src={LeftArrow} alt="Close Account Menu" /> */}
          </button>

          <ButtonToolbar>
            {this.renderMobileMenuitems()}
            {this.renderLogOut()}
          </ButtonToolbar>
        </div>
        <Login showLogin={this.state.showLogin} {...this.props} />

        {this.renderModalRating()}
      </React.Fragment>
    );
  }
}

export default LessonMobileHeader;
